import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  clearExamplannerRadiologistId,
  setExamplannerRadiologistId,
} from "../../Redux/actions";
import { loadUsers } from "../../Utilities/Users/Users";
import { useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { useEffect } from "react";
import CheckBox from "../CheckBox/CheckBox";
import { useTranslation } from "react-i18next";

function FindingDoctor({ shown, closeHandler }) {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const [usersData, setUsersData] = useState(undefined);
  const radiologistSelected = useSelector(
    (state) => state.examPlanner.risData.radiologistId
  );
  const { t } = useTranslation(["examplanner", "common"]);

  const clickRow = (id) => {
    if (id === radiologistSelected) {
      dispatch(clearExamplannerRadiologistId());
      return;
    }
    dispatch(setExamplannerRadiologistId(id));
    closeHandler();
  };

  const loadData = () => {
    loadUsers(keycloak, { field: "diktieren", op: "==", value: 1 })
      .then((data) => {
        setUsersData(data?.users);
      })
      .catch((reason) => {
        console.warn(reason);
        NotificationManager.error("Unable to load diagnostic doctor");
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Modal show={shown} onHide={closeHandler} size="lg">
      <Modal.Header closeButton className="p-2">
        <h5>{t("buttons.selectRefPhy")}</h5>
      </Modal.Header>
      <Modal.Body>
        {usersData === undefined ? (
          <div>loading ...</div>
        ) : usersData.length > 0 ? (
          <div className="d-flex flex-column w-100">
            {usersData?.map((user) => (
              <div
                className="d-flex border rounded p-1 align-items-center mb-1"
                style={{ gap: "10px" }}
                key={user.id}
              >
                <CheckBox
                  onClick={() => {
                    clickRow(user.id);
                  }}
                  selected={user.id === radiologistSelected}
                />
                <div>{user.name}</div>
                <div>{user.vorname}</div>
              </div>
            ))}
          </div>
        ) : (
          <div>{t("alert.noDoctorsAvail")}</div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default FindingDoctor;
