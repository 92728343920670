import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addExamplannerIndication,
  removeExamplannerIndication,
  clearExamplannerIndication,
} from "../../../Redux/actions.js";
import "./stylesheets/IndicationList.scss";
import useDebounce from "../../../Utilities/Hooks/useDebounce";
import { ExamPlannerIndicationImage } from "./ExamPlannerImage.jsx";

import { useTranslation } from "react-i18next";
import { EX_LABEL } from "./ExamPlanner.jsx";
import LoadMoreList from "../../../Blueprints/LoadMoreList/LoadMoreList.jsx";
import { useIndicationsInfinite } from "../../../Utilities/FetchHooks/Ris/RisHooks.jsx";
import usePmedFilter from "../../../Utilities/Filtering/usePmedFilter.jsx";

const PAGE_SIZE = 25;

const ExamPlannerIndicationRow = ({ indication, selectedIndications }) => {
  const dispatch = useDispatch();
  const isSelected =
    selectedIndications.findIndex((i) => i.id === indication.id) > -1;

  return indication[EX_LABEL] != "" ? (
    <div
      key={`indication-expl-indrow-${indication.id}`}
      className={`p-2 border rounded d-flex align-items-center justify-content-left exmpl-row-btn mx-1 ${
        isSelected ? "selected" : ""
      } `}
      onClick={() => {
        if (!isSelected) {
          dispatch(addExamplannerIndication(indication));
        } else {
          dispatch(removeExamplannerIndication(indication));
        }
      }}
    >
      <div className="font-weight-bold">{indication[EX_LABEL]}</div>
      <div className="ml-auto mr-2 p-1 patpos-img-box">
        <ExamPlannerIndicationImage indId={indication.id} />
      </div>
    </div>
  ) : (
    <></>
  );
};

function IndicationList() {
  const dispatch = useDispatch();
  const selectedIndications = useSelector(
    (state) => state.examPlanner.risData.indications
  );
  const selectedBodyPart = useSelector(
    (state) => state.examPlanner.viewControll.selectedBodyPart
  );
  const [searchText, setSearchText] = useState("");
  const searchTextDebounced = useDebounce(searchText, 500);
  const { filter } = usePmedFilter({
    field: EX_LABEL,
    searchPhrase: searchTextDebounced,
  });
  const { t } = useTranslation(["examplanner", "common"]);
  const indicationsQ = useIndicationsInfinite({
    pageSize: PAGE_SIZE,
    filter: filter,
    enabled: true,
    anatomy: selectedBodyPart.id ? { anatomyId: selectedBodyPart.id } : null,
  });
  const ListRenderer = (elementList) => {
    return elementList?.map((element) => (
      <ExamPlannerIndicationRow
        key={`indication-expl-indrow-${element.id}`}
        indication={element}
        selectedIndications={selectedIndications}
      />
    ));
  };
  const dataForList = useMemo(() => {
    if (!indicationsQ.data) return [];
    let indicationsList = [];
    indicationsQ.data.pages.forEach((page) => {
      if (page?.indications) {
        indicationsList = indicationsList.concat(page.indications);
      }
    });
    return indicationsList;
  }, [indicationsQ.data]);

  return (
    <LoadMoreList
      elementList={dataForList}
      loadMore={indicationsQ.fetchNextPage}
      isLoading={indicationsQ.isLoading}
      isLoadingNextPage={indicationsQ.isFetchingNextPage}
      pageSize={PAGE_SIZE}
      hasMoreElements={indicationsQ.hasNextPage}
      listRenderer={ListRenderer}
      showSearch={true}
      customSearchPlaceholder={t("reasonSelect.reasonsSearch")}
      filterString={searchText}
      setFilterString={setSearchText}
      selectedElements={selectedIndications}
      selectedElementLabelAccessor={EX_LABEL}
      deselectElement={(indication) =>
        dispatch(removeExamplannerIndication(indication))
      }
      clearSelectedElements={() => dispatch(clearExamplannerIndication())}
    />
  );
}

export default IndicationList;
