import { useSelector, useDispatch } from "react-redux";
import {
  clearExamplannerBodypart,
  selectExamplannerBodypart,
  selectPreviousBodypart,
} from "../../../../../Redux/actions";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Controller({
  availableViews,
  leftButton = "Rotate left 90",
  rightButton = "Rotate right 90",
}) {
  const dispatch = useDispatch();
  const selectedBodyPart = useSelector(
    (state) => state.examPlanner.viewControll.selectedBodyPart
  );
  const bodyPartHistory = useSelector(
    (state) => state.examPlanner.viewControll.bodyPartHistory
  );
  const setBodyPart = (bPartObj) => {
    if (selectedBodyPart?.id === bPartObj.id) {
      dispatch(clearExamplannerBodypart());
      return;
    }
    dispatch(selectExamplannerBodypart(bPartObj));
  };

  const nextView = () => {
    if (availableViews.length - 1 > viewIdx) {
      setViewIdx(viewIdx + 1);
      return;
    }
    if (availableViews.length - 1 === viewIdx) {
      setViewIdx(0);
      return;
    }
  };

  const prevView = () => {
    if (viewIdx > 0) {
      setViewIdx(viewIdx - 1);
      return;
    }
    if (viewIdx === 0) {
      setViewIdx(availableViews.length - 1);
      return;
    }
  };

  const [viewIdx, setViewIdx] = useState(0);
  const CurrentView = availableViews[viewIdx];
  const { t } = useTranslation(["examplanner", "common"]);

  return (
    <div className="d-flex flex-column image-parent align-items-center justify-content-start p-2">
      <div className="d-flex w-100 justify-content-between">
        {Object.keys(selectedBodyPart).length > 0 && (
          <Button
            className="mb-2"
            onClick={() => dispatch(clearExamplannerBodypart())}
          >
            <i className="fas fa-angle-double-left fa-sm mr-2" />
            {t("buttons.showWholeBody")}
          </Button>
        )}
        {bodyPartHistory.length > 1 && (
          <Button
            className="mb-2"
            onClick={() => dispatch(selectPreviousBodypart())}
          >
            <i className="fas fa-angle-left fa-sm mr-2" />
            {t("buttons.previousBodypart")}
          </Button>
        )}
      </div>
      <CurrentView bpartSetter={setBodyPart} selected={selectedBodyPart} />
      {availableViews.length > 1 && (
        <div
          className="d-flex border mt-auto"
          style={{ width: "270px", borderRadius: "25px" }}
        >
          <div
            className="d-flex small px-1 align-items-center rotate-model-btn left"
            onClick={prevView}
            style={{ flexGrow: 1 }}
          >
            <i className="fas fa-chevron-left fa-xs mr-1" />
            {t("examSelect.rotateLeft90")}
          </div>
          <div
            className="d-flex small px-1 align-items-center justify-content-end rotate-model-btn right"
            onClick={nextView}
            style={{ flexGrow: 1 }}
          >
            {t("examSelect.rotateRight90")}
            <i className="fas fa-chevron-right fa-xs ml-1" />
          </div>
        </div>
      )}
    </div>
  );
}

export default Controller;
