import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import usePmedFilter from "@Utils/Filtering/usePmedFilter";
import useDebounce from "@Utils/Hooks/useDebounce";

function BaseSearch({
  field,
  filterSetter,
  initialSearchPhrase = "",
  searchPhraseSetter = null,
  className = "",
}) {
  const [searchPhrase, setSearchPhraseInternal] = useState(initialSearchPhrase);
  const setSearchPhrase = (value) => {
    searchPhraseSetter && searchPhraseSetter(value);
    setSearchPhraseInternal(value);
  };
  const searchPhraseDebounced = useDebounce(searchPhrase, 500);
  const { filter, updateFilter } = usePmedFilter({
    field,
    searchPhrase: searchPhraseDebounced,
  });

  useEffect(() => {
    filterSetter(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <div className={`d-flex ${className}`}>
      <input
        className="my-2 mr-1 border rounded"
        type="search"
        onChange={(e) => {
          setSearchPhrase(e.target.value);
        }}
        value={searchPhrase}
      />
      <Button className="my-2 mr-2" onClick={updateFilter}>
        <i className="fas fa-search" />
      </Button>
    </div>
  );
}

export default BaseSearch;
