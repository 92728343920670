import style from "./style.scss";

function RechtesKniegelenkTEPlatLiegendLinks2320(props) {
  return (
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 526.6 738.3">

<g id="knee_TEP" data-name="knee TEP">
  <path id="femur" class="knee_lat_tep_right2320_cls4" d="M131,.5c-1.1,1.7-3.4,18.5-10,37.1-7.7,21.6-12.1,30.2-8,39,1.9,4.1,3.5,3.5,6,8,5.6,10,2.5,21.2-.6,33.1,0,0-8,30.9-10.8,62.9-.8,8.8-.9,15.7,3.4,21,5.9,7.2,16.7,5.6,23.9,7.5,38.2,10.2,66.5,17.7,86.6,27.5,15.2,7.4,25.1,14.4,31.6,10,4.8-3.2,2.9-9.2,8-23,3.8-10.3,11.8-25.8,18-25,6.1.8,7,17,12,17s5.1-4.6,7-10c4.5-.8,7.5-1,9.3-.8-5.7-10.4-7.7-12.7-14.3-19.2-.8-.8-.7-.6-11-10-7.2-6.6-10.9-9.8-11-10-2.7-2.8-8.2-9.5-12-41-.5-4.3-1.3-11.4-2-24-1.5-27.2-2.3-40.8,0-55,3.4-20.7,13.5-39.2,11-45.1"/>
  <path id="tibia" class="knee_lat_tep_right2320_cls4" d="M446.1,726.6c-7.1-14,1.6,1.8-23-51-16.6-35.7-16.7-34.6-29-61-24.4-52.5-20-48.3-33-73-10.1-19.1-15.8-27.7-22-46-6-17.7-2.7-16-10-39-5.1-15.9-9.2-24.6-6-36,1.7-6,2.9-3.9,8-15,1.7-3.7,3.8-8.9,11-36,2.4-9,4.5-11.5,3-13-2.3-2.3-5.9,5.8-29,11-12.4,2.8-14,5.6-17,9-2.4,2.8-6,8.4-6,23,0,17.4,5.1,22.6,9.8,45,3.2,15,.7,12,4.4,33.1,4.3,24.4,8,31,3.8,38.9-4,7.6-14.2,14.3-24.2,13.7-27.6-1.6-56.2-59.5-67.8-93.7-2.7-7.9-7.8-24.4-18-26-6.7-1.1-9.3,5.2-25.7,9.2-6.2,1.5-11.9,2.9-19.3,1.8-8.3-1.2-12-4.6-14-3-4.4,3.5,6.5,23.7,7,25,18.5,47.5,27.9,71.7,49,105,26.2,41.3,28.4,30.5,61,80,10.9,16.6,26.3,39.9,43,73,9.7,19.1,13.7,29.5,16.6,36.3"/>
  <path id="fibula_2" data-name="fibula 2" class="knee_lat_tep_right2320_cls4" d="M526.1,704.8c-3.3-12.3-6.6-26.4-9-33.1-8.8-24.9-14.3-32.2-29-66-5.6-12.8-13.8-31.7-25-55-5.1-10.5-16.7-34.3-33-68-8.1-16.8-12.5-26-15-40-2-11.2-1-15.4-4-30-2.2-11-4.3-21.1-10-32-3.7-7-11.4-19.1-27-30-3.8-1.4-9.8-3-16.7-1.8-10,1.7-15.9,9.4-30.3,30.8-15.2,22.6-22.8,34-23,39-.9,20,15.8,37.7,27.3,45.5,3,2.1,9.8,5.7,23.7,12.5,19.9,9.8,20.6,10.1,22,11,12.4,7.9,18.1,19.2,25,33,22.3,45,33.5,67.7,36,74,6,15.2,3.2,9.9,20,56,10,27.6,15.1,41.5,18,47,5.7,10.7,8.1,18.1,10.7,24.7"/>
  <path id="patella" class="knee_lat_tep_right2320_cls2" d="M52.4,331.1c-6.8,2.9-17.7-9.1-24.4-16.8-2.7-3-12.8-14.5-20.7-33.5-4.6-11-7.5-18.4-5.9-27.7,1-5.4,2.4-5.5,7.7-16.7,9.4-19.9,9.3-29,16.4-31.2,4.8-1.5,9.4,1.2,10,1.6,2.1,1.3,4.5,3.7,7.9,14.3,2.9,9.2,2.4,11.3,4.8,19.1,2.4,7.7,5.3,13.3,6.8,16.3,5.3,10.4,8.6,12.6,11.4,21.5.9,3,1.8,5.9,2,9.8.5,9.2-3.5,12.4-9.3,27.9-4.4,11.6-3.9,14.2-6.7,15.4h0Z"/>
  <path id="fibula_1" data-name="fibula 1" class="knee_lat_tep_right2320_cls1" d="M332,466.5c-.8-14.3-6.7-17.8-9.1-43.1.2-8.7.5-11.5,8.2-17.8,11.4-8.1,12.2-30.3,14.6-50.5"/>
  <g id="TEP">
    <path id="TEP_upper_leg" data-name="TEP upper leg" class="knee_lat_tep_right2320_cls3" d="M111.1,201.6c-5.4-6.8-3.9-18.8-1-43,1.8-14.3,9.6-38.1,11-45,4.7-23.9,2.9-25.4-2-29-2.5-1.8-4.2.2-18,0-9.1,0-9.8-1-13,0-6.8,2.2-10.5,8.6-14,15-4.2,7.6-5.4,12.7-8,21-4.4,13.6-7,17.6-10,26-6.9,19.4-7,37-7,48,0,15.1-.1,41.4,15,70,3.9,7.4,14.2,25.1,34,41,3.3,2.7,24.1,18.9,54,26,8.1,1.9,16.1,3,32,5,17.3,2.2,22.7,2.1,26,2,12.5-.6,22.1-3.3,28-5,4-1.2,12.1-3.7,21-8,14.3-6.9,35.2-16.7,47-39,7.2-13.7,7.9-25.6,9-43,.5-8.9,1.9-35.3-8-39-2.4-.9-6.2-.7-9,1-5.1,3.1-4.6,9.7-7,10-3.7.4-5.5-15.9-12-17-6.3-1.1-13.6,12.4-15,15-9.7,18.1-4.1,28.8-11,33-5.4,3.3-10.8-2-39-13-9.3-3.6-16.5-6.1-25-9-9.9-3.4-21.6-7.4-35-11-32.4-8.7-37.5-5.1-43-12h0Z"/>
    <path id="TEP_lower_leg" data-name="TEP lower leg" class="knee_lat_tep_right2320_cls3" d="M137.6,420.6h0c-5.4-4.8-7.4-14.1-4.2-20.5,3.5-6.7,11.7-7.1,29.1-12.2,10.6-3.1,20.8-7.7,31.2-11.2,41.4-14.2,62.2-21.4,70.7-23.5,11.3-2.9,21.4-4.4,32.2-8.2,16.8-5.9,21.2-6.7,28.1-9.2,3.1-1.1,10-4.5,19.7-7.1,10.1-2.6,12.8-1.9,14.6-1,5.3,2.8,7.9,10.4,6.2,16.3-.4,1.4-1.9,5.8-16.6,13.3-9.3,4.7-14.8,5.9-31.2,12.2-13.1,5-14.9,6.2-16.6,8.2-4.4,4.9-5.9,11.6-5.2,25.5,1,19,4.8,27.7,9.4,49,7.5,35,1.8,27.1,7.3,47,1.8,6.1,4.2,13.5,1,21.5-.2.5-4.9,12.2-15.9,14.5-5.9,1.3-11.9-.5-20.4-5.9-15.1-9.7-24.8-22.8-33.4-37.1-13.1-22.1-24.9-50.6-26-53.1-6.6-16.1-8.2-22.1-14.5-24.5-9.7-3.7-16.5,6-40.5,9.2-10.1,1.3-18.8,2.4-24.9-3.1h0Z"/>
  </g>
</g>
<g id="overlays">
  <g id="TEP_overlays" data-name="TEP overlays" class="knee_lat_tep_right2320_cls5">
    <path id="TEP_upper_leg_overlay" data-name="TEP upper leg overlay" class="UpTEP2320Fill"             style={{
            fill: props.colors.UpTEP2320Fill,
            stroke: props.colors.UpTEP2320Fill,
          }} d="M111.1,201.6c-5.4-6.8-3.9-18.8-1-43,1.8-14.3,9.6-38.1,11-45,4.7-23.9,2.9-25.4-2-29-2.5-1.8-4.2.2-18,0-9.1,0-9.8-1-13,0-6.8,2.2-10.5,8.6-14,15-4.2,7.6-5.4,12.7-8,21-4.4,13.6-7,17.6-10,26-6.9,19.4-7,37-7,48,0,15.1-.1,41.4,15,70,3.9,7.4,14.2,25.1,34,41,3.3,2.7,24.1,18.9,54,26,8.1,1.9,16.1,3,32,5,17.3,2.2,22.7,2.1,26,2,12.5-.6,22.1-3.3,28-5,4-1.2,12.1-3.7,21-8,14.3-6.9,35.2-16.7,47-39,7.2-13.7,7.9-25.6,9-43,.5-8.9,1.9-35.3-8-39-2.4-.9-6.2-.7-9,1-5.1,3.1-4.6,9.7-7,10-3.7.4-5.5-15.9-12-17-6.3-1.1-13.6,12.4-15,15-9.7,18.1-4.1,28.8-11,33-5.4,3.3-10.8-2-39-13-9.3-3.6-16.5-6.1-25-9-9.9-3.4-21.6-7.4-35-11-32.4-8.7-37.5-5.1-43-12h0Z"/>
    <path id="TEP_lower_leg_overlay" data-name="TEP lower leg overlay" class="TEP2320Fill"  style={{
            fill: props.colors.TEP2320Fill,
            stroke: props.colors.TEP2320Fill,
          }} d="M137.5,420.6h0c-5.4-4.8-7.4-14.1-4.2-20.5,3.5-6.7,11.7-7.1,29.1-12.2,10.6-3.1,20.8-7.7,31.2-11.2,41.4-14.2,62.2-21.4,70.7-23.5,11.3-2.9,21.4-4.4,32.2-8.2,16.8-5.9,21.2-6.7,28.1-9.2,3.1-1.1,10-4.5,19.7-7.1,10.1-2.6,12.8-1.9,14.6-1,5.3,2.8,7.9,10.4,6.2,16.3-.4,1.4-1.9,5.8-16.6,13.3-9.3,4.7-14.8,5.9-31.2,12.2-13.1,5-14.9,6.2-16.6,8.2-4.4,4.9-5.9,11.6-5.2,25.5,1,19,4.8,27.7,9.4,49,7.5,35,1.8,27.1,7.3,47,1.8,6.1,4.2,13.5,1,21.5-.2.5-4.9,12.2-15.9,14.5-5.9,1.3-11.9-.5-20.4-5.9-15.1-9.7-24.8-22.8-33.4-37.1-13.1-22.1-24.9-50.6-26-53.1-6.6-16.1-8.2-22.1-14.5-24.5-9.7-3.7-16.5,6-40.5,9.2-10.1,1.3-18.8,2.4-24.9-3.1h0Z"/>
  </g>
  <path id="patella_overlay" data-name="patella overlay" class="Patella2320Fill"  style={{
          fill: props.colors.Patella2320Fill,
          stroke: props.colors.Patella2320Fill,
        }} d="M52.4,331.1c-6.8,2.9-17.7-9.1-24.4-16.8-2.7-3-12.8-14.5-20.7-33.5-4.6-11-7.5-18.4-5.9-27.7,1-5.4,2.4-5.5,7.7-16.7,9.4-19.9,9.3-29,16.4-31.2,4.8-1.5,9.4,1.2,10,1.6,2.1,1.3,4.5,3.7,7.9,14.3,2.9,9.2,2.4,11.3,4.8,19.1,2.4,7.7,5.3,13.3,6.8,16.3,5.3,10.4,8.6,12.6,11.4,21.5.9,3,1.8,5.9,2,9.8.5,9.2-3.5,12.4-9.3,27.9-4.4,11.6-3.9,14.2-6.7,15.4h0Z"/>
</g>
</svg>
  );
}

export default RechtesKniegelenkTEPlatLiegendLinks2320;
