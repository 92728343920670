import {
  MakeDeleteObject,
  MakeGetObject,
  MakePostObject,
} from "../../AuthHeader";

export const REST_URL = `${window.conf.SERVER_CONFIG.BASE_URL}`;

export function MakeUrl(
  urlBase,
  pageSize = undefined,
  pageNr = undefined,
  filter = undefined,
  sort = undefined
) {
  const url = new URL(urlBase);
  if (pageSize && pageNr) {
    url.searchParams.append("page_size", pageSize);
    url.searchParams.append("page_number", pageNr);
  }
  if (filter) {
    url.searchParams.append("search", JSON.stringify(filter));
  }
  if (sort) {
    url.searchParams.append("sort", JSON.stringify(sort));
  }
  return url;
}

export async function getFetch(url, keycloak, xmask = undefined) {
  let resp = await fetch(url.href, MakeGetObject(keycloak, xmask));
  if (!resp.ok) {
    return resp.statusText;
  }
  if (resp.status === 204) {
    return null;
  }
  if (resp.status === 404) {
    return null;
  }
  let data = await resp.json();
  return data;
}

export async function postFetch(url, keycloak, body) {
  let resp = null;
  try {
    resp = await fetch(url.href, MakePostObject(keycloak, body));
  } catch (e) {
    console.log(e);
  }
  if (!resp.ok) {
    return resp.statusText;
  }
  let data = await resp.json();
  return data;
}

export async function deleteFetch(url, keycloak) {
  let resp = null;
  try {
    resp = await fetch(url.href, MakeDeleteObject(keycloak));
  } catch (e) {
    console.log(e);
  }
  if (!resp.ok) {
    return { status: "error", text: resp.statusText };
  }
  let data = await resp.json();
  return data;
}
