import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import useWindowSize from "@Utils/Window/windowSize";

import "./style.scss";

function LoadMoreList({
  elementList = [],
  loadMore,
  isLoading = false,
  isLoadingNextPage = false,
  hasMoreElements = false,
  listRenderer = () => {},
  showSearch = true,
  customSearchPlaceholder = "",
  customPreSearchBtn = <></>,
  filterString,
  setFilterString,
  selectedElements = [],
  selectedElementLabelAccessor = "label",
  deselectElement = () => {},
  clearSelectedElements = null,
}) {
  const { t } = useTranslation("common");
  const windowSize = useWindowSize();

  const getListHeight = () => {
    if (windowSize.width < 1200) {
      return "64vh";
    } else {
      return "75vh";
    }
  };
  return (
    <div>
      <div style={{ boxShadow: "0px 15px 20px -15px rgba(0,0,0,0.65)" }}>
        {/* Search bar */}
        {showSearch && (
          <div
            className={`d-flex align-items-center w-100 justify-content-between p-2`}
            style={{ flexBasis: 1 }}
          >
            <Form
              style={{ flexGrow: 20 }}
              onSubmit={(e) => {
                e.preventDefault();
                loadMore();
              }}
            >
              <Form.Group
                className="my-auto d-flex"
                controlId="formExamination"
                style={{ gap: "5px" }}
              >
                {customPreSearchBtn}
                <Form.Control
                  type="search"
                  placeholder={
                    customSearchPlaceholder
                      ? customSearchPlaceholder
                      : t("search")
                  }
                  value={filterString}
                  onChange={(e) => setFilterString(e.target.value)}
                />
              </Form.Group>
            </Form>
            {/* Clear selected elements button */}
            {clearSelectedElements && (
              <button
                style={{ flexGrow: 1, justifySelf: "end" }}
                className="border-0 p-0 m-0 bg-white text-right"
                disabled={selectedElements.length === 0}
              >
                <i
                  onClick={() => clearSelectedElements()}
                  className="fa fa-broom p-2 border rounded btn-like-icon ml-auto"
                />
              </button>
            )}
          </div>
        )}
        {/* Selected elements chips */}
        {selectedElements.length > 0 && (
          <div
            className="d-flex px-2 pb-2 mb-1"
            style={{
              flexWrap: "wrap",
            }}
          >
            {selectedElements.map((element, key) => (
              <div
                className="d-flex border align-items-center p-1 exp-selected-item"
                key={`selexmskey${key}`}
                onClick={() => deselectElement(element)}
              >
                <div className="ml-2">
                  {element[selectedElementLabelAccessor]}
                </div>
                <i className="fas fa-times fa-xs ml-2" />
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Elements list */}
      <div
        name="ExaminationList"
        className="scrollable-list d-flex flex-column pt-3"
        style={{ maxHeight: getListHeight() }}
      >
        {isLoading && !isLoadingNextPage && (
          <div className="d-flex justify-content-center align-items-center">
            <i className="fas fa-spinner fa-spin fa-2x" />
          </div>
        )}
        {listRenderer(elementList)}
        {isLoadingNextPage && (
          <div className="d-flex justify-content-center align-items-center">
            <i className="fas fa-spinner fa-spin fa-2x" />
          </div>
        )}
        <Button
          onClick={() => loadMore()}
          disabled={!hasMoreElements || isLoading}
        >
          <i className="mr-2 fas fa-sync fa-xs" />
          {t("buttons.loadMore", { ns: "common" })}
        </Button>
      </div>
    </div>
  );
}

export default LoadMoreList;
