import { Button } from "react-bootstrap";
import { useState, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addExamplannerExamination,
  removeExamplannerExamination,
  clearExamplannerExamination,
  selectExamplannerBodypart,
} from "../../../Redux/actions.js";
import usePmedFilter from "../../../Utilities/Filtering/usePmedFilter.jsx";

import leying from "./static/patpositions/leying.svg";
import sitting from "./static/patpositions/sitting.svg";
import standing from "./static/patpositions/standing.svg";
import { ExamPlannerExaminationImage } from "./ExamPlannerImage.jsx";
import useDebounce from "@Utils/Hooks/useDebounce";

import { EXAM_LIST_OPTIONS, ExamPlannerContext } from "./ExamPlanner.jsx";
import { useTranslation } from "react-i18next";

import "./stylesheets/IndicationList.scss";
import LoadMoreList from "../../../Blueprints/LoadMoreList/LoadMoreList.jsx";
import { useExaminationsInfinite } from "../../../Utilities/FetchHooks/Ris/RisHooks.jsx";

export const LABEL =
  window.conf.LANG === "DE"
    ? "designationde"
    : window.conf.LANG === "PL"
    ? "designationpl"
    : "designationde";

const LANG_ACCESSOR = LABEL;
const LANG_ACCESSOR_ABBR = "abbrde";
const PAGE_SIZE = 25;
const FILTER_FIELDS = [LANG_ACCESSOR, LANG_ACCESSOR_ABBR];

const ExamPlannerExamRow = ({ examination, selectedExaminations }) => {
  const dispatch = useDispatch();
  const posMap = {
    1: leying,
    2: standing,
    3: sitting,
  };
  const getPosIcon = (posId) => {
    let icn = posMap[posId];
    if (icn) {
      return icn;
    } else {
      return posMap[1];
    }
  };
  const isSelected =
    selectedExaminations.findIndex((ex) => ex.id === examination.id) > -1;

  return (
    <div
      key={`examination-expl-exmrow-${examination.id}`}
      className={`mx-1 p-2 border rounded d-flex align-items-center justify-content-left exmpl-row-btn ${
        isSelected ? "selected" : ""
      }`}
      onClick={() => {
        if (!isSelected) {
          dispatch(selectExamplannerBodypart(examination?.anatomy_organs));
          dispatch(addExamplannerExamination(examination));
        } else {
          dispatch(removeExamplannerExamination(examination));
        }
      }}
    >
      <div className="p-1 patpos-img-box" style={{ flexShrink: 1 }}>
        <ExamPlannerExaminationImage examId={examination.id} />
      </div>
      <div className="p-1 patpos-img-box" style={{ flexShrink: 1 }}>
        <img src={getPosIcon(examination.patpos)} alt="patpos" />
      </div>
      <div className="ml-2 font-weight-bold" style={{ flexShrink: 10 }}>
        {examination[LANG_ACCESSOR]}
      </div>
    </div>
  );
};

function ExaminationList() {
  const { setExamsOrProcedures } = useContext(ExamPlannerContext);
  const { t } = useTranslation(["examplanner", "common"]);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 500);
  const { filter } = usePmedFilter({
    field: FILTER_FIELDS,
    searchPhrase: debouncedSearchText,
  });
  const selectedExaminations = useSelector(
    (state) => state.examPlanner.risData.examinations
  );
  const selectedIndications = useSelector(
    (state) => state.examPlanner.risData.indications
  );
  const selectedBodyPart = useSelector(
    (state) => state.examPlanner.viewControll.selectedBodyPart
  );

  const filtering = useMemo(() => {
    let exmFilter = filter;
    let anatomyFilter = null;
    if (Object.keys(selectedBodyPart).length > 0) {
      anatomyFilter = {
        model: "Exams",
        field: "anatomy_id",
        op: "eq",
        value: selectedBodyPart.id,
      };
    }
    if (exmFilter) {
      if (anatomyFilter) {
        exmFilter = {
          and: [anatomyFilter, filter],
        };
      }
    } else {
      exmFilter = anatomyFilter;
    }
    return exmFilter;
  }, [filter, selectedBodyPart]);

  const examsQ = useExaminationsInfinite({
    pageSize: PAGE_SIZE,
    filter: filtering,
    indid:
      selectedIndications.length > 0
        ? { indIds: selectedIndications.map((a) => a.id) }
        : null,
  });

  const dataForList = useMemo(() => {
    if (!examsQ.data) return [];
    let examsList = [];
    examsQ.data.pages.forEach((page) => {
      if (page?.examinations) {
        examsList = examsList.concat(page.examinations);
      }
    });
    return examsList;
  }, [examsQ.data]);

  const SwitchToProceduresBtn = () => (
    <Button onClick={() => setExamsOrProcedures(EXAM_LIST_OPTIONS.PROCEDURES)}>
      Exams
    </Button>
  );

  const ListRenderer = (elementList) => {
    return elementList.map((element) => (
      <ExamPlannerExamRow
        key={`examination-expl-exmrow-${element.id}`}
        examination={element}
        selectedExaminations={selectedExaminations}
      />
    ));
  };

  return (
    <LoadMoreList
      isLoading={examsQ.isLoading}
      isLoadingNextPage={examsQ.isFetchingNextPage}
      hasMoreElements={examsQ.hasNextPage}
      loadMore={examsQ.fetchNextPage}
      elementList={dataForList}
      listRenderer={ListRenderer}
      clearSelectedElements={() => dispatch(clearExamplannerExamination())}
      selectedElements={selectedExaminations}
      selectedElementLabelAccessor={LABEL}
      deselectElement={(element) =>
        dispatch(removeExamplannerExamination(element))
      }
      customSearchPlaceholder={t("examSelect.examSearch")}
      customPreSearchBtn={<SwitchToProceduresBtn />}
      showSearch={true}
      filterString={searchText}
      setFilterString={setSearchText}
    />
  );
}

export default ExaminationList;
