import images from "../images";

const testExamGuideData = {
  steps: [
    {
      label:
        "n. z. u. Schulter ca. 45° anheben (von Stativ entfernen), sodass das Schulterblatt der zu untersuchenden Seite plan anliegt ",
      image: images.testImage1, //example of single image
      drawing: images.testImage2,
    },
    {
      label:
        "Arm anheben, sodass in der Schulter möglichst ein Winkel von 90° entsteht, Handfläche schaut zum Kopf",
      image: [images.testImage1, images.testImage2, images.testImage1], //example of multiple images
      drawing: [images.testImage2, images.testImage1, images.testImage2],
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: images.testImage2,
      drawing: images.testImage1,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: images.testImage1,
      drawing: images.testImage2,
    },
  ],
};

const exam1101 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1101,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1101_1,
    },
    {
      label:
        "Zentralstrahl 2 Querfinger oberhalb des Beckenkamms auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1101_2,
    },
  ],
};
const exam1102 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1102,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1102_1,
    },
    {
      label:
        "Zentralstrahl 2 Querfinger oberhalb des Beckenkamms auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1102_2,
    },
  ],
};
const exam1103 = {
  steps: [
    {
      label: "Patient liegt in Linksseitenlage, mit Rücken am Bildempfänger",
      image: {},
      drawing: images.drawing_1103,
    },
    {
      label:
        "Seitenlage muss mind. 5 Minuten andauern, damit Flüssigkeitsspiegel erkennbar wird",
      image: {},
      drawing: images.drawing_1103_1,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1103_2,
    },
    {
      label:
        "Zentralstrahl 2 Querfinger oberhalb des Beckenkamms auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1103_3,
    },
  ],
};
const exam1104 = {
  steps: [
    {
      label: "Patient liegt in Linksseitenlage, mit Bauch am Bildempfänger",
      image: {},
      drawing: images.drawing_1104,
    },
    {
      label:
        "Seitenlage muss mind. 5 Minuten andauern, damit Flüssigkeitsspiegel erkennbar wird",
      image: {},
      drawing: images.drawing_1104_1,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1104_2,
    },
    {
      label:
        "Zentralstrahl 2 Querfinger oberhalb des Beckenkamms auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1104_3,
    },
  ],
};
const exam1201 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1206,
    },
    {
      label: "Fersen sind 3 o. 4 cm voneinander entfernt",
      image: {},
      drawing: images.drawing_1201_1,
    },
    {
      label:
        "Beide Füße gleich, nach innen gekippt, so dass sich die Grosszehen berühren",
      image: {},
      drawing: images.drawing_1201_2,
    },
    {
      label: "Arme neben dem Körper, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_1201_3,
    },
    {
      label: "Zentrierung auf Beckenmitte und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1201_4,
    },
  ],
};
const exam1203 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1203,
    },
    {
      label: "beide Beine gleich",
      image: {},
      drawing: images.drawing_2416_1,
    },
    {
      label:
        "Beide Füße gleich, nach innen gekippt, so dass sich die Grosszehen berühren",
      image: {},
      drawing: images.drawing_2416_2,
    },
    {
      label: "Arme neben dem Körper, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2416_3,
    },
    {
      label: "Zentrierung auf Beckenmitte und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2416_4,
    },
  ],
};
const exam1204 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1204,
    },
    {
      label: "Beine in den Knien leicht anwinkeln, Füße aufstellen",
      image: {},
      drawing: images.drawing_1204_1,
    },
    {
      label:
        "Zentrierung 2-3 Querfinger unterhalb des Beckenkamms auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1204_2,
    },
  ],
};
const exam1205 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1205,
    },
    {
      label: "Beide Füße symmetrisch",
      image: {},
      drawing: images.drawing_1205_1,
    },
    {
      label:
        "Zentrierung auf Symphysenmitte/Gesäßfaltenmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1205_2,
    },
  ],
};
const exam1206 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1206,
    },
    {
      label: "Die z. u. Körperseite 25° bis 30° anheben",
      image: {},
      drawing: images.drawing_1206_1,
    },
    {
      label:
        "Zentrierung 2 Querfinger unterhalb des Beckenkamms (d. z. u. Seite) auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1206_2,
    },
  ],
};
const exam1207 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1207,
    },
    {
      label: "Die z. u. Körperseite 25° bis 30° anheben",
      image: {},
      drawing: images.drawing_1207_1,
    },
    {
      label:
        "Zentrierung 2 Querfinger unterhalb des Beckenkamms (d. z. u. Seite) auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1207_2,
    },
  ],
};
const exam1208 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1208,
    },
    {
      label:
        "die nicht z. u. Seite des Patienten wird um 45 ° angehoben, damit liegt die zu untersuchende Darmbeinschaufel parallel zum Bildempfänger liegt",
      image: {},
      drawing: images.drawing_1208_1,
    },
    {
      label: "Bein der angehobene Seite bequem lagern ev. anwinkeln",
      image: {},
      drawing: images.drawing_1208_2,
    },
    {
      label:
        "Zentralstrahl 1 Handbreit oberhalb der Mitte der Leistenbeuge auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1208_3,
    },
  ],
};
const exam1209 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1209,
    },
    {
      label:
        "die nicht z. u. Seite des Patienten wird um 45 ° angehoben, damit liegt die zu untersuchende Darmbeinschaufel parallel zum Bildempfänger liegt",
      image: {},
      drawing: images.drawing_1209_1,
    },
    {
      label: "Bein der angehobene Seite bequem lagern ev. anwinkeln",
      image: {},
      drawing: images.drawing_1209_2,
    },
    {
      label:
        "Zentralstrahl 1 Handbreit oberhalb der Mitte der Leistenbeuge auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1209_3,
    },
  ],
};
const exam1301 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label:
        "Daumen mittig & parallel über dem Bildempfänger, Hand kann dabei leicht schräg fallen",
      image: {},
      drawing: images.drawing_1301_1,
    },
    {
      label:
        "Zentralstrahl oberhalb vom Daumengrundgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1301_2,
    },
  ],
};
const exam1302 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label:
        "Daumen mittig & parallel über dem Bildempfänger, Hand kann dabei leicht schräg fallen",
      image: {},
      drawing: images.drawing_1302_1,
    },
    {
      label:
        "Zentralstrahl oberhalb vom Daumengrundgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1302_2,
    },
  ],
};
const exam1303 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label:
        "Daumen mittig & parallel über dem Bildempfänger, Hand kann dabei leicht schräg fallen",
      image: images.image_1303_1,
      drawing: images.drawing_1303_1,
    },
    {
      label: "Zentralstrahl auf Daumenmitte auf Bildempfängermitte",
      image: images.image_1303_1,
      drawing: images.drawing_1303_2,
    },
  ],
};
const exam1304 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label:
        "Daumen mittig & parallel über dem Bildempfänger, Hand kann dabei leicht schräg fallen",
      image: {},
      drawing: images.drawing_1304_1,
    },
    {
      label: "Zentralstrahl auf Daumenmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1304_2,
    },
  ],
};
const exam1305 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Daumen liegt seitlich & mittig auf dem Bildempfänger ",
      image: images.image_1305_1,
      drawing: images.drawing_1305_1,
    },
    {
      label: "Zentralstrahl auf Daumenmitte auf Bildempfängermitte",
      image: images.image_1305_2,
      drawing: images.drawing_1305_2,
    },
  ],
};
const exam1306 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Daumen liegt seitlich & mittig auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1306_1,
    },
    {
      label: "Zentralstrahl auf Daumenmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1306_2,
    },
  ],
};
const exam1307 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Hand liegt seitlich auf, Daumen wird abgespreizt",
      image: images.image_1307_1,
      drawing: {},
    },
    {
      label:
        "Daumen mittig & parallel über dem Bildempfänger, Hand kann dabei leicht schräg fallen",
      image: images.image_1307_1,
      drawing: {},
    },
    {
      label: "Fingernagel zeigt nach oben",
      image: images.image_1307_1,
      drawing: {},
    },
    {
      label: "Zentralstrahl aufs Daumengrundgelenk auf Bildempfängermitte",
      image: images.image_1307_1,
      drawing: images.drawing_1307_1,
    },
  ],
};
const exam1308 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Hand liegt seitlich auf, Daumen wird abgespreizt",
      image: {},
      drawing: {},
    },
    {
      label:
        "Daumen mittig & parallel über dem Bildempfänger, Hand kann dabei leicht schräg fallen",
      image: {},
      drawing: {},
    },
    {
      label: "Fingernagel zeigt nach oben",
      image: {},
      drawing: {},
    },
    {
      label: "Zentralstrahl aufs Daumengrundgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1308_1,
    },
  ],
};
const exam1309 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Daumen & MHK 1 liegen seitlich & mittig auf dem Bildempfänger",
      image: images.image_1309_1,
      drawing: {},
    },
    {
      label: "Zentralstrahl aufs Daumengrundgelenk auf Bildempfängermitte",
      image: images.image_1309_1,
      drawing: images.drawing_1309_1,
    },
  ],
};
const exam1310 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Daumen & MHK 1 liegen seitlich & mittig auf dem Bildempfänger",
      image: {},
      drawing: {},
    },
    {
      label: "Zentralstrahl aufs Daumengrundgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1310_1,
    },
  ],
};
const exam1311 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Daumen & MHK 1 liegen seitlich & mittig auf dem Bildempfänger",
      image: images.image_1311_1,
      drawing: {},
    },
    {
      label: "Zentralstrahl aufs Daumengrundgelenk auf Bildempfängermitte",
      image: images.image_1311_1,
      drawing: images.drawing_1311_1,
    },
  ],
};
const exam1312 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Daumen & MHK 1 liegen seitlich & mittig auf dem Bildempfänger",
      image: {},
      drawing: {},
    },
    {
      label: "Zentralstrahl aufs Daumengrundgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1312_1,
    },
  ],
};
const exam1313 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Daumen & MHK 1 liegen seitlich & mittig auf dem Bildempfänger",
      image: images.image_1313_1,
      drawing: {},
    },
    {
      label: "Zentralstrahl aufs Daumengrundgelenk auf Bildempfängermitte",
      image: images.image_1313_1,
      drawing: images.drawing_1313_1,
    },
  ],
};
const exam1314 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Daumen & MHK 1 liegen seitlich & mittig auf dem Bildempfänger",
      image: {},
      drawing: {},
    },
    {
      label: "Zentralstrahl aufs Daumengrundgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1314_1,
    },
  ],
};
const exam1315 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "2. Finger liegt plan auf der Bildempfänger ",
      image: images.image_1315_1,
      drawing: images.drawing_1315_1,
    },
    {
      label: "Die übrigen Finger leicht abspreizen",
      image: images.image_1315_2,
      drawing: images.drawing_1315_2,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: images.image_1315_2,
      drawing: images.drawing_1315_3,
    },
  ],
};
const exam1316 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "2. Finger liegt plan auf der Bildempfänger",
      image: {},
      drawing: images.drawing_1315_1,
    },
    {
      label: "die übrigen Finger leicht abspreizen",
      image: {},
      drawing: images.drawing_1316_1,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1316_2,
    },
  ],
};
const exam1317 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Finger liegt vollständig seitlich auf dem Bildempfänger",
      image: images.image_1317_1,
      drawing: images.drawing_1317_1,
    },
    {
      label:
        "übrige Finger beugen, sodass diese außerhalb des Strahlenfeldes sind",
      image: images.image_1317_2,
      drawing: images.drawing_1317_2,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: images.image_1317_2,
      drawing: images.drawing_1317_3,
    },
  ],
};
const exam1318 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Finger liegt vollständig seitlich auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1318_1,
    },
    {
      label:
        "übrige Finger beugen, sodass diese außerhalb des Strahlenfeldes sind",
      image: {},
      drawing: images.drawing_1318_2,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1318_3,
    },
  ],
};
const exam1319 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "3. Finger liegt plan auf der Bildempfänger",
      image: {},
      drawing: images.drawing_1319_1,
    },
    {
      label: "die übrigen Finger leicht abspreizen",
      image: {},
      drawing: images.drawing_1319_2,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1319_3,
    },
  ],
};
const exam1320 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "3. Finger liegt plan auf der Bildempfänger",
      image: {},
      drawing: images.drawing_1320_1,
    },
    {
      label: "die übrigen Finger leicht abspreizen",
      image: {},
      drawing: images.drawing_1320_2,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1320_3,
    },
  ],
};
const exam1321 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "3. Finger liegt lateral mittig und parallel zum Bildempfänger",
      image: {},
      drawing: images.drawing_1321_1,
    },
    {
      label:
        "übrige Finger beugen, sodass diese außerhalb des Strahlenfeldes sind",
      image: {},
      drawing: images.drawing_1321_2,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1321_3,
    },
  ],
};
const exam1322 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "3. Finger liegt lateral mittig und parallel zum Bildempfänger",
      image: {},
      drawing: images.drawing_1321_1,
    },
    {
      label:
        "übrige Finger beugen, sodass diese außerhalb des Strahlenfeldes sind",
      image: {},
      drawing: images.drawing_1322_2,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1322_3,
    },
  ],
};
const exam1323 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "4. Finger liegt plan auf der Bildempfänger",
      image: {},
      drawing: images.drawing_1319_1,
    },
    {
      label: "die übrigen Finger leicht abspreizen",
      image: {},
      drawing: images.drawing_1323_1,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1323_2,
    },
  ],
};
const exam1324 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "4. Finger liegt plan auf der Bildempfänger",
      image: {},
      drawing: images.drawing_1320_1,
    },
    {
      label: "die übrigen Finger leicht abspreizen",
      image: {},
      drawing: images.drawing_1324_1,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1324_2,
    },
  ],
};
const exam1325 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "4. Finger liegt lateral mittig und parallel zum Bildempfänger",
      image: images.image_1325_1,
      drawing: images.drawing_1325_1,
    },
    {
      label:
        "übrige Finger beugen, sodass diese außerhalb des Strahlenfeldes sind",
      image: images.image_1325_2,
      drawing: images.drawing_1325_2,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: images.image_1325_2,
      drawing: images.drawing_1325_3,
    },
  ],
};
const exam1326 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "4. Finger liegt lateral mittig und parallel zum Bildempfänger",
      image: {},
      drawing: images.drawing_1326_1,
    },
    {
      label:
        "übrige Finger beugen, sodass diese außerhalb des Strahlenfeldes sind",
      image: {},
      drawing: images.drawing_1326_2,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1326_3,
    },
  ],
};
const exam1327 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "5. Finger liegt plan auf der Bildempfänger",
      image: {},
      drawing: images.drawing_1319_1,
    },
    {
      label: "die übrigen Finger leicht abspreizen",
      image: {},
      drawing: images.drawing_1327_1,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1327_2,
    },
  ],
};
const exam1328 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "5. Finger liegt plan auf der Bildempfänger",
      image: {},
      drawing: images.drawing_1320_1,
    },
    {
      label: "die übrigen Finger leicht abspreizen",
      image: {},
      drawing: images.drawing_1328_1,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1328_2,
    },
  ],
};
const exam1329 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "5. Finger liegt vollständig seitlich auf dem Bildempfänger",
      image: images.image_1329_1,
      drawing: images.drawing_1329_1,
    },
    {
      label:
        "übrige Finger beugen, sodass diese außerhalb des Strahlenfeldes sind",
      image: images.image_1329_2,
      drawing: images.drawing_1329_2,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: images.image_1329_2,
      drawing: images.drawing_1329_3,
    },
  ],
};
const exam1330 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "5. Finger liegt vollständig seitlich auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1330_1,
    },
    {
      label:
        "übrige Finger beugen, sodass diese außerhalb des Strahlenfeldes sind",
      image: {},
      drawing: images.drawing_1330_2,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1330_3,
    },
  ],
};
const exam1331 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Hand liegt mit der Handinnenfläche auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1331_1,
    },
    {
      label: "Mittelhand auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1331_2,
    },
    {
      label: "Zentralstrahl zwischen 2. und 3. MHK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1331_3,
    },
  ],
};
const exam1332 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Hand liegt mit der Handinnenfläche auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1332_1,
    },
    {
      label: "Mittelhand auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1332_2,
    },
    {
      label: "Zentralstrahl zwischen 2. und 3. MHK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1332_3,
    },
  ],
};

const exam1333 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Hand liegt seitlich auf",
      image: {},
      drawing: images.drawing_1333_1,
    },
    {
      label: "Daumen neben der Mittelhand",
      image: {},
      drawing: images.drawing_1333_2,
    },
    {
      label:
        "Zentralstrahl auf das Grundgelenk des 2. Fingers auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1333_3,
    },
  ],
};
const exam1334 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Hand liegt seitlich auf",
      image: {},
      drawing: images.drawing_1334_1,
    },
    {
      label: "Daumen neben der Mittelhand",
      image: {},
      drawing: images.drawing_1334_2,
    },
    {
      label:
        "Zentralstrahl auf das Grundgelenk des 2. Fingers auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1334_3,
    },
  ],
};
const exam1335 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Hand liegt seitlich auf",
      image: {},
      drawing: images.drawing_1335_1,
    },
    {
      label: "Daumen neben der Mittelhand",
      image: {},
      drawing: images.drawing_1335_2,
    },
    {
      label:
        "Zentralstrahl auf das Grundgelenk des 2. Fingers auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1335_3,
    },
  ],
};
const exam1336 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Hand liegt seitlich auf",
      image: {},
      drawing: images.drawing_1336_1,
    },
    {
      label: "Daumen neben der Mittelhand",
      image: {},
      drawing: images.drawing_1336_2,
    },
    {
      label:
        "Zentralstrahl auf das Grundgelenk des 2. Fingers auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1336_3,
    },
  ],
};
const exam1337 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label:
        "Hand liegt mit der Handinnenfläche und leicht gespreizten Fingern auf dem Bildempfänger",
      image: images.image_1337_2,
      drawing: images.drawing_1337_1,
    },
    {
      label: "Unterarm liegt auf dem Tisch auf",
      image: images.image_1337_1,
      drawing: images.drawing_1337_2,
    },
    {
      label:
        "Zentralstrahl zwischen die Grundgelenke des 2. und 3. Fingers auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1337_3,
    },
  ],
};
const exam1338 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label:
        "Hand liegt mit der Handinnenfläche und leicht gespreizten Fingern auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1338_1,
    },
    {
      label: "Unterarm liegt auf dem Tisch auf",
      image: {},
      drawing: images.drawing_1338_2,
    },
    {
      label:
        "Zentralstrahl zwischen die Grundgelenke des 2. und 3. Fingers auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1338_3,
    },
  ],
};
const exam1339 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Hand liegt mit der Handinnenfläche auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1339_1,
    },
    {
      label:
        "Hand leicht radial anheben, ca 30°, und Finger gefächert aufstellen (wie Zitherspieler Stellung)",
      image: {},
      drawing: images.drawing_1339_2,
    },
    {
      label: "Zentralstrahl zwischen 2. und 3. MHK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1339_3,
    },
  ],
};
const exam1340 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Hand liegt mit der Handinnenfläche auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1340_1,
    },
    {
      label:
        "Hand leicht radial anheben, ca 30°, und Finger gefächert aufstellen (wie Zitherspieler Stellung)",
      image: {},
      drawing: images.drawing_1340_2,
    },
    {
      label: "Zentralstrahl zwischen 2. und 3. MHK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1340_3,
    },
  ],
};
const exam1341 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Hand liegt seitlich auf",
      image: {},
      drawing: images.drawing_1335_1,
    },
    {
      label: "Daumen neben der Mittelhand",
      image: {},
      drawing: images.drawing_1335_2,
    },
    {
      label:
        "Zentralstrahl auf das Grundgelenk des 2. Fingers auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1335_3,
    },
  ],
};
const exam1342 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Hand liegt seitlich auf",
      image: {},
      drawing: images.drawing_1336_1,
    },
    {
      label: "Daumen neben der Mittelhand",
      image: {},
      drawing: images.drawing_1336_2,
    },
    {
      label:
        "Zentralstrahl auf das Grundgelenk des 2. Fingers auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1336_3,
    },
  ],
};
const exam1343 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Hand und Unterarm liegen streng lateral auf",
      image: {},
      drawing: images.drawing_1335_1,
    },
    {
      label: "Finger fächerförmig abwinkeln",
      image: {},
      drawing: {},
    },
    {
      label:
        "Zentralstrahl auf das Grundgelenk des 2. Fingers auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1335_3,
    },
  ],
};
const exam1344 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Hand und Unterarm liegen streng lateral auf",
      image: {},
      drawing: images.drawing_1336_1,
    },
    {
      label: "Finger fächerförmig abwinkeln",
      image: {},
      drawing: {},
    },
    {
      label:
        "Zentralstrahl auf das Grundgelenk des 2. Fingers auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1336_3,
    },
  ],
};
const exam1345 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: {},
    },
    {
      label: "Hand und Unterarm liegen streng lateral auf",
      image: {},
      drawing: images.drawing_1345_1,
    },
    {
      label: "Finger fächerförmig abwinkeln",
      image: {},
      drawing: images.drawing_1345_2,
    },
    {
      label:
        "Zentralstrahl auf das Grundgelenk des 2. Fingers auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1345_3,
    },
  ],
};
const exam1346 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: {},
    },
    {
      label:
        "beide Hände werden nebeneinander leicht radial angehoben, ca 30°, und Finger gefächert aufstellen (wie Zitherspieler Stellung)",
      image: {},
      drawing: images.drawing_1346_1,
    },
    {
      label: "Unterarme sind möglichst parallel",
      image: {},
      drawing: images.drawing_1346_2,
    },
    {
      label: "Zentralstrahl mittig der Hände auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1346_3,
    },
  ],
};
const exam1347 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Hand liegt palmarseitig flach auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1347_1,
    },
    {
      label: "Finger werden ulnar abgewinkelt - Daumen wird radial abgewinkelt",
      image: {},
      drawing: images.drawing_1347_2,
    },
    {
      label: "radialer Bereich des Handgelenk in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1347_3,
    },
    {
      label: "Zentralstrahl aufs Kahnbein auf Bildemfängermitte",
      image: {},
      drawing: images.drawing_1347_4,
    },
  ],
};
const exam1348 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Hand liegt palmarseitig flach auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1348_1,
    },
    {
      label: "Finger werden ulnar abgewinkelt - Daumen wird radial abgewinkelt",
      image: {},
      drawing: images.drawing_1348_2,
    },
    {
      label: "radialer Bereich des Handgelenk in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1348_3,
    },
    {
      label: "Zentralstrahl aufs Kahnbein auf Bildemfängermitte",
      image: {},
      drawing: images.drawing_1348_4,
    },
  ],
};
const exam1349 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Hand und Unterarm liegen palmar auf",
      image: {},
      drawing: images.drawing_1349_1,
    },
    {
      label: "Hand bildet eine Faust, Daumen in die Faust legen",
      image: {},
      drawing: images.drawing_1349_2,
    },
    {
      label: "Faust ulnar abwinkeln",
      image: {},
      drawing: images.drawing_1349_3,
    },
    {
      label: "radialer Bereich des Handgelenk in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1349_4,
    },
    {
      label: "Zentralstrahl aufs Kahnbein auf Bildemfängermitte",
      image: {},
      drawing: images.drawing_1349_5,
    },
  ],
};
const exam1350 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Hand und Unterarm liegen palmar auf",
      image: {},
      drawing: images.drawing_1350_1,
    },
    {
      label: "Hand bildet eine Faust, Daumen in die Faust legen",
      image: {},
      drawing: images.drawing_1350_2,
    },
    {
      label: "Faust ulnar abwinkeln",
      image: {},
      drawing: images.drawing_1350_3,
    },
    {
      label: "radialer Bereich des Handgelenk in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1350_4,
    },
    {
      label: "Zentralstrahl aufs Kahnbein auf Bildemfängermitte",
      image: {},
      drawing: images.drawing_1350_5,
    },
  ],
};
const exam1351 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Hand und Unterarm liegen palmar auf",
      image: {},
      drawing: images.drawing_1350_1,
    },
    {
      label: "Handgelenk radial um ca. 45° anheben",
      image: {},
      drawing: images.drawing_1350_2,
    },
    {
      label: "Handgelenk in Bildempfängermitte ",
      image: {},
      drawing: {},
    },
    {
      label: "Zentralstrahl mittig aufs Handgelenk auf Bildempfängermitte",
      image: {},
      drawing: {},
    },
  ],
};
const exam1352 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Hand und Unterarm liegen palmar auf",
      image: {},
      drawing: images.drawing_1352_1,
    },
    {
      label: "Handgelenk radial um ca. 45° anheben",
      image: {},
      drawing: images.drawing_1352_2,
    },
    {
      label: "Handgelenk in Bildempfängermitte ",
      image: {},
      drawing: {},
    },
    {
      label: "Zentralstrahl mittig aufs Handgelenk auf Bildempfängermitte",
      image: {},
      drawing: {},
    },
  ],
};
const exam1353 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Hand und Unterarm liegen streng lateral auf",
      image: {},
      drawing: images.drawing_1335_1,
    },
    {
      label: "Finger entspannt, Daumen ruhig neben Finger",
      image: {},
      drawing: images.drawing_1335_2,
    },
    {
      label: "Handgelenk in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1353_3,
    },
    {
      label: "Zentralstrahl mittig aufs Handgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1353_4,
    },
  ],
};
const exam1354 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Hand und Unterarm liegen streng lateral auf",
      image: {},
      drawing: images.drawing_1336_1,
    },
    {
      label: "Finger entspannt, Daumen ruhig neben Finger",
      image: {},
      drawing: images.drawing_1336_2,
    },
    {
      label: "Handgelenk in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1354_3,
    },
    {
      label: "Zentralstrahl mittig aufs Handgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1354_4,
    },
  ],
};
const exam1355 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Handrücken liegt auf dem Bildempfänger auf",
      image: {},
      drawing: images.drawing_1355_1,
    },
    {
      label: "Daumen neben der Hand",
      image: {},
      drawing: images.drawing_1355_2,
    },
    {
      label: "wenn möglich, die ulnare Seite ca. 30° anheben",
      image: {},
      drawing: images.drawing_1355_3,
    },
    {
      label:
        "Zentralstrahl auf proximales Ende des 1. und 2. MHK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1355_4,
    },
  ],
};
const exam1356 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Handrücken liegt auf dem Bildempfänger auf",
      image: {},
      drawing: images.drawing_1356_1,
    },
    {
      label: "Daumen neben der Hand",
      image: {},
      drawing: images.drawing_1356_2,
    },
    {
      label: "wenn möglich, die ulnare Seite ca. 30° anheben",
      image: {},
      drawing: images.drawing_1356_3,
    },
    {
      label:
        "Zentralstrahl auf proximales Ende des 1. und 2. MHK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1356_4,
    },
  ],
};
const exam1357 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label:
        "Hand und Unterarm liegen flach mit der palmaren Seite (Handfläche) auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1357_1,
    },
    {
      label: "Handgelenk in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1357_2,
    },
    {
      label: "Zentralstrahl mittig auf Handgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1357_3,
    },
  ],
};
const exam1358 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label:
        "Hand und Unterarm liegen flach mit der palmaren Seite (Handfläche) auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1358_1,
    },
    {
      label: "Handgelenk in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1358_2,
    },
    {
      label: "Zentralstrahl mittig auf Handgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1358_3,
    },
  ],
};
const exam1359 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Handgelenk liegt gerade seitlich auf",
      image: {},
      drawing: images.drawing_1335_1,
    },
    {
      label: "Finger entspannt, Daumen ruhig neben Finger",
      image: {},
      drawing: images.drawing_1335_2,
    },
    {
      label: "Handgelenk in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1353_3,
    },
    {
      label: "Zentralstrahl mittig aufs Handgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1353_4,
    },
  ],
};
const exam1360 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Handgelenk liegt gerade seitlich auf",
      image: {},
      drawing: images.drawing_1336_1,
    },
    {
      label: "Finger entspannt, Daumen ruhig neben Finger",
      image: {},
      drawing: images.drawing_1336_2,
    },
    {
      label: "Handgelenk in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1354_3,
    },
    {
      label: "Zentralstrahl mittig aufs Handgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1354_4,
    },
  ],
};
const exam1361 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Hand und Unterarm liegen palmar auf",
      image: {},
      drawing: images.drawing_1361_1,
    },
    {
      label: "Handgelenk radial um ca. 45° anheben",
      image: {},
      drawing: images.drawing_1361_2,
    },
    {
      label: "Handgelenk in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1361_3,
    },
    {
      label: "Zentralstrahl mittig aufs Handgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1361_4,
    },
  ],
};
const exam1362 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Hand und Unterarm liegen palmar auf",
      image: {},
      drawing: images.drawing_1362_1,
    },
    {
      label: "Handgelenk radial um ca. 45° anheben",
      image: {},
      drawing: images.drawing_1362_2,
    },
    {
      label: "Handgelenk in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1362_3,
    },
    {
      label: "Zentralstrahl mittig aufs Handgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1362_4,
    },
  ],
};
const exam1363 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Patient sitzt oder steht seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1363_1,
    },
    {
      label:
        "Unterarm senkrecht nach oben gestützt, sodass zwischen Oberarm und Handrücken ein Winkel ca. 90° entstehen",
      image: {},
      drawing: images.drawing_1363_2,
    },
    {
      label: "Zentralstrahl mittig auf Handwurzelknochen auf Bildemfängermitte",
      image: {},
      drawing: images.drawing_1363_3,
    },
  ],
};
const exam1364 = {
  steps: [
    {
      label: "Patient sitzt oder steht seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Hand liegt mit der Handinnenfläche auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1364_1,
    },
    {
      label:
        "Unterarm senkrecht nach oben gestützt, sodass zwischen Oberarm und Handrücken ein Winkel ca. 90° entstehen",
      image: {},
      drawing: images.drawing_1364_2,
    },
    {
      label: "Zentralstrahl mittig auf Handwurzelknochen auf Bildemfängermitte",
      image: {},
      drawing: images.drawing_1364_3,
    },
  ],
};
const exam1365 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Unterarm liegt mit der Handinnenfläche nach unten (Pronation)",
      image: {},
      drawing: images.drawing_1363_1,
    },
    {
      label:
        "Hand möglichst senkrecht nach oben ziehen, sodass zwischen Hand und Unterarm ein Winkel von 90° entsteht",
      image: {},
      drawing: images.drawing_1365_2,
    },
    {
      label: "Zentralstrahl mittig auf Handwurzelknochen auf Bildemfängermitte",
      image: {},
      drawing: images.drawing_1365_3,
    },
  ],
};
const exam1366 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Unterarm liegt mit der Handinnenfläche nach unten (Pronation)",
      image: {},
      drawing: images.drawing_1364_1,
    },
    {
      label:
        "Hand möglichst senkrecht nach oben ziehen, sodass zwischen Hand und Unterarm ein Winkel von 90° entsteht",
      image: {},
      drawing: images.drawing_1366_2,
    },
    {
      label: "Zentralstrahl mittig auf Handwurzelknochen auf Bildemfängermitte",
      image: {},
      drawing: images.drawing_1366_3,
    },
  ],
};
const exam1369 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label:
        "2. Finger liegt schräg, ca. 30- 45° radial angehoben, mittig auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1369_1,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1369_2,
    },
  ],
};
const exam1370 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label:
        "2. Finger liegt schräg, ca. 30- 45° radial angehoben, mittig auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1370_1,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1370_2,
    },
  ],
};
const exam1371 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label:
        "2. Finger liegt schräg, ca. 30- 45° radial angehoben, mittig auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1371_1,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1371_2,
    },
  ],
};
const exam1372 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label:
        "2. Finger liegt schräg, ca. 30- 45° radial angehoben, mittig auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1372_1,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1372_2,
    },
  ],
};
const exam1373 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label:
        "2. Finger liegt schräg, ca. 30- 45° radial angehoben, mittig auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1373_1,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1373_2,
    },
  ],
};
const exam1374 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label:
        "2. Finger liegt schräg, ca. 30- 45° radial angehoben, mittig auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1374_1,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1374_2,
    },
  ],
};
const exam1375 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label:
        "2. Finger liegt schräg, ca. 30- 45° radial angehoben, mittig auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1375_1,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1375_2,
    },
  ],
};
const exam1376 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label:
        "2. Finger liegt schräg, ca. 30- 45° radial angehoben, mittig auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1376_1,
    },
    {
      label: "Zentralstrahl auf Mittelglied und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1376_2,
    },
  ],
};
const exam1377 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label:
        "Hände und Unterarme liegen flach mit der palmaren Seite (Handfäche) auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1377_1,
    },
    {
      label: "Handgelenke befinden sich in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1377_2,
    },
    {
      label: "Zentralstrahl mittig der Handgelenke auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1377_3,
    },
  ],
};
const exam1378 = {
  steps: [
    {
      label: "Patient sitzt am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Handgelenke liegen gerade seitlich auf",
      image: {},
      drawing: images.drawing_1378_1,
    },
    {
      label: "Finger entspannt, Daumen ruhig neben Finger",
      image: {},
      drawing: images.drawing_1378_2,
    },
    {
      label: "Zentralstrahl mittig der Handgelenke auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1378_3,
    },
  ],
};
const exam1401 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1409,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1401_1,
    },
    {
      label: "Großzehe in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1401_2,
    },
    {
      label: "Zentralstrahl auf Großzehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1401_3,
    },
  ],
};
const exam1402 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1422,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1402_1,
    },
    {
      label: "Großzehe in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1402_2,
    },
    {
      label: "Zentralstrahl auf Großzehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1402_3,
    },
  ],
};
const exam1403 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1432,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1432_1,
    },
    {
      label: "Zeh liegt medial auf",
      image: {},
      drawing: images.drawing_1403_1,
    },
    {
      label: "Zentralstrahl auf Großzehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1403_3,
    },
  ],
};
const exam1404 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1431,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1431_1,
    },
    {
      label: "Zeh liegt medial auf",
      image: {},
      drawing: images.drawing_1404_1,
    },
    {
      label: "Zentralstrahl auf Großzehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1404_3,
    },
  ],
};
const exam1405 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1450,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1405_1,
    },
    {
      label: "Großzehe in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1401_2,
    },
    {
      label:
        "die laterale Seite leicht (30° - 45°) anheben, damit der Zeh überlagerungsfrei schräg dargestellt werden kann",
      image: {},
      drawing: images.drawing_1405_3,
    },
    {
      label: "Zentralstrahl auf Großzehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1405_4,
    },
  ],
};
const exam1406 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1449,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1406_1,
    },
    {
      label: "Großzehe in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1402_2,
    },
    {
      label:
        "die laterale Seite leicht (30° - 45°) anheben, damit der Zeh überlagerungsfrei schräg dargestellt werden kann",
      image: {},
      drawing: images.drawing_1406_3,
    },
    {
      label: "Zentralstrahl auf Großzehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1406_4,
    },
  ],
};
const exam1407 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1450,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1409_1,
    },
    {
      label: "2. Zeh in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1407_2,
    },
    {
      label: "Zentralstrahl auf 2. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1407_3,
    },
  ],
};
const exam1408 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1449,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1406_1,
    },
    {
      label: "2. Zeh in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1408_2,
    },
    {
      label: "Zentralstrahl auf 2. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1408_3,
    },
  ],
};
const exam1409 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1450,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1409_1,
    },
    {
      label: "2. Zeh in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1409_2,
    },
    {
      label:
        "die laterale Seite leicht (30° - 45°) anheben, damit der 2. Zeh überlagerungsfrei schräg dargestellt werden kann",
      image: {},
      drawing: images.drawing_1409_3,
    },
    {
      label: "Zentralstrahl auf 2. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1409_4,
    },
  ],
};
const exam1410 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1449,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1410_1,
    },
    {
      label: "2. Zeh in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1410_2,
    },
    {
      label:
        "die laterale Seite leicht (30° - 45°) anheben, damit der 2. Zeh überlagerungsfrei schräg dargestellt werden kann",
      image: {},
      drawing: images.drawing_1410_3,
    },
    {
      label: "Zentralstrahl auf 2. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1410_4,
    },
  ],
};
const exam1411 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1450,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1409_1,
    },
    {
      label: "3. Zeh in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1411_2,
    },
    {
      label: "Zentralstrahl auf 3. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1411_3,
    },
  ],
};
const exam1412 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1449,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1406_1,
    },
    {
      label: "3. Zeh in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1412_2,
    },
    {
      label: "Zentralstrahl auf 3. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1412_3,
    },
  ],
};
const exam1413 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1450,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1409_1,
    },
    {
      label: "3. Zeh in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1411_2,
    },
    {
      label:
        "Die laterale Seite leicht (30° - 45°) anheben, damit der 3. Zeh überlagerungsfrei schräg dargestellt werden kann",
      image: {},
      drawing: images.drawing_1413_2,
    },
    {
      label: "Zentralstrahl auf 3. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1413_3,
    },
  ],
};
const exam1414 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1449,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1406_1,
    },
    {
      label: "3. Zeh in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1412_2,
    },
    {
      label:
        "Die laterale Seite leicht (30° - 45°) anheben, damit der 3. Zeh überlagerungsfrei schräg dargestellt werden kann",
      image: {},
      drawing: images.drawing_1414_2,
    },
    {
      label: "Zentralstrahl auf 3. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1414_3,
    },
  ],
};
const exam1415 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1450,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1409_1,
    },
    {
      label: "4. Zeh in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1411_2,
    },
    {
      label: "Zentralstrahl auf 4. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1415_3,
    },
  ],
};
const exam1416 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1449,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1406_1,
    },
    {
      label: "4. Zeh in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1412_2,
    },
    {
      label: "Zentralstrahl auf 4. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1416_3,
    },
  ],
};
const exam1417 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1450,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1409_1,
    },
    {
      label: "4. Zeh in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1411_2,
    },
    {
      label:
        "Die laterale Seite leicht (30° - 45°) anheben, damit der 4. Zeh überlagerungsfrei schräg dargestellt werden kann",
      image: {},
      drawing: images.drawing_1413_2,
    },
    {
      label: "Zentralstrahl auf 4. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1417_3,
    },
  ],
};
const exam1418 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1449,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1406_1,
    },
    {
      label: "4. Zeh in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1412_2,
    },
    {
      label:
        "Die laterale Seite leicht (30° - 45°) anheben, damit der 4. Zeh überlagerungsfrei schräg dargestellt werden kann",
      image: {},
      drawing: images.drawing_1414_2,
    },
    {
      label: "Zentralstrahl auf 4. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1418_3,
    },
  ],
};
const exam1419 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1450,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1409_1,
    },
    {
      label: "5. Zeh in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1411_2,
    },
    {
      label: "Zentralstrahl auf 5. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1419_3,
    },
  ],
};
const exam1420 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1449,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1406_1,
    },
    {
      label: "5. Zeh in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1412_2,
    },
    {
      label: "Zentralstrahl auf 5. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1420_3,
    },
  ],
};

const exam1423 = {
  steps: [
    {
      label: "Patient sitzt oder liegt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1450,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1423_1,
    },
    {
      label: "5. Zeh liegt seitlich in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1423_2,
    },
    {
      label:
        "die laterale Seite leicht (30° - 45°) anheben, damit der 5. Zeh überlagerungsfrei schräg dargestellt werden kann",
      image: {},
      drawing: images.drawing_1423_3,
    },
    {
      label: "Zentralstrahl auf 5. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1423_4,
    },
  ],
};
const exam1424 = {
  steps: [
    {
      label: "Patient sitzt oder liegt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1449,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1424_1,
    },
    {
      label: "5. Zeh liegt seitlich in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1424_2,
    },
    {
      label:
        "die laterale Seite leicht (30° - 45°) anheben, damit der 5. Zeh überlagerungsfrei schräg dargestellt werden kann",
      image: {},
      drawing: images.drawing_1424_3,
    },
    {
      label: "Zentralstrahl auf 5. Zehe und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1424_4,
    },
  ],
};
const exam1425 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1425,
    },
    {
      label: "Zehen (d.z.u.S.)  sind aufgestellt",
      image: {},
      drawing: images.drawing_1425_1,
    },
    {
      label: "Übergang Großzehe /Mittelfuß in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1425_2,
    },
    {
      label:
        "Zentralstrahl auf Übergang Großzehe/Mittelfuß auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1425_3,
    },
  ],
};
const exam1426 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1426,
    },
    {
      label: "Zehen (d.z.u.S.)  sind aufgestellt",
      image: {},
      drawing: images.drawing_1426_1,
    },
    {
      label: "Übergang Großzehe /Mittelfuß in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1426_2,
    },
    {
      label:
        "Zentralstrahl auf Übergang Großzehe/Mittelfuß auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1426_3,
    },
  ],
};
const exam1427 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1427,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1427_1,
    },
    {
      label: "Vorfuß in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1427_2,
    },
    {
      label: "Zentralstrahl zwischen 2. und 3. Zehe auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1427_3,
    },
  ],
};
const exam1428 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1471,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1428_1,
    },
    {
      label: "Vorfuß in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1428_2,
    },
    {
      label: "Zentralstrahl zwischen 2. und 3. Zehe auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1428_3,
    },
  ],
};
const exam1429 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1427,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1427_1,
    },
    {
      label:
        "den Fuß nun lateral ca. 30- 45 ° anheben, die Zehen dürfen sich nicht überlagern",
      image: {},
      drawing: images.drawing_1429_2,
    },
    {
      label: "Vorfuß in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1429_3,
    },
    {
      label: "Zentralstrahl zwischen 2. und 3. Zehe auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1429_4,
    },
  ],
};
const exam1430 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1471,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1428_1,
    },
    {
      label:
        "den Fuß nun lateral ca. 30- 45 ° anheben, die Zehen dürfen sich nicht überlagern",
      image: {},
      drawing: images.drawing_1430_2,
    },
    {
      label: "Vorfuß in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1430_3,
    },
    {
      label: "Zentralstrahl zwischen 2. und 3. Zehe auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1430_4,
    },
  ],
};
const exam1431 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1432,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1432_1,
    },
    {
      label: "Fuß liegt seitlich mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_1432_2,
    },
    {
      label: "Vorfuß in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1432_3,
    },
    {
      label: "Zentralstrahl auf den Vorfuß und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1432_4,
    },
  ],
};
const exam1432 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1431,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1431_1,
    },
    {
      label: "Fuß liegt seitlich mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_1431_2,
    },
    {
      label: "Vorfuß in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1431_3,
    },
    {
      label: "Zentralstrahl auf den Vorfuß und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1431_4,
    },
  ],
};
const exam1433 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1427,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1427_1,
    },
    {
      label: "Mittelfuß auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1433_2,
    },
    {
      label: "Zentralstrahl zwischen 2. und 3. MFK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1433_3,
    },
  ],
};
const exam1434 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1471,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1428_1,
    },
    {
      label: "Mittelfuß auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1434_2,
    },
    {
      label: "Zentralstrahl zwischen 2. und 3. MFK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1434_3,
    },
  ],
};
const exam1435 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1427,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1427_1,
    },
    {
      label:
        "den Fuß nun lateral ca. 30- 45 ° anheben, die MFK dürfen sich nicht überlagern",
      image: {},
      drawing: images.drawing_1429_2,
    },
    {
      label: "Mittelfuß auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1435_3,
    },
    {
      label: "Zentralstrahl zwischen 2. und 3. MFK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1435_4,
    },
  ],
};
const exam1436 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1471,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1428_1,
    },
    {
      label:
        "den Fuß nun lateral ca. 30- 45 ° anheben, die MFK dürfen sich nicht überlagern",
      image: {},
      drawing: images.drawing_1430_2,
    },
    {
      label: "Mittelfuß auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1436_3,
    },
    {
      label: "Zentralstrahl zwischen 2. und 3. MFK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1436_4,
    },
  ],
};
const exam1437 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1432,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1432_1,
    },
    {
      label: "Fuß liegt seitlich mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_1432_2,
    },
    {
      label: "Mittelfuß auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1432_3,
    },
    {
      label: "Zentralstrahl in Mitte der MFK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1438_4,
    },
  ],
};
const exam1438 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1431,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1431_1,
    },
    {
      label: "Fuß liegt seitlich mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_1431_2,
    },
    {
      label: "Mittelfuß auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1431_3,
    },
    {
      label: "Zentralstrahl in Mitte der MFK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1437_4,
    },
  ],
};
const exam1439 = {
  steps: [
    {
      label: "Patient sitzt oder liegt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1409,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1409_1,
    },
    {
      label: "ganzer Fuß auf Bildempfänger",
      image: {},
      drawing: images.drawing_1409_2,
    },
    {
      label: "Zentralstrahl auf etwa Mitte des 2. MFK in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1439_3,
    },
  ],
};
const exam1440 = {
  steps: [
    {
      label: "Patient sitzt oder liegt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1422,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1406_1,
    },
    {
      label: "ganzer Fuß auf Bildempfänger",
      image: {},
      drawing: images.drawing_1410_2,
    },
    {
      label: "Zentralstrahl auf etwa Mitte des 2. MFK in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1440_3,
    },
  ],
};
const exam1441 = {
  steps: [
    {
      label: "Patient sitzt oder liegt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1450,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1409_1,
    },
    {
      label: "ganzer Fuß auf Bildempfänger",
      image: {},
      drawing: images.drawing_1409_2,
    },
    {
      label:
        "den Fuß nun lateral ca. 30- 45 ° anheben, die Zehen und MFK dürfen sich nicht überlagern",
      image: {},
      drawing: images.drawing_1429_2,
    },
    {
      label: "Zentralstrahl zwischen 2. und 3. MFK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1441_4,
    },
  ],
};
const exam1442 = {
  steps: [
    {
      label: "Patient sitzt oder liegt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1422,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1406_1,
    },
    {
      label: "ganzer Fuß auf Bildempfänger",
      image: {},
      drawing: images.drawing_1410_2,
    },
    {
      label:
        "den Fuß nun lateral ca. 30- 45 ° anheben, die Zehen und MFK dürfen sich nicht überlagern",
      image: {},
      drawing: images.drawing_1430_2,
    },
    {
      label: "Zentralstrahl zwischen 2. und 3. MFK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1442_4,
    },
  ],
};
const exam1443 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1431,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1431_1,
    },
    {
      label: "Fuß liegt seitlich mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_1431_2,
    },
    {
      label: "ganzer Fuß auf Bildempfänger",
      image: {},
      drawing: images.drawing_1431_3,
    },
    {
      label: "Zentralstrahl in Mitte der MFK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1443_4,
    },
  ],
};
const exam1444 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1432,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1432_1,
    },
    {
      label: "Fuß liegt seitlich mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_1432_2,
    },
    {
      label: "ganzer Fuß auf Bildempfänger",
      image: {},
      drawing: images.drawing_1432_3,
    },
    {
      label: "Zentralstrahl in Mitte der MFK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1444_4,
    },
  ],
};
const exam1445 = {
  steps: [
    {
      label: "Patient steht seitlich am Stativ",
      image: {},
      drawing: images.drawing_1445,
    },
    {
      label: "laterale Fußseite steht zum Bildempfänger",
      image: {},
      drawing: images.drawing_1445_1,
    },
    {
      label: "das n. z. u. Bein wird angehoben",
      image: {},
      drawing: images.drawing_1445_2,
    },
    {
      label: "Zentralstrahl in Mitte der MFK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1445_3,
    },
  ],
};
const exam1446 = {
  steps: [
    {
      label: "Patient steht seitlich am Stativ",
      image: {},
      drawing: images.drawing_1446,
    },
    {
      label: "laterale Fußseite steht zum Bildempfänger",
      image: {},
      drawing: images.drawing_1446_1,
    },
    {
      label: "das n. z. u. Bein wird angehoben",
      image: {},
      drawing: images.drawing_1446_2,
    },
    {
      label: "Zentralstrahl in Mitte der MFK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1446_3,
    },
  ],
};
const exam1447 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1431,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1431_1,
    },
    {
      label: "Fuß liegt seitlich mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_1431_2,
    },
    {
      label: "Zentralstrahl auf Fersenmitte und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1447_3,
    },
  ],
};
const exam1448 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1432,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1432_1,
    },
    {
      label: "Fuß liegt seitlich mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_1432_2,
    },
    {
      label: "Zentralstrahl auf Fersenmitte und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1448_3,
    },
  ],
};
const exam1449 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1449,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_1449_1,
    },
    {
      label: "Fersenbein liegt auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1449_2,
    },
    {
      label:
        "Vorfuß mit einer Binde zum Körper ziehen, sodass bestenfalls zwischen Fuß und Unterschenkel ein Winkel von 90° entsteht",
      image: {},
      drawing: images.drawing_1449_3,
    },
    {
      label: "Zentralstrahl auf Ferse auf die Bildempfänger",
      image: {},
      drawing: images.drawing_1449_4,
    },
  ],
};
const exam1450 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1450,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_1450_1,
    },
    {
      label: "Fersenbein liegt auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_1450_2,
    },
    {
      label:
        "Vorfuß mit einer Binde zum Körper ziehen, sodass bestenfalls zwischen Fuß und Unterschenkel ein Winkel von 90° entsteht",
      image: {},
      drawing: images.drawing_1450_3,
    },
    {
      label: "Zentralstrahl auf Ferse auf die Bildempfänger",
      image: {},
      drawing: images.drawing_1450_4,
    },
  ],
};
const exam1451 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1450,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1423_1,
    },
    {
      label: "ganzer Fußwurzelbereich auf Bildempfänger",
      image: {},
      drawing: images.drawing_1451_2,
    },
    {
      label:
        "Zentralstrahl proximal der MFK auf die Fußwurzeln auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1451_3,
    },
  ],
};
const exam1452 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1449,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1424_1,
    },
    {
      label: "ganzer Fußwurzelbereich auf Bildempfänger",
      image: {},
      drawing: images.drawing_1452_2,
    },
    {
      label:
        "Zentralstrahl proximal der MFK auf die Fußwurzeln auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1452_3,
    },
  ],
};
const exam1453 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1450,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1423_1,
    },
    {
      label: "ganzer Fußwurzelbereich auf Bildempfänger",
      image: {},
      drawing: images.drawing_1451_2,
    },
    {
      label: "den Fuß nun lateral ca. 30- 45 ° anheben",
      image: {},
      drawing: images.drawing_1429_2,
    },
    {
      label: "Zentralstrahl 2 QF proximal der MFK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1453_4,
    },
  ],
};
const exam1454 = {
  steps: [
    {
      label: "Patient sitzt auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1449,
    },
    {
      label:
        "Bein (d. Z. u.S.) leicht im Knie gebeugt, so dass der Fuß plan aufliegt",
      image: {},
      drawing: images.drawing_1424_1,
    },
    {
      label: "ganzer Fußwurzelbereich auf Bildempfänger",
      image: {},
      drawing: images.drawing_1452_2,
    },
    {
      label: "den Fuß nun lateral ca. 30- 45 ° anheben",
      image: {},
      drawing: images.drawing_1430_2,
    },
    {
      label: "Zentralstrahl 2 QF proximal der MFK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1454_4,
    },
  ],
};
const exam1455 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1431,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1431_1,
    },
    {
      label: "Fuß liegt seitlich mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_1455_2,
    },
    {
      label: "Fußwurzelbereich auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1431_2,
    },
    {
      label: "Zentralstrahl auf Fußwurzel und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1455_4,
    },
  ],
};
const exam1456 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1432,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1432_1,
    },
    {
      label: "Fuß liegt seitlich mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_1456_2,
    },
    {
      label: "Fußwurzelbereich auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1432_2,
    },
    {
      label: "Zentralstrahl auf Fußwurzel und Bildempfängermitte",
      image: {},
      drawing: images.drawing_1456_4,
    },
  ],
};
const exam1457 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1427,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_1457_1,
    },
    {
      label:
        "Vorfuß zum Körper heranziehen, sodass zwischen Fuß und Unterschenkel ein Winkel von 90° entsteht",
      image: {},
      drawing: images.drawing_1457_3,
    },
    {
      label:
        "Fuß ca. 15° - 20° nach medial kippen (um den Malleolus lateralis überlagerungsfrei darzustellen)",
      image: {},
      drawing: images.drawing_1457_2,
    },
    {
      label:
        "Zentralstrahl senkrecht auf gedachte Verbindungslinie der beiden Malleolen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1457_4,
    },
  ],
};
const exam1458 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1471,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_1458_1,
    },
    {
      label:
        "Vorfuß zum Körper heranziehen, sodass zwischen Fuß und Unterschenkel ein Winkel von 90° entsteht",
      image: {},
      drawing: images.drawing_1458_3,
    },
    {
      label:
        "Fuß ca. 15° - 20° nach medial kippen (um den Malleolus lateralis überlagerungsfrei darzustellen)",
      image: {},
      drawing: images.drawing_1458_2,
    },
    {
      label:
        "Zentralstrahl senkrecht auf gedachte Verbindungslinie der beiden Malleolen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1458_4,
    },
  ],
};
const exam1459 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1432,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1432_1,
    },
    {
      label: "Fußgelenk liegt seitlich mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_1455_2,
    },
    {
      label: "Vorfuß wird zum Körper herangezogen",
      image: {},
      drawing: images.drawing_1459_2,
    },
    {
      label:
        "Zentralstrahl auf medialen Malleolus und auf die Bildempfängermite",
      image: {},
      drawing: images.drawing_1459_4,
    },
  ],
};
const exam1460 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1431,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1431_1,
    },
    {
      label: "Fußgelenk liegt seitlich mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_1456_2,
    },
    {
      label: "Vorfuß wird zum Körper herangezogen",
      image: {},
      drawing: images.drawing_1460_2,
    },
    {
      label:
        "Zentralstrahl auf medialen Malleolus und auf die Bildempfängermite",
      image: {},
      drawing: images.drawing_1460_4,
    },
  ],
};
const exam1463 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1427,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_1457_1,
    },
    {
      label: "Fußgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1469_2,
    },
    {
      label: "Unterschenkel und Fuß im Winkel von 45° nach innen drehen",
      image: {},
      drawing: images.drawing_1463_3,
    },
    {
      label:
        "Zentralstrahl senkrecht auf gedachte Verbindungslinie der beiden Malleolen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1463_4,
    },
  ],
};
const exam1464 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1471,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_1458_1,
    },
    {
      label: "Fußgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1470_2,
    },
    {
      label: "Unterschenkel und Fuß im Winkel von 45° nach innen drehen",
      image: {},
      drawing: images.drawing_1464_3,
    },
    {
      label:
        "Zentralstrahl senkrecht auf gedachte Verbindungslinie der beiden Malleolen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1464_4,
    },
  ],
};
const exam1465 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1427,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_1457_1,
    },
    {
      label: "Fußgelenk in Teloshalterung fixieren",
      image: {},
      drawing: images.drawing_1465_2,
    },
    {
      label:
        "Zentralstrahl senkrecht auf gedachte Verbindungslinie der beiden Malleolen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1465_3,
    },
  ],
};
const exam1466 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1471,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_1458_1,
    },
    {
      label: "Fußgelenk in Teloshalterung fixieren",
      image: {},
      drawing: images.drawing_1466_2,
    },
    {
      label:
        "Zentralstrahl senkrecht auf gedachte Verbindungslinie der beiden Malleolen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1466_3,
    },
  ],
};
const exam1467 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1432,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1432_1,
    },
    {
      label: "Fußgelenk in Teloshalterung fixieren",
      image: {},
      drawing: images.drawing_1467_2,
    },
    {
      label:
        "Zentralstrahl auf medialen Malleolus und auf die Bildempfängermite",
      image: {},
      drawing: images.drawing_1467_3,
    },
  ],
};
const exam1468 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1431,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_1431_1,
    },
    {
      label: "Fußgelenk in Teloshalterung fixieren",
      image: {},
      drawing: images.drawing_1468_2,
    },
    {
      label:
        "Zentralstrahl auf medialen Malleolus und auf die Bildempfängermite",
      image: {},
      drawing: images.drawing_1468_3,
    },
  ],
};
const exam1469 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1427,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_1457_1,
    },
    {
      label: "Fußgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1469_2,
    },
    {
      label: "Unterschenkel und Fuß im Winkel von 45° nach außen drehen",
      image: {},
      drawing: images.drawing_1469_3,
    },
    {
      label:
        "Zentralstrahl senkrecht auf gedachte Verbindungslinie der beiden Malleolen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1469_4,
    },
  ],
};
const exam1470 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1471,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_1470_1,
    },
    {
      label: "Fußgelenk auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1470_2,
    },
    {
      label: "Unterschenkel und Fuß im Winkel von 45° nach außen drehen",
      image: {},
      drawing: images.drawing_1470_3,
    },
    {
      label:
        "Zentralstrahl senkrecht auf gedachte Verbindungslinie der beiden Malleolen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1470_4,
    },
  ],
};
const exam1471 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1471,
    },
    {
      label:
        "Beine leicht im Knie angewinkelt, so dass die Füße plan aufliegen",
      image: {},
      drawing: images.drawing_1471_1,
    },
    {
      label: "beide Füße symmetrisch auf Bildempfänger",
      image: {},
      drawing: images.drawing_1471_2,
    },
    {
      label: "Zentralstrahl auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1471_3,
    },
  ],
};
const exam1472 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1471,
    },
    {
      label:
        "Beine leicht im Knie angewinkelt, so dass die Füße plan aufliegen",
      image: {},
      drawing: images.drawing_1471_1,
    },
    {
      label: "beide Füße symmetrisch auf Bildempfänger",
      image: {},
      drawing: images.drawing_1471_2,
    },
    {
      label: "Zentralstrahl auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1472_3,
    },
  ],
};
const exam1473 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1471,
    },
    {
      label:
        "Beine leicht im Knie angewinkelt, so dass die Füße plan aufliegen",
      image: {},
      drawing: images.drawing_1471_1,
    },
    {
      label: "beide Füße symmetrisch auf Bildempfänger",
      image: {},
      drawing: images.drawing_1471_2,
    },
    {
      label:
        "die Füße nun lateral ca. 30- 45 ° anheben, die Zehen und MFK dürfen sich nicht überlagern",
      image: {},
      drawing: images.drawing_1473_3,
    },
    {
      label: "Zentralstrahl auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1473_4,
    },
  ],
};
const exam1474 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1471,
    },
    {
      label:
        "Beine leicht im Knie angewinkelt, so dass die Füße plan aufliegen",
      image: {},
      drawing: images.drawing_1471_1,
    },
    {
      label: "beide Füße symmetrisch auf Bildempfänger",
      image: {},
      drawing: images.drawing_1471_2,
    },
    {
      label:
        "die Füße nun lateral ca. 30- 45 ° anheben, die Zehen und MFK dürfen sich nicht überlagern",
      image: {},
      drawing: images.drawing_1473_3,
    },
    {
      label: "Zentralstrahl auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1474_4,
    },
  ],
};
const exam1501 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1501,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1501_1,
    },
    {
      label: "Wirbelsäule in Stativmitte",
      image: {},
      drawing: images.drawing_1501_2,
    },
    {
      label:
        "Zentralstrahl auf das Brustbein in Höhe des unteren Corpus sterni",
      image: {},
      drawing: images.drawing_1501_3,
    },
  ],
};
const exam1502 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1502,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1502_1,
    },
    {
      label: "Wirbelsäule in Stativmitte",
      image: {},
      drawing: images.drawing_1502_2,
    },
    {
      label:
        "Zentralstrahl auf das Brustbein in Höhe des unteren Corpus sterni",
      image: {},
      drawing: images.drawing_1502_3,
    },
  ],
};
const exam1503 = {
  steps: [
    {
      label: "Patient steht seitlich am Stativ",
      image: {},
      drawing: images.drawing_1503,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1503_1,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1503_2,
    },
    {
      label:
        "Zentralstrahl auf das Brustbein in Höhe des unteren Corpus sterni",
      image: {},
      drawing: images.drawing_1503_3,
    },
  ],
};
const exam1504 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1504,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1504_2,
    },
    {
      label:
        "Zentralstrahl auf das Brustbein in Höhe des unteren Corpus sterni",
      image: {},
      drawing: images.drawing_1504_3,
    },
  ],
};
const exam1505 = {
  steps: [
    {
      label: "Patient steht seitlich am Stativ",
      image: {},
      drawing: images.drawing_1505,
    },
    {
      label:
        "den Patienten mit seinem Rücken um 15° in Richtung des Statives drehen",
      image: {},
      drawing: images.drawing_1505_1,
    },
    {
      label:
        "zwischen Stativ und Patient(enrücken) entsteht ein Winkel von 75°",
      image: {},
      drawing: images.drawing_1505_2,
    },
    {
      label: "Wirbelsäule strecken, Arme über den Kopf nehmen",
      image: {},
      drawing: images.drawing_1505_3,
    },
    {
      label:
        "Zentralstrahl auf die mittlere Axilarlinie in Höhe der Scapulaspitze auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1505_4,
    },
  ],
};
const exam1506 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1502,
    },
    {
      label: "die z. u. Seite 75° anheben, mit Keilkissen unterpolstern",
      image: {},
      drawing: images.drawing_1506_1,
    },
    {
      label: "Wirbelsäule strecken, Arme über den Kopf nehmen",
      image: {},
      drawing: images.drawing_1506_2,
    },
    {
      label:
        "Zentralstrahl auf die mittlere Axilarlinie in Höhe der Scapulaspitze auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1506_3,
    },
  ],
};
const exam1507 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1507,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1507_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1507_2,
    },
    {
      label:
        "Zentralstrahl oberhalb vom Sternum auf den 1. BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1507_3,
    },
  ],
};
const exam1508 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1508_1,
    },
    {
      label: "Arme vor dem Körper",
      image: {},
      drawing: images.drawing_1504_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1508_3,
    },
  ],
};
const exam1509 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1507,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1509_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1509_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1509_3,
    },
  ],
};
const exam1510 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1510_1,
    },
    {
      label: "Arme vor dem Körper",
      image: {},
      drawing: images.drawing_1504_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1510_3,
    },
  ],
};
const exam1511 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1507,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1511_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1511_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1511_3,
    },
  ],
};
const exam1512 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1512_1,
    },
    {
      label: "Arme vor dem Körper",
      image: {},
      drawing: images.drawing_1504_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1512_3,
    },
  ],
};
const exam1513 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1507,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1513_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1513_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1513_3,
    },
  ],
};
const exam1514 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1514_1,
    },
    {
      label: "Arme vor dem Körper",
      image: {},
      drawing: images.drawing_1504_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1514_3,
    },
  ],
};
const exam1515 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1507,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1515_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1515_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1515_3,
    },
  ],
};
const exam1516 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1516_1,
    },
    {
      label: "Arme vor dem Körper",
      image: {},
      drawing: images.drawing_1504_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1516_3,
    },
  ],
};
const exam1517 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1507,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1517_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1517_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1517_3,
    },
  ],
};
const exam1518 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1518_1,
    },
    {
      label: "Arme vor dem Körper",
      image: {},
      drawing: images.drawing_1504_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1518_3,
    },
  ],
};
const exam1519 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1507,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1519_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1519_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1519_3,
    },
  ],
};
const exam1520 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1520_1,
    },
    {
      label: "Arme vor dem Körper",
      image: {},
      drawing: images.drawing_1504_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1520_3,
    },
  ],
};
const exam1521 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1507,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1521_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1521_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1521_3,
    },
  ],
};
const exam1522 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1522_1,
    },
    {
      label: "Arme vor dem Körper",
      image: {},
      drawing: images.drawing_1504_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1522_3,
    },
  ],
};
const exam1523 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1507,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1523_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1523_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1523_3,
    },
  ],
};
const exam1524 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1524_1,
    },
    {
      label: "Arme vor dem Körper",
      image: {},
      drawing: images.drawing_1504_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1524_3,
    },
  ],
};
const exam1525 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1507,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1525_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1525_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1525_3,
    },
  ],
};
const exam1526 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1526_1,
    },
    {
      label: "Arme vor dem Körper",
      image: {},
      drawing: images.drawing_1504_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1526_3,
    },
  ],
};
const exam1527 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1507,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1527_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1527_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1527_3,
    },
  ],
};
const exam1528 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1528_1,
    },
    {
      label: "Arme vor dem Körper",
      image: {},
      drawing: images.drawing_1504_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1528_3,
    },
  ],
};
const exam1529 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1507,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1529_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1529_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1529_3,
    },
  ],
};
const exam1530 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1530_1,
    },
    {
      label: "Arme vor dem Körper",
      image: {},
      drawing: images.drawing_1504_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1530_3,
    },
  ],
};
const exam1531 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1507,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1531_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1531_2,
    },
    {
      label: "Zentralstrahl auf entsprechenden BWK und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1531_3,
    },
  ],
};
const exam1532 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1532,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1532_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1532_2,
    },
    {
      label:
        "Zentralstrahl auf das Brustbein in Höhe des unteren Corpus sterni",
      image: {},
      drawing: images.drawing_1532_3,
    },
  ],
};
const exam1533 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1533_1,
    },
    {
      label: "Arme vor dem Körper",
      image: {},
      drawing: images.drawing_1504_2,
    },
    {
      label:
        "Zentralstrahl eine Handbreit oberhalb des Beckenkamms auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1533_3,
    },
  ],
};
const exam1534 = {
  steps: [
    {
      label: "Patient steht seitlich am Stativ",
      image: {},
      drawing: images.drawing_1534,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1534_1,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches ",
      image: {},
      drawing: images.drawing_1534_2,
    },
    {
      label:
        "Zentralstrahl auf das Brustbein in Höhe des unteren Corpus sterni",
      image: {},
      drawing: images.drawing_1534_3,
    },
  ],
};
const exam1535 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1532,
    },
    {
      label: "z. u. Körperseite 45° anheben",
      image: {},
      drawing: images.drawing_1535_1,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches",
      image: {},
      drawing: images.drawing_1535_2,
    },
    {
      label:
        "Zentralstrahl höhe Sternummitte, 2-3 QF lateral zur angehobenen Seite auf Bildemfängermitte",
      image: {},
      drawing: images.drawing_1535_3,
    },
  ],
};
const exam1536 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1536,
    },
    {
      label: "z. u. Körperseite 45° anheben",
      image: {},
      drawing: images.drawing_1536_1,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches",
      image: {},
      drawing: images.drawing_1536_2,
    },
    {
      label:
        "Zentralstrahl höhe Sternummitte, 2-3 QF lateral zur angehobenen Seite auf Bildemfängermitte",
      image: {},
      drawing: images.drawing_1536_3,
    },
  ],
};
const exam1601 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1101,
    },
    {
      label: "beide Beine gleich",
      image: {},
      drawing: images.drawing_1601_1,
    },
    {
      label: "Kopf leicht anheben",
      image: {},
      drawing: images.drawing_1601_2,
    },
    {
      label: "Mund ist geschlossen",
      image: {},
      drawing: images.drawing_1601_3,
    },
    {
      label: "Zentralstrahl auf die Kinnspitze",
      image: {},
      drawing: images.drawing_1601_4,
    },
  ],
};
const exam1602 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1602,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1602_1,
    },
    {
      label: "Mund ist geschlossen",
      image: {},
      drawing: images.drawing_1602_2,
    },
    {
      label: "Zentralstrahl auf die Kinnspitze",
      image: {},
      drawing: images.drawing_1602_3,
    },
  ],
};
const exam1603 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1101,
    },
    {
      label: "beide Beine gleich",
      image: {},
      drawing: images.drawing_1603_1,
    },
    {
      label: "Oberkörper gerade",
      image: {},
      drawing: images.drawing_1603_2,
    },
    {
      label:
        "Unterkiefer schnell auf und ab bewegen, wobei der übrige Kopf gerade bleibt",
      image: {},
      drawing: images.drawing_1603_3,
    },
    {
      label:
        "Zentralstrahl ca. auf Kinnhöhe des geschlossenen Mundes auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1603_4,
    },
  ],
};
const exam1604 = {
  steps: [
    {
      label: "Patient steht seitlich am Stativ",
      image: {},
      drawing: images.drawing_1604,
    },
    {
      label: "Kopf leicht anheben",
      image: {},
      drawing: images.drawing_1604_1,
    },
    {
      label:
        "Schultern so weit wie möglich runter ziehen, damit untere HWK nicht überlagern",
      image: {},
      drawing: images.drawing_1604_2,
    },
    {
      label:
        "Zentralstrahl  auf die Mitte der HWS in Höhe 3. HWK/4.HWK auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1604_3,
    },
  ],
};
const exam1606 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1606,
    },
    {
      label: "die rechte Körperseite 45° anheben",
      image: {},
      drawing: images.drawing_1606_1,
    },
    {
      label: "Zentralstrahl auf den 4. HWK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1606_2,
    },
  ],
};
const exam1607 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1606,
    },
    {
      label: "die linke Körperseite 45° anheben",
      image: {},
      drawing: images.drawing_1607_1,
    },
    {
      label: "Zentralstrahl auf den 4. HWK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1607_2,
    },
  ],
};
const exam1608 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1606,
    },
    {
      label: "Mund so weit auf wie möglich geöffnet",
      image: {},
      drawing: images.drawing_1608_1,
    },
    {
      label:
        "Kinn anheben, sodass der untere Rand des Hinterhauptes und die oberen Schneidezähne eine Linie bilden",
      image: {},
      drawing: images.drawing_1608_2,
    },
    {
      label:
        "Zentralstrahl auf den geöffneten Mund und auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1608_3,
    },
  ],
};
const exam1609 = {
  steps: [
    {
      label: "Patient steht seitlich am Stativ",
      image: {},
      drawing: images.drawing_1609,
    },
    {
      label: "Kopf nach vorn neigen (Inklination)",
      image: {},
      drawing: images.drawing_1609_1,
    },
    {
      label:
        "Schultern so weit wie möglich runter ziehen, damit untere HWK nicht überlagern",
      image: {},
      drawing: images.drawing_1609_2,
    },
    {
      label:
        "Zentralstrahl  auf die Mitte der HWS in Höhe 3. HWK/4.HWK auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1609_3,
    },
  ],
};
const exam1610 = {
  steps: [
    {
      label: "Patient steht seitlich am Stativ",
      image: {},
      drawing: images.drawing_1609,
    },
    {
      label: "Kopf nach hinten neigen (Reklination)",
      image: {},
      drawing: images.drawing_1610_1,
    },
    {
      label:
        "Schultern so weit wie möglich runter ziehen, damit untere HWK nicht überlagern",
      image: {},
      drawing: images.drawing_1610_2,
    },
    {
      label:
        "Zentralstrahl  auf die Mitte der HWS in Höhe 3. HWK/4.HWK auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1610_3,
    },
  ],
};
const exam1611 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1501,
    },
    {
      label:
        "eine Körperseite 20° schräg anheben, sodass das Schulterblatt der Gegenseite plan anliegt",
      image: {},
      drawing: images.drawing_1611_1,
    },
    {
      label:
        "Schultern so weit wie möglich runter ziehen, damit untere HWK nicht überlagern",
      image: {},
      drawing: images.drawing_1611_2,
    },
    {
      label:
        "Arm der bildemfängerfernen Seite auf dem Kopf abstützen, damit das Schulterblatt nicht überlagert",
      image: {},
      drawing: images.drawing_1611_3,
    },
    {
      label: "Arm der bildemfängernahen Seite locker hängen lassen",
      image: {},
      drawing: images.drawing_1611_4,
    },
    {
      label: "Zentralstrahl auf etwa 7. HWK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1611_5,
    },
  ],
};
const exam1612 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1612,
    },
    {
      label: "die rechte Körperseite 45° anheben",
      image: {},
      drawing: images.drawing_1612_1,
    },
    {
      label: "Zentralstrahl auf den 4. HWK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1612_2,
    },
  ],
};
const exam1613 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1613,
    },
    {
      label: "die linke Körperseite 45° anheben",
      image: {},
      drawing: images.drawing_1613_1,
    },
    {
      label: "Zentralstrahl auf den 4. HWK auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1613_2,
    },
  ],
};
const exam1614 = {
  steps: [
    {
      label: "Patient steht seitlich am Stativ",
      image: {},
      drawing: images.drawing_1614,
    },
    {
      label: "Kopf leicht anheben",
      image: {},
      drawing: images.drawing_1614_1,
    },
    {
      label:
        "Schultern so weit wie möglich runter ziehen, damit untere HWK nicht überlagern",
      image: {},
      drawing: images.drawing_1614_2,
    },
    {
      label:
        "Zentralstrahl  auf die Mitte der HWS in Höhe 3. HWK/4.HWK auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1614_3,
    },
  ],
};
const exam1616 = {
  steps: [
    {
      label: "Kopf leicht anheben",
      image: {},
      drawing: {},
    },
    {
      label:
        "Schultern so weit wie möglich runter ziehen, damit untere HWK nicht überlagern",
      image: {},
      drawing: {},
    },
    {
      label: "Sagittalebene des Kopfes streng parallel zum Bildempfänger",
      image: {},
      drawing: {},
    },
    {
      label:
        "Zentralstrahl  auf die Mitte der HWS in Höhe 3. HWK/4.HWK auf die Bildempfängermitte",
      image: {},
      drawing: {},
    },
  ],
};
const exam1701 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1701,
    },
    {
      label: "beide Beine gleich",
      image: images.image_1701_1,
      drawing: images.drawing_1701_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1701_2,
    },
    {
      label:
        "Zentralstrahl auf Lendenwirbelsäule in Höhe des Beckenkamms auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1701_3,
    },
  ],
};
const exam1702 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1702,
    },
    {
      label: "beide Beine gleich",
      image: {},
      drawing: images.drawing_1702_1,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1702_2,
    },
    {
      label:
        "Zentralstrahl auf Lendenwirbelsäule in Höhe des Beckenkamms auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1702_3,
    },
  ],
};
const exam1703 = {
  steps: [
    {
      label: "Patient steht seitlich am Stativ",
      image: {},
      drawing: images.drawing_1707,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1703_1,
    },
    {
      label:
        "Zentralstrahl auf Lendenwirbelsäule in Höhe des Beckenkamms auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1703_2,
    },
  ],
};
const exam1704 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1704_1,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1704_2,
    },
    {
      label:
        "Zentralstrahl auf Lendenwirbelsäule in Höhe des Beckenkamms auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1704_3,
    },
  ],
};
const exam1705 = {
  steps: [
    {
      label: "Patient liegt schräg auf dem Aufnahmetisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "der Körper bildet mit dem Tisch einen Winkel von 45°",
      image: {},
      drawing: images.drawing_1705_1,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1705_2,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1705_3,
    },
    {
      label:
        "Zentralstrahl im Bereich Beckenkamm auf die LWS (Mitte zwischen Nabel und Beckenkamm) auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1705_4,
    },
  ],
};
const exam1706 = {
  steps: [
    {
      label: "Patient steht mit der linken Körperseite schräg am Stativ",
      image: {},
      drawing: images.drawing_1706,
    },
    {
      label: "der Körper bildet mit dem Stativ einen Winkel von 45°",
      image: {},
      drawing: images.drawing_1706_1,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1706_2,
    },
    {
      label:
        "Zentralstrahl im Bereich Beckenkamm auf die LWS (Mitte zwischen Nabel und Beckenkamm) auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1706_3,
    },
  ],
};
const exam1707 = {
  steps: [
    {
      label: "Patient steht seitlich am Stativ",
      image: {},
      drawing: images.drawing_1707,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1707_1,
    },
    {
      label: "Oberkörper maximal nach vorn beugen",
      image: {},
      drawing: images.drawing_1707_2,
    },
    {
      label:
        "Zentralstrahl 2QF oberhalb vom Beckenkamm und eine Handbreit vertebral der Hautgrenze",
      image: {},
      drawing: images.drawing_1707_3,
    },
  ],
};
const exam1708 = {
  steps: [
    {
      label: "Patient steht seitlich am Stativ",
      image: {},
      drawing: images.drawing_1707,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1708_1,
    },
    {
      label: "Oberkörper maximal nach vorn beugen",
      image: {},
      drawing: images.drawing_1708_2,
    },
    {
      label:
        "Zentralstrahl 2QF oberhalb vom Beckenkamm und eine Handbreit vertebral der Hautgrenze",
      image: {},
      drawing: images.drawing_1708_3,
    },
  ],
};
const exam1709 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1709,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1709_1,
    },
    {
      label:
        "Zentralstrahl auf entsprechenden LWK und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1709_2,
    },
  ],
};
const exam1710 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1710,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1710_1,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1710_2,
    },
    {
      label:
        "Zentralstrahl auf entsprechenden LWK und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1710_3,
    },
  ],
};
const exam1711 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1709,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1709_1,
    },
    {
      label:
        "Zentralstrahl auf entsprechenden LWK und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1709_2,
    },
  ],
};
const exam1712 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1710,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1710_1,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1710_2,
    },
    {
      label:
        "Zentralstrahl auf entsprechenden LWK und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1710_3,
    },
  ],
};
const exam1713 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1709,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1709_1,
    },
    {
      label:
        "Zentralstrahl auf entsprechenden LWK und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1709_2,
    },
  ],
};
const exam1714 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1710,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1710_1,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1710_2,
    },
    {
      label:
        "Zentralstrahl auf entsprechenden LWK und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1710_3,
    },
  ],
};
const exam1715 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1709,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1709_1,
    },
    {
      label:
        "Zentralstrahl auf entsprechenden LWK und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1709_2,
    },
  ],
};
const exam1716 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1710,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1710_1,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1710_2,
    },
    {
      label:
        "Zentralstrahl auf entsprechenden LWK und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1716_3,
    },
  ],
};
const exam1717 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1709,
    },
    {
      label: "Beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1709_1,
    },
    {
      label:
        "Zentralstrahl auf entsprechenden LWK und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1709_2,
    },
  ],
};
const exam1718 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1710,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1710_1,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1710_2,
    },
    {
      label:
        "Zentralstrahl auf entsprechenden LWK und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1716_3,
    },
  ],
};
const exam1719 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1719,
    },
    {
      label: "Beine in den Knien leicht anwinkeln, Füße aufstellen",
      image: {},
      drawing: images.drawing_1719_1,
    },
    {
      label: "Knie leicht auseinander",
      image: {},
      drawing: images.drawing_1719_2,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_1719_3,
    },
    {
      label:
        "Zentralstrahl 1 Handbreit oberhalb der Symphyse und damit auf Bildempfängermite",
      image: {},
      drawing: images.drawing_1719_4,
    },
  ],
};
const exam1720 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1720_1,
    },
    {
      label:
        "Zentralstrahl mittig zwischen Beckenkamm und Steißbeinspitze und damit auf Bildempfängermite",
      image: {},
      drawing: images.drawing_1720_2,
    },
  ],
};
const exam1721 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1719,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_1721_1,
    },
    {
      label:
        "Zentralstrahl 1 Handbreit oberhalb der Symphyse und damit auf Bildempfängermite",
      image: {},
      drawing: images.drawing_1721_2,
    },
  ],
};
const exam1722 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1720_1,
    },
    {
      label:
        "Zentralstrahl senkrecht auf Punkt zwischen Beckenkamm und tastbarem Steißbein auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1722_2,
    },
  ],
};
const exam1723 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1719,
    },
    {
      label: "Beine in den Knien leicht anwinkeln, Füße aufstellen",
      image: {},
      drawing: images.drawing_1719_1,
    },
    {
      label: "Knie leicht auseinander",
      image: {},
      drawing: images.drawing_1719_2,
    },
    {
      label:
        "Zentralstrahl auf den lumbo-sacralen Übergang 3-4 QF unterhalb des Beckenkamms auf Medianlinie und damit auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1723_3,
    },
  ],
};
const exam1724 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1701,
    },
    {
      label: "Beide Beine gleich",
      image: {},
      drawing: images.drawing_1701_1,
    },
    {
      label:
        "Zentralstrahl auf den lumbo-sacralen Übergang 3-4 QF unterhalb des Beckenkamms auf Medianlinie und damit auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1701_3,
    },
  ],
};
const exam1725 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Beine leicht angewinkelt und übereinander",
      image: {},
      drawing: images.drawing_1720_1,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1725_2,
    },
    {
      label:
        "Zentralstrahl senkrecht auf Beckenkamm und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1725_3,
    },
  ],
};
const exam1726 = {
  steps: [
    {
      label: "Patient steht seitlich am Stativ",
      image: {},
      drawing: images.drawing_1726,
    },
    {
      label: "beide Beine gleich",
      image: {},
      drawing: images.drawing_1726_1,
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: images.drawing_1726_2,
    },
    {
      label:
        "Zentralstrahl senkrecht auf Beckenkamm und auf Bildempfängermitte",
      image: {},
      drawing: {},
    },
  ],
};
const exam1727 = {
  steps: [
    {
      label: "der Körper bildet mit dem Stativ einen Winkel von 45°",
      image: {},
      drawing: {},
    },
    {
      label: "Arme außerhalb des Aufnahmebereiches nach oben oder vorn",
      image: {},
      drawing: {},
    },
    {
      label:
        "Zentralstrahl im Bereich Beckenkamm auf die LWS (Mitte zwischen Nabel und Beckenkamm) auf Bildempfängermitte",
      image: {},
      drawing: {},
    },
  ],
};
const exam1728 = {
  steps: [
    {
      label:
        "Bildempfänger steht seitlich/senkrecht neben den Körper, wird mit Arm oder Sandsack fixiert",
      image: {},
      drawing: {},
    },
    {
      label: "der vom Bildempfänger abgewandte Arm aus dem Strahlenfeld nehmen",
      image: {},
      drawing: {},
    },
    {
      label:
        "Zentralstrahl waagerecht, 2 QF oberhalb vom Beckenkamm auf Bildempfängermitte",
      image: {},
      drawing: {},
    },
  ],
};
const exam1801 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1801,
    },
    {
      label: "Die z.u. Körperseite liegt in Tischmitte",
      image: {},
      drawing: images.drawing_1801_1,
    },
    {
      label: "Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1801_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1801_3,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1801_4,
    },
  ],
};
const exam1802 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1801,
    },
    {
      label: "Die z.u. Körperseite liegt in Tischmitte",
      image: {},
      drawing: images.drawing_1802_1,
    },
    {
      label: "Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1802_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1802_3,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1802_4,
    },
  ],
};
const exam1803 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1803,
    },
    {
      label: "Die z.u. Körperseite liegt in Tischmitte",
      image: {},
      drawing: images.drawing_1803_1,
    },
    {
      label: "Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1803_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1803_3,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1803_4,
    },
  ],
};
const exam1804 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1803,
    },
    {
      label: "Die z.u. Körperseite liegt in Tischmitte",
      image: {},
      drawing: images.drawing_1804_1,
    },
    {
      label: "Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1804_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1804_3,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1804_4,
    },
  ],
};
const exam1805 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1801,
    },
    {
      label: "Die z.u. Körperseite liegt in Tischmitte",
      image: {},
      drawing: images.drawing_1801_1,
    },
    {
      label: "n.z.u.S. ca. 30° bis 45° anheben",
      image: {},
      drawing: images.drawing_1805_2,
    },
    {
      label: " Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1805_3,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1805_4,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1805_5,
    },
  ],
};
const exam1806 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1801,
    },
    {
      label: "Die z.u. Körperseite liegt in Tischmitte",
      image: {},
      drawing: images.drawing_1802_1,
    },
    {
      label: "n.z.u.S. ca. 30° bis 45° anheben",
      image: {},
      drawing: images.drawing_1806_2,
    },
    {
      label: " Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1806_3,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1806_4,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1806_5,
    },
  ],
};
const exam1807 = {
  steps: [
    {
      label: "Patient steht mit dem Bauch am Stativ",
      image: {},
      drawing: images.drawing_1807,
    },
    {
      label: "Die z.u. Körperseite befindet sich in Stativmitte",
      image: {},
      drawing: images.drawing_1807_1,
    },
    {
      label: "Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1807_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1807_3,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1807_4,
    },
  ],
};
const exam1808 = {
  steps: [
    {
      label: "Patient steht mit dem Bauch am Stativ",
      image: {},
      drawing: images.drawing_1807,
    },
    {
      label: "Die z.u. Körperseite befindet sich in Stativmitte",
      image: {},
      drawing: images.drawing_1808_1,
    },
    {
      label: "Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1808_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1808_3,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1808_4,
    },
  ],
};
const exam1809 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1809,
    },
    {
      label: "Die z.u. Körperseite befindet sich in Stativmitte",
      image: {},
      drawing: images.drawing_1809_1,
    },
    {
      label: "Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1809_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1809_3,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1809_4,
    },
  ],
};
const exam1810 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1809,
    },
    {
      label: "Die z.u. Körperseite befindet sich in Stativmitte",
      image: {},
      drawing: images.drawing_1810_1,
    },
    {
      label: "Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1810_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1810_3,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1810_4,
    },
  ],
};
const exam1811 = {
  steps: [
    {
      label: "Patient steht mit dem Bauch am Stativ",
      image: {},
      drawing: images.drawing_1807,
    },
    {
      label: "Die z.u. Körperseite befindet sich in Stativmitte",
      image: {},
      drawing: images.drawing_1811_1,
    },
    {
      label: "n.z.u.S. ca. 30° bis 45° anheben",
      image: {},
      drawing: images.drawing_1811_2,
    },
    {
      label: "Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1811_3,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1811_4,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1811_5,
    },
  ],
};
const exam1812 = {
  steps: [
    {
      label: "Patient steht mit dem Bauch am Stativ",
      image: {},
      drawing: images.drawing_1807,
    },
    {
      label: "Die z.u. Körperseite befindet sich in Stativmitte",
      image: {},
      drawing: images.drawing_1812_1,
    },
    {
      label: "n.z.u.S. ca. 30° bis 45° anheben",
      image: {},
      drawing: images.drawing_1812_2,
    },
    {
      label: "Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1812_3,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1812_4,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1812_5,
    },
  ],
};
const exam1813 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1803,
    },
    {
      label: "Die z.u. Körperseite liegt in Tischmitte",
      image: {},
      drawing: images.drawing_1813_1,
    },
    {
      label: "Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1813_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1813_3,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1813_4,
    },
  ],
};
const exam1814 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1803,
    },
    {
      label: "Die z.u. Körperseite liegt in Tischmitte",
      image: {},
      drawing: images.drawing_1814_1,
    },
    {
      label: "Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1814_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1814_3,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1814_4,
    },
  ],
};
const exam1815 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1803,
    },
    {
      label: "Die z.u. Körperseite liegt in Tischmitte",
      image: {},
      drawing: images.drawing_1815_1,
    },
    {
      label: "Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1815_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1815_3,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1815_4,
    },
  ],
};
const exam1816 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1803,
    },
    {
      label: "Die z.u. Körperseite liegt in Tischmitte",
      image: {},
      drawing: images.drawing_1816_1,
    },
    {
      label: "Arm d.z.u.S. nach oben (zieht die Rippen etwas auseinander)",
      image: {},
      drawing: images.drawing_1816_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_1816_3,
    },
    {
      label: "Zentrierung je nach Klinik/Schmerzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1816_4,
    },
  ],
};
const exam1902 = {
  steps: [
    {
      label: "Patient sitzt mit dem Bauch zum Stativ",
      image: {},
      drawing: images.drawing_1902,
    },
    {
      label: "Stirn und Nase berührt das Stativ",
      image: {},
      drawing: images.drawing_1902_1,
    },
    {
      label:
        "Zentralstrahl auf den unteren Rand des Jochbogens und damit auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_1902_2,
    },
  ],
};
const exam1903 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1903,
    },
    {
      label: "Nasenspitze liegt auf",
      image: {},
      drawing: images.drawing_1903_1,
    },
    {
      label: "Medianebene senkrecht zum Bildempfänger",
      image: {},
      drawing: images.drawing_1903_2,
    },
    {
      label:
        "Zentralstrahl senkrecht auf Hinterhauptshöcker auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1903_3,
    },
  ],
};
const exam1904 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1904,
    },
    {
      label: "Kinn zur Brust hin ziehen",
      image: {},
      drawing: images.drawing_1904_1,
    },
    {
      label: "Medianebene senkrecht zum Bildempfänger",
      image: {},
      drawing: images.drawing_1904_2,
    },
    {
      label: "Zentralstrahl senkrecht auf Nasenwurzel in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1904_3,
    },
  ],
};
const exam1912 = {
  steps: [
    {
      label: "Patient steht mit dem Bauch am Stativ",
      image: {},
      drawing: images.drawing_1912,
    },
    {
      label: "Kopf liegt seitlich mit der zu untersuchen Seite an",
      image: {},
      drawing: images.drawing_1912_1,
    },
    {
      label:
        "Zentralstrahl auf dem unteren Rand des Jochbogens und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1912_3,
    },
  ],
};
const exam1913 = {
  steps: [
    {
      label: "Patient steht mit dem Bauch am Stativ",
      image: {},
      drawing: images.drawing_1912,
    },
    {
      label: "Kopf exakt seitlich zum Bildempfänger",
      image: {},
      drawing: images.drawing_1913_1,
    },
    {
      label: "Zentralstrahl auf Nasenbein auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1913_3,
    },
  ],
};
const exam1914 = {
  steps: [
    {
      label: "Patient steht mit dem Bauch am Stativ",
      image: {},
      drawing: images.drawing_1912,
    },
    {
      label: "Kinn und Nasenspitze berühren das Stativ",
      image: {},
      drawing: images.drawing_1914_1,
    },
    {
      label: "Mund geöffnet (damit Keilbeinhöhle erkennbar)",
      image: {},
      drawing: images.drawing_1914_2,
    },
    {
      label:
        "Zentralstrahl 2 Querfinger oberhalb vom oberen Rand des Hinterhauptslochs (etwa Höhe Nase) und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_1914_3,
    },
  ],
};
const exam1943 = {
  steps: [
    {
      label: "Patient sitzt mit dem Rücken zum Stativ",
      image: {},
      drawing: images.drawing_1943,
    },
    {
      label: "Medianebene senkrecht zum Bildempfänger",
      image: {},
      drawing: images.drawing_1943_1,
    },
    {
      label: "Zentralstrahl senkrecht auf Nasenwurzel in Bildempfängermitte",
      image: {},
      drawing: images.drawing_1943_2,
    },
  ],
};
const exam2001 = {
  steps: [
    {
      label: "Patient sitzt oder steht in rückenlage am Stativ",
      image: {},
      drawing: images.drawing_2009,
    },
    {
      label:
        "n. z. u. Schulter ca. 45° anheben (von Stativ entfernen), sodass das Schulterblatt der zu untersuchenden Seite plan anliegt",
      image: {},
      drawing: images.drawing_2225_1,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2001_2,
    },
    {
      label:
        "Zentralstrahl: Drei Querfinger unterhalb des Schlüsselbeins (Clavicula) auf Schultergelenk und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2001_3,
    },
  ],
};
const exam2002 = {
  steps: [
    {
      label: "Patient sitzt oder steht in rückenlage am Stativ",
      image: {},
      drawing: images.drawing_2010,
    },
    {
      label:
        "n. z. u. Schulter ca. 45° anheben (von Stativ entfernen), sodass das Schulterblatt der zu untersuchenden Seite plan anliegt",
      image: {},
      drawing: images.drawing_2226_1,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2002_2,
    },
    {
      label:
        "Zentralstrahl: Drei Querfinger unterhalb des Schlüsselbeins (Clavicula) auf Schultergelenk und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2002_3,
    },
  ],
};
const exam2003 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1606,
    },
    {
      label:
        "der Arm der z. u. Seite wird seitlich angehoben, sodass möglichst zwischen Oberarm und Oberkörper ein Winkel von 90° entsteht",
      image: {},
      drawing: images.drawing_2003_1,
    },
    {
      label: "Unterarm seitlich, sodass Daumen in Richtung Röntgenröhre zeigt",
      image: {},
      drawing: images.drawing_2003_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2003_3,
    },
    {
      label: "Zentralstrahl auf Oberarmkopf",
      image: {},
      drawing: images.drawing_2003_4,
    },
  ],
};
const exam2004 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1606,
    },
    {
      label:
        "der Arm der z. u. Seite wird seitlich angehoben, sodass möglichst zwischen Oberarm und Oberkörper ein Winkel von 90° entsteht",
      image: {},
      drawing: images.drawing_2004_1,
    },
    {
      label: "Unterarm seitlich, sodass Daumen in Richtung Röntgenröhre zeigt",
      image: {},
      drawing: images.drawing_2004_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2004_3,
    },
    {
      label: "Zentralstrahl auf Oberarmkopf",
      image: {},
      drawing: images.drawing_2004_4,
    },
  ],
};
const exam2005 = {
  steps: [
    {
      label: "Patient sitzt oder steht in rückenlage am Stativ",
      image: {},
      drawing: images.drawing_2009,
    },
    {
      label:
        "n. z. u. Schulter ca. 45° anheben (von Stativ entfernen), sodass das Schulterblatt der zu untersuchenden Seite plan anliegt ",
      image: {},
      drawing: images.drawing_2005_5,
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: {},
      drawing: images.drawing_2005_1,
    },
    {
      label: "Unterarm vor dem Bauch halten, Handfläche schaut nach oben",
      image: {},
      drawing: images.drawing_2005_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2005_3,
    },
    {
      label: "Zentralstrahl auf Oberarmkopf",
      image: {},
      drawing: images.drawing_2005_4,
    },
  ],
};
const exam2006 = {
  steps: [
    {
      label: "Patient sitzt oder steht in rückenlage am Stativ",
      image: {},
      drawing: images.drawing_2010,
    },
    {
      label:
        "n. z. u. Schulter ca. 45° anheben (von Stativ entfernen), sodass das Schulterblatt der zu untersuchenden Seite plan anliegt ",
      image: {},
      drawing: images.drawing_2006_1,
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: {},
      drawing: images.drawing_2006_2,
    },
    {
      label: "Unterarm vor dem Bauch halten, Handfläche schaut nach oben",
      image: {},
      drawing: images.drawing_2006_3,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2006_4,
    },
    {
      label: "Zentralstrahl auf Oberarmkopf",
      image: {},
      drawing: images.drawing_2006_5,
    },
  ],
};
const exam2007 = {
  steps: [
    {
      label: "Patient sitzt oder steht in rückenlage am Stativ",
      image: {},
      drawing: images.drawing_2009,
    },
    {
      label:
        "n. z. u. Schulter ca. 45° anheben (von Stativ entfernen), sodass das Schulterblatt der zu untersuchenden Seite plan anliegt ",
      image: {},
      drawing: images.drawing_2007_1,
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: {},
      drawing: images.drawing_2007_2,
    },
    {
      label: "Unterarm nach außen gehalten, Handfläche schaut nach oben",
      image: {},
      drawing: images.drawing_2007_3,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2007_4,
    },
    {
      label: "Zentralstrahl auf Oberarmkopf",
      image: {},
      drawing: images.drawing_2007_5,
    },
  ],
};
const exam2008 = {
  steps: [
    {
      label: "Patient sitzt oder steht in rückenlage am Stativ",
      image: {},
      drawing: images.drawing_2010,
    },
    {
      label:
        "n. z. u. Schulter ca. 45° anheben (von Stativ entfernen), sodass das Schulterblatt der zu untersuchenden Seite plan anliegt ",
      image: {},
      drawing: images.drawing_2008_1,
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: {},
      drawing: images.drawing_2008_2,
    },
    {
      label: "Unterarm nach außen gehalten, Handfläche schaut nach oben",
      image: {},
      drawing: images.drawing_2008_3,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2008_4,
    },
    {
      label: "Zentralstrahl auf Oberarmkopf",
      image: {},
      drawing: images.drawing_2008_5,
    },
  ],
};
const exam2009 = {
  steps: [
    {
      label: "Patient sitzt oder steht in rückenlage am Stativ",
      image: {},
      drawing: images.drawing_2009,
    },
    {
      label:
        "n. z. u. Schulter ca. 45° anheben (von Stativ entfernen), sodass das Schulterblatt der zu untersuchenden Seite plan anliegt ",
      image: {},
      drawing: images.drawing_2009_1,
    },
    {
      label:
        "Arm anheben, sodass in der Schulter möglichst ein Winkel von 90° entsteht, Handfläche schaut zum Kopf",
      image: {},
      drawing: images.drawing_2009_3,
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: {},
      drawing: images.drawing_2009_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2009_4,
    },
    {
      label: "Zentralstrahl auf Oberarmkopf",
      image: {},
      drawing: images.drawing_2009_5,
    },
  ],
};
const exam2010 = {
  steps: [
    {
      label: "Patient sitzt oder steht in rückenlage am Stativ",
      image: {},
      drawing: images.drawing_2010,
    },
    {
      label:
        "n. z. u. Schulter ca. 45° anheben (von Stativ entfernen), sodass das Schulterblatt der zu untersuchenden Seite plan anliegt ",
      image: {},
      drawing: images.drawing_2010_1,
    },
    {
      label:
        "Arm anheben, sodass in der Schulter möglichst ein Winkel von 90° entsteht, Handfläche schaut zum Kopf",
      image: {},
      drawing: images.drawing_2010_3,
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: {},
      drawing: images.drawing_2010_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2010_4,
    },
    {
      label: "Zentralstrahl auf Oberarmkopf",
      image: {},
      drawing: images.drawing_2010_5,
    },
  ],
};
const exam2011 = {
  steps: [
    {
      label: "Patient steht mit der z. u. Körperseite seitlich am Stativ",
      image: {},
      drawing: images.drawing_2011,
    },
    {
      label:
        "Unterarm der zu untersuchenden Schulter liegt leicht vor dem Körper",
      image: {},
      drawing: images.drawing_2011_1,
    },
    {
      label:
        "Oberarm d.z.u.Seite senkrecht zum Körper sodass es deckungsgleich mit dem seitlichem Schulterblatt ist",
      image: {},
      drawing: images.drawing_2011_2,
    },
    {
      label:
        "die n. z. u. Seite um ca. 30-45° nach vorn zum Stativ gedreht, sodass die Scapula senkrecht zum Bildempfänger steht",
      image: {},
      drawing: images.drawing_2011_3,
    },
    {
      label:
        "Zentralstrahl senkrecht auf die Mitte der Scapula und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2011_4,
    },
  ],
};
const exam2012 = {
  steps: [
    {
      label: "Patient steht mit der z. u. Körperseite seitlich am Stativ",
      image: {},
      drawing: images.drawing_2012,
    },
    {
      label:
        "Unterarm der zu untersuchenden Schulter liegt leicht vor dem Körper",
      image: {},
      drawing: images.drawing_2012_1,
    },
    {
      label:
        "Oberarm d.z.u.Seite senkrecht zum Körper sodass es deckungsgleich mit dem seitlichem Schulterblatt ist",
      image: {},
      drawing: images.drawing_2012_2,
    },
    {
      label:
        "die n. z. u. Seite um ca. 30-45° nach vorn zum Stativ gedreht, sodass die Scapula senkrecht zum Bildempfänger steht",
      image: {},
      drawing: images.drawing_2012_3,
    },
    {
      label:
        "Zentralstrahl senkrecht auf die Mitte der Scapula und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2012_4,
    },
  ],
};
const exam2013 = {
  steps: [
    {
      label: "Patient steht mit der z. u. Körperseite seitlich am Stativ",
      image: {},
      drawing: images.drawing_2013,
    },
    {
      label:
        "Arm der zu untersuchenden Schulter liegt leicht vor dem Körper, sodass Oberarm und seitliches Schulterblatt sich nicht überlagern",
      image: {},
      drawing: images.drawing_2013_2,
    },
    {
      label:
        "die n. z. u. Seite um ca. 30-45° zum Stativ gedreht, sodass die Scapula senkrecht zum Bildempfänger steht",
      image: {},
      drawing: images.drawing_2013_3,
    },
    {
      label:
        "Zentralstrahl zwischen Schulterblatt und Thoraxwand auf obere Drittel der Scapula auf Bildempängermitte",
      image: {},
      drawing: images.drawing_2013_4,
    },
  ],
};
const exam2014 = {
  steps: [
    {
      label: "Patient steht mit der z. u. Körperseite seitlich am Stativ",
      image: {},
      drawing: images.drawing_2014,
    },
    {
      label:
        "Arm der zu untersuchenden Schulter liegt leicht vor dem Körper, sodass Oberarm und seitliches Schulterblatt sich nicht überlagern",
      image: {},
      drawing: images.drawing_2014_1,
    },
    {
      label:
        "die n. z. u. Seite um ca. 30-45° zum Stativ gedreht, sodass die Scapula senkrecht zum Bildempfänger steht",
      image: {},
      drawing: images.drawing_2014_2,
    },
    {
      label:
        "Zentralstrahl zwischen Schulterblatt und Thoraxwand auf obere Drittel der Scapula auf Bildempängermitte",
      image: {},
      drawing: images.drawing_2014_3,
    },
  ],
};
const exam2015 = {
  steps: [
    {
      label: "Schlüsselbein (d. a. S.) liegt vollständig am Stativ an",
      image: {},
      drawing: images.drawing_2015_1,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2015_2,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_2015_3,
    },
    {
      label:
        "Zentralstrahl zwischen Schulterblatt und Thoraxwand auf obere Drittel der Scapula auf Bildempängermitte",
      image: {},
      drawing: images.drawing_2015_4,
    },
  ],
};
const exam2016 = {
  steps: [
    {
      label: "Schlüsselbein (d. a. S.) liegt vollständig am Stativ an",
      image: {},
      drawing: images.drawing_2016_1,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2016_2,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_2016_3,
    },
    {
      label:
        "Zentralstrahl zwischen Schulterblatt und Thoraxwand auf obere Drittel der Scapula auf Bildempängermitte",
      image: {},
      drawing: images.drawing_2016_4,
    },
  ],
};
const exam2017 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1606,
    },
    {
      label:
        "Patient leicht drehen, sodass die Clavicula parallel zum Bildempfänger",
      image: {},
      drawing: images.drawing_2017_1,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2017_2,
    },
    {
      label: "Zentralstrahl auf Mitte der Clavicula auf Bildempfänger",
      image: {},
      drawing: images.drawing_2017_3,
    },
  ],
};
const exam2018 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1606,
    },
    {
      label:
        "Patient leicht drehen, sodass die Clavicula parallel zum Bildempfänger",
      image: {},
      drawing: images.drawing_2018_1,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2018_2,
    },
    {
      label: "Zentralstrahl auf Mitte der Clavicula auf Bildempfänger",
      image: {},
      drawing: images.drawing_2018_3,
    },
  ],
};
const exam2019 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1606,
    },
    {
      label:
        "Arme am Körper und die Handinnenfläche auf den Oberschenkeln ablegen",
      image: {},
      drawing: images.drawing_2019_1,
    },
    {
      label: "Zentralstrahl auf Schultereckgelenk und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2019_2,
    },
  ],
};
const exam2020 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1606,
    },
    {
      label:
        "Arme am Körper und die Handinnenfläche auf den Oberschenkeln ablegen",
      image: {},
      drawing: images.drawing_2020_1,
    },
    {
      label: "Zentralstrahl auf Schultereckgelenk und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2020_2,
    },
  ],
};
const exam2021 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1606,
    },
    {
      label:
        "Arme am Körper und die Handinnenfläche auf den Oberschenkeln ablegen",
      image: {},
      drawing: images.drawing_2021_1,
    },
    {
      label: "Zentralstrahl auf Schultereckgelenk und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2021_2,
    },
  ],
};
const exam2022 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1606,
    },
    {
      label:
        "Arme am Körper und die Handinnenfläche auf den Oberschenkeln ablegen",
      image: {},
      drawing: images.drawing_2022_1,
    },
    {
      label: "Zentralstrahl auf Schultereckgelenk und auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2022_2,
    },
  ],
};
const exam2023 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1606,
    },
    {
      label: "Hand in die Hüfte, damit das Schulterblatt am Stativ anliegt",
      image: {},
      drawing: images.drawing_2023_1,
    },
    {
      label:
        "die n. z  u. Seite etwas anheben, damit das Schulterblatt parallel zum Bildempfänger liegt",
      image: {},
      drawing: images.drawing_2023_2,
    },
    {
      label:
        "Zentralstrahl ca. 3 QF unterhalb des Schlüsselbeins auf Schulterblattmitte auf  Bildempfängermitte",
      image: {},
      drawing: images.drawing_2023_3,
    },
  ],
};
const exam2024 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1606,
    },
    {
      label: "Hand in die Hüfte, damit das Schulterblatt am Stativ anliegt",
      image: {},
      drawing: images.drawing_2024_1,
    },
    {
      label:
        "die n. z  u. Seite etwas anheben, damit das Schulterblatt parallel zum Bildempfänger liegt",
      image: {},
      drawing: images.drawing_2024_2,
    },
    {
      label:
        "Zentralstrahl ca. 3 QF unterhalb des Schlüsselbeins auf Schulterblattmitte auf  Bildempfängermitte",
      image: {},
      drawing: images.drawing_2024_3,
    },
  ],
};
const exam2025 = {
  steps: [
    {
      label: "Patient steht mit der z. u. Körperseite seitlich am Stativ",
      image: {},
      drawing: images.drawing_2025,
    },
    {
      label: "Schulterblatt seitlich bzw. axial, tangential zum Stativ",
      image: {},
      drawing: images.drawing_2025_1,
    },
    {
      label: "Oberarm d. Z. u. S. über die Brust legen",
      image: {},
      drawing: images.drawing_2025_2,
    },
    {
      label: "Zentralstrahl auf Schulterblattmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2025_3,
    },
  ],
};
const exam2026 = {
  steps: [
    {
      label: "Patient steht mit der z. u. Körperseite seitlich am Stativ",
      image: {},
      drawing: images.drawing_2026,
    },
    {
      label: "Schulterblatt seitlich bzw. axial, tangential zum Stativ",
      image: {},
      drawing: images.drawing_2026_1,
    },
    {
      label: "Oberarm d. Z. u. S. über die Brust legen",
      image: {},
      drawing: images.drawing_2026_2,
    },
    {
      label: "Zentralstrahl auf Schulterblattmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2026_3,
    },
  ],
};
const exam2027 = {
  steps: [
    {
      label:
        "rechte Seite ca. 10 - 15° anheben (damit Sternum aus Herz- & Wirbelsäulenschatten kommt)",
      image: {},
      drawing: images.drawing_2027_1,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2027_2,
    },
    {
      label:
        "Zentrierung 2QF oberhalb der Scapulaspitze und 2QF paravertebral (neben der Wirbelsäule) rechts auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2027_3,
    },
  ],
};
const exam2028 = {
  steps: [
    {
      label:
        "rechte Seite ca. 10 - 15° anheben (damit Sternum aus Herz- & Wirbelsäulenschatten kommt)",
      image: {},
      drawing: images.drawing_2028_1,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2028_2,
    },
    {
      label:
        "Zentrierung 2QF oberhalb der Scapulaspitze und 2QF paravertebral (neben der Wirbelsäule) rechts auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2028_3,
    },
  ],
};
const exam2029 = {
  steps: [
    {
      label: "Patient steht seitlich am Stativ",
      image: {},
      drawing: images.drawing_2029,
    },
    {
      label:
        "Arme werden auf dem Rücken verschränkt, so dass die Schultern nach hinten gezogen werden",
      image: {},
      drawing: images.drawing_2029_1,
    },
    {
      label: "Zentrierung auf Brustbeinmitte und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2029_2,
    },
  ],
};
const exam2030 = {
  steps: [
    {
      label: "Patient steht mit dem Bauch am Stativ",
      image: {},
      drawing: images.drawing_2030,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_2030_1,
    },
    {
      label:
        "das z.u. Sternoclavilulargelenk befindet sich dicht und mittig am Bildempfänger",
      image: {},
      drawing: images.drawing_2030_2,
    },
    {
      label: "Zentralstrahl auf ca. 2. bis 3. BWK  auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2030_3,
    },
  ],
};
const exam2031 = {
  steps: [
    {
      label: "Patient steht mit dem Bauch am Stativ",
      image: {},
      drawing: images.drawing_2031,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_2031_1,
    },
    {
      label:
        "das z.u. Sternoclavilulargelenk befindet sich dicht und mittig am Bildempfänger",
      image: {},
      drawing: images.drawing_2031_2,
    },
    {
      label: "Zentralstrahl auf ca. 2. bis 3. BWK  auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2031_3,
    },
  ],
};
const exam2032 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2032,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_2032_1,
    },
    {
      label:
        "das z.u. Sternoclavilulargelenk befindet sich dicht und mittig am Bildempfänger",
      image: {},
      drawing: images.drawing_2032_2,
    },
    {
      label: "Zentralstrahl auf ca. 2. bis 3. BWK  auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2032_3,
    },
  ],
};
const exam2033 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2032,
    },
    {
      label: "Arme neben dem Körper",
      image: {},
      drawing: images.drawing_2033_1,
    },
    {
      label:
        "das z.u. Sternoclavilulargelenk befindet sich dicht und mittig am Bildempfänger",
      image: {},
      drawing: images.drawing_2033_2,
    },
    {
      label: "Zentralstrahl auf ca. 2. bis 3. BWK  auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2033_3,
    },
  ],
};
const exam2034 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2034,
    },
    {
      label: "Hand in die Hüfte, damit das Schulterblatt plan aufliegt",
      image: {},
      drawing: images.drawing_2034_1,
    },
    {
      label:
        "die n. z  u. Seite etwas anheben, damit das Schulterblatt parallel zum Bildempfänger liegt",
      image: {},
      drawing: images.drawing_2034_2,
    },
    {
      label:
        "Zentralstrahl ca. 3 QF unterhalb des Schlüsselbeins auf Schulterblattmitte auf  Bildempfängermitte",
      image: {},
      drawing: images.drawing_2034_3,
    },
  ],
};
const exam2035 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2034,
    },
    {
      label: "Hand in die Hüfte, damit das Schulterblatt plan aufliegt",
      image: {},
      drawing: images.drawing_2035_1,
    },
    {
      label:
        "die n. z  u. Seite etwas anheben, damit das Schulterblatt parallel zum Bildempfänger liegt",
      image: {},
      drawing: images.drawing_2035_2,
    },
    {
      label:
        "Zentralstrahl ca. 3 QF unterhalb des Schlüsselbeins auf Schulterblattmitte auf  Bildempfängermitte",
      image: {},
      drawing: images.drawing_2035_3,
    },
  ],
};
const exam2101 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1803,
    },
    {
      label:
        "Zentralstrahl senkrecht aufs Brustbein (Sternum) auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2101_1,
    },
  ],
};
const exam2102 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1803,
    },
    {
      label:
        "Zentralstrahl senkrecht aufs Brustbein (Sternum) auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2101_1,
    },
  ],
};
const exam2103 = {
  steps: [
    {
      label: "Patient steht mit dem Bauch am Stativ",
      image: {},
      drawing: images.drawing_1807,
    },
    {
      label:
        "Arme in die Taille, Ellbogen nach vorne biegen, damit die Schulterblätter nicht die Lungenstrukturen überlagern",
      image: {},
      drawing: images.drawing_2103_1,
    },
    {
      label:
        "Zentralstrahl auf ca. 6. BWK auf Höhe der Schulterblattspitzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2103_2,
    },
  ],
};
const exam2104 = {
  steps: [
    {
      label: "Patient steht mit dem Bauch am Stativ",
      image: {},
      drawing: images.drawing_1807,
    },
    {
      label:
        "Arme in die Taille, Ellbogen nach vorne biegen, damit die Schulterblätter nicht die Lungenstrukturen überlagern",
      image: {},
      drawing: images.drawing_2103_1,
    },
    {
      label:
        "Zentralstrahl auf ca. 6. BWK auf Höhe der Schulterblattspitzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2103_2,
    },
  ],
};
const exam2105 = {
  steps: [
    {
      label: "Patient steht seitlich mit der linken Körperseite am Stativ",
      image: {},
      drawing: images.drawing_2105,
    },
    {
      label: "Arme über den Kopf",
      image: {},
      drawing: images.drawing_2105_1,
    },
    {
      label:
        "Zentralstrahl auf die hintere Axillarlinie ca. eine Handbreit unter der Axelhöhle auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2105_2,
    },
  ],
};
const exam2106 = {
  steps: [
    {
      label: "Patient steht seitlich mit der Linken Körperseite am Stativ",
      image: {},
      drawing: images.drawing_2105,
    },
    {
      label: "Arme über den Kopf",
      image: {},
      drawing: images.drawing_2105_1,
    },
    {
      label:
        "Zentralstrahl auf die hintere Axillarlinie ca. eine Handbreit unter der Axelhöhle auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2105_2,
    },
  ],
};
const exam2107 = {
  steps: [
    {
      label: "Patient steht mit dem Bauch am Stativ",
      image: {},
      drawing: images.drawing_1807,
    },
    {
      label:
        "Arme in die Taille, Ellbogen nach vorne biegen, damit die Schulterblätter nicht die Lungenstrukturen überlagern",
      image: {},
      drawing: images.drawing_2103_1,
    },
    {
      label:
        "Zentralstrahl auf ca. 6. BWK auf Höhe der Schulterblattspitzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2103_2,
    },
  ],
};
const exam2108 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_1803,
    },
    {
      label:
        "Zentralstrahl auf ca. 6. BWK auf Höhe der Schulterblattspitzen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2101_1,
    },
  ],
};
const exam2201 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label:
        "Unterarm liegt mit der Handinnenfläche nach oben (Supination) um Überlagerung von Ulna und Radius zu verhindern",
      image: {},
      drawing: images.drawing_2201_1,
    },
    {
      label: "Zentralstrahl senkrecht auf den Unterarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2201_2,
    },
  ],
};
const exam2202 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label:
        "Unterarm liegt mit der Handinnenfläche nach oben (Supination) um Überlagerung von Ulna und Radius zu verhindern",
      image: {},
      drawing: images.drawing_2202_1,
    },
    {
      label: "Zentralstrahl senkrecht auf den Unterarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2202_2,
    },
  ],
};
const exam2203 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Unterarm liegt mit der Handinnenfläche nach unten (Pronation)",
      image: {},
      drawing: images.drawing_2203_1,
    },
    {
      label: "Zentralstrahl senkrecht auf den Unterarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2203_2,
    },
  ],
};
const exam2204 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Unterarm liegt mit der Handinnenfläche nach unten (Pronation)",
      image: {},
      drawing: images.drawing_2204_1,
    },
    {
      label: "Zentralstrahl senkrecht auf den Unterarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2204_2,
    },
  ],
};
const exam2205 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Unterarm liegt mit der lateralen (ulnaren) Seite auf",
      image: {},
      drawing: images.drawing_2205_1,
    },
    {
      label: "Zentralstrahl senkrecht auf den Unterarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2205_2,
    },
  ],
};
const exam2206 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Unterarm liegt mit der lateralen (ulnaren) Seite auf",
      image: {},
      drawing: images.drawing_2206_1,
    },
    {
      label: "Zentralstrahl senkrecht auf den Unterarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2206_2,
    },
  ],
};
const exam2207 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label:
        "Unterarm liegt mit der Handinnenfläche nach oben (Supination) um Überlagerung von Ulna und Radius zu verhindern",
      image: {},
      drawing: images.drawing_2207_1,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2207_2,
    },
    {
      label: "Zentralstrahl senkrecht auf den Unterarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2207_3,
    },
  ],
};
const exam2208 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label:
        "Unterarm liegt mit der Handinnenfläche nach oben (Supination) um Überlagerung von Ulna und Radius zu verhindern",
      image: {},
      drawing: images.drawing_2208_1,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2208_2,
    },
    {
      label: "Zentralstrahl senkrecht auf den Unterarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2208_3,
    },
  ],
};
const exam2209 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2215,
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: {},
      drawing: images.drawing_2209_1,
    },
    {
      label: "Unterarm liegt mit der lateralen (ulnaren) Seite auf",
      image: {},
      drawing: images.drawing_2209_2,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2209_3,
    },
    {
      label: "Zentralstrahl senkrecht auf den Unterarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2209_4,
    },
  ],
};
const exam2210 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2215,
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: {},
      drawing: images.drawing_2210_1,
    },
    {
      label: "Unterarm liegt mit der lateralen (ulnaren) Seite auf",
      image: {},
      drawing: images.drawing_2210_2,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2210_3,
    },
    {
      label: "Zentralstrahl senkrecht auf den Unterarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2210_4,
    },
  ],
};
const exam2211 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Unterarm liegt mit der Handinnenfläche nach oben auf",
      image: {},
      drawing: images.drawing_2211_1,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2211_2,
    },
    {
      label: "Zentralstrahl auf Ellenbogenmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2211_3,
    },
  ],
};
const exam2212 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Unterarm liegt mit der Handinnenfläche nach oben auf",
      image: {},
      drawing: images.drawing_2212_1,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2212_2,
    },
    {
      label: "Zentralstrahl auf Ellenbogenmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2212_3,
    },
  ],
};
const exam2213 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1301,
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: {},
      drawing: images.drawing_2213_1,
    },
    {
      label: "Unterarm liegt mit der lateralen (ulnaren) Seite auf",
      image: {},
      drawing: images.drawing_2213_2,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2210_3,
    },
    {
      label: "Zentralstrahl auf Ellenbogenmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2213_3,
    },
  ],
};
const exam2214 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_1302,
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: {},
      drawing: images.drawing_2214_1,
    },
    {
      label: "Unterarm liegt mit der lateralen (ulnaren) Seite auf",
      image: {},
      drawing: images.drawing_2214_2,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2209_3,
    },
    {
      label: "Zentralstrahl auf Ellenbogenmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2214_3,
    },
  ],
};
const exam2215 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2215,
    },
    {
      label: "Oberarm liegt vollständig plan auf",
      image: {},
      drawing: images.drawing_2215_1,
    },
    {
      label:
        "Ellenbogen ist gebeugt, sodass die Hand die Schulter berührt und Oberarm und Unterarm übereinander liegen",
      image: {},
      drawing: images.drawing_2215_2,
    },
    {
      label: "Zentralstrahl auf Olecranon auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2215_3,
    },
  ],
};
const exam2216 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2218,
    },
    {
      label: "Oberarm liegt vollständig plan auf",
      image: {},
      drawing: images.drawing_2216_1,
    },
    {
      label:
        "Ellenbogen ist gebeugt, sodass die Hand die Schulter berührt und Oberarm und Unterarm übereinander liegen",
      image: {},
      drawing: images.drawing_2216_2,
    },
    {
      label: "Zentralstrahl auf Olecranon auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2216_3,
    },
  ],
};
const exam2217 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2215,
    },
    {
      label: "dorsale Seite des Unterarms und Handrücken liegen auf",
      image: {},
      drawing: images.drawing_2215_1,
    },
    {
      label:
        "Ellenbogen gebeugt, Oberkörper nach vorn (ggf. muss Patient aufstehen)",
      image: {},
      drawing: images.drawing_2217_2,
    },
    {
      label: "Oberarm und Unterarm liegen übereinander",
      image: {},
      drawing: images.drawing_2217_3,
    },
    {
      label: "Zentralstrahl auf Ellenbogengelenk und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2217_4,
    },
  ],
};
const exam2218 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2218,
    },
    {
      label: "dorsale Seite des Unterarms und Handrücken liegen auf",
      image: {},
      drawing: images.drawing_2216_1,
    },
    {
      label:
        "Ellenbogen gebeugt, Oberkörper nach vorn (ggf. muss Patient aufstehen)",
      image: {},
      drawing: images.drawing_2218_2,
    },
    {
      label: "Oberarm und Unterarm liegen übereinander",
      image: {},
      drawing: images.drawing_2218_3,
    },
    {
      label: "Zentralstrahl auf Ellenbogengelenk und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2218_4,
    },
  ],
};
const exam2219 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2215,
    },
    {
      label: "Unterarm liegt mit der Handinnenfläche nach oben auf",
      image: {},
      drawing: images.drawing_2219_1,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2216_1,
    },
    {
      label: "Zentralstrahl auf Ellenbogenmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2219_3,
    },
  ],
};
const exam2220 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2218,
    },
    {
      label: "Unterarm liegt mit der Handinnenfläche nach oben auf",
      image: {},
      drawing: images.drawing_2220_1,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2215_1,
    },
    {
      label: "Zentralstrahl auf Ellenbogenmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2220_3,
    },
  ],
};
const exam2221 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2218,
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: {},
      drawing: images.drawing_2221_1,
    },
    {
      label: "Unterarm liegt mit der lateralen (ulnaren) Seite auf",
      image: {},
      drawing: images.drawing_2221_2,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2210_3,
    },
    {
      label: "Zentralstrahl auf Radiusköpfchen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2221_4,
    },
  ],
};
const exam2222 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2215,
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: {},
      drawing: images.drawing_2222_1,
    },
    {
      label: "Unterarm liegt mit der lateralen (ulnaren) Seite auf",
      image: {},
      drawing: images.drawing_2222_2,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2209_3,
    },
    {
      label: "Zentralstrahl auf Radiusköpfchen auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2222_4,
    },
  ],
};
const exam2223 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2215,
    },
    {
      label: "Unterarm liegt mit der Handinnenfläche nach oben auf",
      image: {},
      drawing: images.drawing_2223_1,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2223_2,
    },
    {
      label: "Zentralstrahl auf Ellenbogenmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2223_3,
    },
  ],
};
const exam2224 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2218,
    },
    {
      label: "Unterarm liegt mit der Handinnenfläche nach oben auf",
      image: {},
      drawing: images.drawing_2224_1,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2224_2,
    },
    {
      label: "Zentralstrahl auf Ellenbogenmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2224_3,
    },
  ],
};
const exam2225 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1501,
    },
    {
      label:
        "n. z. u. Schulter ca. 45° anheben (von Stativ entfernen), sodass das Schulterblatt der zu untersuchenden Seite plan anliegt",
      image: {},
      drawing: images.drawing_2225_1,
    },
    {
      label: "Handinnenfläche schaut Richtung Röntgenröhre (Supination)",
      image: {},
      drawing: images.drawing_2225_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2225_3,
    },
    {
      label: "Zentralstrahl auf Oberarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2225_4,
    },
  ],
};
const exam2226 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_1501,
    },
    {
      label:
        "n. z. u. Schulter ca. 45° anheben (von Stativ entfernen), sodass das Schulterblatt der zu untersuchenden Seite plan anliegt",
      image: {},
      drawing: images.drawing_2226_1,
    },
    {
      label: "Handinnenfläche schaut Richtung Röntgenröhre (Supination)",
      image: {},
      drawing: images.drawing_2226_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2226_3,
    },
    {
      label: "Zentralstrahl auf Oberarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2226_4,
    },
  ],
};
const exam2227 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_2227,
    },
    {
      label:
        "der Arm der z. u. Seite wird seitlich angehoben, sodass möglichst zwischen Oberarm und Oberkörper ein Winkel von 90° entsteht",
      image: {},
      drawing: images.drawing_2227_1,
    },
    {
      label: "Unterarm seitlich, sodass Daumen in Richtung Röntgenröhre zeigt",
      image: {},
      drawing: images.drawing_2227_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2227_3,
    },
    {
      label: "Zentralstrahl auf Oberarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2227_4,
    },
  ],
};
const exam2228 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_2227,
    },
    {
      label:
        "der Arm der z. u. Seite wird seitlich angehoben, sodass möglichst zwischen Oberarm und Oberkörper ein Winkel von 90° entsteht",
      image: {},
      drawing: images.drawing_2228_1,
    },
    {
      label: "Unterarm seitlich, sodass Daumen in Richtung Röntgenröhre zeigt",
      image: {},
      drawing: images.drawing_2228_2,
    },
    {
      label: "Kopf schaut zur Gegenseite",
      image: {},
      drawing: images.drawing_2228_3,
    },
    {
      label: "Zentralstrahl auf Oberarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2228_4,
    },
  ],
};
const exam2229 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2215,
    },
    {
      label: "Arm liegt mit der Handinnenfläche nach oben auf",
      image: {},
      drawing: images.drawing_2223_1,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2224_2,
    },
    {
      label: "Zentralstrahl auf Oberarm auf Bildempfängermitte",
      image: {},
      drawing: {},
    },
  ],
};
const exam2230 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2218,
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: {},
      drawing: images.drawing_2230_1,
    },
    {
      label: "Unterarm liegt mit der lateralen (ulnaren) Seite auf",
      image: {},
      drawing: images.drawing_2230_2,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: {},
    },
    {
      label: "Zentralstrahl auf Oberarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2230_4,
    },
  ],
};
const exam2232 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2218,
    },
    {
      label: "Arm liegt mit der Handinnenfläche nach oben auf",
      image: {},
      drawing: images.drawing_2224_1,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: images.drawing_2223_2,
    },
    {
      label: "Zentralstrahl auf Oberarm auf Bildempfängermitte",
      image: {},
      drawing: {},
    },
  ],
};
const exam2233 = {
  steps: [
    {
      label: "Patient sitzt seitlich am Aufnahmetisch",
      image: {},
      drawing: images.drawing_2215,
    },
    {
      label: "Ellenbogen ist 90° gebeugt",
      image: {},
      drawing: images.drawing_2233_1,
    },
    {
      label: "Unterarm liegt mit der lateralen (ulnaren) Seite auf",
      image: {},
      drawing: images.drawing_2233_2,
    },
    {
      label: "Oberarm und Unterarm befinden sich in einer Höhe",
      image: {},
      drawing: {},
    },
    {
      label: "Zentralstrahl auf Oberarm auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2233_4,
    },
  ],
};
const exam2301 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_2301_1,
    },
    {
      label: "Fuß (d. z. u.S.) leicht nach innen gedreht",
      image: {},
      drawing: images.drawing_2301_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2301_3,
    },
    {
      label: "Zentralstrahl auf Unterschenkel auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2301_4,
    },
  ],
};
const exam2302 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_2302_1,
    },
    {
      label: "Fuß (d. z. u.S.) leicht nach innen gedreht",
      image: {},
      drawing: images.drawing_2302_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2302_3,
    },
    {
      label: "Zentralstrahl auf Unterschenkel auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2302_4,
    },
  ],
};
const exam2303 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_2303,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_2303_1,
    },
    {
      label: "Fuß (d. z. u.S.) leicht nach innen gedreht",
      image: {},
      drawing: images.drawing_2303_2,
    },
    {
      label: "Unterschenkel und Ferse liegen direkt am Stativ an",
      image: {},
      drawing: images.drawing_2303_3,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2303_4,
    },
    {
      label: "Zentralstrahl auf Unterschenkel auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2303_5,
    },
  ],
};
const exam2304 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_2303,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_2303_1,
    },
    {
      label: "Fuß (d. z. u.S.) leicht nach innen gedreht",
      image: {},
      drawing: images.drawing_2304_2,
    },
    {
      label: "Unterschenkel und Ferse liegen direkt am Stativ an",
      image: {},
      drawing: images.drawing_2304_3,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2304_4,
    },
    {
      label: "Zentralstrahl auf Unterschenkel auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2304_5,
    },
  ],
};
const exam2305 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_2305,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_2305_1,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2305_2,
    },
    {
      label:
        "Unterschenkel und OSG liegen mit der lateralen Seite auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_2305_3,
    },
    {
      label: "Zentralstrahl auf Unterschenkel auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2305_4,
    },
  ],
};
const exam2306 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_2305,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_2306_1,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2306_2,
    },
    {
      label:
        "Unterschenkel und OSG liegen mit der lateralen Seite auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_2306_3,
    },
    {
      label: "Zentralstrahl auf Unterschenkel auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2306_4,
    },
  ],
};
const exam2307 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "Unterschenkel und Knie gerade auf Bildempfänger",
      image: {},
      drawing: images.drawing_2307_1,
    },
    {
      label: "Fuß (d. z. u.S.) leicht nach innen gedreht",
      image: {},
      drawing: images.drawing_2301_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2301_3,
    },
    {
      label: "Zentralstrahl auf Unterschenkel auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2307_4,
    },
  ],
};
const exam2308 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "Unterschenkel und Knie gerade auf Bildempfänger",
      image: {},
      drawing: images.drawing_2308_1,
    },
    {
      label: "Fuß (d. z. u.S.) leicht nach innen gedreht",
      image: {},
      drawing: images.drawing_2302_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2302_3,
    },
    {
      label: "Zentralstrahl auf Unterschenkel auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2308_4,
    },
  ],
};
const exam2309 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_2303,
    },
    {
      label: "Fuß (d. z. u.S.) leicht nach innen gedreht",
      image: {},
      drawing: images.drawing_2303_2,
    },
    {
      label:
        "Unterschenkel und Knie gerade vor dem Bildempfänger, sodass die Patella gerade nach vorn schaut",
      image: {},
      drawing: images.drawing_2309_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2303_4,
    },
    {
      label: "Zentralstrahl auf Unterschenkel auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2309_4,
    },
  ],
};
const exam2310 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_2303,
    },
    {
      label: "Fuß (d. z. u.S.) leicht nach innen gedreht",
      image: {},
      drawing: images.drawing_2304_2,
    },
    {
      label:
        "Unterschenkel und Knie gerade vor dem Bildempfänger, sodass die Patella gerade nach vorn schaut",
      image: {},
      drawing: images.drawing_2310_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2304_4,
    },
    {
      label: "Zentralstrahl auf Unterschenkel auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2310_4,
    },
  ],
};
const exam2311 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_2305,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_2305_1,
    },
    {
      label:
        "Unterschenkel und Knie liegen mit der lateralen Seite auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_2311_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2305_2,
    },
    {
      label: "Zentralstrahl auf Unterschenkel auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2311_4,
    },
  ],
};
const exam2312 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_2305,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_2306_1,
    },
    {
      label:
        "Unterschenkel und Knie liegen mit der lateralen Seite auf dem Bildempfänger",
      image: {},
      drawing: images.drawing_2312_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2306_2,
    },
    {
      label: "Zentralstrahl auf Unterschenkel auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2312_4,
    },
  ],
};
const exam2315 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_2303,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_2303_1,
    },
    {
      label: "Fuß (d. z. u.S.) leicht nach innen gedreht",
      image: {},
      drawing: images.drawing_2303_2,
    },
    {
      label:
        "Zentralstrahl ca 1 QF unterhalb der Patella auf Kniegelenk auf Bildemfpängermitte",
      image: {},
      drawing: images.drawing_2315_3,
    },
  ],
};
const exam2316 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_2303,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_2303_1,
    },
    {
      label: "Fuß (d. z. u.S.) leicht nach innen gedreht",
      image: {},
      drawing: images.drawing_2304_2,
    },
    {
      label:
        "Zentralstrahl ca 1 QF unterhalb der Patella auf Kniegelenk auf Bildemfpängermitte",
      image: {},
      drawing: images.drawing_2316_3,
    },
  ],
};
const exam2317 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_2317_1,
    },
    {
      label: "Fuß (d. z. u.S.) leicht nach innen gedreht",
      image: {},
      drawing: images.drawing_2317_2,
    },
    {
      label:
        "Zentralstrahl ca 1 QF unterhalb der Patella auf Kniegelenk auf Bildemfpängermitte",
      image: {},
      drawing: images.drawing_2317_3,
    },
  ],
};
const exam2318 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "Bein gerade",
      image: {},
      drawing: images.drawing_2318_1,
    },
    {
      label: "Fuß (d. z. u.S.) leicht nach innen gedreht",
      image: {},
      drawing: images.drawing_2318_2,
    },
    {
      label:
        "Zentralstrahl ca 1 QF unterhalb der Patella auf Kniegelenk auf Bildemfpängermitte",
      image: {},
      drawing: images.drawing_2318_3,
    },
  ],
};
const exam2319 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_2305,
    },
    {
      label:
        "das zu untersuchende Knie liegt lateral auf und ist ca. 30- 45° gebeugt",
      image: {},
      drawing: images.drawing_2319_1,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2305_2,
    },
    {
      label:
        "Zentralstrahl ca 2 QF unterhalb der Patella auf Kniegelenk und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2319_4,
    },
  ],
};
const exam2320 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_2305,
    },
    {
      label:
        "das zu untersuchende Knie liegt lateral auf und ist ca. 30- 45° gebeugt",
      image: {},
      drawing: images.drawing_2320_1,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2306_2,
    },
    {
      label:
        "Zentralstrahl ca 2 QF unterhalb der Patella auf Kniegelenk und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2320_4,
    },
  ],
};
const exam2321 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2322_1,
    },
    {
      label: "Fuß (d. z. u.S.) leicht nach innen gedreht",
      image: {},
      drawing: images.drawing_2321_2,
    },
    {
      label:
        "Zentralstrahl ca 1 QF unterhalb der Patella auf Kniegelenk auf Bildemfpängermitte",
      image: {},
      drawing: images.drawing_2321_3,
    },
  ],
};
const exam2322 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2322_1,
    },
    {
      label: "Fuß (d. z. u.S.) leicht nach innen gedreht",
      image: {},
      drawing: images.drawing_2322_2,
    },
    {
      label:
        "Zentralstrahl ca 1 QF unterhalb der Patella auf Kniegelenk auf Bildemfpängermitte",
      image: {},
      drawing: images.drawing_2322_3,
    },
  ],
};
const exam2323 = {
  steps: [
    {
      label: "das zu untersuchende Knie liegt auf und ist leicht gebeugt",
      image: {},
      drawing: images.drawing_2323_1,
    },
    {
      label:
        "Zentralstrahl ca 2 QF unterhalb der Patella auf Kniegelenk und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2323_2,
    },
  ],
};
const exam2324 = {
  steps: [
    {
      label: "das zu untersuchende Knie liegt auf und ist leicht gebeugt",
      image: {},
      drawing: images.drawing_2324_1,
    },
    {
      label:
        "Zentralstrahl ca 2 QF unterhalb der Patella auf Kniegelenk und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2324_2,
    },
  ],
};
const exam2325 = {
  steps: [
    {
      label: "das andere Bein etwas rückwärts (aus dem Bild) stellen",
      image: {},
      drawing: {},
    },
    {
      label: "Kniegelenk leicht gebeugt",
      image: {},
      drawing: {},
    },
    {
      label:
        "Zentralstrahl ca 2 QF unterhalb der Patella auf Kniegelenk und Bildempfängermitte",
      image: {},
      drawing: {},
    },
  ],
};
const exam2326 = {
  steps: [
    {
      label: "das andere Bein etwas rückwärts (aus dem Bild) stellen",
      image: {},
      drawing: {},
    },
    {
      label: "Kniegelenk leicht gebeugt",
      image: {},
      drawing: {},
    },
    {
      label:
        "Zentralstrahl ca 2 QF unterhalb der Patella auf Kniegelenk und Bildempfängermitte",
      image: {},
      drawing: {},
    },
  ],
};
const exam2327 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2322_1,
    },
    {
      label: "Patient rotiert das Knie d. z. u. S. 45° nach Innen",
      image: {},
      drawing: images.drawing_2327_2,
    },
    {
      label:
        "Zentralstrahl ca 2 QF unterhalb der Patella auf Kniegelenk und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2327_3,
    },
  ],
};
const exam2328 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2322_1,
    },
    {
      label: "Patient rotiert das Knie d. z. u. S. 45° nach Innen",
      image: {},
      drawing: images.drawing_2328_2,
    },
    {
      label:
        "Zentralstrahl ca 2 QF unterhalb der Patella auf Kniegelenk und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2328_3,
    },
  ],
};
const exam2329 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2322_1,
    },
    {
      label: "Patient rotiert das Knie d. z. u. S. 45° nach Außen",
      image: {},
      drawing: images.drawing_2329_2,
    },
    {
      label:
        "Zentralstrahl ca 2 QF unterhalb der Patella auf Kniegelenk und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2329_3,
    },
  ],
};
const exam2330 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2322_1,
    },
    {
      label: "Patient rotiert das Knie d. z. u. S. 45° nach Außen",
      image: {},
      drawing: images.drawing_2330_2,
    },
    {
      label:
        "Zentralstrahl ca 2 QF unterhalb der Patella auf Kniegelenk und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2330_3,
    },
  ],
};
const exam2331 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "das z.u. Bein im 120° offenen Winkel lagern",
      image: {},
      drawing: {},
    },
    {
      label:
        "Zentralstrahl auf den gebeugten Unterschenkel auf die untere Spitze der Patella richten",
      image: {},
      drawing: {},
    },
  ],
};
const exam2332 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "das z.u. Bein im 120° offenen Winkel lagern",
      image: {},
      drawing: {},
    },
    {
      label:
        "Zentralstrahl auf den gebeugten Unterschenkel auf die untere Spitze der Patella richten",
      image: {},
      drawing: {},
    },
  ],
};
const exam2333 = {
  steps: [
    {
      label: "Patella liegen am Stativ an",
      image: {},
      drawing: {},
    },
    {
      label:
        "Knie beugen, sodass zwischen Oberschenkel und Stativ ein Winkel von 25° und zwischen Unterschenkel und Stativ einem Winkel von 20° besteht",
      image: {},
      drawing: {},
    },
    {
      label:
        "Zentralstrahl auf die Kniebeuge und damit auf die Bildempfängermitte",
      image: {},
      drawing: {},
    },
  ],
};
const exam2334 = {
  steps: [
    {
      label: "Patella liegen am Stativ an",
      image: {},
      drawing: {},
    },
    {
      label:
        "Knie beugen, sodass zwischen Oberschenkel und Stativ ein Winkel von 25° und zwischen Unterschenkel und Stativ einem Winkel von 20° besteht",
      image: {},
      drawing: {},
    },
    {
      label:
        "Zentralstrahl auf die Kniebeuge und damit auf die Bildempfängermitte",
      image: {},
      drawing: {},
    },
  ],
};
const exam2335 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2335,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2335_1,
    },
    {
      label: "Patella gerade",
      image: {},
      drawing: images.drawing_2335_2,
    },
    {
      label: "Fußrücken liegt auf",
      image: {},
      drawing: images.drawing_2335_3,
    },
    {
      label:
        "Zentralstrahl auf die Kniebeuge und damit auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_2335_4,
    },
  ],
};
const exam2336 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2335,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2335_1,
    },
    {
      label: "Patella gerade",
      image: {},
      drawing: images.drawing_2335_2,
    },
    {
      label: "Fußrücken liegt auf",
      image: {},
      drawing: images.drawing_2335_3,
    },
    {
      label:
        "Zentralstrahl auf die Kniebeuge und damit auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_2336_4,
    },
  ],
};
const exam2337 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2335,
    },
    {
      label:
        "das z.u. Knie zum Körper hin anwinkeln, so dass die Patella senkrecht zum Bildempfänger steht",
      image: {},
      drawing: images.drawing_2337_1,
    },
    {
      label:
        "Zentralstrahl senkrecht auf die Patella und damit auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_2337_2,
    },
  ],
};
const exam2338 = {
  steps: [
    {
      label: "Patient liegt in Bauchlage auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2335,
    },
    {
      label:
        "das z.u. Knie zum Körper hin anwinkeln, so dass die Patella senkrecht zum Bildempfänger steht",
      image: {},
      drawing: images.drawing_2338_1,
    },
    {
      label:
        "Zentralstrahl senkrecht auf die Patella und damit auf die Bildempfängermitte",
      image: {},
      drawing: images.drawing_2338_2,
    },
  ],
};
const exam2339 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "das zu untersuchende Bein 30° anwinkeln",
      image: {},
      drawing: images.drawing_2339_1,
    },
    {
      label:
        "Patient hält den auf dem Oberschenkel abgestellten Bildempfänger fest",
      image: {},
      drawing: images.drawing_2339_2,
    },
    {
      label:
        "Zentralstrahl mittig auf Femoropatellargelenk auf Bildempfängermittte",
      image: {},
      drawing: images.drawing_2339_3,
    },
  ],
};
const exam2340 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "das zu untersuchende Bein 30° anwinkeln",
      image: {},
      drawing: images.drawing_2340_1,
    },
    {
      label:
        "Patient hält den auf dem Oberschenkel abgestellten Bildempfänger fest",
      image: {},
      drawing: images.drawing_2340_2,
    },
    {
      label:
        "Zentralstrahl mittig auf Femoropatellargelenk auf Bildempfängermittte",
      image: {},
      drawing: images.drawing_2340_3,
    },
  ],
};
const exam2343 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2343_1,
    },
    {
      label: "Beide Füße gleich leicht nach innen gekippt",
      image: {},
      drawing: images.drawing_2343_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2343_3,
    },
    {
      label: "Zentrierung auf Oberschenkel und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2343_4,
    },
  ],
};
const exam2344 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2343_1,
    },
    {
      label: "Beide Füße gleich leicht nach innen gekippt",
      image: {},
      drawing: images.drawing_2343_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2344_3,
    },
    {
      label: "Zentrierung auf Oberschenkel und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2344_4,
    },
  ],
};
const exam2345 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_2305,
    },
    {
      label: "Bein liegt flach auf mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_2345_1,
    },
    {
      label: "Kniegelenk leicht gebeugt",
      image: {},
      drawing: images.drawing_2345_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2305_2,
    },
    {
      label: "Zentrierung auf Oberschenkel und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2345_4,
    },
  ],
};
const exam2346 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_2305,
    },
    {
      label: "Bein liegt flach auf mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_2346_1,
    },
    {
      label: "Kniegelenk leicht gebeugt",
      image: {},
      drawing: images.drawing_2346_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2306_2,
    },
    {
      label: "Zentrierung auf Oberschenkel und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2346_4,
    },
  ],
};
const exam2347 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2347_1,
    },
    {
      label: "Beide Füße gleich leicht nach innen gekippt",
      image: {},
      drawing: images.drawing_2347_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2347_3,
    },
    {
      label: "Zentrierung auf Oberschenkel und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2347_4,
    },
  ],
};
const exam2348 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2347_1,
    },
    {
      label: "Beide Füße gleich leicht nach innen gekippt",
      image: {},
      drawing: images.drawing_2347_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2348_3,
    },
    {
      label: "Zentrierung auf Oberschenkel und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2348_4,
    },
  ],
};
const exam2349 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_2349,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2349_1,
    },
    {
      label: "Beide Füße gleich leicht nach innen gekippt",
      image: {},
      drawing: images.drawing_2349_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2349_3,
    },
    {
      label: "Zentrierung auf Oberschenkel und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2349_4,
    },
  ],
};
const exam2350 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_2349,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2349_1,
    },
    {
      label: "Beide Füße gleich leicht nach innen gekippt",
      image: {},
      drawing: images.drawing_2349_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2350_3,
    },
    {
      label: "Zentrierung auf Oberschenkel und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2350_4,
    },
  ],
};
const exam2351 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label:
        "Bein (d.z.u.S.) im Knie angewinkelt und den Oberschenkel maximal nach außen kippen",
      image: {},
      drawing: images.drawing_2351_1,
    },
    {
      label: "Zentrierung auf Oberschenkel und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2351_2,
    },
  ],
};
const exam2352 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label:
        "Bein (d.z.u.S.) im Knie angewinkelt und den Oberschenkel maximal nach außen kippen",
      image: {},
      drawing: images.drawing_2352_1,
    },
    {
      label: "Zentrierung auf Oberschenkel und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2352_2,
    },
  ],
};
const exam2353 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_2353,
    },
    {
      label: "Bein liegt flach auf mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_2353_1,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_2353_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2353_3,
    },
    {
      label: "Zentrierung auf Oberschenkel und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2353_4,
    },
  ],
};
const exam2354 = {
  steps: [
    {
      label: "Patient liegt seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Bein liegt flach auf mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_2354_1,
    },
    {
      label: "Bein kann leicht im Knie angewinkelt sein",
      image: {},
      drawing: images.drawing_2354_2,
    },
    {
      label: "Bein d.n.z.u.S. leicht abspreizen, außerhalb des Strahlenfeldes",
      image: {},
      drawing: images.drawing_2354_3,
    },
    {
      label: "Zentrierung auf Oberschenkel und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2354_4,
    },
  ],
};
const exam2355 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label:
        "Das Knie d.z.u.S. exakt v.d. lagern, sodass Patella mittig der Condylen liegt",
      image: {},
      drawing: images.drawing_2355_1,
    },
    {
      label: "Knie 15° beugen",
      image: {},
      drawing: images.drawing_2355_2,
    },
    {
      label:
        "das Knie im z.B Telos-Aapparat (einem Druck von 15 KP) positionieren",
      image: {},
      drawing: images.drawing_2355_3,
    },
    {
      label: "Zentralstrahl mittig auf Kniegelenk und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2355_4,
    },
  ],
};
const exam2356 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label:
        "Das Knie d.z.u.S. exakt v.d. lagern, sodass Patella mittig der Condylen liegt",
      image: {},
      drawing: images.drawing_2356_1,
    },
    {
      label: "Knie 15° beugen",
      image: {},
      drawing: images.drawing_2356_2,
    },
    {
      label:
        "das Knie im z.B Telos-Aapparat (einem Druck von 15 KP) positionieren",
      image: {},
      drawing: images.drawing_2356_3,
    },
    {
      label: "Zentralstrahl mittig auf Kniegelenk und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2356_4,
    },
  ],
};
const exam2357 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_2357,
    },
    {
      label: "Bein liegt flach auf mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_2357_1,
    },
    {
      label:
        "das Knie im z.B Telos-Aapparat (einem Druck von 15 KP) positionieren",
      image: {},
      drawing: images.drawing_2357_2,
    },
    {
      label: "Zentralstrahl mittig auf Kniegelenk und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2357_3,
    },
  ],
};
const exam2358 = {
  steps: [
    {
      label: "Patient liegt streng seitlich auf dem Untersuchungstisch",
      image: {},
      drawing: images.drawing_1508,
    },
    {
      label: "Bein liegt flach auf mit der lateralen Seite auf",
      image: {},
      drawing: images.drawing_2358_1,
    },
    {
      label:
        "das Knie im z.B Telos-Aapparat (einem Druck von 15 KP) positionieren",
      image: {},
      drawing: images.drawing_2358_2,
    },
    {
      label: "Zentralstrahl mittig auf Kniegelenk und Bildempfängermitte",
      image: {},
      drawing: images.drawing_2358_3,
    },
  ],
};
const exam2359 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "das zu untersuchende Bein 60° anwinkeln",
      image: {},
      drawing: images.drawing_2359_1,
    },
    {
      label:
        "Patient hält den auf dem Oberschenkel abgestellten Bildempfänger fest",
      image: {},
      drawing: images.drawing_2339_2,
    },
    {
      label:
        "Zentralstrahl mittig auf Femoropatellargelenk auf Bildempfängermittte",
      image: {},
      drawing: images.drawing_2339_3,
    },
  ],
};
const exam2360 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "das zu untersuchende Bein 60° anwinkeln",
      image: {},
      drawing: images.drawing_2360_1,
    },
    {
      label:
        "Patient hält den auf dem Oberschenkel abgestellten Bildempfänger fest",
      image: {},
      drawing: images.drawing_2340_2,
    },
    {
      label:
        "Zentralstrahl mittig auf Femoropatellargelenk auf Bildempfängermittte",
      image: {},
      drawing: images.drawing_2340_3,
    },
  ],
};
const exam2361 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "das zu untersuchende Bein 90° anwinkeln",
      image: {},
      drawing: images.drawing_2361_1,
    },
    {
      label:
        "Patient hält den auf dem Oberschenkel abgestellten Bildempfänger fest",
      image: {},
      drawing: images.drawing_2339_2,
    },
    {
      label:
        "Zentralstrahl mittig auf Femoropatellargelenk auf Bildempfängermittte",
      image: {},
      drawing: images.drawing_2361_3,
    },
  ],
};
const exam2362 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2302,
    },
    {
      label: "das zu untersuchende Bein 90° anwinkeln",
      image: {},
      drawing: images.drawing_2362_1,
    },
    {
      label:
        "Patient hält den auf dem Oberschenkel abgestellten Bildempfänger fest",
      image: {},
      drawing: images.drawing_2340_2,
    },
    {
      label:
        "Zentralstrahl mittig auf Femoropatellargelenk auf Bildempfängermittte",
      image: {},
      drawing: images.drawing_2362_3,
    },
  ],
};
const exam2363 = {
  steps: [
    {
      label: "Patient steht mit Rücken am Stativ",
      image: {},
      drawing: images.drawing_2303,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2303_1,
    },
    {
      label: "Beide Füße gleich leicht nach innen gekippt",
      image: {},
      drawing: images.drawing_2363_2,
    },
    {
      label:
        "Zentralstrahl ca 1 QF unterhalb der Patella auf Kniegelenk auf Bildemfpängermitte",
      image: {},
      drawing: images.drawing_2363_3,
    },
  ],
};
const exam2401 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2401_1,
    },
    {
      label:
        "Beide Füße gleich, nach innen gekippt, so dass sich die Grosszehen berühren",
      image: {},
      drawing: images.drawing_2401_2,
    },
    {
      label:
        "Arme außerhalb des Aufnahmebereiches nach oben oder auf der Brust ablegen",
      image: {},
      drawing: images.drawing_2401_3,
    },
    {
      label: "Zentralstrahl auf Schenkelhalsmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2401_4,
    },
  ],
};
const exam2402 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2401_1,
    },
    {
      label:
        "Beide Füße gleich, nach innen gekippt, so dass sich die Grosszehen berühren",
      image: {},
      drawing: images.drawing_2401_2,
    },
    {
      label:
        "Arme außerhalb des Aufnahmebereiches nach oben oder auf der Brust ablegen",
      image: {},
      drawing: images.drawing_2402_3,
    },
    {
      label: "Zentralstrahl auf Schenkelhalsmitte auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2402_4,
    },
  ],
};
const exam2403 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2401_1,
    },
    {
      label: "Beide Füße gleich leicht nach innen gekippt",
      image: {},
      drawing: images.drawing_2401_2,
    },
    {
      label:
        "Arme außerhalb des Aufnahmebereiches nach oben oder auf der Brust ablegen",
      image: {},
      drawing: images.drawing_2401_3,
    },
    {
      label:
        "Zentralstrahl 2 QF unter der Verbindungslinie der Leistenbeugenmitte und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2403_4,
    },
  ],
};
const exam2404 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label:
        "Bein (d.z.u.S.) im Knie angewinkelt und den Oberschenkel maximal nach außen kippen",
      image: {},
      drawing: images.drawing_2404_1,
    },
    {
      label:
        "Arme außerhalb des Aufnahmebereiches nach oben oder auf der Brust ablegen",
      image: {},
      drawing: images.drawing_2404_2,
    },
    {
      label: "Zentrierung auf Mitte der Leistenbeuge auf Bildempfängermitten",
      image: {},
      drawing: images.drawing_2404_3,
    },
  ],
};
const exam2405 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label:
        "Bein (d.z.u.S.) im Knie angewinkelt und den Oberschenkel maximal nach außen kippen",
      image: {},
      drawing: images.drawing_2405_1,
    },
    {
      label:
        "Arme außerhalb des Aufnahmebereiches nach oben oder auf der Brust ablegen",
      image: {},
      drawing: images.drawing_2405_2,
    },
    {
      label: "Zentrierung auf Mitte der Leistenbeuge auf Bildempfängermitten",
      image: {},
      drawing: images.drawing_2405_3,
    },
  ],
};
const exam2406 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label: "die Hüfte (d.z.u.S.) um ca. 45° anheben",
      image: {},
      drawing: images.drawing_2406_1,
    },
    {
      label: "Bein (d.z.u.S.) bleibt gestreckt",
      image: {},
      drawing: images.drawing_2406_2,
    },
    {
      label: "das n.z.u. Bein nach außen rotiert, im Knie leicht angewinkelt",
      image: {},
      drawing: images.drawing_2406_3,
    },
    {
      label: "Zentrierung auf Mitte der Leistenbeuge auf Bildempfängermitten",
      image: {},
      drawing: images.drawing_2406_4,
    },
  ],
};
const exam2407 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label: "die Hüfte (d.z.u.S.) um ca. 45° anheben",
      image: {},
      drawing: images.drawing_2407_1,
    },
    {
      label: "Bein (d.z.u.S.) bleibt gestreckt",
      image: {},
      drawing: images.drawing_2407_2,
    },
    {
      label: "das n.z.u. Bein nach außen rotiert, im Knie leicht angewinkelt",
      image: {},
      drawing: images.drawing_2407_3,
    },
    {
      label: "Zentrierung auf Mitte der Leistenbeuge auf Bildempfängermitten",
      image: {},
      drawing: images.drawing_2407_4,
    },
  ],
};
const exam2408 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2401_1,
    },
    {
      label:
        "Bein (d.z.u.S.) wird so weit wie möglich lateral abgespreizt, wobei das Becken weiterhin gerade liegen muss",
      image: {},
      drawing: images.drawing_2404_1,
    },
    {
      label: "Zentrierung auf Mitte der Leistenbeuge auf Bildempfängermitten",
      image: {},
      drawing: images.drawing_2404_3,
    },
  ],
};
const exam2409 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2401_1,
    },
    {
      label:
        "Bein (d.z.u.S.) wird so weit wie möglich lateral abgespreizt, wobei das Becken weiterhin gerade liegen muss",
      image: {},
      drawing: images.drawing_2405_1,
    },
    {
      label: "Zentrierung auf Mitte der Leistenbeuge auf Bildempfängermitten",
      image: {},
      drawing: images.drawing_2405_3,
    },
  ],
};
const exam2410 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2401_1,
    },
    {
      label:
        "Bein (d.z.u.S.) wird so weit wie möglich lateral abgespreizt, wobei das Becken weiterhin gerade liegen muss",
      image: {},
      drawing: images.drawing_2410_2,
    },
    {
      label: "Zentrierung auf Mitte der Leistenbeuge auf Bildempfängermitten",
      image: {},
      drawing: images.drawing_2410_3,
    },
  ],
};
const exam2411 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2401_1,
    },
    {
      label:
        "Bein (d.z.u.S.) wird so weit wie möglich lateral abgespreizt, wobei das Becken weiterhin gerade liegen muss",
      image: {},
      drawing: images.drawing_2411_2,
    },
    {
      label: "Zentrierung auf Mitte der Leistenbeuge auf Bildempfängermitten",
      image: {},
      drawing: images.drawing_2411_3,
    },
  ],
};
const exam2412 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2401_1,
    },
    {
      label: "Beide Füße gleich leicht nach innen gekippt",
      image: {},
      drawing: images.drawing_2401_2,
    },
    {
      label:
        "Arme außerhalb des Aufnahmebereiches nach oben oder auf der Brust ablegen",
      image: {},
      drawing: images.drawing_2401_3,
    },
    {
      label:
        "Zentralstrahl kurz unterhalb der Leistenbeugenmitte (um den Schaft der TEP auch  darzustellen) auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2401_4,
    },
  ],
};
const exam2413 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2401_1,
    },
    {
      label: "Beide Füße gleich leicht nach innen gekippt",
      image: {},
      drawing: images.drawing_2401_2,
    },
    {
      label:
        "Arme außerhalb des Aufnahmebereiches nach oben oder auf der Brust ablegen",
      image: {},
      drawing: images.drawing_2402_3,
    },
    {
      label:
        "Zentralstrahl kurz unterhalb der Leistenbeugenmitte (um den Schaft der TEP auch  darzustellen) auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2402_4,
    },
  ],
};
const exam2414 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label:
        "Bein (d.z.u.S.) im Knie angewinkelt und den Oberschenkel maximal nach außen kippen",
      image: {},
      drawing: images.drawing_2404_1,
    },
    {
      label:
        "Arme außerhalb des Aufnahmebereiches nach oben oder auf der Brust ablegen",
      image: {},
      drawing: images.drawing_2404_2,
    },
    {
      label:
        "Zentralstrahl kurz unterhalb der Leistenbeugenmitte (um den Schaft der TEP auch  darzustellen) auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2404_3,
    },
  ],
};
const exam2415 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label:
        "Bein (d.z.u.S.) im Knie angewinkelt und den Oberschenkel maximal nach außen kippen",
      image: {},
      drawing: images.drawing_2405_1,
    },
    {
      label:
        "Arme außerhalb des Aufnahmebereiches nach oben oder auf der Brust ablegen",
      image: {},
      drawing: images.drawing_2405_2,
    },
    {
      label:
        "Zentralstrahl kurz unterhalb der Leistenbeugenmitte (um den Schaft der TEP auch  darzustellen) auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2405_3,
    },
  ],
};
const exam2416 = {
  steps: [
    {
      label: "Patient steht mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2416,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2416_1,
    },
    {
      label: "Beide Füße gleich leicht nach innen gekippt",
      image: {},
      drawing: images.drawing_2416_2,
    },
    {
      label:
        "Arme außerhalb des Aufnahmebereiches nach oben oder auf der Brust ablegen",
      image: {},
      drawing: images.drawing_2416_3,
    },
    {
      label:
        "Zentralstrahl 2 QF unter der Verbindungslinie der Leistenbeugenmitte und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2416_4,
    },
  ],
};
const exam2417 = {
  steps: [
    {
      label: "Patient liegt mit Rücken auf Bucky-Tisch",
      image: {},
      drawing: images.drawing_2402,
    },
    {
      label: "beide Beine gerade und parallel nebeneinander",
      image: {},
      drawing: images.drawing_2401_1,
    },
    {
      label: "Beide Füße gleich leicht nach innen gekippt",
      image: {},
      drawing: images.drawing_2401_2,
    },
    {
      label:
        "Arme außerhalb des Aufnahmebereiches nach oben oder auf der Brust ablegen",
      image: {},
      drawing: images.drawing_2417_3,
    },
    {
      label:
        "Zentralstrahl 2 QF unter der Verbindungslinie der Leistenbeugenmitte und damit auf Bildempfängermitte",
      image: {},
      drawing: images.drawing_2403_4,
    },
  ],
};

export const ExamGuideData = {
  1710: testExamGuideData, // this is test data replace with real data once ready
  1315: exam1315, // this is real data use it as reference
  1101: exam1101,
  1102: exam1102,
  1103: exam1103,
  1104: exam1104,
  1201: exam1201,
  1203: exam1203,
  1204: exam1204,
  1205: exam1205,
  1206: exam1206,
  1207: exam1207,
  1208: exam1208,
  1209: exam1209,
  1301: exam1301,
  1302: exam1302,
  1303: exam1303,
  1304: exam1304,
  1305: exam1305,
  1306: exam1306,
  1307: exam1307,
  1308: exam1308,
  1309: exam1309,
  1310: exam1310,
  1311: exam1311,
  1312: exam1312,
  1313: exam1313,
  1314: exam1314,
  1316: exam1316,
  1317: exam1317,
  1318: exam1318,
  1319: exam1319,
  1320: exam1320,
  1321: exam1321,
  1322: exam1322,
  1323: exam1323,
  1324: exam1324,
  1325: exam1325,
  1326: exam1326,
  1327: exam1327,
  1328: exam1328,
  1329: exam1329,
  1330: exam1330,
  1331: exam1331,
  1332: exam1332,
  1333: exam1333,
  1334: exam1334,
  1335: exam1335,
  1336: exam1336,
  1337: exam1337,
  1338: exam1338,
  1339: exam1339,
  1340: exam1340,
  1341: exam1341,
  1342: exam1342,
  1343: exam1343,
  1344: exam1344,
  1345: exam1345,
  1346: exam1346,
  1347: exam1347,
  1348: exam1348,
  1349: exam1349,
  1351: exam1351,
  1350: exam1350,
  1352: exam1352,
  1353: exam1353,
  1354: exam1354,
  1355: exam1355,
  1356: exam1356,
  1357: exam1357,
  1359: exam1359,
  1360: exam1360,
  1358: exam1358,
  1361: exam1361,
  1362: exam1362,
  1363: exam1363,
  1364: exam1364,
  1365: exam1365,
  1366: exam1366,
  1369: exam1369,
  1370: exam1370,
  1371: exam1371,
  1372: exam1372,
  1373: exam1373,
  1374: exam1374,
  1375: exam1375,
  1376: exam1376,
  1377: exam1377,
  1378: exam1378,
  1401: exam1401,
  1402: exam1402,
  1403: exam1403,
  1404: exam1404,
  1405: exam1405,
  1406: exam1406,
  1407: exam1407,
  1408: exam1408,
  1409: exam1409,
  1410: exam1410,
  1411: exam1411,
  1412: exam1412,
  1413: exam1413,
  1414: exam1414,
  1415: exam1415,
  1416: exam1416,
  1417: exam1417,
  1418: exam1418,
  1419: exam1419,
  1420: exam1420,
  1423: exam1423,
  1424: exam1424,
  1425: exam1425,
  1426: exam1426,
  1427: exam1427,
  1428: exam1428,
  1429: exam1429,
  1430: exam1430,
  1431: exam1431,
  1432: exam1432,
  1433: exam1433,
  1434: exam1434,
  1435: exam1435,
  1436: exam1436,
  1437: exam1437,
  1438: exam1438,
  1439: exam1439,
  1440: exam1440,
  1441: exam1441,
  1442: exam1442,
  1443: exam1443,
  1444: exam1444,
  1445: exam1445,
  1446: exam1446,
  1447: exam1447,
  1448: exam1448,
  1449: exam1449,
  1450: exam1450,
  1451: exam1451,
  1452: exam1452,
  1453: exam1453,
  1454: exam1454,
  1455: exam1455,
  1456: exam1456,
  1457: exam1457,
  1458: exam1458,
  1459: exam1459,
  1460: exam1460,
  1463: exam1463,
  1464: exam1464,
  1465: exam1465,
  1466: exam1466,
  1467: exam1467,
  1468: exam1468,
  1469: exam1469,
  1470: exam1470,
  1471: exam1471,
  1472: exam1472,
  1473: exam1473,
  1474: exam1474,
  1501: exam1501,
  1502: exam1502,
  1503: exam1503,
  1504: exam1504,
  1505: exam1505,
  1506: exam1506,
  1507: exam1507,
  1508: exam1508,
  1509: exam1509,
  1510: exam1510,
  1511: exam1511,
  1512: exam1512,
  1513: exam1513,
  1514: exam1514,
  1515: exam1515,
  1516: exam1516,
  1517: exam1517,
  1518: exam1518,
  1519: exam1519,
  1520: exam1520,
  1521: exam1521,
  1522: exam1522,
  1523: exam1523,
  1524: exam1524,
  1525: exam1525,
  1526: exam1526,
  1527: exam1527,
  1528: exam1528,
  1529: exam1529,
  1530: exam1530,
  1531: exam1531,
  1532: exam1532,
  1533: exam1533,
  1534: exam1534,
  1535: exam1535,
  1536: exam1536,
  1601: exam1601,
  1602: exam1602,
  1603: exam1603,
  1604: exam1604,
  1606: exam1606,
  1607: exam1607,
  1608: exam1608,
  1609: exam1609,
  1610: exam1610,
  1611: exam1611,
  1612: exam1612,
  1613: exam1613,
  1614: exam1614,
  1616: exam1616,
  1701: exam1701,
  1702: exam1702,
  1703: exam1703,
  1704: exam1704,
  1705: exam1705,
  1706: exam1706,
  1707: exam1707,
  1708: exam1708,
  1709: exam1709,
  1710: exam1710,
  1711: exam1711,
  1712: exam1712,
  1713: exam1713,
  1714: exam1714,
  1715: exam1715,
  1716: exam1716,
  1717: exam1717,
  1718: exam1718,
  1719: exam1719,
  1720: exam1720,
  1721: exam1721,
  1722: exam1722,
  1723: exam1723,
  1724: exam1724,
  1725: exam1725,
  1726: exam1726,
  1727: exam1727,
  1728: exam1728,
  1801: exam1801,
  1802: exam1802,
  1803: exam1803,
  1804: exam1804,
  1805: exam1805,
  1806: exam1806,
  1807: exam1807,
  1808: exam1808,
  1809: exam1809,
  1810: exam1810,
  1811: exam1811,
  1812: exam1812,
  1813: exam1813,
  1814: exam1814,
  1815: exam1815,
  1816: exam1816,
  1902: exam1902,
  1903: exam1903,
  1904: exam1904,
  1912: exam1912,
  1913: exam1913,
  1914: exam1914,
  1943: exam1943,
  2001: exam2001,
  2002: exam2002,
  2003: exam2003,
  2004: exam2004,
  2005: exam2005,
  2006: exam2006,
  2007: exam2007,
  2008: exam2008,
  2009: exam2009,
  2010: exam2010,
  2011: exam2011,
  2012: exam2012,
  2013: exam2013,
  2014: exam2014,
  2015: exam2015,
  2016: exam2016,
  2017: exam2017,
  2018: exam2018,
  2019: exam2019,
  2020: exam2020,
  2021: exam2021,
  2022: exam2022,
  2023: exam2023,
  2024: exam2024,
  2025: exam2025,
  2026: exam2026,
  2027: exam2027,
  2028: exam2028,
  2029: exam2029,
  2030: exam2030,
  2031: exam2031,
  2032: exam2032,
  2033: exam2033,
  2034: exam2034,
  2035: exam2035,
  2101: exam2101,
  2102: exam2102,
  2103: exam2103,
  2104: exam2104,
  2105: exam2105,
  2106: exam2106,
  2107: exam2107,
  2108: exam2108,
  2201: exam2201,
  2202: exam2202,
  2203: exam2203,
  2204: exam2204,
  2205: exam2205,
  2206: exam2206,
  2207: exam2207,
  2208: exam2208,
  2209: exam2209,
  2210: exam2210,
  2211: exam2211,
  2212: exam2212,
  2213: exam2213,
  2214: exam2214,
  2215: exam2215,
  2216: exam2216,
  2217: exam2217,
  2218: exam2218,
  2219: exam2219,
  2220: exam2220,
  2221: exam2221,
  2222: exam2222,
  2223: exam2223,
  2224: exam2224,
  2225: exam2225,
  2226: exam2226,
  2227: exam2227,
  2228: exam2228,
  2229: exam2229,
  2230: exam2230,
  2232: exam2232,
  2233: exam2233,
  2301: exam2301,
  2302: exam2302,
  2303: exam2303,
  2304: exam2304,
  2305: exam2305,
  2306: exam2306,
  2307: exam2307,
  2308: exam2308,
  2309: exam2309,
  2310: exam2310,
  2311: exam2311,
  2312: exam2312,
  2315: exam2315,
  2316: exam2316,
  2317: exam2317,
  2318: exam2318,
  2319: exam2319,
  2320: exam2320,
  2321: exam2321,
  2322: exam2322,
  2323: exam2323,
  2324: exam2324,
  2325: exam2325,
  2326: exam2326,
  2327: exam2327,
  2328: exam2328,
  2329: exam2329,
  2330: exam2330,
  2331: exam2331,
  2332: exam2332,
  2333: exam2333,
  2334: exam2334,
  2335: exam2335,
  2336: exam2336,
  2337: exam2337,
  2338: exam2338,
  2339: exam2339,
  2340: exam2340,
  2343: exam2343,
  2344: exam2344,
  2345: exam2345,
  2346: exam2346,
  2347: exam2347,
  2348: exam2348,
  2349: exam2349,
  2350: exam2350,
  2351: exam2351,
  2352: exam2352,
  2353: exam2353,
  2354: exam2354,
  2355: exam2355,
  2356: exam2356,
  2357: exam2357,
  2358: exam2358,
  2359: exam2359,
  2360: exam2360,
  2361: exam2361,
  2362: exam2362,
  2363: exam2363,
  2401: exam2401,
  2402: exam2402,
  2403: exam2403,
  2404: exam2404,
  2405: exam2405,
  2406: exam2406,
  2407: exam2407,
  2408: exam2408,
  2409: exam2409,
  2410: exam2410,
  2411: exam2411,
  2412: exam2412,
  2413: exam2413,
  2414: exam2414,
  2415: exam2415,
  2416: exam2416,
  2417: exam2417,
};
