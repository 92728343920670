import { useContext, useEffect, useState } from "react";
import {
  useExaminationsInfinite,
  useExamination,
} from "../../../Utilities/FetchHooks/Ris/RisHooks";
import { ClipLoader } from "react-spinners";
import { Button } from "react-bootstrap";
import BaseSearch from "../../../Blueprints/SearchBars/BaseSearch/BaseSearch";
import styles from "./ExamSection.module.scss";
import { ExamPlannerExaminationImage } from "../../ExamPlannerV3/Components/ExamPlannerImage";
import useWindowSize from "../../../Utilities/Window/windowSize";
import { ExamHelperContext } from "../../../Pages/ExamHelper";
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 20;
const LABEL_ACCESSOR = "designationde";
const ABBR = "abbrde";

export default function ExamsSection({ selectedExam, selectedExamSetter }) {
  const { t } = useTranslation(["common"]);
  const [searchPhrase, setSearchPhrase] = useState("");
  const {
    examListShrinked,
    setExamListShrinked,
    allowedExams,
    setAllowedExams,
  } = useContext(ExamHelperContext);

  return (
    <div
      className={`${styles.ExamSection} ${styles.border} ${
        examListShrinked ? styles.closed : ""
      }`}
      onClick={() => {
        if (examListShrinked) setExamListShrinked(!examListShrinked);
      }}
    >
      <div>
        {!examListShrinked ? (
          !allowedExams || allowedExams.length === 0 ? (
            <ExamList
              selectedExam={selectedExam}
              selectedExamSetter={selectedExamSetter}
              searchPhrase={searchPhrase}
              setSearchPhrase={setSearchPhrase}
            />
          ) : (
            <AllowedExams
              allowedExams={allowedExams}
              selectedExam={selectedExam}
              selectedExamSetter={selectedExamSetter}
              setAllowedExams={setAllowedExams}
            />
          )
        ) : (
          <div className={styles.shrinkedHeader}>
            <i className="fas fa-chevron-right p-2 text-white" />
            <div className={styles.headerText}>{t("examList")}</div>
          </div>
        )}
      </div>
    </div>
  );
}

function ExamRow({ examId, selectedExam, selectedExamSetter }) {
  const e = useExamination({ examId: examId, enabled: true });
  const { setExamListShrinked } = useContext(ExamHelperContext);
  const exam = e.data;
  if (!exam)
    return (
      <div>
        <ClipLoader />
      </div>
    );
  return (
    <div
      onClick={() => {
        selectedExamSetter(exam.id === selectedExam ? null : exam.id);
        if (window.conf.EXAM_HELPER.CLOSE_LIST_ON_EXAM_SELECT) {
          setExamListShrinked(true);
        }
      }}
      className={`${styles.ExamRow} ${
        exam.id === selectedExam && styles.selected
      }`}
      key={`${exam.id}_${exam[LABEL_ACCESSOR]}`}
    >
      <ExamPlannerExaminationImage
        examId={exam?.id}
        style={{
          width: "50px",
          maxHeight: "50px",
          marginRight: "10px",
        }}
      />
      {exam[LABEL_ACCESSOR]}
    </div>
  );
}

function ExamList({
  selectedExam,
  selectedExamSetter,
  searchPhrase,
  setSearchPhrase,
}) {
  const { t } = useTranslation(["common"]);
  const [examsFilter, setExamsFilter] = useState(undefined);
  const { examListShrinked, setExamListShrinked } =
    useContext(ExamHelperContext);
  const { data, isLoading, isError, refetch, fetchNextPage, hasNextPage } =
    useExaminationsInfinite({
      pageSize: PAGE_SIZE,
      filter: examsFilter,
      sort: { field: "designationde", direction: "asc" },
    });
  const [exams, setExams] = useState([]);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width < 1000 && !examListShrinked) {
      setExamListShrinked(true);
    }
  }, [width]);

  useEffect(() => {
    if (!data) return;
    if (!data.pages) return;
    let exams = [];
    data.pages.forEach((page) => {
      if (page?.examinations) {
        exams = exams.concat(page.examinations);
      }
    });
    setExams(exams);
  }, [data]);

  return (
    <>
      <div className="border-bottom d-flex mb-2 p-1 align-items-center">
        {width > 768 && <h3 className="text-white">{t("exams")}</h3>}
        <BaseSearch
          field={[LABEL_ACCESSOR, ABBR]}
          initialSearchPhrase={searchPhrase}
          searchPhraseSetter={setSearchPhrase}
          filterSetter={setExamsFilter}
          className={`${width > 768 && "ml-auto"}`}
        />
        <div className="my-2" title="Shrink window">
          <Button onClick={() => setExamListShrinked(true)}>
            <i className="fas fa-chevron-left" />
          </Button>
        </div>
      </div>
      {isLoading && <ClipLoader size={50} />}
      {isError && (
        <>
          <h3>{t("erros.unableToLoad")}</h3>
          <Button onClick={refetch}>{t("buttons.reload")}</Button>
        </>
      )}
      <div className={styles.ExamListWrapper}>
        {!isLoading &&
          exams.map((exam) => (
            <div
              onClick={() => {
                selectedExamSetter(exam.id === selectedExam ? null : exam.id);
                if (window.conf.EXAM_HELPER.CLOSE_LIST_ON_EXAM_SELECT) {
                  setExamListShrinked(true);
                }
              }}
              className={`${styles.ExamRow} ${
                exam.id === selectedExam && styles.selected
              }`}
              key={`${exam.id}_${exam[LABEL_ACCESSOR]}`}
            >
              <ExamPlannerExaminationImage
                examId={exam?.id}
                style={{
                  width: "50px",
                  maxHeight: "50px",
                  marginRight: "10px",
                }}
              />
              {exam[LABEL_ACCESSOR]}
            </div>
          ))}
        {!isLoading && exams.length === 0 && (
          <>
            <h3>{t("erros.unableToLoad")}</h3>
            <Button onClick={refetch}>Reload</Button>
          </>
        )}
        <Button disabled={!hasNextPage} onClick={fetchNextPage}>
          {t("buttons.loadMore")}
        </Button>
      </div>
    </>
  );
}

function AllowedExams({
  allowedExams,
  selectedExam,
  selectedExamSetter,
  setAllowedExams,
}) {
  const { t } = useTranslation(["common"]);
  return (
    <div>
      <div className="d-flex">
        <h1 className="text-white">{t("exams")}</h1>
        <Button className="ml-auto m-2" onClick={() => setAllowedExams([])}>
          {t("showAll")}
        </Button>
      </div>
      <div className={styles.ExamListWrapper}>
        {allowedExams.map((exam) => (
          <ExamRow
            examId={exam}
            selectedExam={selectedExam}
            selectedExamSetter={selectedExamSetter}
          />
        ))}
      </div>
    </div>
  );
}
