export const QaApiResponse1331 = {
  data: [
    {
      examid: 1331,
      qaAnatomyCriteria:
        "Visuell scharfe, überlagerungsfreie Darstellung der gelenknahen Knochen Konturen",
      qaCriteriaId: 75,
      colors: { gelenknahen_KnochenFill: "#128476" },
      exam: "Linke Mittelhand d.p sitzend",
    },
    {
      examid: 1331,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen abhängig von der Fragestellung",
      qaCriteriaId: 76,
      colors: {
        weichteilenFill: "#F088B6",
      },
      exam: "Linke Mittelhand d.p sitzend",
    },
    {
      examid: 1331,
      qaAnatomyCriteria: "Überlagerungsfreie Darstellung aller 5 MHK",
      qaCriteriaId: 77,
      colors: {
        MHKFill: "#FFE500",
      },
      exam: "Linke Mittelhand d.p sitzend",
    },
  ],
};

export const QaApiResponse2034 = {
  data: [
    {
      examid: 2034,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung der laterale Teile der Scapula (medial von den Rippen überlagert)",
      qaCriteriaId: 599,
      colors: {
        scapulaFill: "#b91818",
        ripsFill: "#48EF3F",
      },
      exam: "Linkes Schulterblatt v.d liegend",
    },
  ],
};

export const QaApiResponse1459 = {
  data: [
    {
      examid: 1459,
      qaAnatomyCriteria: "Fibula im hinteren Drittel der Tibia",
      qaCriteriaId: 268,
      colors: {
        fibulaFill: "#F088B6",
        tibiaFill: "#FFE500",
      },
      exam: "Linkes oberes Sprunggelenk lat liegend",
    },
    {
      examid: 1459,
      qaAnatomyCriteria: "Talusrolle ist strichförmig",
      qaCriteriaId: 269,
      colors: {
        TalusrolleFill: "#39aa35",
      },
      altColors:{TalusrolleFill: "#45c1f1"},
      exam: "Linkes oberes Sprunggelenk lat liegend",
    },
    {
      examid: 1459,
      qaAnatomyCriteria:
        "laterale und mediale Konturen der Talusrolle decken sich",
      qaCriteriaId: 270,
      colors: {
        TalusrolleFill: "#45c1f1",
      },
      exam: "Linkes oberes Sprunggelenk lat liegend",
    },
  ],
};

export const QaApiResponse1401 = {
  data: [
    {
      examid: 1401,
      qaAnatomyCriteria: "Gelenke dürfen nicht überlagern",
      qaCriteriaId: 144,
      colors: {
        a1401_gelenkeFill: "#b14e97",
      },
      exam: "Linke Großzehe (1.Zehe) d.pl sitzend",
    },
    {
      examid: 1401,
      qaAnatomyCriteria: "Phalangen müssen gut voneinander getrennt sein",
      qaCriteriaId: 145,
      colors: {
        a1401_phalangenFill: "#45c1f1",
      },
      exam: "Linke Großzehe (1.Zehe) d.pl sitzend",
    },
    {
      examid: 1401,
      qaAnatomyCriteria: "Basis (Caput) keine Doppelkontur",
      qaCriteriaId: 146,
      colors: {
        a1401_basisFill: "#39aa35",
        a1401_caputFill: "#fdeb1d",
      },
      exam: "Linke Großzehe (1.Zehe) d.pl sitzend",
    },
  ],
};

export const QaApiResponse1402 = {
  data: [
    {
      examid: 1402,
      qaAnatomyCriteria: "Gelenke dürfen nicht überlagern",
      qaCriteriaId: 147,
      colors: {
        a1402_gelenkeFill: "#b14e97",
      },
      exam: "Rechte Großzehe (1.Zehe) d.pl sitzend",
    },
    {
      examid: 1402,
      qaAnatomyCriteria: "Phalangen müssen gut voneinander getrennt sein",
      qaCriteriaId: 148,
      colors: {
        a1402_phalangenFill: "#45c1f1",
      },
      exam: "Rechte Großzehe (1.Zehe) d.pl sitzend",
    },
    {
      examid: 1402,
      qaAnatomyCriteria: "Basis (Caput) keine Doppelkontur",
      qaCriteriaId: 149,
      colors: {
        a1402_basisFill: "#39aa35",
        a1402_caputFill: "#fdeb1d",
      },
      exam: "Rechte Großzehe (1.Zehe) d.pl sitzend",
    },
  ],
};

export const QaApiResponse1403 = {
  data: [
    {
      examid: 1403,
      qaAnatomyCriteria: "Gute laterale Darstellung des Großzehs",
      qaCriteriaId: 150,
      colors: {
        a1403_GroßzehFill: "#41abe2",
      },
      exam: "Linke Großzehe (1.Zehe) lat sitzend",
    },
    {
      examid: 1403,
      qaAnatomyCriteria: "Gelenke einsehbar",
      qaCriteriaId: 151,
      colors: {
        a1403_gelenkeFill: "#9a4493",
      },
      exam: "Linke Großzehe (1.Zehe) lat sitzend",
    },
  ],
};

export const QaApiResponse1404 = {
  data: [
    {
      examid: 1404,
      qaAnatomyCriteria: "Gute laterale Darstellung des Großzehs",
      qaCriteriaId: 153,
      colors: {
        a1404_GroßzehFill: "#41abe2",
      },
      exam: "Rechte Großzehe (1.Zehe) lat sitzend",
    },
    {
      examid: 1404,
      qaAnatomyCriteria: "Gelenke einsehbar",
      qaCriteriaId: 154,
      colors: {
        a1404_gelenkeFill: "#9a4493",
      },
      exam: "Rechte Großzehe (1.Zehe) lat sitzend",
    },
  ],
};

export const QaApiResponse1407 = {
  data: [
    {
      examid: 1407,
      qaAnatomyCriteria: "Gelenke dürfen nicht überlagern",
      qaCriteriaId: 158,
      colors: {
        a1407_gelenkeFill: "#a83f90",
      },
      exam: "Linke 2.Zehe d.pl sitzend",
    },
    {
      examid: 1407,
      qaAnatomyCriteria: "Phalangen müssen gut voneinander getrennt sein",
      qaCriteriaId: 159,
      colors: {
        a1407_phalangenFill: "#15bae4",
      },
      exam: "Linke 2.Zehe d.pl sitzend",
    },
  ],
};

export const QaApiResponse1408 = {
  data: [
    {
      examid: 1408,
      qaAnatomyCriteria: "Gelenke dürfen nicht überlagern",
      qaCriteriaId: 160,
      colors: {
        a1408_gelenkeFill: "#a83f90",
      },
      exam: "Rechte 2.Zehe d.pl sitzend",
    },
    {
      examid: 1408,
      qaAnatomyCriteria: "Phalangen müssen gut voneinander getrennt sein",
      qaCriteriaId: 161,
      colors: {
        a1408_phalangenFill: "#15bae4",
      },
      exam: "Rechte 2.Zehe d.pl sitzend",
    },
  ],
};

export const QaApiResponse1409 = {
  data: [
    {
      examid: 1409,
      qaAnatomyCriteria: "Gelenke dürfen nicht überlagern",
      qaCriteriaId: 162,
      colors: {
        a1409_gelenkeFill: "#a83f90",
      },
      exam: "Linke 2.Zehe d.pl. obl sitzend",
    },
    {
      examid: 1409,
      qaAnatomyCriteria: "Phalangen müssen gut voneinander getrennt sein",
      qaCriteriaId: 163,
      colors: {
        a1409_phalangenFill: "#15bae4",
      },
      exam: "Linke 2.Zehe d.pl. obl sitzend",
    },
  ],
};

export const QaApiResponse1410 = {
  data: [
    {
      examid: 1410,
      qaAnatomyCriteria: "Gelenke dürfen nicht überlagern",
      qaCriteriaId: 164,
      colors: {
        a1410_gelenkeFill: "#a83f90",
      },
      exam: "Rechte 2.Zehe d.pl. obl sitzend",
    },
    {
      examid: 1410,
      qaAnatomyCriteria: "Phalangen müssen gut voneinander getrennt sein",
      qaCriteriaId: 165,
      colors: {
        a1410_phalangenFill: "#15bae4",
      },
      exam: "Rechte 2.Zehe d.pl. obl sitzend",
    },
  ],
};

export const QaApiResponse1411 = {
  data: [
    {
      examid: 1411,
      qaAnatomyCriteria: "Gelenke dürfen nicht überlagern",
      qaCriteriaId: 166,
      colors: {
        a1411_gelenkeFill: "#a83f90",
      },
      exam: "Linke 3.Zehe d.pl sitzend",
    },
    {
      examid: 1411,
      qaAnatomyCriteria: "Phalangen müssen gut voneinander getrennt sein",
      qaCriteriaId: 167,
      colors: {
        a1411_phalangenFill: "#15bae4",
      },
      exam: "Linke 3.Zehe d.pl sitzend",
    },
  ],
};

export const QaApiResponse1412 = {
  data: [
    {
      examid: 1412,
      qaAnatomyCriteria: "Gelenke dürfen nicht überlagern",
      qaCriteriaId: 168,
      colors: {
        a1412_gelenkeFill: "#a83f90",
      },
      exam: "Rechte 3.Zehe d.pl sitzend",
    },
    {
      examid: 1412,
      qaAnatomyCriteria: "Phalangen müssen gut voneinander getrennt sein",
      qaCriteriaId: 169,
      colors: {
        a1412_phalangenFill: "#15bae4",
      },
      exam: "Rechte 3.Zehe d.pl sitzend",
    },
  ],
};

export const QaApiResponse1413 = {
  data: [

    {
      examid: 1413,
      qaAnatomyCriteria: "Gelenke dürfen nicht überlagern",
      qaCriteriaId: 171,
      colors: {
        a1413_gelenkeFill: "#a83f90",
      },
      exam: "Linke 3.Zehe d.pl. obl sitzend",
    },
    {
      examid: 1413,
      qaAnatomyCriteria: "Phalangen müssen gut voneinander getrennt sein",
      qaCriteriaId: 172,
      colors: {
        a1413_phalangenFill: "#15bae4",
      },
      exam: "Linke 3.Zehe d.pl. obl sitzend",
    },
  ],
};

export const QaApiResponse1415 = {
  data: [

    {
      examid: 1415,
      qaAnatomyCriteria: "Phalangen müssen gut dargestellt sein",
      qaCriteriaId: 177,
      colors: {
        a1415_phalangenFill: "#15bae4",
      },
      exam: "Linke 4.Zehe d.pl sitzend",
    },
    {
      examid: 1415,
      qaAnatomyCriteria: "Gelenke muss einsehbar sein",
      qaCriteriaId: 178,
      colors: {
        a1415_gelenkeFill: "#a83f90",
      },
      exam: "Linke 4.Zehe d.pl sitzend",
    },
  ],
};

export const QaApiResponse1416 = {
  data: [

    {
      examid: 1416,
      qaAnatomyCriteria: "Phalangen müssen gut dargestellt sein",
      qaCriteriaId: 180,
      colors: {
        a1416_phalangenFill: "#15bae4",
      },
      exam: "Rechte 4.Zehe d.pl sitzend",
    },
    {
      examid: 1416,
      qaAnatomyCriteria: "Gelenke muss einsehbar sein",
      qaCriteriaId: 181,
      colors: {
        a1416_gelenkeFill: "#a83f90",
      },
      exam: "Rechte 4.Zehe d.pl sitzend",
    },
  ],
};

export const QaApiResponse1417 = {
  data: [

    {
      examid: 1417,
      qaAnatomyCriteria: "Gelenke dürfen nicht überlagern",
      qaCriteriaId: 183,
      colors: {
        a1417_gelenkeFill: "#a83f90",
      },
      exam: "Linke 4.Zehe d.pl. obl sitzend",
    },
    {
      examid: 1417,
      qaAnatomyCriteria: "Phalangen müssen gut voneinander getrennt sein",
      qaCriteriaId: 184,
      colors: {
        a1417_phalangenFill: "#15bae4",
      },
      exam: "Linke 4.Zehe d.pl. obl sitzend",
    },
  ],
};

export const QaApiResponse1418 = {
  data: [

    {
      examid: 1418,
      qaAnatomyCriteria: "Gelenke dürfen nicht überlagern",
      qaCriteriaId: 186,
      colors: {
        a1418_gelenkeFill: "#a83f90",
      },
      exam: "Rechte 4.Zehe d.pl. obl sitzend",
    },
    {
      examid: 1418,
      qaAnatomyCriteria: "Phalangen müssen gut voneinander getrennt sein",
      qaCriteriaId: 187,
      colors: {
        a1418_phalangenFill: "#15bae4",
      },
      exam: "Rechte 4.Zehe d.pl. obl sitzend",
    },
  ],
};

export const QaApiResponse1419 = {
  data: [

    {
      examid: 1419,
      qaAnatomyCriteria: "Phalangen müssen gut dargestellt sein",
      qaCriteriaId: 189,
      colors: {
        a1419_phalangenFill: "#15bae4",
      },
      exam: "Linke 5.Zehe d.pl sitzend",
    },
    {
      examid: 1419,
      qaAnatomyCriteria: "Gelenke muss einsehbar sein",
      qaCriteriaId: 190,
      colors: {
        a1419_gelenkeFill: "#a83f90",
      },
      exam: "Linke 5.Zehe d.pl sitzend",
    },
  ],
};

export const QaApiResponse1425 = {
  data: [
    {
      examid: 1425,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung Sesambeine und Mittelfußköpfchen",
      qaCriteriaId: 202,
      colors: {
        a1425_sesambeineFill: "#ffed00",
        a1425_MittelfußköpfchenFill: "#22a1dc",
      },
      exam: "Linkes Sesambein (Großzehe) tang liegend",
    },
  ],
};

export const QaApiResponse1902 = {
  data: [
    {
      examid: 1902,
      qaAnatomyCriteria:
        "Vollständige und symmetrische Darstellung des gesamten Schädels",
      qaCriteriaId: 524,
      colors: {
        Schadel1902Fill: "#fe0",
      },
      exam: "Schädel p.a sitzend",
    },
    {
      examid: 1902,
      qaAnatomyCriteria: "Nasenscheidewand streng in der Mitte",
      qaCriteriaId: 912,
      colors: {
        Nasenscheidewand1902Fill: "#008000",
      },
      exam: "Schädel p.a liegend",
    },
    {
      examid: 1902,
      qaAnatomyCriteria: "Darstellung der Felsenbeinoberkante in Orbitamitte",
      qaCriteriaId: 525,
      colors: {
        Felsenbeinoberkante1902Fill: "#e72166",
        Orbita1902Fill: "#45b4e8",
      },
      exam: "Schädel p.a sitzend",
    },
  ],
};

export const QaApiResponse1903 = {
  data: [
    {
      examid: 1903,
      qaAnatomyCriteria: "Schädel symmetrisch und vollständig abgebildet",
      qaCriteriaId: 526,
      colors: {
        Schädel1903Fill: "#fe0",
      },
      exam: "Schädel p.a liegend",
    },
    {
      examid: 1903,
      qaAnatomyCriteria: "Nasenscheidewand streng in der Mitte",
      qaCriteriaId: 527,
      colors: {
        Nasenscheidewand1903Fill: "#008000",
      },
      exam: "Schädel p.a liegend",
    },
    {
      examid: 1903,
      qaAnatomyCriteria: "Darstellung der Felsenbeinoberkante in Orbitamitte",
      qaCriteriaId: 528,
      colors: {
        Felsenbeinoberkante1903Fill: "#e72166",
        Orbita1903Fill: "#45b4e8",
      },
      exam: "Schädel p.a liegend",
    },
  ],
};

export const QaApiResponse2023 = {
  data: [
    {
      examid: 2023,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung der laterale Teile der Scapula (medial von den Rippen überlagert)",
      qaCriteriaId: 585,
      colors: { Scapula2023Fill: "#b91818", ribs2023Fill: "#48EF3F" },
      exam: "Linkes Schulterblatt v.d stehend",
    },
  ],
};

export const QaApiResponse2030 = {
  data: [
    {
      examid: 2030,
      qaAnatomyCriteria:
        "Seitengleiche Darstellung der beiden Sternoclaviculagelenke",
      qaCriteriaId: 595,
      colors: {
        Sternoclaviculagelenke2030Fill: "#954c9d",
      },
      exam: "Linkes Sternoclaviculargelenk d.v.obl stehend",
    },
  ],
};

export const QaApiResponse2201 = {
  data: [
    {
      examid: 2201,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung von Radius und Ulna durch Supination",
      qaCriteriaId: 631,
      colors: {
        Radius2201Fill: "#7E20F7",
        Ulna2201Fill: "#6BF720",
      },
      exam: "Linker Unterarm mit Handgelenk p.d sitzend",
    },
  ],
};

export const QaApiResponse2202 = {
  data: [
    {
      examid: 2202,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung von Ulna und Radius durch Supination",
      qaCriteriaId: 632,
      colors: {
        Ulna2202Fill: "#6BF720",
        Radius2202Fill: "#7E20F7",
        
      },
      exam: "Rechter Unterarm mit Handgelenk p.d sitzend",
    },
  ],
};

export const QaApiResponse2203 = {
  data: [
    {
      examid: 2203,
      qaAnatomyCriteria:
        "Handgelenk vollständig dargestellt --->Metacarpalia, HWK und distaler UA",
      qaCriteriaId: 633,
      colors: {
        Handgelenk2203Fill: "#F72020",
        Metacarpalia2203Fill: "#fe0",
        HWK2203Fill: "#7E20F7",
        DistalUA2203Fill: "#00FFFF",
      },
      exam: "Linker Unterarm mit Handgelenk d.p sitzend",
    },
  ],
};

export const QaApiResponse2204 = {
  data: [
    {
      examid: 2204,
      qaAnatomyCriteria:
        "Handgelenk vollständig dargestellt --->Metacarpalia, HWK und distaler UA",
      qaCriteriaId: 634,
      colors: {
        Handgelenk2204Fill: "#F72020",
        Metacarpalia2204Fill: "#fe0",
        HWK2204Fill: "#7E20F7",
        DistalUA2204Fill: "#00FFFF",
      },
      exam: "Rechter Unterarm mit Handgelenk d.p sitzend",
    },
  ],
};

export const QaApiResponse2205 = {
  data: [
    {
      examid: 2205,
      qaAnatomyCriteria:
        "Ulna und Radius im distalen Bereich deckungsgleich übereinander",
      qaCriteriaId: 635,
      colors: {
        Ulna2205Fill: "#fe0",
        Radius2205Fill: "#00FFFF",
      },
      exam: "Linker Unterarm mit Handgelenk lat sitzend",
    },
    {
      examid: 2205,
      qaAnatomyCriteria: "Handgelenk seitlich dargestellt",
      qaCriteriaId: 636,
      colors: {
        Handgelenk2204Fill: "#F72020",
      },
      exam: "",
    },
  ],
};

export const QaApiResponse2206 = {
  data: [
    {
      examid: 2206,
      qaAnatomyCriteria:
        "Ulna und Radius im distalen Bereich deckungsgleich übereinander",
      qaCriteriaId: 637,
      colors: {
        Ulna2206Fill: "#fe0",
        Radius2206Fill: "#00FFFF",
      },
      exam: "Rechter Unterarm mit Handgelenk lat sitzend",
    },
    {
      examid: 2206,
      qaAnatomyCriteria: "Handgelenk seitlich dargestellt",
      qaCriteriaId: 638,
      colors: {
        Handgelenk2206Fill: "#F72020",
      },
      exam: "",
    },
  ],
};

export const QaApiResponse2207 = {
  data: [
    {
      examid: 2207,
      qaAnatomyCriteria: "Gute Darstellung des Gelenkspaltes",
      qaCriteriaId: 639,
      colors: {
        Gelenkspalte2207Fill: "#F72020",
      },
      exam: "Rechter Unterarm mit Ellenbogen v.d sitzend",
    },
    {
      examid: 2207,
      qaAnatomyCriteria: "Radiusköpfchen überlagerungsfrei",
      qaCriteriaId: 640,
      colors: {
        RadiusHead2207Fill: "#6BF720",
      },
      exam: "Rechter Unterarm mit Ellenbogen v.d sitzend",
    },
  ],
};

export const QaApiResponse2208 = {
  data: [
    {
      examid: 2208,
      qaAnatomyCriteria: "Gute Darstellung des Gelenkspaltes",
      qaCriteriaId: 641,
      colors: {
        Gelenkspalte2208Fill: "#F72020",
      },
      exam: "Linker Unterarm mit Ellenbogen v.d sitzend",
    },
    {
      examid: 2208,
      qaAnatomyCriteria: "Radiusköpfchen überlagerungsfrei",
      qaCriteriaId: 642,
      colors: {
        RadiusHead2208Fill: "#6BF720",
      },
      exam: "Linker Unterarm mit Ellenbogen v.d sitzend",
    },
  ],
};

export const QaApiResponse2209 = {
  data: [
    {
      examid: 2209,
      qaAnatomyCriteria: "Oberarm Kondylen liegen deckungsgleich übereinander",
      qaCriteriaId: 643,
      colors: {
        Oberarmkondylen2209Fill: "#F72020",
      },
      exam: "Rechter Unterarm mit Ellenbogen lat sitzend",
    },
    {
      examid: 2209,
      qaAnatomyCriteria: "Radiusköpfchen gut sichtbar",
      qaCriteriaId: 644,
      colors: {
        RadiusHead2209Fill: "#6BF720",
      },
      exam: "Rechter Unterarm mit Ellenbogen lat sitzend",
    },
  ],
};

export const QaApiResponse2210 = {
  data: [
    {
      examid: 2210,
      qaAnatomyCriteria: "Oberarm Kondylen liegen deckungsgleich übereinander",
      qaCriteriaId: 645,
      colors: {
        Oberarmkondylen2210Fill: "#F72020",
      },
      exam: "Linker Unterarm mit Ellenbogen lat sitzend",
    },
    {
      examid: 2210,
      qaAnatomyCriteria: "Radiusköpfchen gut sichtbar",
      qaCriteriaId: 646,
      colors: {
        RadiusHead2210Fill: "#6BF720",
      },
      exam: "Linker Unterarm mit Ellenbogen lat sitzend",
    },
  ],
};

export const QaApiResponse2211 = {
  data: [
    {
      examid: 2211,
      qaAnatomyCriteria: "Gute Darstellung des Gelenkspaltes",
      qaCriteriaId: 647,
      colors: {
        Gelenkspalte2211Fill: "#F72020",
      },
      exam: "Linker Ellenbogen v.d sitzend",
    },
    {
      examid: 2211,
      qaAnatomyCriteria: "Radiusköpfchen überlagerungsfrei",
      qaCriteriaId: 648,
      colors: {
        RadiusHead2211Fill: "#6BF720",
      },
      exam: "Linker Ellenbogen v.d sitzend",
    },
  ],
};

export const QaApiResponse2212 = {
  data: [
    {
      examid: 2212,
      qaAnatomyCriteria: "Gute Darstellung des Gelenkspaltes",
      qaCriteriaId: 649,
      colors: {
        Gelenkspalte2212Fill: "#F72020",
      },
      exam: "Rechter Ellenbogen v.d sitzend",
    },
    {
      examid: 2212,
      qaAnatomyCriteria: "Radiusköpfchen überlagerungsfrei",
      qaCriteriaId: 650,
      colors: {
        RadiusHead2212Fill: "#6BF720",
      },
      exam: "Rechter Ellenbogen v.d sitzend",
    },
  ],
};

export const QaApiResponse2213 = {
  data: [
    {
      examid: 2213,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung des humeroulnaren Gelenkes",
      qaCriteriaId: 651,
      colors: {
        humeroulnarenGelenke2213Fill: "#FF0000",
      },
      exam: "Linker Ellenbogen lat sitzend",
    },
    {
      examid: 2213,
      qaAnatomyCriteria: "Radiusköpfchen gut erkennbar",
      qaCriteriaId: 652,
      colors: {
        Oberarmcondylen2213Fill: "#6BF720",
      },
      exam: "Linker Ellenbogen lat sitzend",
    },
    {
      examid: 2213,
      qaAnatomyCriteria: "Radiusköpfchen gut erkennbar",
      qaCriteriaId: 653,
      colors: {
        RadiusHead2213Fill: "#0000FF",
      },
      exam: "Linker Ellenbogen lat sitzend",
    },
    {
      examid: 2213,
      qaAnatomyCriteria: "Unterarm streng seitlich",
      qaCriteriaId: 654,
      colors: {
        Unterarm2213Fill: "#FFFF00",
      },
      exam: "Linker Ellenbogen lat sitzend",
    },
  ],
};

export const QaApiResponse2214 = {
  data: [
    {
      examid: 2214,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung des humeroulnaren Gelenkes",
      qaCriteriaId: 655,
      colors: {
        humeroulnarenGelenke2214Fill: "#FF0000",
      },
      exam: "Rechter Ellenbogen lat sitzend",
    },
    {
      examid: 2214,
      qaAnatomyCriteria: "Oberarmcondylen übereinander",
      qaCriteriaId: 656,
      colors: {
        Oberarmcondylen2214Fill: "#6BF720",
      },
      exam: "Rechter Ellenbogen lat sitzend",
    },
    {
      examid: 2214,
      qaAnatomyCriteria: "Radiusköpfchen gut erkennbar",
      qaCriteriaId: 657,
      colors: {
        RadiusHead2214Fill: "#0000FF",
      },
      exam: "Rechter Ellenbogen lat sitzend",
    },
    {
      examid: 2214,
      qaAnatomyCriteria: "Unterarm streng seitlich",
      qaCriteriaId: 658,
      colors: {
        Unterarm2214Fill: "#FFFF00",
      },
      exam: "Rechter Ellenbogen lat sitzend",
    },
  ],
};

export const QaApiResponse2215 = {
  data: [
    {
      examid: 2215,
      qaAnatomyCriteria: "Ober- und Unterarm deckungsgleich/übereinander",
      qaCriteriaId: 659,
      colors: {
        Oberarm2215Fill: "#FFFF00",
        Unterarm2215Fill: "#0000FF",
      },
      exam: "Olekranon Links ax sitzend",
    },
    {
      examid: 2215,
      qaAnatomyCriteria: "Gut Darstellung des Olecranon",
      qaCriteriaId: 660,
      colors: {
        Olecranon2215Fill: "#FF0000",
      },
      exam: "Olekranon Links ax sitzend",
    },
  ],
};

export const QaApiResponse2216 = {
  data: [
    {
      examid: 2216,
      qaAnatomyCriteria: "Ober- und Unterarm deckungsgleich/übereinander",
      qaCriteriaId: 661,
      colors: {
        Oberarm2216Fill: "#FFFF00",
        Unterarm2216Fill: "#0000FF",
      },
      exam: "Olekranon Rechts ax sitzend",
    },
    {
      examid: 2216,
      qaAnatomyCriteria: "Gut Darstellung des Olecranon",
      qaCriteriaId: 662,
      colors: {
        Olecranon2216Fill: "#FF0000",
      },
      exam: "Olekranon Rechts ax sitzend",
    },
  ],
};

export const QaApiResponse2217 = {
  data: [
    {
      examid: 2217,
      qaAnatomyCriteria:
        "Gute Darstellung beider Epicondylen und der Rinne des Nervus ulnaris",
      qaCriteriaId: 663,
      colors: {
        Epicondylen2217Fill: "#6BF720",
        Rinne2217Fill: "#F72020",
      },
      exam: "Linke Sulcus ulnaris ax sitzend",
    },
  ],
};

export const QaApiResponse2218 = {
  data: [
    {
      examid: 2218,
      qaAnatomyCriteria:
        "Gute Darstellung beider Epicondylen und der Rinne des Nervus ulnaris",
      qaCriteriaId: 664,
      colors: {
        Epicondylen2218Fill: "#6BF720",
        Rinne2218Fill: "#F72020",
      },
      exam: "Rechte Sulcus ulnaris ax sitzend",
    },
  ],
};

export const QaApiResponse2219 = {
  data: [
    {
      examid: 2219,
      qaAnatomyCriteria: "Überlagerungsfreie Darstellung des Radiusköpfchen",
      qaCriteriaId: 665,
      colors: {
        RadiusHead2219Fill: "#005f38",
      },
      exam: "Linkes Radiusköpfchen ml sitzend",
    },
    {
      examid: 2219,
      qaAnatomyCriteria: "Gute Beurteilung des Gelenkes Humerus--> Radius",
      qaCriteriaId: 666,
      colors: {
        Gelenk2219Fill: "#F72020",
      },
      exam: "Linkes Radiusköpfchen ml sitzend",
    },
  ],
};

export const QaApiResponse2220 = {
  data: [
    {
      examid: 2220,
      qaAnatomyCriteria: "Überlagerungsfreie Darstellung des Radiusköpfchen",
      qaCriteriaId: 667,
      colors: {
        RadiusHead2220Fill: "#005f38",
      },
      exam: "Rechtes Radiusköpfchen ml sitzend",
    },
    {
      examid: 2220,
      qaAnatomyCriteria: "Gute Beurteilung des Gelenkes Humerus--> Radius",
      qaCriteriaId: 668,
      colors: {
        Gelenk2220Fill: "#F72020",
      },
      exam: "Rechtes Radiusköpfchen ml sitzend",
    },
  ],
};

export const QaApiResponse2221 = {
  data: [
    {
      examid: 2221,
      qaAnatomyCriteria: "Überlagerungsfreie Darstellung des Radiusköpfchen",
      qaCriteriaId: 669,
      colors: {
        RadiusHead2221Fill: "#005f38",
      },
      exam: "Rechtes Radiusköpfchen nach Greenspan lat sitzend",
    },
    {
      examid: 2221,
      qaAnatomyCriteria: "Freie Darstellung der Articulatio humero-ulnaris",
      qaCriteriaId: 670,
      colors: {
        Articulati2221Fill: "#F72020",
      },
      exam: "Rechtes Radiusköpfchen nach Greenspan lat sitzend",
    },
  ],
};

export const QaApiResponse2222 = {
  data: [
    {
      examid: 2222,
      qaAnatomyCriteria: "Überlagerungsfreie Darstellung des Radiusköpfchen",
      qaCriteriaId: 671,
      colors: {
        RadiusHead2222Fill: "#005f38",
      },
      exam: "Linkes Radiusköpfchen nach Greenspan lat sitzend",
    },
    {
      examid: 2222,
      qaAnatomyCriteria: "Freie Darstellung der Articulatio humero-ulnaris",
      qaCriteriaId: 672,
      colors: {
        Articulati2222Fill: "#F72020",
      },
      exam: "Linkes Radiusköpfchen nach Greenspan lat sitzend",
    },
  ],
};

export const QaApiResponse2223 = {
  data: [
    {
      examid: 2223,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung des Processus coronoideus",
      qaCriteriaId: 673,
      colors: {
        Kronenfortsatz2223Fill: "#F72020",
      },
      exam: "Linker Kronenfortsatz ml sitzend",
    },
  ],
};

export const QaApiResponse2224 = {
  data: [
    {
      examid: 2224,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung des Processus coronoideus",
      qaCriteriaId: 674,
      colors: {
        Kronenfortsatz2224Fill: "#F72020",
      },
      exam: "Rechter Kronenfortsatz ml sitzend",
    },
  ],
};

export const QaApiResponse2225 = {
  data: [
    {
      examid: 2225,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung Oberarmkopf und Schulter",
      qaCriteriaId: 675,
      colors: {
        Oberarmkopf2225Fill: "#F72020",
        Schulter2225Fill: "#4e8dcb",
      },
      exam: "Linker Oberarm mit Schulter v.d stehend",
    },
    {
      examid: 2225,
      qaAnatomyCriteria: "Tuberculum majus erkennbar",
      qaCriteriaId: 676,
      colors: {
        Tuberculum_majus2225Fill: "#6BF720",
      },
      exam: "Linker Oberarm mit Schulter v.d stehend",
    },
  ],
};

export const QaApiResponse2226 = {
  data: [
    {
      examid: 2226,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung Oberarmkopf und Schulter",
      qaCriteriaId: 677,
      colors: {
        Oberarmkopf2226Fill: "#F72020",
        Schulter2226Fill: "#4e8dcb",
      },
      exam: "Rechter Oberarm mit Schulter v.d stehend",
    },
    {
      examid: 2226,
      qaAnatomyCriteria: "Tuberculum majus erkennbar",
      qaCriteriaId: 678,
      colors: {
        Tuberculum_majus2226Fill: "#6BF720",
      },
      exam: "Rechter Oberarm mit Schulter v.d stehend",
    },
  ],
};

export const QaApiResponse2227 = {
  data: [
    {
      examid: 2227,
      qaAnatomyCriteria: "Schultergelenk muss beurteilbar sein",
      qaCriteriaId: 679,
      colors: {
        Schultergelenk2227Fill: "#F72020",
      },
      exam: "Linker Oberarm mit Schulter lat stehend",
    },
    {
      examid: 2227,
      qaAnatomyCriteria: "Oberarm seitlich",
      qaCriteriaId: 680,
      colors: {
        Oberarm2227Fill: "#6BF720",
      },
      exam: "Linker Oberarm mit Schulter lat stehend",
    },
  ],
};

export const QaApiResponse2228 = {
  data: [
    {
      examid: 2228,
      qaAnatomyCriteria: "Schultergelenk muss beurteilbar sein",
      qaCriteriaId: 681,
      colors: {
        Schultergelenk2228Fill: "#F72020",
      },
      exam: "Rechter Oberarm mit Schulter lat stehend",
    },
    {
      examid: 2228,
      qaAnatomyCriteria: "Oberarm seitlich",
      qaCriteriaId: 682,
      colors: {
        Oberarm2228Fill: "#6BF720",
      },
      exam: "Rechter Oberarm mit Schulter lat stehend",
    },
  ],
};

export const QaApiResponse2229 = {
  data: [
    {
      examid: 2229,
      qaAnatomyCriteria: "Gelenkspalt gut dargestellt",
      qaCriteriaId: 683,
      colors: {
        Gelenkspalt2229Fill: "#f088b6",
      },
      exam: "Linker Oberarm mit Ellenbogen v.d sitzend",
    },
    {
      examid: 2229,
      qaAnatomyCriteria: "Epicondylen gut dargestellt",
      qaCriteriaId: 684,
      colors: {
        Epicondylen2229Fill: "#6BF720",
      },
      exam: "Linker Oberarm mit Ellenbogen v.d sitzend",
    },
    {
      examid: 2229,
      qaAnatomyCriteria: "Radiusköpfchen überlagerungsfre",
      qaCriteriaId: 685,
      colors: {
        RadiusHead2229Fill: "#4e8dcb",
      },
      exam: "Linker Oberarm mit Ellenbogen v.d sitzend",
    },
  ],
};

export const QaApiResponse2230 = {
  data: [
    {
      examid: 2230,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung des humeroulnaren Gelenks",
      qaCriteriaId: 686,
      colors: {
        humeroulnarenGelenk2230Fill: "#F72020",
      },
      exam: "Rechter Oberarm mit Ellenbogen lat sitzend",
    },
    {
      examid: 2230,
      qaAnatomyCriteria: "Gute Darstellung Radiusköpfchen",
      qaCriteriaId: 687,
      colors: {
        RadiusHead2230Fill: "#6BF720",
      },
      exam: "Rechter Oberarm mit Ellenbogen lat sitzend",
    },
    {
      examid: 2230,
      qaAnatomyCriteria: "Oberarm und Ellenbogen gut belichtet",
      qaCriteriaId: 688,
      colors: {
        Oberarm2230Fill: "#45b4e8",
        Ellenbogen2230Fill: "#fe0",
      },
      exam: "Rechter Oberarm mit Ellenbogen lat sitzend",
    },
  ],
};

export const QaApiResponse2232 = {
  data: [
    {
      examid: 2232,
      qaAnatomyCriteria: "Gelenkspalt gut dargestellt",
      qaCriteriaId: 689,
      colors: {
        Gelenkspalt2232Fill: "#f088b6",
      },
      exam: "Rechter Oberarm mit Ellenbogen v.d sitzend",
    },
    {
      examid: 2232,
      qaAnatomyCriteria: "Epicondylen gut dargestellt",
      qaCriteriaId: 690,
      colors: {
        Epicondylen2232Fill: "#6BF720",
      },
      exam: "Rechter Oberarm mit Ellenbogen v.d sitzend",
    },
    {
      examid: 2232,
      qaAnatomyCriteria: "Radiusköpfchen überlagerungsfre",
      qaCriteriaId: 691,
      colors: {
        RadiusHead2232Fill: "#4e8dcb",
      },
      exam: "Rechter Oberarm mit Ellenbogen v.d sitzend",
    },
  ],
};

export const QaApiResponse2233 = {
  data: [
    {
      examid: 2233,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung des humeroulnaren Gelenks",
      qaCriteriaId: 692,
      colors: {
        humeroulnarenGelenk2233Fill: "#F72020",
      },
      exam: "Linker Oberarm mit Ellenbogen lat sitzend",
    },
    {
      examid: 2233,
      qaAnatomyCriteria: "Gute Darstellung Radiusköpfchen",
      qaCriteriaId: 693,
      colors: {
        RadiusHead2233Fill: "#6BF720",
      },
      exam: "Linker Oberarm mit Ellenbogen lat sitzend",
    },
    {
      examid: 2233,
      qaAnatomyCriteria: "Oberarm und Ellenbogen gut belichtet",
      qaCriteriaId: 694,
      colors: {
        Oberarm2233Fill: "#45b4e8",
        Ellenbogen2233Fill: "#fe0",
      },
      exam: "Linker Oberarm mit Ellenbogen lat sitzend",
    },
  ],
};

export const QaApiResponse2301 = {
  data: [
    {
      examid: 2301,
      qaAnatomyCriteria:
        "Gut belichtete kontrastreiche Darstellung von Tibia, Talus und Fibula mit OSG",
      qaCriteriaId: 695,
      colors: {
        Tibia2301Fill: "#6BF720",
        Talus2301Fill: "#7E20F7",
        Fibula2301Fill: "#45b4e8",
        OSG2301Fill: "#F72020",
      },
      exam: "Linker Unterschenkel mit oberem Sprunggelenk v.d liegend",
    },
  ],
};

export const QaApiResponse2302 = {
  data: [
    {
      examid: 2302,
      qaAnatomyCriteria:
        "Gut belichtete kontrastreiche Darstellung von Tibia, Talus und Fibula mit OSG",
      qaCriteriaId: 696,
      colors: {
        tibia2302fill: "#6BF720",
        Talus2302Fill: "#7E20F7",
        Fibula2302Fill: "#45b4e8",
        OSG2302Fill: "#F72020",
      },
      exam: "Rechter Unterschenkel mit oberem Sprunggelenk v.d liegend",
    },
  ],
};

export const QaApiResponse2303 = {
  data: [
    {
      examid: 2303,
      qaAnatomyCriteria:
        "Gut belichtete kontrastreiche Darstellung von Tibia, Talus und Fibula mit OSG",
      qaCriteriaId: 697,
      colors: {
        Tibia2303Fill: "#6BF720",
        Talus2303Fill: "#7E20F7",
        Fibula2303Fill: "#45b4e8",
        OSG2303Fill: "#F72020",
      },
      exam: "Linker Unterschenkel mit oberem Sprunggelenk v.d stehend",
    },
  ],
};

export const QaApiResponse2304 = {
  data: [
    {
      examid: 2304,
      qaAnatomyCriteria:
        "Gut belichtete kontrastreiche Darstellung von Tibia, Talus und Fibula mit OSG",
      qaCriteriaId: 698,
      colors: {
        Tibia2304Fill: "#6BF720",
        Talus2304Fill: "#7E20F7",
        Fibula2304Fill: "#45b4e8",
        OSG2304Fill: "#F72020",
      },
      exam: "Rechter Unterschenkel mit oberem Sprunggelenk v.d stehend",
    },
  ],
};

export const QaApiResponse2305 = {
  data: [
    {
      examid: 2305,
      qaAnatomyCriteria:
        "Fibula projiziert sich in Höhe des OSG auf das mittlere bis hintere Drittel der Tibia",
      qaCriteriaId: 699,
      colors: {
        Fibula2305Fill: "#7E20F7",
        Tibia2305Fill: "#f59d24",
        OSG2305Fill: "#6BF720",
      },
      exam: "Linker Unterschenkel mit oberem Sprunggelenk lat liegend",
    },
    {
      examid: 2305,
      qaAnatomyCriteria: "Calcaneus und Talus seitlich dargestellt",
      qaCriteriaId: 700,
      colors: {
        Calcaneus2305Fill: "#F72020",
        Talus2305Fill: "#2020F7",
      },
      exam: "Linker Unterschenkel mit oberem Sprunggelenk lat liegend",
    },
  ],
};

export const QaApiResponse2306 = {
  data: [
    {
      examid: 2306,
      qaAnatomyCriteria:
        "Fibula projiziert sich in Höhe des OSG auf das mittlere bis hintere Drittel der Tibia",
      qaCriteriaId: 701,
      colors: {
        
        Fibula2306Fill: "#7E20F7",
        OSG2306Fill: "#6BF720",
        Tibia2306Fill: "#f59d24",
      },
      exam: "Rechter Unterschenkel mit oberem Sprunggelenk lat liegend",
    },
    {
      examid: 2306,
      qaAnatomyCriteria: "Calcaneus und Talus seitlich dargestellt",
      qaCriteriaId: 702,
      colors: {
        Calcaneus2306Fill: "#F72020",
        Talus2306Fill: "#2020F7",
      },
      exam: "Rechter Unterschenkel mit oberem Sprunggelenk lat liegend",
    },
  ],
};

export const QaApiResponse2307 = {
  data: [
    {
      examid: 2307,
      qaAnatomyCriteria:
        "Vollständige darstellung Femur, Fibula, Tibia und Patella",
      qaCriteriaId: 704,
      colors: {
        Femur2307Fill: "#F72020",
        Fibula2307Fill: "#7E20F7",
        Tibia2307Fill: "#fe0",
        Patella2307Fill: "#6BF720",
      },
      exam: "Linker Unterschenkel mit Knie v.d liegend",
    },
    {
      examid: 2307,
      qaAnatomyCriteria: "Patella mittelständig",
      qaCriteriaId: 705,
      colors: {
        Patella2307Fill: "#6BF720",
      },
      exam: "Linker Unterschenkel mit Knie v.d liegend",
    },
    {
      examid: 2307,
      qaAnatomyCriteria: "Gelenkfläche bzw Gelenkspalt gut einsehbar",
      qaCriteriaId: 706,
      colors: {
        Gelenkspalt2307Fill: "#15a2ce",
      },
      exam: "Linker Unterschenkel mit Knie v.d liegend",
    },
  ],
};

export const QaApiResponse2308 = {
  data: [
    {
      examid: 2308,
      qaAnatomyCriteria:
        "Vollständige darstellung Femur, Fibula, Tibia und Patella",
      qaCriteriaId: 708,
      colors: {
        Femur2308Fill: "#F72020",
        Fibula2308Fill: "#7E20F7",
        Tibia2308Fill: "#fe0",
        Patella2308Fill: "#6BF720",
      },
      exam: "Rechter Unterschenkel mit Knie v.d liegend",
    },
    {
      examid: 2308,
      qaAnatomyCriteria: "Patella mittelständig",
      qaCriteriaId: 709,
      colors: {
        Patella2308Fill: "#6BF720",
      },
      exam: "Rechter Unterschenkel mit Knie v.d liegend",
    },
    {
      examid: 2308,
      qaAnatomyCriteria: "Gelenkfläche bzw Gelenkspalt gut einsehbar",
      qaCriteriaId: 710,
      colors: {
        Gelenkspalt2308Fill: "#15a2ce",
      },
      exam: "Rechter Unterschenkel mit Knie v.d liegend",
    },
  ],
};

export const QaApiResponse2309 = {
  data: [
    {
      examid: 2309,
      qaAnatomyCriteria: "Gute Darstellung des Gelenkspaltes",
      qaCriteriaId: 711,
      colors: {
        gelenkspalt2309fill: "#f59d24",
      },
      exam: "Linker Unterschenkel mit Knie v.d stehend",
    },
    {
      examid: 2309,
      qaAnatomyCriteria: "Patella mittelständig",
      qaCriteriaId: 712,
      colors: {
        patella2309fill: "#4e8dcb",
      },
      exam: "Linker Unterschenkel mit Knie v.d stehend",
    },
  ],
};

export const QaApiResponse2310 = {
  data: [
    {
      examid: 2310,
      qaAnatomyCriteria: "Gute Darstellung des Gelenkspaltes",
      qaCriteriaId: 713,
      colors: {
        gelenkspalt2310fill: "#f59d24",
      },
      exam: "Rechter Unterschenkel mit Knie v.d stehend",
    },
    {
      examid: 2310,
      qaAnatomyCriteria: "Patella mittelständig",
      qaCriteriaId: 714,
      colors: {
        patella2310fill: "#4e8dcb",
      },
      exam: "Rechter Unterschenkel mit Knie v.d stehend",
    },
  ],
};

export const QaApiResponse2311 = {
  data: [
    {
      examid: 2311,
      qaAnatomyCriteria: "Femurcondylen decken sich-liegen übereinander",
      qaCriteriaId: 715,
      colors: {
        femurcondylen2311fill: "#f59d24",
      },
      exam: "Linker Unterschenkel mit Knie lat liegend",
    },
  ],
};

export const QaApiResponse2312 = {
  data: [
    {
      examid: 2312,
      qaAnatomyCriteria: "Femurcondylen decken sich-liegen übereinander",
      qaCriteriaId: 716,
      colors: {
        femurcondylen2312fill: "#f59d24",
      },
      exam: "Rechter Unterschenkel mit Knie lat liegend",
    },
  ],
};

export const QaApiResponse2315 = {
  data: [
    {
      examid: 2315,
      qaAnatomyCriteria: "Gut einsehbarer Gelenkspalt",
      qaCriteriaId: 719,
      colors: {
        Gelenkspalt2315Fill: "#4eae33",
      },
      exam: "Linkes Kniegelenk v.d stehend",
    },
    {
      examid: 2315,
      qaAnatomyCriteria: "Symmetrische Darstellung der Femurcondylen",
      qaCriteriaId: 720,
      colors: {
        Femurcondylen2315Fill: "#f07f3e",
      },
      exam: "Linkes Kniegelenk v.d stehend",
    },
    {
      examid: 2315,
      qaAnatomyCriteria: "Patella fast mittelständig",
      qaCriteriaId: 721,
      colors: {
        Patella2315Fill: "#4e8dcb",
      },
      exam: "Linkes Kniegelenk v.d stehend",
    },
  ],
};

export const QaApiResponse2316 = {
  data: [
    {
      examid: 2316,
      qaAnatomyCriteria: "Gut einsehbarer Gelenkspalt",
      qaCriteriaId: 722,
      colors: {
        Gelenkspalt2316Fill: "#4eae33",
      },
      exam: "Rechtes Kniegelenk v.d stehend",
    },
    {
      examid: 2316,
      qaAnatomyCriteria: "Symmetrische Darstellung der Femurcondylen",
      qaCriteriaId: 723,
      colors: {
        Femurcondylen2316Fill: "#f07f3e",
      },
      exam: "Rechtes Kniegelenk v.d stehend",
    },
    {
      examid: 2316,
      qaAnatomyCriteria: "Patella fast mittelständig",
      qaCriteriaId: 724,
      colors: {
        Patella2316Fill: "#4e8dcb",
      },
      exam: "Rechtes Kniegelenk v.d stehend",
    },
  ],
};

export const QaApiResponse2317 = {
  data: [
    {
      examid: 2317,
      qaAnatomyCriteria: "Gut erkennbare Gelenkspalt der TEP",
      qaCriteriaId: 725,
      colors: {
        Gelenkspalt2317Fill: "#4e8dcb",
        TEP2317Fill: "#f088b6",
      },
      exam: "Linkes Kniegelenk TEP v.d liegend",
    },
    {
      examid: 2317,
      qaAnatomyCriteria: "Gute Beurteilung der TEP",
      qaCriteriaId: 726,
      colors: {
        TEP2317Fill: "#f088b6",
      },
      exam: "Linkes Kniegelenk TEP v.d liegend",
    },
  ],
};

export const QaApiResponse2318 = {
  data: [
    {
      examid: 2318,
      qaAnatomyCriteria: "Gut erkennbare Gelenkspalt der TEP",
      qaCriteriaId: 727,
      colors: {
        Gelenkspalt2318Fill: "#4e8dcb",
        TEP2318Fill: "#f088b6",
      },
      exam: "Rechtes Kniegelenk TEP v.d liegend",
    },
    {
      examid: 2318,
      qaAnatomyCriteria: "Gute Beurteilung der TEP",
      qaCriteriaId: 728,
      colors: {
        TEP2318Fill: "#f088b6",
      },
      exam: "",
    },
  ],
};

export const QaApiResponse2319 = {
  data: [
    {
      examid: 2319,
      qaAnatomyCriteria: "Femurcondylen sind in Deckung",
      qaCriteriaId: 729,
      colors: {
        UpTEP2319Fill: "#f088b6",
      },
      exam: "Linkes Kniegelenk TEP lat liegend",
    },
    {
      examid: 2319,
      qaAnatomyCriteria: "TEP vollständig dargestellt",
      qaCriteriaId: 730,
      colors: {
        UpTEP2319Fill:"#5bb033",
        TEP2319Fill: "#5bb033",  
      },
      altColors:{
        UpTEP2319Fill:"#f088b6",
        TEP2319Fill: "#5bb033",
      },

      exam: "Linkes Kniegelenk TEP lat liegend",
    },
    {
      examid: 2319,
      qaAnatomyCriteria: "Patella seitlich",
      qaCriteriaId: 731,
      colors: {
        Patella2319Fill: "#4e8dcb",
      },
      exam: "Linkes Kniegelenk TEP lat liegend",
    },
  ],
};

export const QaApiResponse2320 = {
  data: [
    {
      examid: 2320,
      qaAnatomyCriteria: "Femurcondylen sind in Deckung",
      qaCriteriaId: 732,
      colors: {UpTEP2320Fill:"#f088b6"},
      exam: "Rechtes Kniegelenk TEP lat liegend",
    },
    {
      examid: 2320,
      qaAnatomyCriteria: "TEP vollständig dargestellt",
      qaCriteriaId: 733,
      colors: {
        UpTEP2320Fill:"#5bb033",
        TEP2320Fill: "#5bb033",
      },
      altColors:{
        UpTEP2320Fill:"#f088b6",
        TEP2320Fill: "#5bb033",
      },
      exam: "Rechtes Kniegelenk TEP lat liegend",
    },
    {
      examid: 2320,
      qaAnatomyCriteria: "Patella seitlich",
      qaCriteriaId: 734,
      colors: {
        Patella2320Fill: "#4e8dcb",
      },
      exam: "Rechtes Kniegelenk TEP lat liegend",
    },
  ],
};

export const QaApiResponse2321 = {
  data: [
    {
      examid: 2321,
      qaAnatomyCriteria: "Gut einsehbarer Gelenkspalt",
      qaCriteriaId: 735,
      colors: {
        joint_gap2321Fill: "#f07f3e",
      },
      exam: "Linkes Kniegelenk v.d liegend",
    },
    {
      examid: 2321,
      qaAnatomyCriteria: "Symmetrische Darstellung der Femurcondylen",
      qaCriteriaId: 736,
      colors: {
        Femurcondyles2321Fill: "#f088b6",
      },
      exam: "",
    },
    {
      examid: 2321,
      qaAnatomyCriteria: "Patella fast mittelständig",
      qaCriteriaId: 737,
      colors: {
        patella2321Fill: "#5bb033",
      },
      exam: "",
    },
  ],
};

export const QaApiResponse2322 = {
  data: [
    {
      examid: 2322,
      qaAnatomyCriteria: "Gut einsehbarer Gelenkspalt",
      qaCriteriaId: 738,
      colors: {
        joint_gap2322Fill: "#f07f3e",
      },
      exam: "Rechtes Kniegelenk v.d liegend",
    },
    {
      examid: 2322,
      qaAnatomyCriteria: "Symmetrische Darstellung der Femurcondylen",
      qaCriteriaId: 739,
      colors: {
        Femurcondyles2322Fill: "#f088b6",
      },
      exam: "Rechtes Kniegelenk v.d liegend",
    },
    {
      examid: 2322,
      qaAnatomyCriteria: "Patella fast mittelständig",
      qaCriteriaId: 740,
      colors: {
        patella2322Fill: "#5bb033",
      },
      exam: "Rechtes Kniegelenk v.d liegend",
    },
  ],
};

export const QaApiResponse2323 = {
  data: [
    {
      examid: 2323,
      qaAnatomyCriteria: "Femurcondylen sind deckungsgleich überlagern sich",
      qaCriteriaId: 741,
      colors: {
        Femurcondylen2323Fill: "#f07f3e",
      },
      exam: "Linkes Kniegelenk lat liegend",
    },
    {
      examid: 2323,
      qaAnatomyCriteria: "Patella seitlich und frei",
      qaCriteriaId: 742,
      colors: {
        patella2323Fill: "#5bb033",
      },
      exam: "Linkes Kniegelenk lat liegend",
    },
    {
      examid: 2323,
      qaAnatomyCriteria: "Retropatellargelenk überlagerungsfrei",
      qaCriteriaId: 743,
      colors: {
        Retropatellargelenk2323Fill: "#f088b6",
      },
      exam: "Linkes Kniegelenk lat liegend",
    },
  ],
};

export const QaApiResponse2324 = {
  data: [
    {
      examid: 2324,
      qaAnatomyCriteria: "Femurcondylen sind deckungsgleich überlagern sich",
      qaCriteriaId: 744,
      colors: {
        Femurcondylen2324Fill: "#f07f3e",
      },
      exam: "Rechtes Kniegelenk lat liegend",
    },
    {
      examid: 2324,
      qaAnatomyCriteria: "Patella seitlich und frei",
      qaCriteriaId: 745,
      colors: {
        patella2324Fill: "#5bb033",
      },
      exam: "Rechtes Kniegelenk lat liegend",
    },
    {
      examid: 2324,
      qaAnatomyCriteria: "Retropatellargelenk überlagerungsfrei",
      qaCriteriaId: 746,
      colors: {
        Retropatellargelenk2324Fill: "#f088b6",
      },
      exam: "Rechtes Kniegelenk lat liegend",
    },
  ],
};

export const QaApiResponse2325 = {
  data: [
    {
      examid: 2325,
      qaAnatomyCriteria: "Kniegelenkspalt gut einsehbar",
      qaCriteriaId: 747,
      colors: {
        Kniegelenkspalt2325Fill: "#f07f3e",
      },
      exam: "Linkes Kniegelenk lat stehend",
    },
    {
      examid: 2325,
      qaAnatomyCriteria: "Retropatellar Bereich einsehbar",
      qaCriteriaId: 748,
      colors: {
        Retropatellar2325Fill: "#5bb033",
      },
      exam: "Linkes Kniegelenk lat stehend",
    },
    {
      examid: 2325,
      qaAnatomyCriteria: "Condylen-Rundungen weitgehend deckungsgleich",
      qaCriteriaId: 749,
      colors: {
        CondylenRundungen2325Fill: "#f088b6",
      },
      exam: "Linkes Kniegelenk lat stehend",
    },
  ],
};

export const QaApiResponse2326 = {
  data: [
    {
      examid: 2326,
      qaAnatomyCriteria: "Kniegelenkspalt gut einsehbar",
      qaCriteriaId: 750,
      colors: {
        Kniegelenkspalt2326Fill: "#f07f3e",
      },
      exam: "Rechtes Kniegelenk lat stehend",
    },
    {
      examid: 2326,
      qaAnatomyCriteria: "Retropatellar Bereich einsehbar",
      qaCriteriaId: 751,
      colors: {
        Retropatellar2326Fill: "#5bb033",
      },
      exam: "Rechtes Kniegelenk lat stehend",
    },
    {
      examid: 2326,
      qaAnatomyCriteria: "Condylen-Rundungen weitgehend deckungsgleich",
      qaCriteriaId: 752,
      colors: {
        CondylenRundungen2326Fill: "#f088b6",
      },
      exam: "Rechtes Kniegelenk lat stehend",
    },
  ],
};

export const QaApiResponse2327 = {
  data: [
    {
      examid: 2327,
      qaAnatomyCriteria: "Bessere Abbildung einer gelenknahen Tibiakopffraktur",
      qaCriteriaId: 753,
      colors: {
        gelenknahen2327Fill: "#f07f3e",
        Tibiakopffraktur2327Fill: "#4eae33",
      },
      exam: "Linkes Kniegelenk Innenrotation v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2328 = {
  data: [
    {
      examid: 2328,
      qaAnatomyCriteria: "Bessere Abbildung einer gelenknahen Tibiakopffraktur",
      qaCriteriaId: 754,
      colors: {
        gelenknahen2328Fill: "#f07f3e",
        Tibiakopffraktur2328Fill: "#4eae33",
      },
      exam: "Rechtes Kniegelenk Innenrotation v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2329 = {
  data: [
    {
      examid: 2329,
      qaAnatomyCriteria: "Bessere Abbildung einer gelenknahen Tibiakopffraktur",
      qaCriteriaId: 755,
      colors: {
        gelenknahen2329Fill: "#f07f3e",
        Tibiakopffraktur2329Fill: "#4eae33",
      },
      exam: "Linkes Kniegelenk Außenrotation v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2330 = {
  data: [
    {
      examid: 2330,
      qaAnatomyCriteria: "Bessere Abbildung einer gelenknahen Tibiakopffraktur",
      qaCriteriaId: 756,
      colors: {
        gelenknahen2330Fill: "#f07f3e",
        Tibiakopffraktur2330Fill: "#4eae33",
      },
      exam: "Rechtes Kniegelenk Außenrotation v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2331 = {
  data: [
    {
      examid: 2331,
      qaAnatomyCriteria: "Vergrößerte Darstellung des Gelenkspaltes",
      qaCriteriaId: 757,
      colors: {
        Gelenkspalt2331Fill: "#7E20F7",
      },
      exam: "Linkes Kniegelenk nach Frik (Tunnelaufnahme) v.d liegend",
    },
    {
      examid: 2331,
      qaAnatomyCriteria:
        "tunnelartige Darstellung Gelenkgrube (Fossa intercondylaris)",
      qaCriteriaId: 758,
      colors: {
        Gelenkgrube2331Fill: "#fe0",
      },
      exam: "Linkes Kniegelenk nach Frik (Tunnelaufnahme) v.d liegend",
    },
    {
      examid: 2331,
      qaAnatomyCriteria: "Tibiaplateau lateral strichförmig",
      qaCriteriaId: 759,
      colors: {
        Tibiaplateau2331Fill: "#4e8dcb",
      },
      exam: "Linkes Kniegelenk nach Frik (Tunnelaufnahme) v.d liegend",
    },
    {
      examid: 2331,
      qaAnatomyCriteria: "Gleichmäßig Darstellung Femurkondylen",
      qaCriteriaId: 760,
      colors: {
        Femurkondylen2331Fill: "#4eae33",
      },
      exam: "Linkes Kniegelenk nach Frik (Tunnelaufnahme) v.d liegend",
    },
    {
      examid: 2331,
      qaAnatomyCriteria: "Patella mittlig",
      qaCriteriaId: 761,
      colors: {
        Patella2331Fill: "#F72020",
      },
      exam: "Linkes Kniegelenk nach Frik (Tunnelaufnahme) v.d liegend",
    },
  ],
};

export const QaApiResponse2332 = {
  data: [
    {
      examid: 2332,
      qaAnatomyCriteria: "Vergrößerte Darstellung des Gelenkspaltes",
      qaCriteriaId: 762,
      colors: {
        Gelenkspalt2332Fill: "#7E20F7",
      },
      exam: "Rechtes Kniegelenk nach Frik (Tunnelaufnahme) v.d liegend",
    },
    {
      examid: 2332,
      qaAnatomyCriteria:
        "tunnelartige Darstellung Gelenkgrube (Fossa intercondylaris)",
      qaCriteriaId: 763,
      colors: {
        Gelenkgrube2332Fill: "#fe0",
      },
      exam: "Rechtes Kniegelenk nach Frik (Tunnelaufnahme) v.d liegend",
    },
    {
      examid: 2332,
      qaAnatomyCriteria: "Tibiaplateau lateral strichförmig",
      qaCriteriaId: 764,
      colors: {
        Tibiaplateau2332Fill: "#4e8dcb",
      },
      exam: "Rechtes Kniegelenk nach Frik (Tunnelaufnahme) v.d liegend",
    },
    {
      examid: 2332,
      qaAnatomyCriteria: "Gleichmäßig Darstellung Femurkondylen",
      qaCriteriaId: 765,
      colors: {
        Femurkondylen2332Fill: "#4eae33",
      },
      exam: "Rechtes Kniegelenk nach Frik (Tunnelaufnahme) v.d liegend",
    },
    {
      examid: 2332,
      qaAnatomyCriteria: "Patella mittlig",
      qaCriteriaId: 766,
      colors: {
        Patella2332Fill: "#F72020",
      },
      exam: "Rechtes Kniegelenk nach Frik (Tunnelaufnahme) v.d liegend",
    },
  ],
};

export const QaApiResponse2333 = {
  data: [
    {
      examid: 2333,
      qaAnatomyCriteria: "Vergrößerte Darstellung des Gelenkspaltes",
      qaCriteriaId: 767,
      colors: {
        Gelenkspalt2333Fill: "#f59d24",
      },
      exam: "Linkes Kniegelenk nach Rosenberg d.v stehend",
    },
    {
      examid: 2333,
      qaAnatomyCriteria: "Patella mittlig",
      qaCriteriaId: 768,
      colors: {
        Patella2333Fill: "#4e8dcb",
      },
      exam: "Linkes Kniegelenk nach Rosenberg d.v stehend",
    },
  ],
};

export const QaApiResponse2334 = {
  data: [
    {
      examid: 2334,
      qaAnatomyCriteria: "Vergrößerte Darstellung des Gelenkspaltes",
      qaCriteriaId: 769,
      colors: {
        Gelenkspalt2334Fill: "#f59d24",
      },
      exam: "Rechtes Kniegelenk nach Rosenberg d.v stehend",
    },
    {
      examid: 2334,
      qaAnatomyCriteria: "Patella mittlig",
      qaCriteriaId: 770,
      colors: {
        Patella2334Fill: "#4e8dcb",
      },
      exam: "Rechtes Kniegelenk nach Rosenberg d.v stehend",
    },
  ],
};

export const QaApiResponse2335 = {
  data: [
    {
      examid: 2335,
      qaAnatomyCriteria:
        "Gut beurteilbare kontrastreiche Darstellung der Patella",
      qaCriteriaId: 771,
      colors: {
        Patella2335Fill: "#4e8dcb",
      },
      exam: "Linke Kniescheibe d.v liegend",
    },
    {
      examid: 2335,
      qaAnatomyCriteria:
        "Die bildempfängernah gelegene Kniescheibe stellt sich scharf konturiert in Projektion auf die Mitte der Femurkondylen da",
      qaCriteriaId: 772,
      colors: {
        Patella2335Fill: "#4e8dcb",
        Femurkondylen2335Fill: "#f59d24",
      },
      exam: "Linke Kniescheibe d.v liegend",
    },
  ],
};

export const QaApiResponse2336 = {
  data: [
    {
      examid: 2336,
      qaAnatomyCriteria:
        "Gut beurteilbare kontrastreiche Darstellung der Patella",
      qaCriteriaId: 773,
      colors: {
        Patella2336Fill: "#4e8dcb",
      },
      exam: "Rechte Kniescheibe d.v liegend",
    },
    {
      examid: 2336,
      qaAnatomyCriteria:
        "Die bildempfängernah gelegene Kniescheibe stellt sich scharf konturiert in Projektion auf die Mitte der Femurkondylen da",
      qaCriteriaId: 774,
      colors: {
        Patella2336Fill: "#4e8dcb",
        Femurkondylen2336Fill: "#f59d24",
      },
      exam: "Rechte Kniescheibe d.v liegend",
    },
  ],
};

export const QaApiResponse2337 = {
  data: [
    {
      examid: 2337,
      qaAnatomyCriteria: "Axiale & freie Darstellung der Patella",
      qaCriteriaId: 775,
      colors: {
        Patella2337Fill: "#4e8dcb",
      },
      exam: "Linke Kniescheibe ax liegend",
    },
    {
      examid: 2337,
      qaAnatomyCriteria:
        "Retropatellargelenk frei dargestellt (Femoropatellargelenk)",
      qaCriteriaId: 776,
      colors: {
        Femoropatellargelenk2337Fill: "#f59d24",
      },
      exam: "Linke Kniescheibe ax liegend",
    },
  ],
};

export const QaApiResponse2338 = {
  data: [
    {
      examid: 2338,
      qaAnatomyCriteria: "Axiale & freie Darstellung der Patella",
      qaCriteriaId: 777,
      colors: {
        Patella2338Fill: "#4e8dcb",
      },
      exam: "Rechte Kniescheibe ax liegend",
    },
    {
      examid: 2338,
      qaAnatomyCriteria:
        "Retropatellargelenk frei dargestellt (Femoropatellargelenk)",
      qaCriteriaId: 778,
      colors: {
        Femoropatellargelenk2338Fill: "#f59d24",
      },
      exam: "Rechte Kniescheibe ax liegend",
    },
  ],
};

export const QaApiResponse2339 = {
  data: [
    {
      examid: 2339,
      qaAnatomyCriteria: "Axiale & freie Darstellung der Patella",
      qaCriteriaId: 779,
      colors: {
        Patella2339Fill: "#4e8dcb",
      },
      exam: "Linke Patella De`file` 30° ax sitzend",
    },
    {
      examid: 2339,
      qaAnatomyCriteria:
        "Retropatellargelenk frei dargestellt (Femoropatellargelenk)",
      qaCriteriaId: 780,
      colors: {
        Femoropatellargelenk2339Fill: "#f59d24",
      },
      exam: "Linke Patella De`file` 30° ax sitzend",
    },
  ],
};

export const QaApiResponse2340 = {
  data: [
    {
      examid: 2340,
      qaAnatomyCriteria: "Axiale & freie Darstellung der Patella",
      qaCriteriaId: 781,
      colors: {
        Patella2340Fill: "#4e8dcb",
      },
      exam: "Rechte Patella De`file` 30° ax sitzend",
    },
    {
      examid: 2340,
      qaAnatomyCriteria:
        "Retropatellargelenk frei dargestellt (Femoropatellargelenk)",
      qaCriteriaId: 782,
      colors: {
        Femoropatellargelenk2340Fill: "#f59d24",
      },
      exam: "Rechte Patella De`file` 30° ax sitzend",
    },
  ],
};

export const QaApiResponse2341 = {
  data: [
    {
      examid: 2341,
      qaAnatomyCriteria:
        "Hüft,- Knie und Sprunggelenk sind symmetrisch abgebildet",
      qaCriteriaId: 783,
      colors: {
        Hipgelenk2341Fill: "#F72020",
        Kniegelenk2341Fill: "#008000",
        OSG2341Fill: "#7E20F7",
      },
      exam: "Linke Ganzbeinstandaufnahme v.d stehend",
    },
    {
      examid: 2341,
      qaAnatomyCriteria:
        "Meist kommt es zu einer Unterbelichtung der Hüfte und Überbelichtung des OSG, möglichst Maßband mit aufnehmen",
      qaCriteriaId: 784,
      colors: {
        Hip2341Fill: "#4e8dcb",
        OSG2341Fill: "#7E20F7",
      },
      exam: "Linke Ganzbeinstandaufnahme v.d stehend",
    },
  ],
};

export const QaApiResponse2342 = {
  data: [
    {
      examid: 2342,
      qaAnatomyCriteria:
        "Hüft,- Knie und Sprunggelenk sind symmetrisch abgebildet",
      qaCriteriaId: 786,
      colors: {
        Hipgelenk2342Fill: "#F72020",
        Kniegelenk2342Fill: "#008000",
        OSG2342Fill: "#7E20F7",
      },
      exam: "Rechte Ganzbeinstandaufnahme v.d stehend",
    },
    {
      examid: 2342,
      qaAnatomyCriteria:
        "Meist kommt es zu einer Unterbelichtung der Hüfte und Überbelichtung des OSG, möglichst Maßband mit aufnehmen",
      qaCriteriaId: 787,
      colors: {
        Hip2342Fill: "#4e8dcb",
        OSG2342Fill: "#7E20F7",
      },
      exam: "Rechte Ganzbeinstandaufnahme v.d stehend",
    },
  ],
};

export const QaApiResponse2343 = {
  data: [
    {
      examid: 2343,
      qaAnatomyCriteria: "Gelenkspalt gut einsehbar",
      qaCriteriaId: 789,
      colors: {
        Gelenkspalt2343Fill: "#008000",
      },
      exam: "Linker Oberschenkel mit Knie v.d liegend",
    },
    {
      examid: 2343,
      qaAnatomyCriteria: "Patella fast mittig Zwischen den Femurkondylen",
      qaCriteriaId: 790,
      colors: {
        Patella2343Fill: "#2020F7",
        Femurkondylen2343Fill: "#eb5b9a",
      },
      exam: "Linker Oberschenkel mit Knie v.d liegend",
    },
    {
      examid: 2343,
      qaAnatomyCriteria:
        "Oberschenkelschaft sowie das nahezu orthograd getroffene Kniegelenk sind gut beurteilbar",
      qaCriteriaId: 791,
      colors: {
        Oberschenkelschaft2343Fill: "#fe0",
        Kniegelenk2343Fill: "#6ec2b5",
      },
      exam: "Linker Oberschenkel mit Knie v.d liegend",
    },
    {
      examid: 2343,
      qaAnatomyCriteria: "Weichteilmantel erkennbar",
      qaCriteriaId: 792,
      colors: {
        Weichteilmantel2343Fill: "#7E20F7",
      },
      exam: "Linker Oberschenkel mit Knie v.d liegend",
    },
  ],
};

export const QaApiResponse2344 = {
  data: [
    {
      examid: 2344,
      qaAnatomyCriteria: "Gelenkspalt gut einsehbar",
      qaCriteriaId: 793,
      colors: {
        Gelenkspalt2344Fill: "#008000",
      },
      exam: "Rechter Oberschenkel mit Knie v.d liegend",
    },
    {
      examid: 2344,
      qaAnatomyCriteria: "Patella fast mittig Zwischen den Femurkondylen",
      qaCriteriaId: 794,
      colors: {
        Patella2344Fill: "#2020F7",
        Femurkondylen2344Fill: "#eb5b9a",
      },
      exam: "Rechter Oberschenkel mit Knie v.d liegend",
    },
    {
      examid: 2344,
      qaAnatomyCriteria:
        "Oberschenkelschaft sowie das nahezu orthograd getroffene Kniegelenk sind gut beurteilbar",
      qaCriteriaId: 795,
      colors: {
        Oberschenkelschaft2344Fill: "#fe0",
        Kniegelenk2344Fill: "#6ec2b5",
      },
      exam: "Rechter Oberschenkel mit Knie v.d liegend",
    },
    {
      examid: 2344,
      qaAnatomyCriteria: "Weichteilmantel erkennbar",
      qaCriteriaId: 796,
      colors: {
        Weichteilmantel2344Fill: "#7E20F7",
      },
      exam: "Rechter Oberschenkel mit Knie v.d liegend",
    },
  ],
};

export const QaApiResponse2345 = {
  data: [
    {
      examid: 2345,
      qaAnatomyCriteria:
        "Oberschenkelschaft und Kniegelenk sind gut beurteilbar",
      qaCriteriaId: 797,
      colors: {
        Oberschenkelschaft2345Fill: "#6ec2b5",
        Kniegelenk2345Fill: "#f59d24",
      },
      exam: "Linker Oberschenkel mit Knie lat liegend",
    },
    {
      examid: 2345,
      qaAnatomyCriteria: "Patella seitlich",
      qaCriteriaId: 798,
      colors: {
        Patella2345Fill: "#ff0000",
      },
      exam: "Linker Oberschenkel mit Knie lat liegend",
    },
    {
      examid: 2345,
      qaAnatomyCriteria: "Weichteilmantel erkennbar",
      qaCriteriaId: 799,
      colors: {
        Weichteilmantel2345Fill: "#7E20F7",
      },
      exam: "Linker Oberschenkel mit Knie lat liegend",
    },
  ],
};

export const QaApiResponse2346 = {
  data: [
    {
      examid: 2346,
      qaAnatomyCriteria:
        "Oberschenkelschaft und Kniegelenk sind gut beurteilbar",
      qaCriteriaId: 800,
      colors: {
        Oberschenkelschaft2346Fill: "#6ec2b5",
        Kniegelenk2346Fill: "#f59d24",
      },
      exam: "Rechter Oberschenkel mit Knie lat liegend",
    },
    {
      examid: 2346,
      qaAnatomyCriteria: "Patella seitlich",
      qaCriteriaId: 801,
      colors: {
        Patella2346Fill: "#ff0000",
      },
      exam: "Rechter Oberschenkel mit Knie lat liegend",
    },
    {
      examid: 2346,
      qaAnatomyCriteria: "Weichteilmantel erkennbar",
      qaCriteriaId: 802,
      colors: {
        Weichteilmantel2346Fill: "#7E20F7",
      },
      exam: "Rechter Oberschenkel mit Knie lat liegend",
    },
  ],
};

export const QaApiResponse2347 = {
  data: [
    {
      examid: 2347,
      qaAnatomyCriteria: "Gut belichtete Aufnahme Oberschenkel / Hüfte",
      qaCriteriaId: 803,
      colors: {
        Oberschenkel2347Fill: "#ADD8E6",
        Hip2347Fill: "#fe0",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d liegend",
    },
    {
      examid: 2347,
      qaAnatomyCriteria: "Oberschenkel wird exakt vd abgebildet",
      qaCriteriaId: 804,
      colors: {
        Oberschenkel2347Fill: "#ADD8E6",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d liegend",
    },
    {
      examid: 2347,
      qaAnatomyCriteria: "Trochanter major lateral randständig",
      qaCriteriaId: 805,
      colors: {
        Trochanter_major2347Fill: "#ff0000",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d liegend",
    },
    {
      examid: 2347,
      qaAnatomyCriteria: "Trochanter minor medial ständig",
      qaCriteriaId: 806,
      colors: {
        Trochanter_minor2347Fill: "#f39600",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d liegend",
    },
    {
      examid: 2347,
      qaAnatomyCriteria: "Hüftgelenk vollständig abgebildet",
      qaCriteriaId: 807,
      colors: {
        Hipjoint2347Fill: "#4eae33",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d liegend",
    },
    {
      examid: 2347,
      qaAnatomyCriteria: "Schenkelhals unverkürzt dargestellt",
      qaCriteriaId: 808,
      colors: {
        Schenkelhals2347Fill: "#2020F7",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d liegend",
    },
    {
      examid: 2347,
      qaAnatomyCriteria: "Weichteilmantel erkennbar",
      qaCriteriaId: 809,
      colors: {
        Weichteilmantel2347Fill: "#7E20F7",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d liegend",
    },
  ],
};

export const QaApiResponse2348 = {
  data: [
    {
      examid: 2348,
      qaAnatomyCriteria: "Gut belichtete Aufnahme Oberschenkel / Hüfte",
      qaCriteriaId: 810,
      colors: {
        Oberschenkel2348Fill: "#ADD8E6",
        Hip2348Fill: "#fe0",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d liegend",
    },
    {
      examid: 2348,
      qaAnatomyCriteria: "Oberschenkel wird exakt vd abgebildet",
      qaCriteriaId: 811,
      colors: {
        Oberschenkel2348Fill: "#ADD8E6",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d liegend",
    },
    {
      examid: 2348,
      qaAnatomyCriteria: "Trochanter major lateral randständig",
      qaCriteriaId: 812,
      colors: {
        Trochanter_major2348Fill: "#ff0000",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d liegend",
    },
    {
      examid: 2348,
      qaAnatomyCriteria: "Trochanter minor medial ständig",
      qaCriteriaId: 813,
      colors: {
        Trochanter_minor2348Fill: "#f07f3e",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d liegend",
    },
    {
      examid: 2348,
      qaAnatomyCriteria: "Hüftgelenk vollständig abgebildet",
      qaCriteriaId: 814,
      colors: {
        Hipjoint2348Fill: "#4eae33",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d liegend",
    },
    {
      examid: 2348,
      qaAnatomyCriteria: "Schenkelhals unverkürzt dargestellt",
      qaCriteriaId: 815,
      colors: {
        Schenkelhals2348Fill: "#2020F7",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d liegend",
    },
    {
      examid: 2348,
      qaAnatomyCriteria: "Weichteilmantel erkennbar",
      qaCriteriaId: 816,
      colors: {
        Weichteilmantel2348Fill: "#7E20F7",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d liegend",
    },
  ],
};

export const QaApiResponse2349 = {
  data: [
    {
      examid: 2349,
      qaAnatomyCriteria: "Gut belichtete Aufnahme Oberschenkel / Hüfte",
      qaCriteriaId: 817,
      colors: {
        Oberschenkel2349Fill: "#ADD8E6",
        Hip2349Fill: "#fe0",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d stehend",
    },
    {
      examid: 2349,
      qaAnatomyCriteria: "Oberschenkel wird exakt vd abgebildet",
      qaCriteriaId: 818,
      colors: {
        Oberschenkel2349Fill: "#ADD8E6",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d stehend",
    },
    {
      examid: 2349,
      qaAnatomyCriteria: "Trochanter major lateral randständig",
      qaCriteriaId: 819,
      colors: {
        Trochanter_major2349Fill: "#ff0000",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d stehend",
    },
    {
      examid: 2349,
      qaAnatomyCriteria: "Trochanter minor medial ständig",
      qaCriteriaId: 820,
      colors: {
        Trochanter_minor2349Fill: "#f07f3e",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d stehend",
    },
    {
      examid: 2349,
      qaAnatomyCriteria: "Hüftgelenk vollständig abgebildet",
      qaCriteriaId: 821,
      colors: {
        Hipjoint2349Fill: "#4eae33",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d stehend",
    },
    {
      examid: 2349,
      qaAnatomyCriteria: "Schenkelhals unverkürzt dargestellt",
      qaCriteriaId: 822,
      colors: {
        Schenkelhals2349Fill: "#2020F7",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d stehend",
    },
    {
      examid: 2349,
      qaAnatomyCriteria: "Weichteilmantel erkennbar",
      qaCriteriaId: 823,
      colors: {
        Weichteilmantel2349Fill: "#7E20F7",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d stehend",
    },
  ],
};

export const QaApiResponse2350 = {
  data: [
    {
      examid: 2350,
      qaAnatomyCriteria: "Gut belichtete Aufnahme Oberschenkel / Hüfte",
      qaCriteriaId: 824,
      colors: {
        Oberschenkel2350Fill: "#ADD8E6",
        Hip2350Fill: "#fe0",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d stehend",
    },
    {
      examid: 2350,
      qaAnatomyCriteria: "Oberschenkel wird exakt vd abgebildet",
      qaCriteriaId: 825,
      colors: {
        Oberschenkel2350Fill: "#ADD8E6",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d stehend",
    },
    {
      examid: 2350,
      qaAnatomyCriteria: "Trochanter major lateral randständig",
      qaCriteriaId: 826,
      colors: {
        Trochanter_major2350Fill: "#ff0000",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d stehend",
    },
    {
      examid: 2350,
      qaAnatomyCriteria: "Trochanter minor medial ständig",
      qaCriteriaId: 827,
      colors: {
        Trochanter_minor2350Fill: "#f07f3e",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d stehend",
    },
    {
      examid: 2350,
      qaAnatomyCriteria: "Hüftgelenk vollständig abgebildet",
      qaCriteriaId: 828,
      colors: {
        Hipjoint2350Fill: "#4eae33",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d stehend",
    },
    {
      examid: 2350,
      qaAnatomyCriteria: "Schenkelhals unverkürzt dargestellt",
      qaCriteriaId: 829,
      colors: {
        Schenkelhals2350Fill: "#2020F7",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d stehend",
    },
    {
      examid: 2350,
      qaAnatomyCriteria: "Weichteilmantel erkennbar",
      qaCriteriaId: 830,
      colors: {
        Weichteilmantel2350Fill: "#7E20F7",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d stehend",
    },
  ],
};

export const QaApiResponse2351 = {
  data: [
    {
      examid: 2351,
      qaAnatomyCriteria:
        "Gute Beurteilung des Hüftkopfes, der Pfanne und des Schenkelhalses",
      qaCriteriaId: 831,
      colors: {
        Femoralhead2351Fill: "#fe0",
        Pfanne2351Fill: "#2020F7",
        Schenkelhals2351Fill: "#7E20F7",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d.obl liegend",
    },
    {
      examid: 2351,
      qaAnatomyCriteria:
        "Trochanter major kaum zu beurteilen, dafür aber der der minor gut zu erkennen",
      qaCriteriaId: 832,
      colors: {
        TrochanterMajor2351Fill: "#1fe536",
        TrochanterMinor2351Fill: "#F72020",
      },
      exam: "Linker Oberschenkel mit Hüfte v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2352 = {
  data: [
    {
      examid: 2352,
      qaAnatomyCriteria:
        "Gute Beurteilung des Hüftkopfes, der Pfanne und des Schenkelhalses",
      qaCriteriaId: 833,
      colors: {
        Femoralhead2352Fill: "#fe0",
        Pfanne2352Fill: "#2020F7",
        Schenkelhals2352Fill: "#7E20F7",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d.obl liegend",
    },
    {
      examid: 2352,
      qaAnatomyCriteria:
        "Trochanter major kaum zu beurteilen, dafür aber der der minor gut zu erkennen",
      qaCriteriaId: 834,
      colors: {
        TrochanterMajor2352Fill: "#1fe536",
        TrochanterMinor2352Fill: "#F72020",
      },
      exam: "Rechter Oberschenkel mit Hüfte v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2353 = {
  data: [
    {
      examid: 2353,
      qaAnatomyCriteria: "Kleiner Rollhügel (Trochanter minor) gut erkennbar",
      qaCriteriaId: 835,
      colors: {
        Trochanter_minor2353Fill: "#79d1ec",
        patella2321Opacity: 0.5,
      },
      exam: "Linker Oberschenkel mit Hüfte lat liegend",
    },
    {
      examid: 2353,
      qaAnatomyCriteria: "Oberschenkel wird exakt lateral abgebildet",
      qaCriteriaId: 836,
      colors: {
        Oberschenkel2353Fill: "#d2de26",
      },
      exam: "Linker Oberschenkel mit Hüfte lat liegend",
    },
    {
      examid: 2353,
      qaAnatomyCriteria: "Hüftgelenk gut einsehbar",
      qaCriteriaId: 837,
      colors: {
        Hipjoint2353Fill: "#1fe536",
      },
      exam: "Linker Oberschenkel mit Hüfte lat liegend",
    },
    {
      examid: 2353,
      qaAnatomyCriteria: "Weichteile beurteilbar",
      qaCriteriaId: 838,
      colors: {
        Weichteile2353Fill: "#594056",
      },
      exam: "Linker Oberschenkel mit Hüfte lat liegend",
    },
    {
      examid: 2353,
      qaAnatomyCriteria:
        "Schenkelhals stark verkürzt und durch den Trochanter major verdeckt",
      qaCriteriaId: 839,
      colors: {
        Schenkelhals2353Fill: "#7E20F7",
        TrochanterMajor2353Fill: "#F72020",
      },
      exam: "Linker Oberschenkel mit Hüfte lat liegend",
    },
  ],
};

export const QaApiResponse2354 = {
  data: [
    {
      examid: 2354,
      qaAnatomyCriteria: "Kleiner Rollhügel (Trochanter minor) gut erkennbar",
      qaCriteriaId: 840,
      colors: {
        Trochanter_minor2354Fill: "#79d1ec",
      },
      exam: "Rechter Oberschenkel mit Hüfte lat liegend",
    },
    {
      examid: 2354,
      qaAnatomyCriteria: "Oberschenkel wird exakt lateral abgebildet",
      qaCriteriaId: 841,
      colors: {
        Oberschenkel2354Fill: "#d2de26",
      },
      exam: "Rechter Oberschenkel mit Hüfte lat liegend",
    },
    {
      examid: 2354,
      qaAnatomyCriteria: "Hüftgelenk gut einsehbar",
      qaCriteriaId: 842,
      colors: {
        Hipjoint2354Fill: "#1fe536",
      },
      exam: "Rechter Oberschenkel mit Hüfte lat liegend",
    },
    {
      examid: 2354,
      qaAnatomyCriteria: "Weichteile beurteilbar",
      qaCriteriaId: 843,
      colors: {
        Weichteile2354Fill: "#594056",
      },
      exam: "Rechter Oberschenkel mit Hüfte lat liegend",
    },
    {
      examid: 2354,
      qaAnatomyCriteria:
        "Schenkelhals stark verkürzt und durch den Trochanter major verdeckt",
      qaCriteriaId: 844,
      colors: {
        Schenkelhals2354Fill: "#7E20F7",
        TrochanterMajor2354Fill: "#F72020",
        
      },
      exam: "Rechter Oberschenkel mit Hüfte lat liegend",
    },
  ],
};

export const QaApiResponse2355 = {
  data: [
    {
      examid: 2355,
      qaAnatomyCriteria: "Plan parallele Darstellung der Tibia Gelenkflächen",
      qaCriteriaId: 845,
      colors: {
        Tibiaplateau2355Fill: "#e60f7c",
      },
      exam: "Linkes Kniegelenk Stressaufnahme v.d liegend",
    },
    {
      examid: 2355,
      qaAnatomyCriteria: "Gut einsehbarer Gelenkspalt",
      qaCriteriaId: 846,
      colors: {
        Gelenkspalt2355Fill: "#fe0",
      },
      exam: "Linkes Kniegelenk Stressaufnahme v.d liegend",
    },
    {
      examid: 2355,
      qaAnatomyCriteria:
        "Beurteilt wird die Aufklappbarkeit des medialen Gelenkspaltes unter Druck",
      qaCriteriaId: 847,
      colors: {
        medialenGelenkspalt2355Fill: "#037433",
      },
      exam: "Linkes Kniegelenk Stressaufnahme v.d liegend",
    },
  ],
};

export const QaApiResponse2356 = {
  data: [
    {
      examid: 2356,
      qaAnatomyCriteria: "Plan parallele Darstellung der Tibia Gelenkflächen",
      qaCriteriaId: 848,
      colors: {
        Tibiaplateau2356Fill: "#e60f7c",
      },
      exam: "Rechtes Kniegelenk Stressaufnahme v.d liegend",
    },
    {
      examid: 2356,
      qaAnatomyCriteria: "Gut einsehbarer Gelenkspalt",
      qaCriteriaId: 849,
      colors: {
        Gelenkspalt2356Fill: "#fe0",
      },
      exam: "Rechtes Kniegelenk Stressaufnahme v.d liegend",
    },
    {
      examid: 2356,
      qaAnatomyCriteria:
        "Beurteilt wird die Aufklappbarkeit des medialen Gelenkspaltes unter Druck",
      qaCriteriaId: 850,
      colors: {
        medialenGelenkspalt2356Fill: "#037433",
      },
      exam: "Rechtes Kniegelenk Stressaufnahme v.d liegend",
    },
  ],
};

export const QaApiResponse2357 = {
  data: [
    {
      examid: 2357,
      qaAnatomyCriteria: "Exakt seitliche Darstellung des Kniegelenkes",
      qaCriteriaId: 851,
      colors: {
        Kniegelenk2357Fill: "#f59d24",
      },
      exam: "Linkes Kniegelenk Stressaufnahme lat liegend",
    },
    {
      examid: 2357,
      qaAnatomyCriteria:
        "Gut einsehbares Femoropatellargelenk (Retropatellargelenk)",
      qaCriteriaId: 852,
      colors: {
        Retropatellargelenk2357Fill: "#037433",
      },
      exam: "Linkes Kniegelenk Stressaufnahme lat liegend",
    },
  ],
};

export const QaApiResponse2358 = {
  data: [
    {
      examid: 2358,
      qaAnatomyCriteria: "Exakt seitliche Darstellung des Kniegelenkes",
      qaCriteriaId: 853,
      colors: {
        Kniegelenk2358Fill: "#f59d24",
      },
      exam: "Rechtes Kniegelenk Stressaufnahme lat liegend",
    },
    {
      examid: 2358,
      qaAnatomyCriteria:
        "Gut einsehbares Femoropatellargelenk (Retropatellargelenk)",
      qaCriteriaId: 854,
      colors: {
        Retropatellargelenk2358Fill: "#037433",
      },
      exam: "Rechtes Kniegelenk Stressaufnahme lat liegend",
    },
  ],
};

export const QaApiResponse2359 = {
  data: [
    {
      examid: 2359,
      qaAnatomyCriteria: "Axiale & freie Darstellung der Patella",
      qaCriteriaId: 855,
      colors: {
        Patella2359Fill: "#4e8dcb",
      },
      exam: "Linke Patella De`file` 60° ax sitzend",
    },
    {
      examid: 2359,
      qaAnatomyCriteria:
        "Retropatellargelenk frei dargestellt (Femoropatellargelenk)",
      qaCriteriaId: 856,
      colors: {
        Femoropatellargelenk2359Fill: "#f59d24",
      },
      exam: "Linke Patella De`file` 60° ax sitzend",
    },
  ],
};

export const QaApiResponse2360 = {
  data: [
    {
      examid: 2360,
      qaAnatomyCriteria: "Axiale & freie Darstellung der Patella",
      qaCriteriaId: 857,
      colors: {
        Patella2360Fill: "#4e8dcb",
      },
      exam: "Rechte Patella De`file` 60° ax sitzend",
    },
    {
      examid: 2360,
      qaAnatomyCriteria:
        "Retropatellargelenk frei dargestellt (Femoropatellargelenk)",
      qaCriteriaId: 858,
      colors: {
        Femoropatellargelenk2360Fill: "#f59d24",
      },
      exam: "Rechte Patella De`file` 60° ax sitzend",
    },
  ],
};

export const QaApiResponse2361 = {
  data: [
    {
      examid: 2361,
      qaAnatomyCriteria: "Axiale & freie Darstellung der Patella",
      qaCriteriaId: 859,
      colors: {
        Patella2361Fill: "#4e8dcb",
      },
      exam: "Linke Patella De`file` 90° ax sitzend",
    },
    {
      examid: 2361,
      qaAnatomyCriteria:
        "Retropatellargelenk frei dargestellt (Femoropatellargelenk)",
      qaCriteriaId: 860,
      colors: {
        Femoropatellargelenk2361Fill: "#f59d24",
      },
      exam: "Linke Patella De`file` 90° ax sitzend",
    },
  ],
};

export const QaApiResponse2362 = {
  data: [
    {
      examid: 2362,
      qaAnatomyCriteria: "Axiale & freie Darstellung der Patella",
      qaCriteriaId: 861,
      colors: {
        Patella2362Fill: "#4e8dcb",
      },
      exam: "Rechte Patella De`file` 90° ax sitzend",
    },
    {
      examid: 2362,
      qaAnatomyCriteria:
        "Retropatellargelenk frei dargestellt (Femoropatellargelenk)",
      qaCriteriaId: 862,
      colors: {
        Femoropatellargelenk2362Fill: "#f59d24",
      },
      exam: "Rechte Patella De`file` 90° ax sitzend",
    },
  ],
};

export const QaApiResponse2363 = {
  data: [
    {
      examid: 2363,
      qaAnatomyCriteria: "Gut einsehbarer Gelenkspalt",
      qaCriteriaId: 863,
      colors: {
        Gelenkspalt2363Fill: "#f7a600",
      },
      exam: "Beide Knie v.d stehend",
    },
    {
      examid: 2363,
      qaAnatomyCriteria: "Symmetrische Darstellung der Femurcondylen",
      qaCriteriaId: 864,
      colors: {
        Femurcondylen2363Fill: "#7E20F7",
      },
      exam: "Beide Knie v.d stehend",
    },
    {
      examid: 2363,
      qaAnatomyCriteria: "Patella fast mittelständig",
      qaCriteriaId: 865,
      colors: {
        Patella2363Fill: "#6BF720",
      },
      exam: "Beide Knie v.d stehend",
    },
  ],
};

export const QaApiResponse2401 = {
  data: [
    {
      examid: 2401,
      qaAnatomyCriteria: "Vollständige Abbildung des Hüftgelenkes in Bildmitte",
      qaCriteriaId: 866,
      colors: {
        hipjoint2401Fill: "#F72020",
      },
      exam: "Linke Hüfte v.d liegend",
    },
    {
      examid: 2401,
      qaAnatomyCriteria: "Oberschenkelhals ist nicht verkürzt ",
      qaCriteriaId: 867,
      colors: {
        femoralneck2401Fill: "#F7ED20",
      },
      exam: "Linke Hüfte v.d liegend",
    },
    {
      examid: 2401,
      qaAnatomyCriteria:
        "Trochanter major ist lateral am Oberschenkelhals zu sehen",
      qaCriteriaId: 868,
      colors: {
        Trochantermajor2401Fill: "#6BF720",
      },
      exam: "Linke Hüfte v.d liegend",
    },
    {
      examid: 2401,
      qaAnatomyCriteria:
        "Trochanter minor ist an der Innenseite des Oberschenkelhalses nur knapp zu sehen",
      qaCriteriaId: 869,
      colors: {
        Trochanterminor2401Fill: "#2020F7",
      },
      exam: "Linke Hüfte v.d liegend",
    },
  ],
};

export const QaApiResponse2402 = {
  data: [
    {
      examid: 2402,
      qaAnatomyCriteria: "Vollständige Abbildung des Hüftgelenkes in Bildmitte",
      qaCriteriaId: 870,
      colors: {
        hipjoint2402Fill: "#F72020",
      },
      exam: "Rechte Hüfte v.d liegend",
    },
    {
      examid: 2402,
      qaAnatomyCriteria: "Oberschenkelhals ist nicht verkürzt ",
      qaCriteriaId: 871,
      colors: {
        femoralneck2402Fill: "#F7ED20",
      },
      exam: "Rechte Hüfte v.d liegend",
    },
    {
      examid: 2402,
      qaAnatomyCriteria:
        "Trochanter major ist lateral am Oberschenkelhals zu sehen",
      qaCriteriaId: 872,
      colors: {
        Trochantermajor2402Fill: "#6BF720",
      },
      exam: "Rechte Hüfte v.d liegend",
    },
    {
      examid: 2402,
      qaAnatomyCriteria:
        "Trochanter minor ist an der Innenseite des Oberschenkelhalses nur knapp zu sehen",
      qaCriteriaId: 873,
      colors: {
        Trochanterminor2402Fill: "#2020F7",
      },
      exam: "Rechte Hüfte v.d liegend",
    },
  ],
};

export const QaApiResponse2403 = {
  data: [
    {
      examid: 2403,
      qaAnatomyCriteria: "Die gesamte TEP ist gut beurteilbar",
      qaCriteriaId: 874,
      colors: {
        TEP2403Fill: "#F7ED20",
      },
      exam: "Hüfte Übersicht TEP v.d liegend",
    },
    {
      examid: 2403,
      qaAnatomyCriteria: "Beide Trochanter gut dargestellt",
      qaCriteriaId: 875,
      colors: {
        Trochanter2403Fill: "#2020F7",
      },
      exam: "Hüfte Übersicht TEP v.d liegend",
    },
  ],
};

export const QaApiResponse2404 = {
  data: [
    {
      examid: 2404,
      qaAnatomyCriteria: "Komplette Darstellung des Hüftgelenkes",
      qaCriteriaId: 876,
      colors: {
        hipjoint2404Fill: "#F72020",
      },
      exam: "Linke Hüfte v.d.obl liegend",
    },
    {
      examid: 2404,
      qaAnatomyCriteria:
        "Axiale Darstellung des Hüftkopfes und Schenkelhals, Trochanteren überlagern sich",
      qaCriteriaId: 877,
      colors: {
        Femoralhead2404Fill: "#6BF720",
        Schenkelhals2404Fill: "#0000FF",
        TrochanterMajor2404Fill: "#f59d24",
        TrochanterMinor2404Fill: "#f59d24", 
      },
      altColors:{
        Femoralhead2404Fill: "#6BF720",
        Schenkelhals2404Fill: "#0000FF",
        TrochanterMajor2404Fill: "#FF00FF",
        TrochanterMinor2404Fill: "#4eacf2",
      },
      exam: "Linke Hüfte v.d.obl liegend",
    },
    {
      examid: 2404,
      qaAnatomyCriteria:
        "Oberschenkelhals und Oberschenkel verlaufen in einer Geraden",
      qaCriteriaId: 878,
      colors: {
        Schenkelhals2404Fill: "#0000FF",
        Oberschenkel2404Fill: "#fe0",
      },
      exam: "Linke Hüfte v.d.obl liegend",
    },
    {
      examid: 2404,
      qaAnatomyCriteria:
        "Trochanter major ist teilweise hinter den Oberschenkelhals projiziert",
      qaCriteriaId: 879,
      colors: {
        TrochanterMajor2404Fill: "#FF00FF",
        Schenkelhals2404Fill: "#0000FF",
      },
      exam: "Linke Hüfte v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2405 = {
  data: [
    {
      examid: 2405,
      qaAnatomyCriteria: "Komplette Darstellung des Hüftgelenkes",
      qaCriteriaId: 880,
      colors: {
        hipjoint2405Fill: "#F72020",
      },
      exam: "Linke Hüfte v.d.obl liegend",
    },
    {
      examid: 2405,
      qaAnatomyCriteria:
        "Axiale Darstellung des Hüftkopfes und Schenkelhals, Trochanteren überlagern sich",
      qaCriteriaId: 881,
      colors: {
        Femoralhead2405Fill: "#6BF720",
        Schenkelhals2405Fill: "#0000FF",
        TrochanterMajor2405Fill: "#f59d24",
        TrochanterMinor2405Fill: "#f59d24", 
      },
      altColors:{
        Femoralhead2405Fill: "#6BF720",
        Schenkelhals2405Fill: "#0000FF",
        TrochanterMajor2405Fill: "#FF00FF",
        TrochanterMinor2405Fill: "#4eacf2",
      },
      exam: "Linke Hüfte v.d.obl liegend",
    },
    {
      examid: 2405,
      qaAnatomyCriteria:
        "Oberschenkelhals und Oberschenkel verlaufen in einer Geraden",
      qaCriteriaId: 882,
      colors: {
        Schenkelhals2405Fill: "#0000FF",
        Oberschenkel2405Fill: "#fe0",
      },
      exam: "Linke Hüfte v.d.obl liegend",
    },
    {
      examid: 2405,
      qaAnatomyCriteria:
        "Trochanter major ist teilweise hinter den Oberschenkelhals projiziert",
      qaCriteriaId: 883,
      colors: {
        TrochanterMajor2405Fill: "#FF00FF",
        Schenkelhals2405Fill: "#0000FF",
      },
      exam: "Linke Hüfte v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2406 = {
  data: [
    {
      examid: 2406,
      qaAnatomyCriteria: "Verkürzte Darstellung der Beckenschaufel",
      qaCriteriaId: 884,
      colors: {
        Beckenschaufel2406Fill: "#5cbd76",
      },
      exam: "Linkes Hüftloch v.d.obl liegend",
    },
    {
      examid: 2406,
      qaAnatomyCriteria: "Querovale Darstellung der Foramen obturatum",
      qaCriteriaId: 885,
      colors: {
        Foramen_obturatum2406Fill: "#F72020",
      },
      exam: "Linkes Hüftloch v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2407 = {
  data: [
    {
      examid: 2407,
      qaAnatomyCriteria: "Verkürzte Darstellung der Beckenschaufel",
      qaCriteriaId: 886,
      colors: {
        Beckenschaufel2407Fill: "#5cbd76",
      },
      exam: "Rechtes Hüftloch v.d.obl liegend",
    },
    {
      examid: 2407,
      qaAnatomyCriteria: "Querovale Darstellung der Foramen obturatum",
      qaCriteriaId: 887,
      colors: {
        Foramen_obturatum2407Fill: "#F72020",
      },
      exam: "Rechtes Hüftloch v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2408 = {
  data: [
    {
      examid: 2408,
      qaAnatomyCriteria: "Gute Darstellung des Hüftgelenkes",
      qaCriteriaId: 888,
      colors: {
        Hipjoint2408Fill: "#5cbd76",
      },
      exam: "Linke Hüfte Abduktion v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2409 = {
  data: [
    {
      examid: 2409,
      qaAnatomyCriteria: "Gute Darstellung des Hüftgelenkes",
      qaCriteriaId: 889,
      colors: {
        Hipjoint2409Fill: "#5cbd76",
      },
      exam: "Rechte Hüfte Abduktion v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2410 = {
  data: [
    {
      examid: 2410,
      qaAnatomyCriteria: "Gute Darstellung des Hüftgelenkes",
      qaCriteriaId: 890,
      colors: {
        Hipjoint2410Fill: "#5cbd76",
      },
      exam: "Linke Hüfte Adduktion v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2411 = {
  data: [
    {
      examid: 2411,
      qaAnatomyCriteria: "Gute Darstellung des Hüftgelenkes",
      qaCriteriaId: 891,
      colors: {
        Hipjoint2411Fill: "#5cbd76",
      },
      exam: "Rechte Hüfte Adduktion v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2412 = {
  data: [
    {
      examid: 2412,
      qaAnatomyCriteria:
        "Vollständige Abbildung des Hüftgelenkes mit der TEP in ganzer Länge",
      qaCriteriaId: 892,
      colors: {
        Hipjoint2412Fill: "#5cbd76",
      },
      exam: "Linkes Hüftgelenk TEP v.d liegend",
    },
    {
      examid: 2412,
      qaAnatomyCriteria:
        "Trochanter major ist lateral am Oberschenkel zu sehen",
      qaCriteriaId: 893,
      colors: {
        TrochanterMajor2412Fill: "#dce11f",
        Schenkel2412Fill: "#029ed0",
      },
      exam: "Linkes Hüftgelenk TEP v.d liegend",
    },
    {
      examid: 2412,
      qaAnatomyCriteria:
        "Trochanter minor ist an der Innenseite des Oberschenkels nur knapp zu sehen",
      qaCriteriaId: 894,
      colors: {
        TrochanterMinor2412Fill: "#cf2027",
        Schenkel2412Fill: "#029ed0",
      },
      exam: "Linkes Hüftgelenk TEP v.d liegend",
    },
  ],
};

export const QaApiResponse2413 = {
  data: [
    {
      examid: 2413,
      qaAnatomyCriteria:
        "Vollständige Abbildung des Hüftgelenkes mit der TEP in ganzer Länge",
      qaCriteriaId: 895,
      colors: {
        Hipjoint2413Fill: "#5cbd76",
      },
      exam: "Rechtes Hüftgelenk TEP v.d liegend",
    },
    {
      examid: 2413,
      qaAnatomyCriteria:
        "Trochanter major ist lateral am Oberschenkel zu sehen",
      qaCriteriaId: 896,
      colors: {
        TrochanterMajor2413Fill: "#dce11f",
        Schenkel2413Fill: "#029ed0",
      },
      exam: "Rechtes Hüftgelenk TEP v.d liegend",
    },
    {
      examid: 2413,
      qaAnatomyCriteria:
        "Trochanter minor ist an der Innenseite des Oberschenkels nur knapp zu sehen",
      qaCriteriaId: 897,
      colors: {
        TrochanterMinor2413Fill: "#cf2027",
        Schenkel2413Fill: "#029ed0",
      },
      exam: "Rechtes Hüftgelenk TEP v.d liegend",
    },
  ],
};

export const QaApiResponse2414 = {
  data: [
    {
      examid: 2414,
      qaAnatomyCriteria:
        "Komplette Darstellung des Hüftgelenkes und der gesamten TEP",
      qaCriteriaId: 898,
      colors: {
        Hipjoint2414Fill: "#2020F7",
      },
      exam: "Linkes Hüftgelenk TEP nach Lauenstein v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2415 = {
  data: [
    {
      examid: 2415,
      qaAnatomyCriteria:
        "Komplette Darstellung des Hüftgelenkes und der gesamten TEP",
      qaCriteriaId: 899,
      colors: {
        Hipjoint2415Fill: "#2020F7",
      },
      exam: "Rechtes Hüftgelenk TEP nach Lauenstein v.d.obl liegend",
    },
  ],
};

export const QaApiResponse2416 = {
  data: [
    {
      examid: 2416,
      qaAnatomyCriteria:
        "Beide Hüftgelenksspalten deutlich und gleichmäßig erkennbar",
      qaCriteriaId: 900,
      colors: {
        Hipjoint2416Fill: "#008000",
      },
      exam: "Hüfte Übersicht v.d stehend",
    },
    {
      examid: 2416,
      qaAnatomyCriteria: "Oberschenkelhals ist nicht verkürzt",
      qaCriteriaId: 901,
      colors: {
        Oberschenkelhals2416Fill: "#cf2027",
      },
      exam: "Hüfte Übersicht v.d stehend",
    },
    {
      examid: 2416,
      qaAnatomyCriteria:
        "Trochanter major ist lateral am Oberschenkel zu sehen",
      qaCriteriaId: 902,
      colors: {
        TrochanterMajor2416Fill: "#dce11f",
        Schenkel2416Fill: "#029ed0",
      },
      exam: "Hüfte Übersicht v.d stehend",
    },
    {
      examid: 2416,
      qaAnatomyCriteria:
        "Trochanter minor ist an der Innenseite des Oberschenkels nur knapp zu sehen",
      qaCriteriaId: 903,
      colors: {
        TrochanterMinor2416Fill: "#7E20F7",
        Schenkel2416Fill: "#029ed0",
      },
      exam: "Hüfte Übersicht v.d stehend",
    },
  ],
};

export const QaApiResponse2417 = {
  data: [
    {
      examid: 2417,
      qaAnatomyCriteria:
        "Beide Hüftgelenksspalten deutlich und gleichmäßig erkennbar",
      qaCriteriaId: 904,
      colors: {
        Hipjoint2417Fill: "#008000",
      },
      exam: "Hüfte Übersicht v.d stehend",
    },
    {
      examid: 2417,
      qaAnatomyCriteria: "Oberschenkelhals ist nicht verkürzt",
      qaCriteriaId: 905,
      colors: {
        Oberschenkelhals2417Fill: "#cf2027",
      },
      exam: "Hüfte Übersicht v.d stehend",
    },
    {
      examid: 2417,
      qaAnatomyCriteria:
        "Trochanter major ist lateral am Oberschenkel zu sehen",
      qaCriteriaId: 906,
      colors: {
        TrochanterMajor2417Fill: "#dce11f",
        Schenkel2417Fill: "#029ed0",
      },
      exam: "Hüfte Übersicht v.d stehend",
    },
    {
      examid: 2417,
      qaAnatomyCriteria:
        "Trochanter minor ist an der Innenseite des Oberschenkels nur knapp zu sehen",
      qaCriteriaId: 907,
      colors: {
        TrochanterMinor2417Fill: "#7E20F7",
        Schenkel2417Fill: "#029ed0",
      },
      exam: "Hüfte Übersicht v.d stehend",
    },
  ],
};

export const QaApiResponse2108 = {
  data: [
    {
      examid: 2108,
      qaAnatomyCriteria:
        "Es muss eine vollständig dargestellte Lunge inklusive Lungenspitze und Zwerchfellrippenwinkel auf dem Bild zu beurteilen sein",
      qaCriteriaId: 627,
      colors: {
        Lungenspitze2108Fill: "#b91818",
        Zwerchfellwinkel2108Fill: "#7E20F7",
      },
      exam: "Thorax Bettaufnahme a.p liegend",
    },
    {
      examid: 2108,
      qaAnatomyCriteria: "Beide Lungenflügel sollten symmetrisch sein",
      qaCriteriaId: 628,
      colors: { Lungs2108Fill: "#008000" },
      exam: "Thorax Bettaufnahme a.p liegend",
    },
    {
      examid: 2108,
      qaAnatomyCriteria: "Die Wirbelsäule befindet sich in der Mitte",
      qaCriteriaId: 629,
      colors: { Spine2108Fill: "#f4f40c" },
      exam: "Thorax Bettaufnahme a.p liegend",
    },
    {
      examid: 2108,
      qaAnatomyCriteria:
        "Möglichst keine Überlagerung durch die Scapulae",
      qaCriteriaId: 630,
      colors: {},
      exam: "Thorax Bettaufnahme a.p liegend",
    },
  ],
};

export const QaApiResponse2107 = {
  data: [
    {
      examid: 2107,
      qaAnatomyCriteria:
        "Es müssen beide Lungenflügel inklusive der Lungenspitzen und des Zwerchfelles dargestellt sein",
      qaCriteriaId: 625,
      colors: {
        Lung_lobes2107Fill: "#6fd899",
        Lungenspitze2107Fill: "#e073d8",
        Zwerchfelle2107Fill: "#3951a3",
      },
      exam: "Thorax Herzfernaufnahme Inspiration p.a stehend",
    },
    {
      examid: 2107,
      qaAnatomyCriteria: "Herzschatten gut beurteilbar durch gute Inspiration",
      qaCriteriaId: 626,
      colors: {
        Herz2107Fill: "#d5e227",
      },
      exam: "Thorax Herzfernaufnahme Inspiration p.a stehend",
    },
  ],
};

export const QaApiResponse2106 = {
  data: [
    {
      examid: 2106,
      qaAnatomyCriteria:
        "Es muss eine komplette Lunge inklusive Lungenspitze und Zwerchfellrippenwinkel dargestellt sein",
      qaCriteriaId: 622,
      colors: {
        
        Lungenspitze2106Fill: "#6fd899",
        Zwerchfellrippenwinkel2106Fill: "#3951a3",
      },
      exam: "Thorax Exspiration lat stehend",
    },
    {
      examid: 2106,
      qaAnatomyCriteria:
        "Der Thorax muss streng seitlich abgebildet sein mit einer streng seitlich abgebildeten Wirbelsäule die Wirbelkörperhinterkanten einfach konturiert, aufweisen",
      qaCriteriaId: 623,
      colors: { Spine2106Fill: "#f4f40c", WKhinterkanten2106Fill: "#e52325" },
      exam: "Thorax Exspiration lat stehend",
    },
    {
      examid: 2106,
      qaAnatomyCriteria: "Sternum seitlich dargestellt",
      qaCriteriaId: 624,
      colors: {
        Sternum2106Fill: "#e073d8",
      },
      exam: "Thorax Exspiration lat stehend",
    },
  ],
};

export const QaApiResponse2105 = {
  data: [
    {
      examid: 2105,
      qaAnatomyCriteria:
        "Es muss eine komplette Lunge inklusive Lungenspitze und Zwerchfellrippenwinkel dargestellt sein",
      qaCriteriaId: 618,
      colors: {
        Lungenspitze2105Fill: "#6fd899",
        Zwerchfellrippenwinkel2105Fill: "#3951a3",
       
      },
      exam: "Thorax Inspiration lat stehend",
    },
    {
      examid: 2105,
      qaAnatomyCriteria:
        "Der Thorax muss streng seitlich abgebildet sein mit einer streng seitlich abgebildeten Wirbelsäule die Wirbelkörperhinterkanten dürfen keine Doppelkontur aufweisen",
      qaCriteriaId: 619,
      colors: { Spine2105Fill: "#f4f40c", WKhinterkanten2105Fill: "#e52325" },
      exam: "Thorax Inspiration lat stehend",
    },
    {
      examid: 2105,
      qaAnatomyCriteria: "Erkennbarkeit der retrokardialen Lunge",
      qaCriteriaId: 620,
      colors: {
        retrokardialen_Lunge2105Fill: "#00f9ff",
      },
      exam: "Thorax Inspiration lat stehend",
    },
    {
      examid: 2105,
      qaAnatomyCriteria: "Sternum lateral",
      qaCriteriaId: 621,
      colors: {
        Sternum2105Fill: "#e073d8",
      },
      exam: "Thorax Inspiration lat stehend",
    },
  ],
};

export const QaApiResponse2104 = {
  data: [
    {
      examid: 2104,
      qaAnatomyCriteria:
        "Es müssen beide Lungenflügel inklusive der Lungenspitzen und des Zwerchfelles dargestellt sein",
      qaCriteriaId: 614,
      colors: {
        Lung_lobes2104Fill: "#6fd899",
        Lungenspitze2104Fill: "#e073d8",
        Zwerchfelle2104Fill: "#3951a3",
      },
      exam: "Thorax Exspiration p.a stehend",
    },
    {
      examid: 2104,
      qaAnatomyCriteria:
        "Winkel Zwerchfell Rippen dargestellt rechts und links",
      qaCriteriaId: 615,
      colors: {
        Zwerchfellwinkel2104Fill: "#e52325",
      },
      exam: "Thorax Exspiration p.a stehend",
    },
    {
      examid: 2104,
      qaAnatomyCriteria:
        "Eine schwache Erkennbarkeit der Brustwirbel 3 bis 5 sollte gegeben sein",
      qaCriteriaId: 616,
      colors: {
        BW_2104Fill: "#00f9ff",
      },
      exam: "Thorax Exspiration p.a stehend",
    },
    {
      examid: 2104,
      qaAnatomyCriteria:
        "Die Schulterblätter befinden sich außerhalb der Lungen",
      qaCriteriaId: 617,
      colors: {
        Schulter2104Fill: "#c2d82e",
      },
      exam: "Thorax Exspiration p.a stehend",
    },
  ],
};

export const QaApiResponse2103 = {
  data: [
    {
      examid: 2103,
      qaAnatomyCriteria:
        "Es müssen beide Lungenflügel inklusive der Lungenspitzen und des Zwerchfelles dargestellt sein",
      qaCriteriaId: 610,
      colors: {
        Lung_lobes2103Fill: "#6fd899",
        Lungenspitze2103Fill: "#e073d8",
        Zwerchfelle2103Fill: "#3951a3",
      },
      exam: "Thorax Inspiration p.a stehend",
    },
    {
      examid: 2103,
      qaAnatomyCriteria:
        "Winkel Zwerchfell Rippen dargestellt rechts und links",
      qaCriteriaId: 611,
      colors: {
        Zwerchfellwinkel2103Fill: "#e52325",
      },
      exam: "Thorax Inspiration p.a stehend",
    },
    {
      examid: 2103,
      qaAnatomyCriteria:
        "Eine schwache Erkennbarkeit der Brustwirbel 3 bis 5 sollte gegeben sein",
      qaCriteriaId: 612,
      colors: {
        BW_2103Fill: "#00f9ff",
      },
      exam: "Thorax Inspiration p.a stehend",
    },
    {
      examid: 2103,
      qaAnatomyCriteria:
        "Die Schulterblätter befinden sich außerhalb der Lungen",
      qaCriteriaId: 613,
      colors: {
        Schulter2103Fill: "#c2d82e",
      },
      exam: "Thorax Inspiration p.a stehend",
    },
  ],
};

export const QaApiResponse2102 = {
  data: [
    {
      examid: 2102,
      qaAnatomyCriteria:
        "Es muss eine vollständig dargestellte Lunge inklusive Lungenspitze und Zwerchfellrippenwinkel zu beurteilen sein",
      qaCriteriaId: 606,
      colors: {
        Lungenspitze2102Fill: "#e073d8",
        Zwerchfellwinkel2102Fill: "#3951a3",
      },
      exam: "Thorax mit Raster Exspiration a.p liegend",
    },
    {
      examid: 2102,
      qaAnatomyCriteria: "Darstellung der Trachea und er Stammbronchien",
      qaCriteriaId: 607,
      colors: {
        Trachea2102Fill: "#f4f40c",
      },
      exam: "Thorax mit Raster Exspiration a.p liegend",
    },
    {
      examid: 2102,
      qaAnatomyCriteria: "Beide Lungenflügel sollen symmetrisch sein",
      qaCriteriaId: 608,
      colors: {
        Lungs2102Fill: "#6FD899",
      },
      exam: "Thorax mit Raster Exspiration a.p liegend",
    },
    {
      examid: 2102,
      qaAnatomyCriteria: "Möglichst keine Überlagerung durch die Scapulae",
      qaCriteriaId: 609,
      colors: "",
      exam: "Thorax mit Raster Exspiration a.p liegend",
    },
  ],
};

export const QaApiResponse2101 = {
  data: [
    {
      examid: 2101,
      qaAnatomyCriteria:
        "Eine vollständig dargestellte Lunge einschließlich der Lungenspitze und des Zwerchfellwinkels muss gezeigt werden",
      qaCriteriaId: 601,
      colors: {
        Lungenspitze2101Fill: "#b91818",
        Zwerchfellwinkel2101Fill: "#3951a3",
      },
      exam: "Thorax mit Raster Inspiration a.p liegend",
    },
    {
      examid: 2101,
      qaAnatomyCriteria:
        "Darstellung der Gefäße bis in die Lungenperipherie sowie der Trachea und der Stammbronchien",
      qaCriteriaId: 602,
      colors: {
        Vessels2101Fill: "#00eaea",
        Trachea2101Fill: "#f4f40c",
      },
      exam: "Thorax mit Raster Inspiration a.p liegend",
    },
    {
      examid: 2101,
      qaAnatomyCriteria: "Beide Claviculae gleich dargestellt",
      qaCriteriaId: 603,
      colors: {
        Claviculae2101Fill: "#954c9d",
      },
      exam: "Thorax mit Raster Inspiration a.p liegend",
    },
    {
      examid: 2101,
      qaAnatomyCriteria: "Beide Lungenflügel sollen symmetrisch sein",
      qaCriteriaId: 604,
      colors: {
        Lungs2101Fill: "#6FD899",
      },
      exam: "Thorax mit Raster Inspiration a.p liegend",
    },
    {
      examid: 2101,
      qaAnatomyCriteria: "Möglichst keine Überlagerung durch die Scapulae",
      qaCriteriaId: 605,
      colors: "",
      exam: "Thorax mit Raster Inspiration a.p liegend",
    },
  ],
};

export const QaApiResponse2035 = {
  data: [
    {
      examid: 2035,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung der laterale Teile der Scapula (medial von den Rippen überlagert)",
      qaCriteriaId: 600,
      colors: { Scapula2035Fill: "#48EF3F", ribs2035Fill: "#f18a00" },
      exam: "Rechtes Schulterblatt v.d liegend",
    },
  ],
};

export const QaApiResponse2033 = {
  data: [
    {
      examid: 2033,
      qaAnatomyCriteria:
        "Beide Sternoclaviculagelenke stellen sich seitengleich dar",
      qaCriteriaId: 598,
      colors: {
        Sternoclaviculagelenke2033Fill: "#954c9d",
      },
      exam: "Rechtes Sternoclaviculargelenk d.v.obl liegend",
    },
  ],
};

export const QaApiResponse2032 = {
  data: [
    {
      examid: 2032,
      qaAnatomyCriteria:
        "Beide Sternoclaviculagelenke stellen sich seitengleich dar",
      qaCriteriaId: 597,
      colors: {
        Sternoclaviculagelenke2032Fill: "#954c9d",
      },
      exam: "Linkes Sternoclaviculargelenk d.v.obl liegend",
    },
  ],
};

export const QaApiResponse2031 = {
  data: [
    {
      examid: 2031,
      qaAnatomyCriteria:
        "Seitengleiche Darstellung der beiden Sternoclaviculagelenke",
      qaCriteriaId: 596,
      colors: {
        Sternoclaviculagelenke2031Fill: "#954c9d",
      },
      exam: "Rechtes Sternoclaviculargelenk d.v.obl stehend",
    },
  ],
};

export const QaApiResponse2029 = {
  data: [
    {
      examid: 2029,
      qaAnatomyCriteria:
        "Sternum stellt sich in allen drei Abschnitten überlagerungsfrei dar",
      qaCriteriaId: 593,
      colors: {
       
        Manubrium2029Fill: "#48EF3F",
        corpus2029Fill: "#0000FF",
        sternum2029Fill: "#FFFF00",
      },
      exam: "Brustbein lat stehend",
    },
    {
      examid: 2029,
      qaAnatomyCriteria:
        "Gute Darstellung des Gelenkes zwischen Manubrium und Corpus",
      qaCriteriaId: 594,
      colors: {
        Gelenk2029Fill: "#b91818",
        Manubrium2029Fill: "#48EF3F",
        corpus2029Fill: "#0000FF",
      },
      exam: "Brustbein lat stehend",
    },
  ],
};

export const QaApiResponse2028 = {
  data: [
    {
      examid: 2028,
      qaAnatomyCriteria: "Brustbein projiziert sich neben die Wirbelsäule",
      qaCriteriaId: 591,
      colors: { Brustbein2028Fill: "#b91818" },
      exam: "Brustbein d.v.obl liegend",
    },
  ],
};

export const QaApiResponse2027 = {
  data: [
    {
      examid: 2027,
      qaAnatomyCriteria: "Brustbein projiziert sich neben die Wirbelsäule",
      qaCriteriaId: 589,
      colors: { Brustbein2027Fill: "#b91818" },
      exam: "Brustbein d.v.obl stehend",
    },
  ],
};

export const QaApiResponse2026 = {
  data: [
    {
      examid: 2026,
      qaAnatomyCriteria:
        "Scapula ohne Überlagerung von Rippen seitlich dargestellt",
      qaCriteriaId: 588,
      colors: { Scapula2026Fill: "#b91818", ribs2026Fill: "#48EF3F" },
      exam: "Rechtes Schulterblatt lat stehend",
    },
  ],
};

export const QaApiResponse2025 = {
  data: [
    {
      examid: 2025,
      qaAnatomyCriteria:
        "Scapula ohne Überlagerung von Rippen seitlich dargestellt",
      qaCriteriaId: 587,
      colors: { Scapula2025Fill: "#b91818", ribs2025Fill: "#48EF3F" },
      exam: "Linkes Schulterblatt lat stehend",
    },
  ],
};

export const QaApiResponse2024 = {
  data: [
    {
      examid: 2024,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung der laterale Teile der Scapula (medial von den Rippen überlagert)",
      qaCriteriaId: 586,
      colors: { Scapula2024Fill: "#b91818", ribs2024Fill: "#48EF3F" },
      exam: "Rechtes Schulterblatt v.d stehend",
    },
  ],
};

export const QaApiResponse2022 = {
  data: [
    {
      examid: 2022,
      qaAnatomyCriteria:
        "Acromion und laterale Clavicula stellen sich überlagerungsfrei dar",
      qaCriteriaId: 584,
      colors: { Acromion2022Fill: "#b91818", Clavicula2022Fill: "#48EF3F" },
      exam: "Rechtes Acromioklavikulargelenk mit Belastung d.v stehend",
    },
  ],
};

export const QaApiResponse2021 = {
  data: [
    {
      examid: 2021,
      qaAnatomyCriteria:
        "Acromion und laterale Clavicula stellen sich überlagerungsfrei dar",
      qaCriteriaId: 583,
      colors: { Acromion2021Fill: "#b91818", Clavicula2021Fill: "#48EF3F" },
      exam: "Linkes Acromioklavikulargelenk mit Belastung d.v stehend",
    },
  ],
};

export const QaApiResponse2020 = {
  data: [
    {
      examid: 2020,
      qaAnatomyCriteria:
        "Acromion und laterale Clavicula stellen sich überlagerungsfrei dar",
      qaCriteriaId: 582,
      colors: { Acromion2020Fill: "#b91818", Clavicula2020Fill: "#48EF3F" },
      exam: "Rechtes Acromioklavikulargelenk d.v stehend",
    },
  ],
};

export const QaApiResponse2019 = {
  data: [
    {
      examid: 2019,
      qaAnatomyCriteria:
        "Acromion und laterale Clavicula stellen sich überlagerungsfrei dar",
      qaCriteriaId: 581,
      colors: { Acromion2019Fill: "#b91818", Clavicula2019Fill: "#48EF3F" },
      exam: "Linkes Acromioklavikulargelenk d.v stehend",
    },
  ],
};

export const QaApiResponse2018 = {
  data: [
    {
      examid: 2018,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung des gesamten Schlüsselbeins",
      qaCriteriaId: 580,
      colors: { clavicula2018Fill: "#4eae33" },
      exam: "Rechtes Schlüsselbein tang stehend",
    },
  ],
};

export const QaApiResponse2017 = {
  data: [
    {
      examid: 2017,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung des gesamten Schlüsselbeins",
      qaCriteriaId: 579,
      colors: { clavicula2017Fill: "#4eae33" },
      exam: "Linkes Schlüsselbein tang stehend",
    },
  ],
};

export const QaApiResponse2016 = {
  data: [
    {
      examid: 2016,
      qaAnatomyCriteria: "Gesamte Clavicula stellt sich dar",
      qaCriteriaId: 577,
      colors: { clavicula2016Fill: "#4eae33" },
      exam: "Rechtes Schlüsselbein d.v stehend",
    },
    {
      examid: 2016,
      qaAnatomyCriteria: "Beide Gelenke (AC-Gelenk/SC-Gelenk) sind abgebildet",
      qaCriteriaId: 578,
      colors: { acjoint2016Fill: "#b91818", scjoint2016Fill: "#7E20F7" },
      exam: "Rechtes Schlüsselbein d.v stehend",
    },
  ],
};

export const QaApiResponse2015 = {
  data: [
    {
      examid: 2015,
      qaAnatomyCriteria: "Gesamte Clavicula stellt sich dar",
      qaCriteriaId: 575,
      colors: { clavicula2015Fill: "#4eae33" },
      exam: "Linkes Schlüsselbein d.v stehend",
    },
    {
      examid: 2015,
      qaAnatomyCriteria: "Beide Gelenke (AC-Gelenk/SC-Gelenk) sind abgebildet",
      qaCriteriaId: 576,
      colors: { acjoint2015Fill: "#b91818", scjoint2015Fill: "#7E20F7" },
      exam: "Linkes Schlüsselbein d.v stehend",
    },
  ],
};

export const QaApiResponse2014 = {
  data: [
    {
      examid: 2014,
      qaAnatomyCriteria: "Scapula und Rippen überlagern sich nicht",
      qaCriteriaId: 574,
      colors: { scapula2014Fill: "#E5348B", rips2014Fill: "#5DC5EC" },
      exam: "Rechtes Schulter outlet view d.v.obl stehend",
    },
  ],
};

export const QaApiResponse2013 = {
  data: [
    {
      examid: 2013,
      qaAnatomyCriteria: "Scapula und Rippen überlagern sich nicht",
      qaCriteriaId: 573,
      colors: { scapula2013Fill: "#E5348B", rips2013Fill: "#5DC5EC" },
      exam: "Linkes Schulter outlet view d.v.obl stehend",
    },
  ],
};

export const QaApiResponse2012 = {
  data: [
    {
      examid: 2012,
      qaAnatomyCriteria: "Scapula überlagerungsfrei neben den Rippen",
      qaCriteriaId: 571,
      colors: { scapula2012Fill: "#FFFF00", rips2012Fill: "#E5348B" },
      exam: "Rechtes Schultergelenk Y-Aufnahme d.v.obl stehend",
    },
    {
      examid: 2012,
      qaAnatomyCriteria: "Oberarmschaft und Scapula in Deckung",
      qaCriteriaId: 572,
      colors: { scapula2012Fill: "#FFFF00", upperarm2012Fill: "#0000FF" },
      exam: "Rechtes Schultergelenk Y-Aufnahme d.v.obl stehend",
    },
  ],
};

export const QaApiResponse2011 = {
  data: [
    {
      examid: 2011,
      qaAnatomyCriteria: "Scapula überlagerungsfrei neben den Rippen",
      qaCriteriaId: 569,
      colors: { scapula2011Fill: "#FFFF00", rips2011Fill: "#E5348B" },
      exam: "Linkes Schultergelenk Y-Aufnahme d.v.obl stehend",
    },
    {
      examid: 2011,
      qaAnatomyCriteria: "Oberarmschaft und Scapula in Deckung",
      qaCriteriaId: 570,
      colors: { scapula2011Fill: "#FFFF00", upperarm2011Fill: "#0000FF" },
      exam: "Linkes Schultergelenk Y-Aufnahme d.v.obl stehend",
    },
  ],
};

export const QaApiResponse2010 = {
  data: [
    {
      examid: 2010,
      qaAnatomyCriteria: "Gute Beurteilbarkeit des Schultergelenkes",
      qaCriteriaId: 566,
      colors: { jointgap2010Fill: "#f59c1a" },
      exam: "Rechtes Schultergelenk Schwedenstatus Elevation v.d stehend",
    },
    {
      examid: 2010,
      qaAnatomyCriteria: "Weichteile der Schulter müssen beurteilbar sein",
      qaCriteriaId: 567,
      colors: { Weichteile2010Fill: "#aa599e" },
      exam: "Rechtes Schultergelenk Schwedenstatus Elevation v.d stehend",
    },
    {
      examid: 2010,
      qaAnatomyCriteria: "Cavitas glenoidalis strichförmig dargestellt",
      qaCriteriaId: 568,
      colors: { cavitas2010Fill: "#1ba8e1" },
      exam: "Rechtes Schultergelenk Schwedenstatus Elevation v.d stehend",
    },
  ],
};

export const QaApiResponse2009 = {
  data: [
    {
      examid: 2009,
      qaAnatomyCriteria: "Gute Beurteilbarkeit des Schultergelenkes",
      qaCriteriaId: 563,
      colors: { jointgap2009Fill: "#f59c1a" },
      exam: "Linkes Schultergelenk Schwedenstatus Elevation v.d stehend",
    },
    {
      examid: 2009,
      qaAnatomyCriteria: "Weichteile der Schulter müssen beurteilbar sein",
      qaCriteriaId: 564,
      colors: { Weichteile2009Fill: "#aa599e" },
      exam: "Linkes Schultergelenk Schwedenstatus Elevation v.d stehend",
    },
    {
      examid: 2009,
      qaAnatomyCriteria: "Cavitas glenoidalis strichförmig dargestellt",
      qaCriteriaId: 565,
      colors: { cavitas2009Fill: "#1ba8e1" },
      exam: "Linkes Schultergelenk Schwedenstatus Elevation v.d stehend",
    },
  ],
};

export const QaApiResponse2008 = {
  data: [
    {
      examid: 2008,
      qaAnatomyCriteria:
        "Freie Darstellung des Gelenkspaltes und des subacromialraumes",
      qaCriteriaId: 559,
      colors: { jointgap2008Fill: "#f59c1a", subacromial2008Fill: "#d01b17" },
      exam: "Rechtes Schultergelenk Schwedenstatus Außenrotation v.d stehend",
    },
    {
      examid: 2008,
      qaAnatomyCriteria: "Weichteile der Schulter müssen beurteilbar sein",
      qaCriteriaId: 560,
      colors: { Weichteile2008Fill: "#aa599e" },
      exam: "Rechtes Schultergelenk Schwedenstatus Außenrotation v.d stehend",
    },
    {
      examid: 2008,
      qaAnatomyCriteria:
        "Tuberculum majus und Minus ca 1 cm nebeneinander abgebildet",
      qaCriteriaId: 561,
      colors: {
        Tuberculummajus2008Fill: "#4eae33",
        Tuberculumminus2008Fill: "#0000FF",
      },
      exam: "Rechtes Schultergelenk Schwedenstatus Außenrotation v.d stehend",
    },
    {
      examid: 2008,
      qaAnatomyCriteria: "Kavitas strichförmig",
      qaCriteriaId: 562,
      colors: { cavitas2008Fill: "#1ba8e1" },
      exam: "Rechtes Schultergelenk Schwedenstatus Außenrotation v.d stehend",
    },
  ],
};

export const QaApiResponse2007 = {
  data: [
    {
      examid: 2007,
      qaAnatomyCriteria:
        "Freie Darstellung des Gelenkspaltes und des subacromialraumes",
      qaCriteriaId: 555,
      colors: { jointgap2007Fill: "#f59c1a", subacromial2007Fill: "#d01b17" },
      exam: "Linkes Schultergelenk Schwedenstatus Außenrotation v.d stehend",
    },
    {
      examid: 2007,
      qaAnatomyCriteria: "Weichteile der Schulter müssen beurteilbar sein",
      qaCriteriaId: 556,
      colors: { Weichteile2007Fill: "#aa599e" },
      exam: "Linkes Schultergelenk Schwedenstatus Außenrotation v.d stehend",
    },
    {
      examid: 2007,
      qaAnatomyCriteria:
        "Tuberculum majus und Minus ca 1 cm nebeneinander abgebildet",
      qaCriteriaId: 557,
      colors: {
        Tuberculummajus2007Fill: "#4eae33",
        Tuberculumminus2007Fill: "#0000FF",
      },
      exam: "Linkes Schultergelenk Schwedenstatus Außenrotation v.d stehend",
    },
    {
      examid: 2007,
      qaAnatomyCriteria: "Kavitas strichförmig",
      qaCriteriaId: 558,
      colors: { cavitas2007Fill: "#1ba8e1" },
      exam: "Linkes Schultergelenk Schwedenstatus Außenrotation v.d stehend",
    },
  ],
};

export const QaApiResponse2006 = {
  data: [
    {
      examid: 2006,
      qaAnatomyCriteria:
        "Tuberculum majus lateral randständig und Tuberculum minus mittelständig sollen nebeneinander dargestellt sein",
      qaCriteriaId: 551,
      colors: {
        Tuberculum_majus2006Fill: "#0000FF",
        Tuberculum_minus2006Fill: "#04a339",
      },
      exam: "Rechtes Schultergelenk Schwedenstatus Innenrotation v.d stehend",
    },
    {
      examid: 2006,
      qaAnatomyCriteria:
        "Freie Darstellung des Gelenkspaltes und des subacromialraumes",
      qaCriteriaId: 552,
      colors: {
        Gelenkspalte2006Fill: "#f59c1a",
        subacromialraume2006Fill: "#d01b17",
      },
      exam: "Rechtes Schultergelenk Schwedenstatus Innenrotation v.d stehend",
    },
    {
      examid: 2006,
      qaAnatomyCriteria: "Cavitas glenoidalis strichförmig",
      qaCriteriaId: 553,
      colors: { cavitas_glenoidalis2006Fill: "#1ba8e1" },
      exam: "Rechtes Schultergelenk Schwedenstatus Innenrotation v.d stehend",
    },
    {
      examid: 2006,
      qaAnatomyCriteria: "Weichteile der Schulter müssen beurteilbar sein",
      qaCriteriaId: 554,
      colors: { Weichteile2006Fill: "#aa599e" },
      exam: "Rechtes Schultergelenk Schwedenstatus Innenrotation v.d stehend",
    },
  ],
};

export const QaApiResponse2005 = {
  data: [
    {
      examid: 2005,
      qaAnatomyCriteria:
        "Tuberculum majus lateral randständig und Tuberculum minus mittelständig sollen nebeneinander dargestellt sein",
      qaCriteriaId: 547,
      colors: {
        Tuberculum_majus2005Fill: "#0000FF",
        Tuberculum_minus2005Fill: "#04a339",
      },
      exam: "Linkes Schultergelenk Schwedenstatus Innenrotation v.d stehend",
    },
    {
      examid: 2005,
      qaAnatomyCriteria:
        "Freie Darstellung des Gelenkspaltes und des subacromialraumes",
      qaCriteriaId: 548,
      colors: {
        Gelenkspalte2005Fill: "#f59c1a",
        subacromialraume2005Fill: "#d01b17",
      },
      exam: "Linkes Schultergelenk Schwedenstatus Innenrotation v.d stehend",
    },
    {
      examid: 2005,
      qaAnatomyCriteria: "Cavitas glenoidalis strichförmig",
      qaCriteriaId: 549,
      colors: { cavitas_glenoidalis2005Fill: "#1ba8e1" },
      exam: "Linkes Schultergelenk Schwedenstatus Innenrotation v.d stehend",
    },
    {
      examid: 2005,
      qaAnatomyCriteria: "Weichteile der Schulter müssen beurteilbar sein",
      qaCriteriaId: 550,
      colors: { Weichteile2005Fill: "#aa599e" },
      exam: "Linkes Schultergelenk Schwedenstatus Innenrotation v.d stehend",
    },
  ],
};

export const QaApiResponse2004 = {
  data: [
    {
      examid: 2004,
      qaAnatomyCriteria: "Gute Beurteilbarkeit des Schultergelenkes",
      qaCriteriaId: 545,
      colors: { jointgap2004Fill: "#04a339" },
      exam: "Rechtes Schultergelenk lat stehend",
    },
    {
      examid: 2004,
      qaAnatomyCriteria: "Cavitas glenoidalis strichförmig dargestellt",
      qaCriteriaId: 546,
      colors: { jointsocket2004Fill: "#F72020" },
      exam: "Rechtes Schultergelenk lat stehend",
    },
  ],
};

export const QaApiResponse2003 = {
  data: [
    {
      examid: 2003,
      qaAnatomyCriteria: "Gute Beurteilbarkeit des Schultergelenkes",
      qaCriteriaId: 543,
      colors: { jointgap2003Fill: "#04a339" },
      exam: "Linkes Schultergelenk lat stehend",
    },
    {
      examid: 2003,
      qaAnatomyCriteria: "Cavitas glenoidalis strichförmig dargestellt",
      qaCriteriaId: 544,
      colors: { jointsocket2003Fill: "#F72020" },
      exam: "Linkes Schultergelenk lat stehend",
    },
  ],
};

export const QaApiResponse2002 = {
  data: [
    {
      examid: 2002,
      qaAnatomyCriteria:
        "Freie Darstellung des Gelenkspaltes zwischen Humeruskopf und Gelenkpfanne",
      qaCriteriaId: 542,
      colors: {
        jointgap2002Fill: "#2020F7",
        headofhumerus2002Fill: "#fe0",
        jointsocket2002Fill: "#F72020",
      },
      exam: "Rechtes Schultergelenk v.d stehend",
    },
  ],
};

export const QaApiResponse2001 = {
  data: [
    {
      examid: 2001,
      qaAnatomyCriteria:
        "Freie Darstellung des Gelenkspaltes zwischen Humeruskopf und Gelenkpfanne",
      qaCriteriaId: 541,
      colors: {
        jointgap2001Fill: "#2020F7",
        headofhumerus2001Fill: "#fe0",
        jointsocket2001Fill: "#F72020",
      },
      exam: "Linkes Schultergelenk v.d stehend",
    },
  ],
};

export const QaApiResponse1943 = {
  data: [
    {
      examid: 1943,
      qaAnatomyCriteria: "Schädel symmetrisch und vollständig abgebildet",
      qaCriteriaId: 537,
      colors: {
        Schädel1943Fill: "#de3c8e",
      },
      exam: "Schädel a.p sitzend",
    },
    {
      examid: 1943,
      qaAnatomyCriteria: "Siebbeinzellen symmetrisch dargestellt",
      qaCriteriaId: 538,
      colors: {
        Siebbeinzellen1943Fill: "#e00914",
      },
      exam: "Schädel a.p sitzend",
    },
    {
      examid: 1943,
      qaAnatomyCriteria: "Nasenscheidewand streng in der Mitte",
      qaCriteriaId: 539,
      colors: {
        Nasenscheidewand1943Fill: "#45b4e8",
      },
      exam: "Schädel a.p sitzend",
    },
    {
      examid: 1943,
      qaAnatomyCriteria:
        "Felsenbeine projizieren sich in das untere bis mittlere Drittel der Orbitae",
      qaCriteriaId: 540,
      colors: { Felsenbeine1943Fill: "#04a339", Orbita1943Fill: "#fe0" },
      exam: "Schädel a.p sitzend",
    },
  ],
};

export const QaApiResponse1914 = {
  data: [
    {
      examid: 1914,
      qaAnatomyCriteria: "Symmetrische Abbildung beider Orbitae",
      qaCriteriaId: 910,
      colors: {
        orbita1914Fill: "#ffe400",
      },
      exam: "Nasennebenhöhlen o.n stehend",
    },
    {
      examid: 1914,
      qaAnatomyCriteria:
        "Unterhalb des Oberkieferhöhlenbogens befinden sich die Felsenbeinoberkanten",
      qaCriteriaId: 911,
      colors: {
        Felsenbeinoberkante1914Fill: "#04a339",
        Oberkieferhöhlenbogen1914Fill: "#e00914",
      },
      exam: "Nasennebenhöhlen o.n stehend",
    },
    {
      examid: 1914,
      qaAnatomyCriteria: "Durch offenen Mund Darstellung der Keilbeinhöhle",
      qaCriteriaId: 912,
      colors: {
        Keilbeinhöhle1914Fill: "#45b4e8",
      },
      exam: "Nasennebenhöhlen o.n stehend",
    },
  ],
};

export const QaApiResponse1913 = {
  data: [
    {
      examid: 1913,
      qaAnatomyCriteria:
        "Streng seitliche Darstellung des Nasenbeins inkl. der Spina nasalis anterior",
      qaCriteriaId: 536,
      colors: {
        Nasenbein1913Fill: "#e00914",
        SpinaNasalisAnterior1913Fill: "#2020F7",
      },
      exam: "Nasenbein lat sitzend",
    },
  ],
};

export const QaApiResponse1912 = {
  data: [
    {
      examid: 1912,
      qaAnatomyCriteria:
        "Scharfe Konturen von des Gesichtsschädels und der Weichteile",
      qaCriteriaId: 533,
      colors: {
        Gesichtsschädel1912Fill: "#e8e100",
        Weichteile1912Fill: "#a14191",
      },
      exam: "Gesichtsschädel lat sitzend",
    },
    {
      examid: 1912,
      qaAnatomyCriteria:
        "In einer Ebene: vordere Schädelbasis und harter Gaumen",
      qaCriteriaId: 534,
      colors: {
        Schädelbasis1912Fill: "#e00914",
        harterGaumen1912Fill: "#13bae3",
      },
      exam: "Gesichtsschädel lat sitzend",
    },
    {
      examid: 1912,
      qaAnatomyCriteria:
        "Deckungsgleiche Darstellung des schmalen V-förmigen Processus pterygoideus, des Jochbeins und der Kieferköpfchen",
      qaCriteriaId: 535,
      colors: {
        Processus_pterygoideus1912Fill: "#04a339",
        Jochbein1912Fill: "#0000FF",
        Kieferköpfchen1912Fill: "#964B00",
      },
      exam: "Gesichtsschädel lat sitzend",
    },
  ],
};

export const QaApiResponse1904 = {
  data: [
    {
      examid: 1904,
      qaAnatomyCriteria: "Schädel symmetrisch und vollständig abgebildet",
      qaCriteriaId: 529,
      colors: {
        Schädel1904Fill: "#de3c8e",
      },
      exam: "Schädel a.p sitzend",
    },
    {
      examid: 1904,
      qaAnatomyCriteria: "Siebbeinzellen symmetrisch dargestellt",
      qaCriteriaId: 530,
      colors: {
        Siebbeinzellen1904Fill: "#e00914",
      },
      exam: "Schädel a.p sitzend",
    },
    {
      examid: 1904,
      qaAnatomyCriteria: "Nasenscheidewand streng in der Mitte",
      qaCriteriaId: 531,
      colors: {
        Nasenscheidewand1904Fill: "#45b4e8",
      },
      exam: "Schädel a.p sitzend",
    },
    {
      examid: 1904,
      qaAnatomyCriteria:
        "Felsenbeine projizieren sich in das untere bis mittlere Drittel der Orbitae",
      qaCriteriaId: 532,
      colors: { Felsenbeinoberkante1904Fill: "#04a339", Orbita1904Fill: "#fe0" },
      exam: "Schädel a.p sitzend",
    },
  ],
};

export const QaApiResponse1816 = {
  data: [
    {
      examid: 1816,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein.",
      qaCriteriaId: 522,
      colors: {
        ribs1816Fill: "#e4ea11",
      },
      exam: "Rechte Rippen 8-12 v.d liegend",
    },
    {
      examid: 1816,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 523,
      colors:{soft1816Fill: "#7E20F7"},
      exam: "Rechte Rippen 8-12 v.d liegend",
    },
  ],
};

export const QaApiResponse1815 = {
  data: [
    {
      examid: 1815,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein.",
      qaCriteriaId: 520,
      colors: {
        ribs1815Fill: "#e4ea11",
      },
      exam: "Linke Rippen 8-12 v.d liegend",
    },
    {
      examid: 1815,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 521,
      colors: {
        soft1815Fill: "#7E20F7",
      },
      exam: "Linke Rippen 8-12 v.d liegend",
    },
  ],
};

export const QaApiResponse1814 = {
  data: [
    {
      examid: 1814,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein.",
      qaCriteriaId: 518,
      colors: {
        ribs1814Fill: "#e4ea11",
      },
      exam: "Rechte Rippen 1-7 v.d liegend",
    },
    {
      examid: 1814,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 519,
      colors: {soft1814Fill: "#7E20F7",},
      exam: "Rechte Rippen 1-7 v.d liegend",
    },
  ],
};

export const QaApiResponse1813 = {
  data: [
    {
      examid: 1813,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein.",
      qaCriteriaId: 516,
      colors: {
        ribs1813Fill: "#e4ea11",
      },
      exam: "Linke Rippen 1-7 v.d liegend",
    },
    {
      examid: 1813,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 517,
      colors: {soft1813Fill: "#7E20F7",},
      exam: "Linke Rippen 1-7 v.d liegend",
    },
  ],
};

export const QaApiResponse1812 = {
  data: [
    {
      examid: 1812,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein.",
      qaCriteriaId: 514,
      colors: {
        ribs1812Fill: "#e4ea11",
      },
      exam: "Rechte Rippen d.v.obl stehend",
    },
    {
      examid: 1812,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 515,
      colors: {soft1812Fill: "#7E20F7",},
      exam: "Rechte Rippen d.v.obl stehend",
    },
  ],
};

export const QaApiResponse1811 = {
  data: [
    {
      examid: 1811,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein.",
      qaCriteriaId: 512,
      colors: {
        ribs1811Fill: "#e4ea11",
      },
      exam: "Linke Rippen d.v.obl stehend",
    },
    {
      examid: 1811,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 513,
      colors: {soft1811Fill: "#7E20F7",},
      exam: "Linke Rippen d.v.obl stehend",
    },
  ],
};

export const QaApiResponse1810 = {
  data: [
    {
      examid: 1810,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein.",
      qaCriteriaId: 510,
      colors: {
        ribs1810Fill: "#e4ea11",
      },
      exam: "Rechte Rippen v.d stehend",
    },
    {
      examid: 1810,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 511,
      colors: {soft1810Fill: "#7E20F7",},
      exam: "Rechte Rippen v.d stehend",
    },
  ],
};

export const QaApiResponse1809 = {
  data: [
    {
      examid: 1809,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein.",
      qaCriteriaId: 508,
      colors: {
        ribs1809Fill: "#e4ea11",
      },
      exam: "Linke Rippen v.d stehend",
    },
    {
      examid: 1809,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 509,
      colors: {soft1809Fill: "#7E20F7"},
      exam: "",
    },
  ],
};

export const QaApiResponse1808 = {
  data: [
    {
      examid: 1808,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein.",
      qaCriteriaId: 506,
      colors: {
        ribs1808Fill: "#e4ea11",
      },
      exam: "Rechte Rippen d.v stehend",
    },
    {
      examid: 1808,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 507,
      colors: {soft1808Fill: "#7E20F7"},
      exam: "",
    },
  ],
};

export const QaApiResponse1807 = {
  data: [
    {
      examid: 1807,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein.",
      qaCriteriaId: 504,
      colors: {
        ribs1807Fill: "#e4ea11",
      },
      exam: "Linke Rippen d.v stehend",
    },
    {
      examid: 1807,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 505,
      colors: {soft1807Fill: "#7E20F7"},
      exam: "",
    },
  ],
};

export const QaApiResponse1806 = {
  data: [
    {
      examid: 1806,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein.",
      qaCriteriaId: 502,
      colors: {
        ribs1806Fill: "#e4ea11",
      },
      exam: "Rechte Rippen d.v.obl liegend",
    },
    {
      examid: 1806,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 503,
      colors: {soft1806Fill: "#7E20F7"},
      exam: "",
    },
  ],
};

export const QaApiResponse1805 = {
  data: [
    {
      examid: 1805,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein.",
      qaCriteriaId: 500,
      colors: {
        ribs1805Fill: "#e4ea11",
      },
      exam: "Linke Rippen d.v.obl liegend",
    },
    {
      examid: 1805,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 501,
      colors: {soft1805Fill: "#7E20F7"},
      exam: "",
    },
  ],
};

export const QaApiResponse1101 = {
  data: [
    {
      examid: 1101,
      qaAnatomyCriteria:
        "Darstellung des seitl Psoasrandes/ Psoasschatten, Nierenkonturen,des unteren Leberrandes und Gas und Flüssigkeit im Magen-Darm,kanal",
      qaCriteriaId: 1,
      colors: {
        Psoasschatten1101Fill: "#FFFF00",
        Nierenkonturen1101Fill: "#00AA76",
        unteren_Leberrandes1101Fill: "#0000FF",
      },
      exam: "Abdomen a.p stehend",
    },
    {
      examid: 1101,
      qaAnatomyCriteria: "Komplette Darstellung des Zwerchfelles",
      qaCriteriaId: 2,
      colors: {
        Zwerchfelles1101Fill: "#7E20F7",
      },
      exam: "Abdomen a.p stehend",
    },
    {
      examid: 1101,
      qaAnatomyCriteria:
        "je nach Körpergröße sollte das ganze Abdomen bis hin zur Symphyse dargestellt sein",
      qaCriteriaId: 3,
      colors: { Abdomen1101Fill: "#DCE0BD", Symphyse1101Fill: "#FF0037" },
      exam: "Abdomen a.p stehend",
    },
  ],
};

export const QaApiResponse1102 = {
  data: [
    {
      examid: 1102,
      qaAnatomyCriteria:
        "Darstellung des seitl Psoasrandes/Psoasschatten, Nierenkonturen,des unteren Leberrandes und Gas und Flüssigkeit im Magen-Darm,kanal",
      qaCriteriaId: 4,
      colors: {
        Psoasschatten1102Fill: "#FFFF00",
        Nierenkonturen1102Fill: "#00AA76",
        unteren_Leberrandes1102Fill: "#0000FF",
      },
      exam: "Abdomen p.a stehend",
    },
    {
      examid: 1102,
      qaAnatomyCriteria: "Komplette Darstellung des Zwerchfelles",
      qaCriteriaId: 5,
      colors: {
        Zwerchfelles1102Fill: "#7E20F7",
      },
      exam: "Abdomen p.a stehend",
    },
    {
      examid: 1102,
      qaAnatomyCriteria:
        "Je nach Körpergröße sollte das ganze Abdomen bis hin zur Symphyse dargestellt sein",
      qaCriteriaId: 6,
      colors: { Abdomen1102Fill: "#DCE0BD", Symphyse1102Fill: "#FF0037" },
      exam: "Abdomen p.a stehend",
    },
  ],
};

export const QaApiResponse1103 = {
  data: [
    {
      examid: 1103,
      qaAnatomyCriteria:
        "Komplette Darstellung von Symphyse und Zwerchfellkuppen",
      qaCriteriaId: 8,
      colors: {
        Symphyse1103Fill: "#69b42f",
        Zwerchfellkuppen1103Fill: "#7E20F7",
        
      },
      exam: "Abdomen in linksseitenlage a.p liegend",
    },
    {
      examid: 1103,
      qaAnatomyCriteria: "Einen gut sichtbaren rechten Sinus phrenico-costalis",
      qaCriteriaId: 9,
      colors: {
        Sinus_phrenico_costalis1103Fill: "#FF0037",
      },
      exam: "Abdomen in linksseitenlage a.p liegend",
    },
    {
      examid: 1103,
      qaAnatomyCriteria: "Evtl. unter dem rechten Zwerchfell freie Luft",
      qaCriteriaId: 10,
      colors: {
        Zwerchfell_freieLuft1103Fill: "#09AAE3",
      },
      exam: "Abdomen in linksseitenlage a.p liegend",
    },
  ],
};

export const QaApiResponse1104 = {
  data: [
    {
      examid: 1104,
      qaAnatomyCriteria:
        "Komplette Darstellung von Symphyse und Zwerchfellkuppen",
      qaCriteriaId: 11,
      colors: {
        Symphyse1104Fill: "#69b42f",
        Zwerchfellkuppen1104Fill: "#7E20F7",
        
      },
      exam: "Abdomen in linksseitenlage p.a liegend",
    },
    {
      examid: 1104,
      qaAnatomyCriteria: "Einen gut sichtbaren rechten Sinus phrenico-costalis",
      qaCriteriaId: 12,
      colors: {
        Sinus_phrenico_costalis1104Fill: "#FF0037",
      },
      exam: "",
    },
    {
      examid: 1104,
      qaAnatomyCriteria: "Evtl. unter dem rechten Zwerchfell freie Luft",
      qaCriteriaId: 13,
      colors: {
        Zwerchfell_freieLuft1104Fill: "#09AAE3",
      },
      exam: "",
    },
  ],
};

export const QaApiResponse1201 = {
  data: [
    {
      examid: 1201,
      qaAnatomyCriteria:
        "Komplette und symmetrische Darstellung des Beckens inkl. der Hüftgelenke",
      qaCriteriaId: 14,
      colors: {
        beckenFill: "#D3D3D3",
        HuftgelenkeFill: "#0000FF",
      },
      altColors:{
        beckenFill: "#D3D3D3",
        ileosacral_jointFill: "#FF0000",
        sacrumFill: "#FFFF00",
        processus_spinosusFill: "#650A72",
        HuftgelenkeFill: "#0000FF",
      },
      exam: "Becken v.d stehend",
    },
    {
      examid: 1201,
      qaAnatomyCriteria:
        "Schenkelhälse beider Oberschenkel müssen frei dargestellt sein",
      qaCriteriaId: 15,
      colors: {
        schenkelhalseFill: "#48EF3F",
      },
      exam: "",
    },
    {
      examid: 1201,
      qaAnatomyCriteria: "Trochanter minor ist knapp erkennbar",
      qaCriteriaId: 16,
      colors: {
        Trochanter_minorFill: "#E94190",
      },
      exam: "",
    },
    {
      examid: 1201,
      qaAnatomyCriteria: "Konturen der Iliosakralgelenke erkennbar",
      qaCriteriaId: 17,
      colors: {
        ileosacral_jointFill: "#FF0000",
      },
      exam: "",
    },
    {
      examid: 1201,
      qaAnatomyCriteria:
        "allgemein symmetrisch Foramen obturatum (gleich groß)",
      qaCriteriaId: 18,
      colors: {
        foramen_obturatumFill: "#09AAE3",
      },
      exam: "",
    },
    {
      examid: 1201,
      qaAnatomyCriteria:
        "Sacrum und Processus spinosus in einer Linie mit der Symphyse",
      qaCriteriaId: 19,
      colors: {
        sacrumFill: "#FFFF00",
        processus_spinosusFill: "#650A72",
        symphyseFill: "#128476",
      },
      exam: "",
    },
  ],
};

export const QaApiResponse1203 = {
  data: [
    {
      examid: 1203,
      qaAnatomyCriteria:
        "Komplette und symmetrische Darstellung des Beckens inkl. der Hüftgelenke",
      qaCriteriaId: 20,
      colors: {
        becken1203Fill: "#D3D3D3",
        Huftgelenke1203Fill: "#0000FF",
      },
      altColors:{
        becken1203Fill: "#D3D3D3",
        ileosacral_joint1203Fill: "#FF0000",
        sacrum1203Fill: "#FFFF00",
        processus_spinosus1203Fill: "#650A72",
        Huftgelenke1203Fill: "#0000FF",
      },
      exam: "Becken v.d stehend",
    },
    {
      examid: 1203,
      qaAnatomyCriteria:
        "Schenkelhälse beider Oberschenkel müssen frei dargestellt sein",
      qaCriteriaId: 21,
      colors: {
        schenkelhalse1203Fill: "#48EF3F",
      },
      exam: "",
    },
    {
      examid: 1203,
      qaAnatomyCriteria: "Trochanter minor ist knapp erkennbar",
      qaCriteriaId: 22,
      colors: {
        Trochanter_minor1203Fill: "#E94190",
      },
      exam: "",
    },
    {
      examid: 1203,
      qaAnatomyCriteria: "Konturen der Iliosakralgelenke erkennbar",
      qaCriteriaId: 23,
      colors: {
        ileosacral_joint1203Fill: "#FF0000",
      },
      exam: "",
    },
    {
      examid: 1203,
      qaAnatomyCriteria:
        "allgemein symmetrisch Foramen obturatum (gleich groß)",
      qaCriteriaId: 24,
      colors: {
        foramen_obturatum1203Fill: "#09AAE3",
      },
      exam: "",
    },
    {
      examid: 1203,
      qaAnatomyCriteria:
        "Sacrum und Processus spinosus in einer Linie mit der Symphyse",
      qaCriteriaId: 25,
      colors: {
        sacrum1203Fill: "#FFFF00",
        processus_spinosus1203Fill: "#650A72",
        symphyse1203Fill: "#128476",
      },
      exam: "",
    },
  ],
};

export const QaApiResponse1204 = {
  data: [
    {
      examid: 1204,
      qaAnatomyCriteria: "Vollständige Darstellung beider ISG",
      qaCriteriaId: 26,
      colors: {
        ISG1204Fill: "#F72020",
      },
      exam: "Iliosakralgelenke v.d liegend",
    },
  ],
};

export const QaApiResponse1205 = {
  data: [
    {
      examid: 1205,
      qaAnatomyCriteria: "Vollständige symmetrische Darstellung der Symphyse",
      qaCriteriaId: 27,
      colors: {
        Symphyse1205Fill: "#e2cf47",
      },
      exam: "Symphyse d.v liegend",
    },
    {
      examid: 1205,
      qaAnatomyCriteria: "Foramen Obturatum stellen sich beide gleich dar",
      qaCriteriaId: 28,
      colors: {
        Foramen_Obturatum1205Fill: "#ea8ce5",
      },
      exam: "",
    },
  ],
};

export const QaApiResponse1206 = {
  data: [
    {
      examid: 1206,
      qaAnatomyCriteria:
        "Komplette Abbildung des filmfernen Iliosacralgelenkes, das als Spalt dargestellt wird",
      qaCriteriaId: 29,
      colors: {
        ISG1206Fill: "#F72020",
      },
      exam: "Linkes Iliosakralgelenk v.d.obl liegend",
    },
  ],
};

export const QaApiResponse1207 = {
  data: [
    {
      examid: 1207,
      qaAnatomyCriteria:
        "Komplette Abbildung des filmfernen Iliosacralgelenkes, das als Spalt dargestellt wird",
      qaCriteriaId: 31,
      colors: {
        ISG1207Fill: "#F72020",
      },
      exam: "Rechtes Iliosakralgelenk v.d.obl liegend",
    },
  ],
};

export const QaApiResponse1208 = {
  data: [
    {
      examid: 1208,
      qaAnatomyCriteria:
        "Gute Beurteilbarkeit des Hüftgelenkspaltes, hinteren Pfannerandes und der Darmbeinschaufel",
      qaCriteriaId: 33,
      colors: {
        Hipjointspace1208Fill: "#F72020",
        Pfannerande1208Fill: "#5FB265",
        Darmbeinschaufel1208Fill: "#7E20F7",
      },
      exam: "Linkes Becken ALA nach Judet v.d.obl liegend",
    },
  ],
};

export const QaApiResponse1209 = {
  data: [
    {
      examid: 1209,
      qaAnatomyCriteria:
        "Gute Beurteilbarkeit des Hüftgelenkspaltes, hinteren Pfannerandes und der Darmbeinschaufel",
      qaCriteriaId: 34,
      colors: {
        Hipjointspace1209Fill: "#F72020",
        Pfannerande1209Fill: "#5FB265",
        Darmbeinschaufel1209Fill: "#7E20F7",
      },
      exam: "Rechtes Becken ALA nach Judet v.d.obl liegend",
    },
  ],
};

export const QaApiResponse1301 = {
  data: [
    {
      examid: 1301,
      qaAnatomyCriteria:
        "Darstellung des vollständigen Daumens vom Daumengrundgelenk über Daumengrund- und -endglied bis zur Daumenspitze sowie des Weichteilmantels",
      qaCriteriaId: 35,
      colors: {
        basejoint1301Fill: "#F72020",
        basephalanx1301Fill: "#2020F7",
        endphalanx1301Fill: "#F7ED20",
        fingertip1301Fill: "#7E20F7",
        softpart1301Fill: "#6BF720",
      },
      exam: "Linker Daumen (1.Finger) d.p sitzend",
    },
  ],
};

export const QaApiResponse1302 = {
  data: [
    {
      examid: 1302,
      qaAnatomyCriteria:
        "Darstellung des vollständigen Daumens vom Daumengrundgelenk über Daumengrund- und -endglied bis zur Daumenspitze sowie des Weichteilmantels",
      qaCriteriaId: 36,
      colors: {
        basejoint1302Fill: "#F72020",
        basephalanx1302Fill: "#2020F7",
        endphalanx1302Fill: "#F7ED20",
        fingertip1302Fill: "#7E20F7",
        softpart1302Fill: "#6BF720",
      },
      exam: "Rechter Daumen (1.Finger) d.p sitzend",
    },
  ],
};

export const QaApiResponse1303 = {
  data: [
    {
      examid: 1303,
      qaAnatomyCriteria:
        "Darstellung des vollständigen Daumens vom Daumengrundgelenk über Daumengrund- und -endglied bis zur Daumenspitze sowie des Weichteilmantels",
      qaCriteriaId: 37,
      colors: {
        basejoint1303Fill: "#F72020",
        basephalanx1303Fill: "#2020F7",
        endphalanx1303Fill: "#F7ED20",
        fingertip1303Fill: "#7E20F7",
        softpart1303Fill: "#6BF720",
      },
      exam: "Linker Daumen (1.Finger) p.d sitzend",
    },
  ],
};

export const QaApiResponse1304 = {
  data: [
    {
      examid: 1304,
      qaAnatomyCriteria:
        "Darstellung des vollständigen Daumens vom Daumengrundgelenk über Daumengrund- und -endglied bis zur Daumenspitze sowie des Weichteilmantels",
      qaCriteriaId: 38,
      colors: {
        basejoint1304Fill: "#F72020",
        basephalanx1304Fill: "#2020F7",
        endphalanx1304Fill: "#F7ED20",
        fingertip1304Fill: "#7E20F7",
        softpart1304Fill: "#6BF720",
      },
      exam: "Rechter Daumen (1.Finger) p.d sitzend",
    },
  ],
};

export const QaApiResponse1305 = {
  data: [
    {
      examid: 1305,
      qaAnatomyCriteria:
        "Vollständige Abbildung des Daumens vom Daumengrundgelenk bis hin zur Daumenspitze inklusive des unteren Bereiches des ersten Mittelhandknochens",
      qaCriteriaId: 39,
      colors: {
        
        Daumengrundgelenk1305Fill: "#5FB265",
        fingertip1305Fill: "#F72020",
        erstenMHK1305Fill: "#1E2DBA",
      },
      exam: "Linker Daumen (1.Finger) lat sitzend",
    },
  ],
};

export const QaApiResponse1306 = {
  data: [
    {
      examid: 1306,
      qaAnatomyCriteria:
        "Vollständige Abbildung des Daumens vom Daumengrundgelenk bis hin zur Daumenspitze inklusive des unteren Bereiches des ersten Mittelhandknochens",
      qaCriteriaId: 40,
      colors: {
        
        Daumengrundgelenk1306Fill: "#5FB265",
        fingertip1306Fill: "#F72020",
        erstenMHK1306Fill: "#1E2DBA",
      },
      exam: "Rechter Daumen (1.Finger) lat sitzend",
    },
  ],
};
export const QaApiResponse1307 = {
  data: [
    {
      examid: 1307,
      qaAnatomyCriteria:
        "Exakte Abbildung des Daumengrundgelenks in dorsopalmarer Richtung",
      qaCriteriaId: 41,
      colors: {
        daumengrundgelenk1307fill: "#E7237A",
      },
      exam: "Linkes Daumengrundgelenk d.p sitzend",
    },
  ],
};

export const QaApiResponse1308 = {
  data: [
    {
      examid: 1308,
      qaAnatomyCriteria:
        "Exakte Abbildung des Daumengrundgelenks in dorsopalmarer Richtung",
      qaCriteriaId: 42,
      colors: {
        daumengrundgelenk1308fill: "#E7237A",
      },
      exam: "Rechtes Daumengrundgelenk d.p sitzend",
    },
  ],
};

export const QaApiResponse1309 = {
  data: [
    {
      examid: 1309,
      qaAnatomyCriteria:
        "Exakte Abbildung des Daumengrundgelenks in dorsopalmarer Richtung",
      qaCriteriaId: 43,
      colors: {
        daumengrundgelenk1309fill: "#E7237A",
      },
      exam: "Linkes Daumengrundgelenk lat sitzend",
    },
  ],
};

export const QaApiResponse1310 = {
  data: [
    {
      examid: 1310,
      qaAnatomyCriteria:
        "Exakte Abbildung des Daumengrundgelenks in dorsopalmarer Richtung",
      qaCriteriaId: 44,
      colors: {
        daumengrundgelenk1310fill: "#E7237A",
      },
      exam: "Rechtes Daumengrundgelenk lat sitzend",
    },
  ],
};
export const QaApiResponse1311 = {
  data: [
    {
      examid: 1311,
      qaAnatomyCriteria: "Exakte Abbildung des Daumensattelgelenks",
      qaCriteriaId: 45,
      colors: {
        daumensattelgelenk1311fill: "#E7237A",
      },
      exam: "Linkes Daumensattelgelenk d.p sitzend",
    },
  ],
};

export const QaApiResponse1312 = {
  data: [
    {
      examid: 1312,
      qaAnatomyCriteria: "Exakte Abbildung des Daumensattelgelenks",
      qaCriteriaId: 46,
      colors: {
        daumensattelgelenk1312fill: "#E7237A",
      },
      exam: "Rechtes Daumensattelgelenk d.p sitzend",
    },
  ],
};
export const QaApiResponse1313 = {
  data: [
    {
      examid: 1313,
      qaAnatomyCriteria: "Exakte Abbildung des Daumensattelgelenks",
      qaCriteriaId: 47,
      colors: {
        daumensattelgelenk1313fill: "#E7237A",
      },
      exam: "Linkes Daumensattelgelenk lat sitzend",
    },
  ],
};
export const QaApiResponse1314 = {
  data: [
    {
      examid: 1314,
      qaAnatomyCriteria: "Exakte Abbildung des Daumensattelgelenks",
      qaCriteriaId: 48,
      colors: {
        daumensattelgelenk1314fill: "#E7237A",
      },
      exam: "Rechtes Daumensattelgelenk lat sitzend",
    },
  ],
};
export const QaApiResponse1315 = {
  data: [
    {
      examid: 1315,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Endglied",
      qaCriteriaId: 49,
      colors: {
        
        grundgelenk1315fill: "#E7237A",
        endglied1315fill: "#0639F4",
      },
      exam: "Linker 2.Finger d.p sitzend",
    },
  ],
};
export const QaApiResponse1316 = {
  data: [
    {
      examid: 1316,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Endglied",
      qaCriteriaId: 50,
      colors: {
       
        grundgelenk1316fill: "#E7237A",
        endglied1316fill: "#0639F4",
      },
      exam: "Rechter 2.Finger d.p sitzend",
    },
  ],
};

export const QaApiResponse1317 = {
  data: [
    {
      examid: 1317,
      qaAnatomyCriteria:
        "Es muss der Finger möglichst  von dem Fingergrundgelenk bis zur Fingerspitze dargestellt sein",
      qaCriteriaId: 51,
      colors: {
       
        grundgelenk1317fill: "#E7237A",
        fingerspitze1317fill: "#0639F4",
      },
      exam: "Linker 2.Finger lat sitzend",
    },
  ],
};
export const QaApiResponse1318 = {
  data: [
    {
      examid: 1318,
      qaAnatomyCriteria:
        "Es muss der Finger möglichst  von dem Fingergrundgelenk bis zur Fingerspitze dargestellt sein",
      qaCriteriaId: 52,
      colors: {
        
        grundgelenk1318fill: "#E7237A",
        fingerspitze1318fill: "#0639F4",
      },
      exam: "Rechter 2.Finger lat sitzend",
    },
  ],
};

export const QaApiResponse1319 = {
  data: [
    {
      examid: 1319,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Fingerkuppe",
      qaCriteriaId: 53,
      colors: {
        
        Grundgelenk1319Fill: "#E7237A",
        Fingerkuppe1319Fill: "#0639F4",
      },
      exam: "Linker 3.Finger d.p sitzend",
    },
  ],
};
export const QaApiResponse1320 = {
  data: [
    {
      examid: 1320,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Fingerkuppe",
      qaCriteriaId: 54,
      colors: {
        
        Grundgelenk1320Fill: "#E7237A",
        Fingerkuppe1320Fill: "#0639F4",
      },
      exam: "Rechter 3.Finger d.p sitzend",
    },
  ],
};

export const QaApiResponse1321 = {
  data: [
    {
      examid: 1321,
      qaAnatomyCriteria:
        "Es muss der Finger von dem Fingergrundgelenk bis zur Fingerspitze dargestellt sein",
      qaCriteriaId: 55,
      colors: {
        
        grundgelenk1321fill: "#E7237A",
        fingerspitze1321fill: "#0639F4",
      },
      exam: "Linker 3.Finger lat sitzend",
    },
    {
      examid: 1321,
      qaAnatomyCriteria:
        "Die Darstellung muss streng lateral erfolgen, die Gelenkspalte müssen frei dargestellt sein",
      qaCriteriaId: 56,
      colors: {
        gelenkspalte1321fill: "#6BF720",
        grundgelenk1321fill: "#6BF720",
      },
      altColors:{
        gelenkspalte1321fill: "#6BF720",
        grundgelenk1321fill: "#E7237A",
      },
      exam: "",
    },
    {
      examid: 1321,
      qaAnatomyCriteria: "Grundgelenk muss beurteilbar sein",
      qaCriteriaId: 57,
      colors: {
        grundgelenk1321fill: "#E7237A",
      },

      exam: "",
    },
  ],
};

export const QaApiResponse1322 = {
  data: [
    {
      examid: 1322,
      qaAnatomyCriteria:
        "Es muss der Finger von dem Fingergrundgelenk bis zur Fingerspitze dargestellt sein",
      qaCriteriaId: 58,
      colors: {
        
        grundgelenk1322fill: "#E7237A",
        fingerspitze1322fill: "#0639F4",
      },
      exam: "Rechter 3.Finger lat sitzend",
    },
    {
      examid: 1322,
      qaAnatomyCriteria:
        "Die Darstellung muss streng lateral erfolgen, die Gelenkspalte müssen frei dargestellt sein",
      qaCriteriaId: 59,
      colors: {
        gelenkspalte1322fill: "#6BF720",
        grundgelenk1322fill: "#6BF720",
      },
      altColors:{
        gelenkspalte1322fill: "#6BF720",
        grundgelenk1322fill: "#E7237A",
      },
      exam: "",
    },
    {
      examid: 1322,
      qaAnatomyCriteria: "Grundgelenk muss beurteilbar sein",
      qaCriteriaId: 60,
      colors: {
        grundgelenk1322fill: "#E7237A",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1323 = {
  data: [
    {
      examid: 1323,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Fingerkuppe",
      qaCriteriaId: 61,
      colors: {
        
        Grundgelenk1323Fill: "#E7237A",
        Fingerkuppe1323Fill: "#0639F4",
      },
      exam: "Linker 4.Finger d.p sitzend",
    },
  ],
};
export const QaApiResponse1324 = {
  data: [
    {
      examid: 1324,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Fingerkuppe",
      qaCriteriaId: 62,
      colors: {
        
        Grundgelenk1324Fill: "#E7237A",
        Fingerkuppe1324Fill: "#0639F4",
      },
      exam: "Rechter 4.Finger d.p sitzend",
    },
  ],
};
export const QaApiResponse1325 = {
  data: [
    {
      examid: 1325,
      qaAnatomyCriteria:
        "Es muss der Finger von dem Fingergrundgelenk bis zur Fingerspitze dargestellt sein",
      qaCriteriaId: 63,
      colors: {
        
        Grundgelenk1325Fill: "#6BF720",
        Fingerkuppe1325Fill: "#E7237A",
      },
      exam: "Linker 4.Finger lat sitzend",
    },
    {
      examid: 1325,
      qaAnatomyCriteria:
        "Die Darstellung muss streng lateral erfolgen, die Gelenkspalte müssen frei dargestellt sein",
      qaCriteriaId: 64,
      colors: {
        Gelenkspalte1325Fill: "#0639F4",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1326 = {
  data: [
    {
      examid: 1326,
      qaAnatomyCriteria:
        "Es muss der Finger von dem Fingergrundgelenk bis zur Fingerspitze dargestellt sein",
      qaCriteriaId: 65,
      colors: {
        
        Grundgelenk1326Fill: "#6BF720",
        Fingerkuppe1326Fill: "#E7237A",
      },
      exam: "Rechter 4.Finger lat sitzend",
    },
    {
      examid: 1326,
      qaAnatomyCriteria:
        "Die Darstellung muss streng lateral erfolgen, die Gelenkspalte müssen frei dargestellt sein",
      qaCriteriaId: 66,
      colors: {
        Gelenkspalte1326Fill: "#0639F4",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1327 = {
  data: [
    {
      examid: 1327,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Fingerkuppe",
      qaCriteriaId: 67,
      colors: {
        
        Grundgelenk1327Fill: "#E7237A",
        Fingerkuppe1327Fill: "#0639F4",
      },
      exam: "Linker 5.Finger d.p sitzend",
    },
    {
      examid: 1327,
      qaAnatomyCriteria:
        "Das Fingerendgelenk muss überlagerungsfrei dargestellt sein",
      qaCriteriaId: 68,
      colors: {
        Fingerendgelenk1327Fill: "#5FB265",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1328 = {
  data: [
    {
      examid: 1328,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Fingerkuppe",
      qaCriteriaId: 69,
      colors: {
        
        Grundgelenk1328Fill: "#E7237A",
        Fingerkuppe1328Fill: "#0639F4",
      },
      exam: "Rechter 5.Finger d.p sitzend",
    },
    {
      examid: 1328,
      qaAnatomyCriteria:
        "Das Fingerendgelenk muss überlagerungsfrei dargestellt sein",
      qaCriteriaId: 70,
      colors: {
        Fingerendgelenk1328Fill: "#5FB265",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1329 = {
  data: [
    {
      examid: 1329,
      qaAnatomyCriteria:
        "Es muss der Finger von dem Fingergrundgelenk bis zur Fingerspitze dargestellt sein",
      qaCriteriaId: 71,
      colors: {
        
        grundgelenk1329fill: "#E7237A",
        fingerkuppe1329fill: "#0639F4",
      },
      exam: "Linker 5.Finger lat sitzend",
    },
    {
      examid: 1329,
      qaAnatomyCriteria: "Alle Gelenkspalten müssen gut einsehbar sein",
      qaCriteriaId: 72,
      colors: {
        gelenkspalte1329fill: "#5FB265",
        grundgelenk1329fill: "#5FB265",
      },
      altColors:{
        gelenkspalte1329fill: "#5FB265",
        grundgelenk1329fill: "#E7237A",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1330 = {
  data: [
    {
      examid: 1330,
      qaAnatomyCriteria:
        "Es muss der Finger von dem Fingergrundgelenk bis zur Fingerspitze dargestellt sein",
      qaCriteriaId: 73,
      colors: {
        
        grundgelenk1330fill: "#E7237A",
        fingerkuppe1330fill: "#0639F4",
      },
      exam: "Rechter 5.Finger lat sitzend",
    },
    {
      examid: 1330,
      qaAnatomyCriteria: "Alle Gelenkspalten müssen gut einsehbar sein",
      qaCriteriaId: 74,
      colors: {
        gelenkspalte1330fill: "#5FB265",
        grundgelenk1330fill: "#5FB265",
      },
      altColors:{
        gelenkspalte1330fill: "#5FB265",
        grundgelenk1330fill: "#E7237A",
      },
      exam: "Rechter 5.Finger lat sitzend",
    },
  ],
};
export const QaApiResponse1332 = {
  data: [
    {
      examid: 1332,
      qaAnatomyCriteria:
        "Visuell scharfe, überlagerungsfreie Darstellung der gelenknahen Knochen Konturen",
      qaCriteriaId: 78,
      colors: { gelenknahen1332KnochenFilll: "#128476" },
      exam: "Rechte Mittelhand d.p sitzend",
    },
    {
      examid: 1332,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen abhängig von der Fragestellung",
      qaCriteriaId: 79,
      colors: {
        weichteilen1332Fill: "#F088B6",
      },
      exam: "Rechte Mittelhand d.p sitzend",
    },
    {
      examid: 1332,
      qaAnatomyCriteria: "Überlagerungsfreie Darstellung aller 5 MHK",
      qaCriteriaId: 80,
      colors: {
        MHK1332Fill: "#F7ED20",
      },
      exam: "Rechte Mittelhand d.p sitzend",
    },
  ],
};
export const QaApiResponse1333 = {
  data: [
    {
      examid: 1333,
      qaAnatomyCriteria:
        "Scharfe überlagerungsfreie Darstellung der Knochenkonturen",
      qaCriteriaId: 81,
      colors: {
        MHK1333Fill: "#F088B6",
      },
      exam: "Linke Mittelhand d.p.obl sitzend",
    },
    {
      examid: 1333,
      qaAnatomyCriteria:
        "Darstellung der angrenzenden Weichteile je nach Fragestellung",
      qaCriteriaId: 82,
      colors: {
        weichteile1333Fill: "#0639F4",
      },
      exam: "Linke Mittelhand d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1334 = {
  data: [
    {
      examid: 1334,
      qaAnatomyCriteria:
        "Scharfe überlagerungsfreie Darstellung der Knochenkonturen",
      qaCriteriaId: 83,
      colors: {
        MHK1334Fill: "#F088B6",
      },
      exam: "Rechte Mittelhand d.p.obl sitzend",
    },
    {
      examid: 1334,
      qaAnatomyCriteria:
        "Darstellung der angrenzenden Weichteile je nach Fragestellung",
      qaCriteriaId: 84,
      colors: {
        weichteile1334Fill: "#0639F4",
      },
      exam: "Rechte Mittelhand d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1335 = {
  data: [
    {
      examid: 1335,
      qaAnatomyCriteria: "Mittelhandknochen dürfen sich überlagern",
      qaCriteriaId: 85,
      colors: {
        mhk1335fill: "#48ffc7",
      },
      exam: "Linke Mittelhand lat sitzend",
    },
    {
      examid: 1335,
      qaAnatomyCriteria:
        "Weichteile gut erkennbar, da es sich meist um Fremdkörpersuche handelt ist hier die Lage entscheidend",
      qaCriteriaId: 86,
      colors: {
        weichteile1335fill: "#f753ff",
      },
      exam: "Linke Mittelhand lat sitzend",
    },
    {
      examid: 1335,
      qaAnatomyCriteria: "Daumen je nach Fragestellung lagern",
      qaCriteriaId: 87,
      colors: {thumb1335Fill: "#F7ED20"},
      exam: "Linke Mittelhand lat sitzend",
    },
  ],
};
export const QaApiResponse1336 = {
  data: [
    {
      examid: 1336,
      qaAnatomyCriteria: "Mittelhandknochen dürfen sich überlagern",
      qaCriteriaId: 88,
      colors: {
        mhk1336fill: "#48ffc7",
      },
      exam: "Rechte Mittelhand lat sitzend",
    },
    {
      examid: 1336,
      qaAnatomyCriteria:
        "Weichteile gut erkennbar, da es sich meist um Fremdkörpersuche handelt ist hier die Lage entscheidend",
      qaCriteriaId: 89,
      colors: {
        weichteile1336fill: "#f753ff",
      },
      exam: "Mittelhandknochen dürfen sich überlagern",
    },
    {
      examid: 1336,
      qaAnatomyCriteria: "Daumen je nach Fragestellung lagern",
      qaCriteriaId: 90,
      colors: {thumb1336Fill: "#F7ED20"},
      exam: "Mittelhandknochen dürfen sich überlagern",
    },
  ],
};
export const QaApiResponse1337 = {
  data: [
    {
      examid: 1337,
      qaAnatomyCriteria:
        "Komplette Darstellung der Hand von den Fingerkuppen bis zum Handgelenk",
      qaCriteriaId: 91,
      colors: {
        FullHand1337Fill: "#DBDBB9",
        Fingerkuppe1337Fill: "#0639F4",
        Handgelenk1337Fill: "#E7237A",
      },
      exam: "Linke Hand d.p sitzend",
    },
  ],
};
export const QaApiResponse1338 = {
  data: [
    {
      examid: 1338,
      qaAnatomyCriteria:
        "Komplette Darstellung der Hand von den Fingerkuppen bis zum Handgelenk",
      qaCriteriaId: 92,
      colors: {
        FullHand1338Fill: "#DBDBB9",
        Fingerkuppe1338Fill: "#0639F4",
        Handgelenk1338Fill: "#E7237A",
      },
      exam: "Rechte Hand d.p sitzend",
    },
  ],
};
export const QaApiResponse1339 = {
  data: [
    {
      examid: 1339,
      qaAnatomyCriteria:
        "Abbildung der kompletten Hand mit schräger Darstellung der Mittelhandknochen und des Handgelenkes ohne Überlagerungen",
      qaCriteriaId: 93,
      colors: {
        FullHand1339Fill: "#FFFF00",
        HWK1339Fill: "#FFFF00",
        MittelHand1339Fill: "#0000FF",
        Handgelenk1339Fill: "#507963",
      },
      altColors:{
        FullHand1339Fill: "#FFFF00",
        HWK1339Fill: "#e3a6cb",
        MittelHand1339Fill: "#0000FF",
        Handgelenk1339Fill: "#507963",
      },
      exam: "Linke Hand d.p.obl sitzend",
    },
    {
      examid: 1339,
      qaAnatomyCriteria:
        "Die Mittelhandknochen 4 und 5 und die Handwurzelknochen dürfen sich leicht an der Basis überlagern",
      qaCriteriaId: 94,
      colors: {
        MHK4_5_1339Fill: "#e51e25",
        HWK1339Fill: "#e3a6cb",
      },
      exam: "Linke Hand d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1340 = {
  data: [
    {
      examid: 1340,
      qaAnatomyCriteria:
        "Abbildung der kompletten Hand mit schräger Darstellung der Mittelhandknochen und des Handgelenkes ohne Überlagerungen",
      qaCriteriaId: 95,
      colors: {
        FullHand1340Fill: "#FFFF00",
        HWK1340Fill: "#FFFF00",
        MittelHand1340Fill: "#0000FF",
        Handgelenk1340Fill: "#507963",
      },
      altColors:{
        FullHand1340Fill: "#FFFF00",
        HWK1340Fill: "#e3a6cb",
        MittelHand1340Fill: "#0000FF",
        Handgelenk1340Fill: "#507963",
      },
      exam: "Rechte Hand d.p.obl sitzend",
    },
    {
      examid: 1340,
      qaAnatomyCriteria:
        "Die Mittelhandknochen 4 und 5 und die Handwurzelknochen dürfen sich leicht an der Basis überlagern",
      qaCriteriaId: 96,
      colors: {
        MHK4_5_1340Fill: "#e51e25",
        HWK1340Fill: "#e3a6cb",
      },
      exam: "Rechte Hand d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1341 = {
  data: [
    {
      examid: 1341,
      qaAnatomyCriteria:
        "Mittelhandknochen und Finger müssen deckungsgleich übereinander liegen",
      qaCriteriaId: 97,
      colors: {
        MHK1341Fill: "#F7ED20",
        Finger1341Fill: "#E7237A",
      },
      exam: "Linke Hand lat sitzend",
    },
    {
      examid: 1341,
      qaAnatomyCriteria: "Weichteile müssen gut beurteilbar sein",
      qaCriteriaId: 98,
      colors: {
        weichteile1341Fill: "#5FB265",
      },
      exam: "Linke Hand lat sitzend",
    },
  ],
};
export const QaApiResponse1342 = {
  data: [
    {
      examid: 1342,
      qaAnatomyCriteria:
        "Mittelhandknochen und Finger müssen deckungsgleich übereinander liegen",
      qaCriteriaId: 99,
      colors: {
        MHK1342Fill: "#F7ED20",
        Finger1342Fill: "#E7237A",
      },
      exam: "Rechte Hand lat sitzend",
    },
    {
      examid: 1342,
      qaAnatomyCriteria: "Weichteile müssen gut beurteilbar sein",
      qaCriteriaId: 100,
      colors: {
        weichteile1342Fill: "#5FB265",
      },
      exam: "Rechte Hand lat sitzend",
    },
  ],
};
export const QaApiResponse1343 = {
  data: [
    {
      examid: 1343,
      qaAnatomyCriteria: "Hand streng lateral",
      qaCriteriaId: 101,
      colors: {
        Hand1343Fill: "#F7ED20",
      },
      exam: "Linke Hand gespreizt lat sitzend",
    },
    {
      examid: 1343,
      qaAnatomyCriteria: "Radius und Ulna überdecken sich",
      qaCriteriaId: 102,
      colors: {
        Radius1343Fill: "#5FB265",
        Ulna1343Fill: "#E7237A",
      },
      exam: "Linke Hand gespreizt lat sitzend",
    },
    {
      examid: 1343,
      qaAnatomyCriteria: "Gelenkspalt aller Finger deutlich sichtbar",
      qaCriteriaId: 103,
      colors: {
        Gelenkspalt1343Fill: "#2020F7",
      },
      exam: "Linke Hand gespreizt lat sitzend",
    },
  ],
};
export const QaApiResponse1344 = {
  data: [
    {
      examid: 1344,
      qaAnatomyCriteria: "Hand streng lateral",
      qaCriteriaId: 104,
      colors: {
        Hand1344Fill: "#F7ED20",
      },
      exam: "Rechte Hand gespreizt lat sitzend",
    },
    {
      examid: 1345,
      qaAnatomyCriteria: "Radius und Ulna überdecken sich",
      qaCriteriaId: 105,
      colors: {
        Radius1344Fill: "#5FB265",
        Ulna1344Fill: "#E7237A",
      },
      exam: "Rechte Hand gespreizt lat sitzend",
    },
    {
      examid: 1345,
      qaAnatomyCriteria: "Gelenkspalt aller Finger deutlich sichtbar",
      qaCriteriaId: 106,
      colors: {
        Gelenkspalt1344Fill: "#2020F7",
      },
      exam: "Rechte Hand gespreizt lat sitzend",
    },
  ],
};
export const QaApiResponse1345 = {
  data: [
    {
      examid: 1345,
      qaAnatomyCriteria: "Unterarm möglichst eine Linie mit der Hand",
      qaCriteriaId: 107,
      colors: {
        Unterarm1345Fill: "#46c5f2",
        Hand1345Fill: "#F7ED20",
      },
      exam: "Beide Hände d.p sitzend",
    },
  ],
};
export const QaApiResponse1346 = {
  data: [
    {
      examid: 1346,
      qaAnatomyCriteria: "MHK und Finger dürfen sich nicht überlagern",
      qaCriteriaId: 108,
      colors: {
        MHK1346Fill: "#e3a6cb",
        Finger1346Fill: "#46c5f2",
      },
      exam: "Beide Hände d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1347 = {
  data: [
    {
      examid: 1347,
      qaAnatomyCriteria: "Gute Abbildung des Kahnbein (os scaphoideum)",
      qaCriteriaId: 109,
      colors: {
        Kahnbein1347Fill: "#2020F7",
      },
      exam: "Linkes Kahnbein Daumen raus d.p sitzend",
    },
  ],
};
export const QaApiResponse1348 = {
  data: [
    {
      examid: 1348,
      qaAnatomyCriteria: "Gute Abbildung des Kahnbein (os scaphoideum)",
      qaCriteriaId: 110,
      colors: {
        Kahnbein1348Fill: "#2020F7",
      },
      exam: "Rechtes Kahnbein Daumen raus d.p sitzend",
    },
  ],
};
export const QaApiResponse1349 = {
  data: [
    {
      examid: 1349,
      qaAnatomyCriteria: "Kahnbein (os scaphoideum) muss frei erkennbar sein",
      qaCriteriaId: 111,
      colors: {
        Kahnbein1349Fill: "#2020F7",
      },
      exam: "Linkes Kahnbein Daumen rein d.p sitzend",
    },
  ],
};
export const QaApiResponse1350 = {
  data: [
    {
      examid: 1350,
      qaAnatomyCriteria: "Kahnbein (os scaphoideum) muss frei erkennbar sein",
      qaCriteriaId: 112,
      colors: {
        Kahnbein1350Fill: "#2020F7",
      },
      exam: "Rechtes Kahnbein Daumen rein d.p sitzend",
    },
  ],
};
export const QaApiResponse1351 = {
  data: [
    {
      examid: 1351,
      qaAnatomyCriteria: "Vollständige Darstellung des Os Scaphoideum",
      qaCriteriaId: 113,
      colors: {
        Kahnbein1351Fill: "#2020F7",
      },
      exam: "Linkes Kahnbein d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1352 = {
  data: [
    {
      examid: 1352,
      qaAnatomyCriteria: "Vollständige Darstellung des Os Scaphoideum",
      qaCriteriaId: 114,
      colors: {
        Kahnbein1352Fill: "#2020F7",
      },
      exam: "Rechtes Kahnbein d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1353 = {
  data: [
    {
      examid: 1353,
      qaAnatomyCriteria:
        "Exakt seitliche Aufnahme, Radius und Ulna in Deckung mit Handwurzel eine Linie",
      qaCriteriaId: 115,
      colors: {
        Radius1353Fill: "#7a21d8",
        Ulna1353Fill: "#46c5f2",
        HWK1353Fill: "#F7ED20",
      },
      exam: "Linke Handwurzel lat sitzend",
    },
  ],
};
export const QaApiResponse1354 = {
  data: [
    {
      examid: 1354,
      qaAnatomyCriteria:
        "Exakt seitliche Aufnahme, Radius und Ulna in Deckung mit Handwurzel eine Linie",
      qaCriteriaId: 116,
      colors: {
        Radius1354Fill: "#7a21d8",
        Ulna1354Fill: "#46c5f2",
        HWK1354Fill: "#F7ED20",
      },
      exam: "Rechte Handwurzel lat sitzend",
    },
  ],
};
export const QaApiResponse1355 = {
  data: [
    {
      examid: 1355,
      qaAnatomyCriteria:
        "Os Tranpezium und Os Trapezoideum und Basis 1+2. MHK sind überlagerungsfrei",
      qaCriteriaId: 117,
      colors: {
        Ostranpezium1355Fill: "#F7ED20",
        Ostrapezoideum1355Fill: "#E7237A",
        Basis1355Fill: "#7a21d8",
        MHK1355Fill: "#46c5f2",
      },
      exam: "Linkes Os trapezium und Os trapezoideum p.d sitzend",
    },
  ],
};
export const QaApiResponse1356 = {
  data: [
    {
      examid: 1356,
      qaAnatomyCriteria:
        "Os Tranpezium und Os Trapezoideum und Basis 1+2. MHK sind überlagerungsfrei",
      qaCriteriaId: 118,
      colors: {
        Ostranpezium1356Fill: "#F7ED20",
        Ostrapezoideum1356Fill: "#E7237A",
        Basis1356Fill: "#7a21d8",
        MHK1356Fill: "#46c5f2",
      },
      exam: "Rechtes Os trapezium und Os trapezoideum p.d sitzend",
    },
  ],
};
export const QaApiResponse1357 = {
  data: [
    {
      examid: 1357,
      qaAnatomyCriteria:
        "Proximale Anteile der Mittelhand, die Handwurzelknochen komplett und Anteile distale Radius und Ulna abgebildet",
      qaCriteriaId: 119,
      colors: {
        MittelHand1357Fill: "#F7ED20",
        HWK1357Fill: "#E7237A",
        Radius1357Fill: "#0639F4",
        Ulna1357Fill: "#46c5f2",
      },
      exam: "Linkes Handgelenk d.p sitzend",
    },
  ],
};
export const QaApiResponse1358 = {
  data: [
    {
      examid: 1358,
      qaAnatomyCriteria:
        "Proximale Anteile der Mittelhand, die Handwurzelknochen komplett und Anteile distale Radius und Ulna abgebildet",
      qaCriteriaId: 120,
      colors: {
        MittelHand1358Fill: "#F7ED20",
        HWK1358Fill: "#E7237A",
        Radius1358Fill: "#0639F4",
        Ulna1358Fill: "#46c5f2",
      },
      exam: "Rechtes Handgelenk d.p sitzend",
    },
  ],
};
export const QaApiResponse1359 = {
  data: [
    {
      examid: 1359,
      qaAnatomyCriteria: "Ulnar und Radius müssen Deckungsgleich sein",
      qaCriteriaId: 121,
      colors: {
        Ulna1359Fill: "#F7ED20",
        Radius1359Fill: "#0639F4",
      },
      exam: "Linkes Handgelenk lat sitzend",
    },
  ],
};
export const QaApiResponse1360 = {
  data: [
    {
      examid: 1360,
      qaAnatomyCriteria: "Ulnar und Radius müssen Deckungsgleich sein",
      qaCriteriaId: 122,
      colors: {
        Ulna1360Fill: "#F7ED20",
        Radius1360Fill: "#0639F4",
      },
      exam: "Rechtes Handgelenk lat sitzend",
    },
  ],
};
export const QaApiResponse1361 = {
  data: [
    {
      examid: 1361,
      qaAnatomyCriteria:
        "Handgelenk schräg , distale Ulna und Radius sowie Handwurzelknochen dargestellt",
      qaCriteriaId: 123,
      colors: {
        Handgelenk1361Fill: "#71b62b",
        Ulna1361Fill: "#e3a6cb",
        Radius1361Fill: "#59c3e6",
        HWK1361Fill: "#d02c17",
      },
      exam: "Linkes Handgelenk d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1362 = {
  data: [
    {
      examid: 1362,
      qaAnatomyCriteria:
        "Handgelenk schräg , distale Ulna und Radius sowie Handwurzelknochen dargestellt",
      qaCriteriaId: 124,
      colors: {
        Handgelenk1362Fill: "#71b62b",
        Ulna1362Fill: "#e3a6cb",
        Radius1362Fill: "#59c3e6",
        HWK1362Fill: "#d02c17",
      },
      exam: "Rechtes Handgelenk d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1363 = {
  data: [
    {
      examid: 1363,
      qaAnatomyCriteria: "Darstellung des Karpaltunnels",
      qaCriteriaId: 125,
      colors: {
        Karpaltunnel1363Fill: "#71b62b",
      },
      exam: "Linker Karpaltunnel (Hand aufliegend) ax sitzend",
    },
  ],
};
export const QaApiResponse1364 = {
  data: [
    {
      examid: 1364,
      qaAnatomyCriteria: "Darstellung des Karpaltunnels",
      qaCriteriaId: 126,
      colors: {
        Karpaltunnel1364Fill: "#71b62b",
      },
      exam: "Rechter Karpaltunnel (Hand aufliegend) ax sitzend",
    },
  ],
};
export const QaApiResponse1365 = {
  data: [
    {
      examid: 1365,
      qaAnatomyCriteria: "Gut U förmige Darstellung des Karpaltunnels",
      qaCriteriaId: 127,
      colors: {
        Karpaltunnel1365Fill: "#71b62b",
      },
      exam: "Linker Karpaltunnel (Unterarm aufliegend) ax sitzend",
    },
    {
      examid: 1365,
      qaAnatomyCriteria: "Os Pisiforme gut erkennbar",
      qaCriteriaId: 128,
      colors: {
        Ospisiforme1365Fill: "#b91e18",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1366 = {
  data: [
    {
      examid: 1366,
      qaAnatomyCriteria: "Gut U förmige Darstellung des Karpaltunnels",
      qaCriteriaId: 129,
      colors: {
        Karpaltunnel1366Fill: "#71b62b",
      },
      exam: "Rechter Karpaltunnel (Unterarm aufliegend) ax sitzend",
    },
    {
      examid: 1366,
      qaAnatomyCriteria: "Os Pisiforme gut erkennbar",
      qaCriteriaId: 130,
      colors: {
        Ospisiforme1366Fill: "#b91e18",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1369 = {
  data: [
    {
      examid: 1369,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Fingerkuppe",
      qaCriteriaId: 131,
      colors: {
     
        Grundgelenk1369Fill: "#E7237A",
        Fingerkuppe1369Fill: "#0639F4",
      },
      exam: "Linker 2.Finger d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1370 = {
  data: [
    {
      examid: 1370,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Fingerkuppe",
      qaCriteriaId: 132,
      colors: {
    
        Grundgelenk1370Fill: "#E7237A",
        Fingerkuppe1370Fill: "#0639F4",
      },
      exam: "Rechter 2.Finger d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1371 = {
  data: [
    {
      examid: 1371,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Fingerkuppe",
      qaCriteriaId: 133,
      colors: {
     
        Grundgelenk1371Fill: "#E7237A",
        Fingerkuppe1371Fill: "#0639F4",
      },
      exam: "Linker 3.Finger d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1372 = {
  data: [
    {
      examid: 1372,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Fingerkuppe",
      qaCriteriaId: 134,
      colors: {
       
        Grundgelenk1372Fill: "#E7237A",
        Fingerkuppe1372Fill: "#0639F4",
      },
      exam: "Rechter 3.Finger d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1373 = {
  data: [
    {
      examid: 1373,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Fingerkuppe",
      qaCriteriaId: 135,
      colors: {
     
        Grundgelenk1373Fill: "#E7237A",
        Fingerkuppe1373Fill: "#0639F4",
      },
      exam: "Linker 4.Finger d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1374 = {
  data: [
    {
      examid: 1374,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Fingerkuppe",
      qaCriteriaId: 136,
      colors: {

        Grundgelenk1374Fill: "#E7237A",
        Fingerkuppe1374Fill: "#0639F4",
      },
      exam: "Rechter 4.Finger d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1375 = {
  data: [
    {
      examid: 1375,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Fingerkuppe",
      qaCriteriaId: 137,
      colors: {

        Grundgelenk1375Fill: "#E7237A",
        Fingerkuppe1375Fill: "#0639F4",
      },
      exam: "Linker 5.Finger d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1376 = {
  data: [
    {
      examid: 1376,
      qaAnatomyCriteria:
        "Komplette Darstellung des Fingers vom Grundgelenk bis zur Fingerkuppe",
      qaCriteriaId: 138,
      colors: {

        Grundgelenk1376Fill: "#E7237A",
        Fingerkuppe1376Fill: "#0639F4",
      },
      exam: "Rechter 5.Finger d.p.obl sitzend",
    },
  ],
};
export const QaApiResponse1377 = {
  data: [
    {
      examid: 1377,
      qaAnatomyCriteria:
        "Proximale Anteile der Mittelhand, die Handwurzelknochen komplett und Anteile distale Radius und Ulna abgebildet",
      qaCriteriaId: 139,
      colors: {
        Mittelhand1377Fill: "#71b62b",
        HWK1377Fill: "#d02c17",
        Radius1377Fill: "#e3a6cb",
        Ulna1377Fill: "#59c3e6",
      },
      exam: "beide Handgelenke d.p sitzend",
    },
    {
      examid: 1377,
      qaAnatomyCriteria: "Beide Unterarme laufen möglichst parallel",
      qaCriteriaId: 140,
      colors: {
        Radius1377Fill: "#e3a6cb",
        Ulna1377Fill: "#59c3e6",
      },
      exam: "beide Handgelenke d.p sitzend",
    },
  ],
};
export const QaApiResponse1378 = {
  data: [
    {
      examid: 1378,
      qaAnatomyCriteria:
        "Beide Unterarme müssen Deckungsgleich sein und laufen möglichst parallel",
      qaCriteriaId: 141,
      colors: {
        radius1378fill: "#e3a6cb",
      },
      exam: "beide Handgelenke lat sitzend",
    },
    {
      examid: 1378,
      qaAnatomyCriteria:
        "Mittelhandknochen, Handwurzelkochen und distaler Unterarm müssen abgebildet sein",
      qaCriteriaId: 143,
      colors: {
        mhk1378fill: "#71b62b",
        hwk1378fill: "#d02c17",
        distalunterarm1378fill: "#59c3e6",
      },
      exam: "beide Handgelenke lat sitzend",
    },
  ],
};
export const QaApiResponse1405 = {
  data: [
    {
      examid: 1405,
      qaAnatomyCriteria:
        "Großzehe in Grund- und Endphalanx möglichst überlagerungsfrei",
      qaCriteriaId: 156,
      colors: {
        Grundphalanx1405Fill: "#d02c17",
        Endphalanx1405Fill: "#59c3e6",
      },
      exam: "Linke Großzehe (1.Zehe) d.pl. obl sitzend",
    },
  ],
};
export const QaApiResponse1406 = {
  data: [
    {
      examid: 1406,
      qaAnatomyCriteria:
        "Großzehe in Grund- und Endphalanx möglichst überlagerungsfrei",
      qaCriteriaId: 157,
      colors: {
        Grundphalanx1406Fill: "#d02c17",
        Endphalanx1406Fill: "#59c3e6",
      },
      exam: "Rechte Großzehe (1.Zehe) d.pl. obl sitzend",
    },
  ],
};
export const QaApiResponse1414 = {
  data: [

    {
      examid: 1414,
      qaAnatomyCriteria: "Gelenke dürfen nicht überlagern",
      qaCriteriaId: 174,
      colors: {
        a1414_gelenkeFill: "#a83f90",
      },
      exam: "Rechte 3.Zehe d.pl. obl sitzend",
    },
    {
      examid: 1414,
      qaAnatomyCriteria: "Phalangen müssen gut voneinander getrennt sein",
      qaCriteriaId: 175,
      colors: {
        a1414_phalangenFill: "#15bae4",
      },
      exam: "Rechte 3.Zehe d.pl. obl sitzend",
    },
  ],
};
export const QaApiResponse1420 = {
  data: [

    {
      examid: 1420,
      qaAnatomyCriteria: "Phalangen müssen gut dargestellt sein",
      qaCriteriaId: 192,
      colors: {
        phalangen1420Fill: "#15bae4",
      },
      exam: "",
    },
    {
      examid: 1420,
      qaAnatomyCriteria: "Gelenke muss einsehbar sein",
      qaCriteriaId: 193,
      colors: {
        gelenke1420Fill: "#a83f90",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1421 = {
  data: [
    {
      examid: 1421,
      qaAnatomyCriteria: "Überlagerungsfreie Darstellung der Kleinzehe",
      qaCriteriaId: 194,
      colors: {
        kleinzeh1421Fill: "#2a97d4",
      },
      exam: "Linke 5.Zehe lat sitzend",
    },
  ],
};
export const QaApiResponse1422 = {
  data: [
    {
      examid: 1422,
      qaAnatomyCriteria: "Überlagerungsfreie Darstellung der Kleinzehe",
      qaCriteriaId: 195,
      colors: {
        kleinzeh1422Fill: "#2a97d4",
      },
      exam: "Rechte 5.Zehe lat sitzend",
    },
  ],
};
export const QaApiResponse1423 = {
  data: [

    {
      examid: 1423,
      qaAnatomyCriteria: "Gelenke dürfen nicht überlagern",
      qaCriteriaId: 197,
      colors: {
        gelenkeFill1423: "#c04599",
      },
      exam: "Linke 5.Zehe d.pl. obl sitzend",
    },
    {
      examid: 1423,
      qaAnatomyCriteria: "Phalangen müssen gut voneinander getrennt sein",
      qaCriteriaId: 198,
      colors: {
        phalangenFill1423: "#4eae33",
      },
      exam: "Linke 5.Zehe d.pl. obl sitzend",
    },
  ],
};
export const QaApiResponse1424 = {
  data: [
    {
      examid: 1424,
      qaAnatomyCriteria: "Gelenke dürfen nicht überlagern",
      qaCriteriaId: 200,
      colors: {
        gelenkeFill1424: "#c04599",
      },
      exam: "Rechte 5.Zehe d.pl. obl sitzend",
    },
    {
      examid: 1424,
      qaAnatomyCriteria: "Phalangen müssen gut voneinander getrennt sein",
      qaCriteriaId: 201,
      colors: {
        phalangenFill1424: "#4eae33",
      },
      exam: "Rechte 5.Zehe d.pl. obl sitzend",
    },
  ],
};
export const QaApiResponse1426 = {
  data: [
    {
      examid: 1426,
      qaAnatomyCriteria:
        "Überlagerungsfreie Darstellung Sesambeine und Mittelfußköpfchen",
      qaCriteriaId: 203,
      colors: {
        sesambeine1426Fill: "#ffed00",
        MetatarsalHead1426Fill: "#22a1dc",
      },
      exam: "Rechtes Sesambein (Großzehe) tang liegend",
    },
  ],
};
export const QaApiResponse1427 = {
  data: [
    {
      examid: 1427,
      qaAnatomyCriteria: "Gelenke sind frei abgebildet",
      qaCriteriaId: 204,
      colors: {
        gelenke1427Fill: "#a83f90",
      },
      exam: "Linker Vorfuß d.pl liegend",
    },
    {
      examid: 1427,
      qaAnatomyCriteria: "Phalangen sind gut voneinander getrennt",
      qaCriteriaId: 205,
      colors: {
        phalangen1427Fill: "#4eae33",
      },
      exam: "Linker Vorfuß d.pl liegend",
    },
  ],
};
export const QaApiResponse1428 = {
  data: [
    {
      examid: 1428,
      qaAnatomyCriteria: "Gelenke sind frei abgebildet",
      qaCriteriaId: 206,
      colors: {
        gelenke1428Fill: "#a83f90",
      },
      exam: "Rechter Vorfuß d.pl liegend",
    },
    {
      examid: 1428,
      qaAnatomyCriteria: "Phalangen sind gut voneinander getrennt",
      qaCriteriaId: 207,
      colors: {
        phalangen1428Fill: "#4eae33",
      },
      exam: "Rechter Vorfuß d.pl liegend",
    },
  ],
};
export const QaApiResponse1429 = {
  data: [
    {
      examid: 1429,
      qaAnatomyCriteria: "Phalangen überlagern sich nicht",
      qaCriteriaId: 208,
      colors: {
        phalangen1429Fill: "#15bae4",
      },
      exam: "Linker Vorfuss d.pl. obl liegend",
    },
  ],
};
export const QaApiResponse1430 = {
  data: [
    {
      examid: 1430,
      qaAnatomyCriteria: "Phalangen überlagern sich nicht",
      qaCriteriaId: 209,
      colors: {
        phalangen1430Fill: "#15bae4",
      },
      exam: "Rechter Vorfuss d.pl. obl liegend",
    },
  ],
};
export const QaApiResponse1431 = {
  data: [
    {
      examid: 1431,
      qaAnatomyCriteria: "Abbildung von Phalangen 1-5 übereinander projiziert",
      qaCriteriaId: 210,
      colors: {
        phalangen1431Fill: "#15bae4",
      },
      exam: "Linker Vorfuss lat liegend",
    },
  ],
};
export const QaApiResponse1432 = {
  data: [
    {
      examid: 1432,
      qaAnatomyCriteria: "Abbildung von Phalangen 1-5 übereinander projiziert",
      qaCriteriaId: 211,
      colors: {
        phalangen1432Fill: "#15bae4",
      },
      exam: "Rechter Vorfuss lat liegend",
    },
  ],
};
export const QaApiResponse1433 = {
  data: [
    {
      examid: 1433,
      qaAnatomyCriteria: "Gut belichtete Aufnahme der MFK",
      qaCriteriaId: 212,
      colors: {
        MFK1433Fill: "#4eae33",
      },
      exam: "Linker Mittelfuß d.pl liegend",
    },
  ],
};
export const QaApiResponse1434 = {
  data: [
    {
      examid: 1434,
      qaAnatomyCriteria: "Gut belichtete Aufnahme der MFK",
      qaCriteriaId: 213,
      colors: {
        MFK1434Fill: "#4eae33",
      },
      exam: "Rechter Mittelfuß d.pl liegend",
    },
  ],
};
export const QaApiResponse1435 = {
  data: [
    {
      examid: 1435,
      qaAnatomyCriteria:
        "Gute Belichtung und überlagerungsfreie Darstellung der MFK",
      qaCriteriaId: 214,
      colors: {
        MFK1435Fill: "#4eae33",
      },
      exam: "Linker Mittelfuß d.pl. obl liegend",
    },
  ],
};
export const QaApiResponse1436 = {
  data: [
    {
      examid: 1436,
      qaAnatomyCriteria:
        "Gute Belichtung und überlagerungsfreie Darstellung der MFK",
      qaCriteriaId: 215,
      colors: {
        MFK1436Fill: "#4eae33",
      },
      exam: "Rechter Mittelfuß d.pl. obl liegend",
    },
  ],
};
export const QaApiResponse1437 = {
  data: [
    {
      examid: 1437,
      qaAnatomyCriteria: "Erkennbares Fußgewölbe",
      qaCriteriaId: 216,
      colors: {
        FootArch1437Fill: "#c62585",
      },
      exam: "Linker Mittelfuß lat liegend",
    },
    {
      examid: 1437,
      qaAnatomyCriteria: "MFK überlagern sich, Weichteile erkennbar",
      qaCriteriaId: 217,
      colors: {
        MFK1437Fill: "#ffe000",
        Weichteile1437Fill: "#008000",
      },
      exam: "Linker Mittelfuß lat liegend",
    },
  ],
};
export const QaApiResponse1438 = {
  data: [
    {
      examid: 1438,
      qaAnatomyCriteria: "Erkennbares Fußgewölbe",
      qaCriteriaId: 218,
      colors: {
        FootArch1438Fill: "#c62585",
      },
      exam: "Rechter Mittelfuß lat liegend",
    },
    {
      examid: 1438,
      qaAnatomyCriteria: "MFK überlagern sich, Weichteile erkennbar",
      qaCriteriaId: 219,
      colors: {
        MFK1438Fill: "#ffe000",
        Weichteile1438Fill: "#008000",
      },
      exam: "Rechter Mittelfuß lat liegend",
    },
  ],
};
export const QaApiResponse1439 = {
  data: [
    {
      examid: 1439,
      qaAnatomyCriteria:
        "Calcaneus bis Phalangen werden vollständig dargestellt",
      qaCriteriaId: 220,
      colors: {
        calcaneus1439Fill: "#c62585",
        phalangen1439Fill: "#4eae33",
      },
      exam: "Linker Fuss d.pl liegend",
    },

  ],
};
export const QaApiResponse1440 = {
  data: [
    {
      examid: 1440,
      qaAnatomyCriteria:
        "Calcaneus bis Phalangen werden vollständig dargestellt",
      qaCriteriaId: 223,
      colors: {
        calcaneus1440Fill: "#c62585",
        phalangen1440Fill: "#4eae33",
      },
      exam: "Rechter Fuss d.pl liegend",
    },

  ],
};
export const QaApiResponse1441 = {
  data: [
    {
      examid: 1441,
      qaAnatomyCriteria: "Ferse bis Phalangen müssen abgebildet sein",
      qaCriteriaId: 227,
      colors: {
        Ferse1441Fill: "#4eae33",
        phalangen1441Fill: "#4cbced",
      },
      exam: "Linker Fuss d.pl. obl liegend",
    },
    {
      examid: 1441,
      qaAnatomyCriteria:
        "Metatarsalia und Phalangen dürfen sich nicht übereinander projizieren (außer an der Basis von Metatarsale I und II)",
      qaCriteriaId: 228,
      colors: {
        Metatarsalia1441Fill: "#ffe000",
        phalangen1441Fill: "#4dbced",
        Metatarsalia_one_two1441Fill: "#e72166",
      },
      exam: "Linker Fuss d.pl. obl liegend",
    },

  ],
};
export const QaApiResponse1442 = {
  data: [
    {
      examid: 1442,
      qaAnatomyCriteria: "Ferse bis Phalangen müssen abgebildet sein",
      qaCriteriaId: 230,
      colors: {
        Ferse1442Fill: "#6cb52d",
        phalangen1442Fill: "#4cbced",
      },
      exam: "Rechter Fuss d.pl. obl liegend",
    },
    {
      examid: 1442,
      qaAnatomyCriteria:
        "Metatarsalia und Phalangen dürfen sich nicht übereinander projizieren (außer an der Basis von Metatarsale I und II)",
      qaCriteriaId: 231,
      colors: {
        Metatarsalia1442Fill: "#ffe000",
        phalangen1442Fill: "#4dbced",
        Metatarsalia_one_two1442Fill: "#e72166",
      },
      exam: "Rechter Fuss d.pl. obl liegend",
    },
  ],
};
export const QaApiResponse1443 = {
  data: [
    {
      examid: 1443,
      qaAnatomyCriteria: "Fußgewölbe gut erkennbar",
      qaCriteriaId: 232,
      colors: {
        FootArch1443Fill: "#69b42d",
      },
      exam: "Linker Fuss lat liegend",
    },
    {
      examid: 1443,
      qaAnatomyCriteria: "Ferse bis Phalangen müssen abgebildet sein",
      qaCriteriaId: 233,
      colors: {
        Ferse1443Fill: "#d83e8f",
        phalangen1443Fill: "#4cbced",
      },
      exam: "Linker Fuss lat liegend",
    },
    {
      examid: 1443,
      qaAnatomyCriteria: "OSG muss abgebildet sein",
      qaCriteriaId: 234,
      colors: {
        OSG1443Fill: "#8B5007",
      },
      exam: "Linker Fuss lat liegend",
    },
    {
      examid: 1443,
      qaAnatomyCriteria: "Talusrolle strichförmig",
      qaCriteriaId: 235,
      colors: {
        Talusrolle1443Fill: "#7a21d8",
      },
      exam: "Linker Fuss lat liegend",
    },
  ],
};
export const QaApiResponse1444 = {
  data: [
    {
      examid: 1444,
      qaAnatomyCriteria: "Fußgewölbe gut erkennbar",
      qaCriteriaId: 236,
      colors: {
        FootArch1444Fill: "#69b42d",
      },
      exam: "Linker Fuss lat liegend",
    },
    {
      examid: 1444,
      qaAnatomyCriteria: "Ferse bis Phalangen müssen abgebildet sein",
      qaCriteriaId: 237,
      colors: {
        ferse1444fill: "#e72166",
        phalangen1444fill: "#4cbced",
      },
      exam: "Linker Fuss lat liegend",
    },
    {
      examid: 1444,
      qaAnatomyCriteria: "OSG muss abgebildet sein",
      qaCriteriaId: 238,
      colors: {
        osg1444fill: "#8B5007",
      },
      exam: "Linker Fuss lat liegend",
    },
    {
      examid: 1444,
      qaAnatomyCriteria: "Talusrolle strichförmig",
      qaCriteriaId: 239,
      colors: {
        talusrolle1444fill: "#7a21d8",
      },
      exam: "Linker Fuss lat liegend",
    },
  ],
};
export const QaApiResponse1445 = {
  data: [
    {
      examid: 1445,
      qaAnatomyCriteria: "Fußgewölbe gut beurteilbar",
      qaCriteriaId: 240,
      colors: {
        FootArch1445fill: "#6cb52d",
      },
      exam: "Linker Fuss lat stehend",
    },
    {
      examid: 1445,
      qaAnatomyCriteria: "Ferse bis Phalangen müssen abgebildet sein",
      qaCriteriaId: 241,
      colors: {
        ferse1445fill: "#e72166",
        phalangen1445fill: "#4cbced",
      },
      exam: "Linker Fuss lat stehend",
    },
    {
      examid: 1445,
      qaAnatomyCriteria: "Beide Sprunggelenke gut einsehbar",
      qaCriteriaId: 242,
      colors: {
        beidesg1445fill: "#8B5007",
      },
      exam: "Linker Fuss lat stehend",
    },
    {
      examid: 1445,
      qaAnatomyCriteria: "Trochlea tali strichförmig",
      qaCriteriaId: 243,
      colors: {
        trochleatali1445fill: "#7a21d8",
      },
      exam: "Linker Fuss lat stehend",
    },
  ],
};
export const QaApiResponse1446 = {
  data: [
    {
      examid: 1446,
      qaAnatomyCriteria: "Fußgewölbe gut beurteilbar",
      qaCriteriaId: 244,
      colors: {
        FootArch1446fill: "#6cb52d",
      },
      exam: "Rechter Fuss lat stehend",
    },
    {
      examid: 1446,
      qaAnatomyCriteria: "Ferse bis Phalangen müssen abgebildet sein",
      qaCriteriaId: 245,
      colors: {
        ferse1446fill: "#e72166",
        phalangen1446fill: "#4cbced",
      },
      exam: "",
    },
    {
      examid: 1446,
      qaAnatomyCriteria: "Beide Sprunggelenke gut einsehbar",
      qaCriteriaId: 246,
      colors: {
        beidesg1446fill: "#8B5007",
      },
      exam: "Rechter Fuss lat stehend",
    },
    {
      examid: 1446,
      qaAnatomyCriteria: "Trochlea tali strichförmig",
      qaCriteriaId: 247,
      colors: {
        trochleatali1446fill: "#7a21d8",
      },
      exam: "Rechter Fuss lat stehend",
    },
  ],
};
export const QaApiResponse1447 = {
  data: [
    {
      examid: 1447,
      qaAnatomyCriteria: "Seitl. Darstellung des Fersenbeins/Calcaneus",
      qaCriteriaId: 248,
      colors: {
        Calcaneus1447Fill: "#48EF3F",
      },
      exam: "Linkes Fersenbein lat liegend",
    },
    {
      examid: 1447,
      qaAnatomyCriteria: "Gut einsehbares unteres Sprunggelenk",
      qaCriteriaId: 249,
      colors: {
        USG1447Fill: "#7a21d8",
      },
      exam: "Linkes Fersenbein lat liegend",
    },
    {
      examid: 1447,
      qaAnatomyCriteria: "Weichteile beurteilbar",
      qaCriteriaId: 250,
      colors: {
        Weichteile1447Fill: "#4cbced",
      },
      exam: "Linkes Fersenbein lat liegend",
    },
  ],
};
export const QaApiResponse1448 = {
  data: [
    {
      examid: 1448,
      qaAnatomyCriteria: "Seitl. Darstellung des Fersenbeins/Calcaneus",
      qaCriteriaId: 251,
      colors: {
        Calcaneus1448Fill: "#48EF3F",
      },
      exam: "Rechtes Fersenbein lat liegend",
    },
    {
      examid: 1448,
      qaAnatomyCriteria: "Gut einsehbares unteres Sprunggelenk",
      qaCriteriaId: 252,
      colors: {
        USG1448Fill: "#7a21d8",
      },
      exam: "Rechtes Fersenbein lat liegend",
    },
    {
      examid: 1448,
      qaAnatomyCriteria: "Weichteile beurteilbar",
      qaCriteriaId: 253,
      colors: {
        Weichteile1448Fill: "#4cbced",
      },
      exam: "Rechtes Fersenbein lat liegend",
    },
  ],
};
export const QaApiResponse1449 = {
  data: [
    {
      examid: 1449,
      qaAnatomyCriteria: "Calcaneus muss in ganzer Länge abgebildet sein",
      qaCriteriaId: 254,
      colors: {
        calcaneus1449fill: "#48EF3F",
      },
      exam: "Linkes Fersenbein ax sitzend",
    },
  ],
};
export const QaApiResponse1450 = {
  data: [
    {
      examid: 1450,
      qaAnatomyCriteria: "Calcaneus muss in ganzer Länge abgebildet sein",
      qaCriteriaId: 255,
      colors: {
        calcaneus1450fill: "#48EF3F",
      },
      exam: "Rechtes Fersenbein ax sitzend",
    },
  ],
};
export const QaApiResponse1451 = {
  data: [
    {
      examid: 1451,
      qaAnatomyCriteria: "Darstellung der Fußwurzel außer Talus und Calcaneus",
      qaCriteriaId: 256,
      colors: {
        fw1451fill: "#FFE500",
        talus1451fill: "#F088B6",
        calcaneus1451fill: "#4cbced",
      },
      exam: "Linke Fusswurzel d.pl sitzend",
    },
  ],
};
export const QaApiResponse1452 = {
  data: [
    {
      examid: 1452,
      qaAnatomyCriteria: "Darstellung der Fußwurzel außer Talus und Calcaneus",
      qaCriteriaId: 257,
      colors: {
        fw1452fill: "#FFE500",
        talus1452fill: "#F088B6",
        calcaneus1452fill: "#4cbced",
      },
      exam: "Rechte Fusswurzel d.pl sitzend",
    },
  ],
};
export const QaApiResponse1453 = {
  data: [
    {
      examid: 1453,
      qaAnatomyCriteria:
        "Gute Darstellung und Abgrenzung der einzelnen FW Knochen",
      qaCriteriaId: 258,
      colors: {
        fwk1453fill: "#48EF3F",
      },
      exam: "Linke Fusswurzel d.pl. obl sitzend",
    },
  ],
};
export const QaApiResponse1454 = {
  data: [
    {
      examid: 1454,
      qaAnatomyCriteria:
        "Gute Darstellung und Abgrenzung der einzelnen FW Knochen",
      qaCriteriaId: 259,
      colors: {
        fwk1454fill: "#48EF3F",
      },
      exam: "Rechte Fusswurzel d.pl. obl sitzend",
    },
  ],
};
export const QaApiResponse1455 = {
  data: [
    {
      examid: 1455,
      qaAnatomyCriteria:
        "Gute Darstellung des seitlichen Talus und des Calcaneus",
      qaCriteriaId: 260,
      colors: { talus1455fill: "#48EF3F", calcaneus1455fill: "#F088B6" },
      exam: "Linke Fusswurzel lat liegend",
    },
  ],
};
export const QaApiResponse1456 = {
  data: [
    {
      examid: 1456,
      qaAnatomyCriteria:
        "Gute Darstellung des seitlichen Talus und des Calcaneus",
      qaCriteriaId: 261,
      colors: { talus1456Fill: "#48EF3F", Calcaneus1456Fill: "#F088B6" },
      exam: "Rechte Fusswurzel lat liegend",
    },
  ],
};
export const QaApiResponse1457 = {
  data: [
    {
      examid: 1457,
      qaAnatomyCriteria: "OSG frei einsehbar",
      qaCriteriaId: 262,
      colors: {
        OSG1457Fill: "#6600CC",
      },
      exam: "Linkes oberes Sprunggelenk v.d liegend",
    },
    {
      examid: 1457,
      qaAnatomyCriteria: "Beide Malleolen mit distaler Fibula frei abgebildet",
      qaCriteriaId: 263,
      colors: {
        beideMalleolen1457Fill: "#0000FF",
        TibialMalleolus1457Fill: "#0000FF",
        distalFibula1457Fill: "#FFD700",
      },
      altColors:{ 
        TibialMalleolus1457Fill: "#4cbced",
        beideMalleolen1457Fill: "#0000FF",
        distalFibula1457Fill: "#FFD700",
      },
      exam: "Linkes oberes Sprunggelenk v.d liegend",
    },
    {
      examid: 1457,
      qaAnatomyCriteria: "Zwischen Talus und Malleolen freie Gelenksicht",
      qaCriteriaId: 264,
      colors: {
        Gelenksicht1457Fill: "#e72166",
        Talus1457Fill: "#48EF3F",
        TibialMalleolus1457Fill: "#4cbced",
        
      },
      exam: "Linkes oberes Sprunggelenk v.d liegend",
    },
  ],
};
export const QaApiResponse1458 = {
  data: [
    {
      examid: 1458,
      qaAnatomyCriteria: "OSG frei einsehbar",
      qaCriteriaId: 265,
      colors: {
        OSG1458Fill: "#6600CC",
      },
      exam: "Rechtes oberes Sprunggelenk v.d liegend",
    },
    {
      examid: 1458,
      qaAnatomyCriteria: "Beide Malleolen mit distaler Fibula frei abgebildet",
      qaCriteriaId: 266,
      colors: {
        beideMalleolen1458Fill: "#0000FF",
        TibialMalleolus1458Fill: "#0000FF",
        distalFibula1458Fill: "#FFD700",
      },
      altColors:{ 
        TibialMalleolus1458Fill: "#4cbced",
        beideMalleolen1458Fill: "#0000FF",
        distalFibula1458Fill: "#FFD700",
      },
      exam: "Rechtes oberes Sprunggelenk v.d liegend",
    },
    {
      examid: 1458,
      qaAnatomyCriteria: "Zwischen Talus und Malleolen freie Gelenksicht",
      qaCriteriaId: 267,
      colors: {
        Gelenksicht1458Fill: "#e72166",
        Talus1458Fill: "#48EF3F",
        TibialMalleolus1458Fill: "#4cbced",
        
      },
      exam: "Rechtes oberes Sprunggelenk v.d liegend",
    },
  ],
};
export const QaApiResponse1460 = {
  data: [
    {
      examid: 1460,
      qaAnatomyCriteria: "Fibula im hinteren Drittel der Tibia",
      qaCriteriaId: 271,
      colors: {
        fibula1460Fill: "#F088B6",
        tibia1460Fill: "#FFE500",
      },
      exam: "Rechtes oberes Sprunggelenk lat liegend",
    },
    {
      examid: 1460,
      qaAnatomyCriteria: "Talusrolle ist strichförmig",
      qaCriteriaId: 272,
      colors: {
        Talusrolle1460Fill: "#39aa35",
      },
      altColors:{Talusrolle1460Fill: "#45c1f1",},
      exam: "Rechtes oberes Sprunggelenk lat liegend",
    },
    {
      examid: 1460,
      qaAnatomyCriteria:
        "Laterale und mediale Konturen der Talusrolle decken sich",
      qaCriteriaId: 273,
      colors: {
        Talusrolle1460Fill: "#45c1f1",
      },
      exam: "Rechtes oberes Sprunggelenk lat liegend",
    },
  ],
};
export const QaApiResponse1463 = {
  data: [
    {
      examid: 1463,
      qaAnatomyCriteria:
        "Gute Einsicht in den hinteren unteren Abschnitt Sprunggelenk, überlagerungsfreie Darstellung Außenknöche",
      qaCriteriaId: 276,
      colors: {
        SG1463Fill: "#15bae4",
        OuterAnkles1463Fill: "#cd4592",
      },
      exam: "Linkes oberes Sprunggelenk Innenrotation v.d.obl liegend",
    },
  ],
};
export const QaApiResponse1464 = {
  data: [
    {
      examid: 1464,
      qaAnatomyCriteria:
        "Gute Einsicht in den hinteren unteren Abschnitt Sprunggelenk, überlagerungsfreie Darstellung Außenknöche",
      qaCriteriaId: 277,
      colors: {
        SG1464Fill: "#15bae4",
        OuterAnkles1464Fill: "#cd4592",
      },
      exam: "Rechtes oberes Sprunggelenk Innenrotation d.v.obl liegend",
    },
  ],
};
export const QaApiResponse1465 = {
  data: [
    {
      examid: 1465,
      qaAnatomyCriteria: "Öffnungswinkel zwischen Tibia und Talus",
      qaCriteriaId: 278,
      colors: {
        OpeningAngle1465Fill: "#e60a81",
        Tibia1465Fill: "#15bae4",
        Talus1465Fill: "#ffe100",
      },
      exam: "Linkes oberes Sprunggelenk Stressaufnahme v.d liegend",
    },
  ],
};
export const QaApiResponse1466 = {
  data: [
    {
      examid: 1466,
      qaAnatomyCriteria: "Öffnungswinkel zwischen Tibia und Talus",
      qaCriteriaId: 281,
      colors: {
        OpeningAngle1466Fill: "#e60a81",
        Tibia1466Fill: "#54c3ea",
        Talus1466Fill: "#ffe100",
      },
      exam: "Rechtes oberes Sprunggelenk Stressaufnahme v.d liegend",
    },
  ],
};
export const QaApiResponse1467 = {
  data: [
    {
      examid: 1467,
      qaAnatomyCriteria:
        "Abstand zwischen hinterem Teil der Tibia-Gelenkfläche zum nächstgelegenen Punkt der Talus-Oberfläche",
      qaCriteriaId: 284,
      colors: {
        Tibia_GelenkSurface1467Fill: "#54c3ea",
        Talus_Surface1467Fill: "#e60a81",
      },
      exam: "Linkes oberes Sprunggelenk Stressaufnahme lat liegend",
    },
    {
      examid: 1467,
      qaAnatomyCriteria:
        "Für exakte Messung korrekt laterale Aufnahme des OSG nötig",
      qaCriteriaId: 286,
      colors: { OSG1467Fill: "#ffe100" },
      exam: "Linkes oberes Sprunggelenk Stressaufnahme lat liegend",
    },
  ],
};
export const QaApiResponse1468 = {
  data: [
    {
      examid: 1468,
      qaAnatomyCriteria:
        "Abstand zwischen hinterem Teil der Tibia-Gelenkfläche zum nächstgelegenen Punkt der Talus-Oberfläche",
      qaCriteriaId: 287,
      colors: {
        Tibia_Surface1468Fill: "#54c3ea",
        Talus_Surface1468Fill: "#e60a81",
      },
      exam: "Rechtes oberes Sprunggelenk Stressaufnahme lat liegend",
    },
    {
      examid: 1468,
      qaAnatomyCriteria:
        "Für exakte Messung korrekt laterale Aufnahme des OSG nötig",
      qaCriteriaId: 289,
      colors: { OSG1468Fill: "#ffe100" },
      exam: "",
    },
  ],
};
export const QaApiResponse1469 = {
  data: [
    {
      examid: 1469,
      qaAnatomyCriteria: "Gute Darstellung der Tibiahinterkante",
      qaCriteriaId: 290,
      colors: { Tibiahinterkante1469Fill: "#e60a81" },
      exam: "Linkes oberes Sprunggelenk Außenrotation v.d.obl liegend",
    },
  ],
};
export const QaApiResponse1470 = {
  data: [
    {
      examid: 1470,
      qaAnatomyCriteria: "Gute Darstellung der Tibiahinterkante",
      qaCriteriaId: 291,
      colors: { Tibiahinterkante1470Fill: "#e60a81" },
      exam: "Rechtes oberes Sprunggelenk Außenrotation d.v.obl liegend",
    },
  ],
};
export const QaApiResponse1471 = {
  data: [
    {
      examid: 1471,
      qaAnatomyCriteria:
        "Calcaneus bis Phalangen werden überlagerungsfrei dargestellt",
      qaCriteriaId: 292,
      colors: {
        Calcaneus1471Fill: "#e72166",
        phalangen1471Fill: "#4cbced",
      },
      exam: "Beide Füße d.pl liegend",
    },
  ],
};
export const QaApiResponse1472 = {
  data: [
    {
      examid: 1472,
      qaAnatomyCriteria: "Gelenke sind frei abgebildet",
      qaCriteriaId: 295,
      colors: { Gelenke1472Fill: "#c4047f" },
      exam: "Beide Vorfüße d.pl liegend",
    },
    {
      examid: 1472,
      qaAnatomyCriteria: "Phalangen sind gut voneinander getrennt",
      qaCriteriaId: 296,
      colors: { Phalangen1472Fill: "#6cb52d" },
      exam: "Beide Vorfüße d.pl liegend",
    },
  ],
};
export const QaApiResponse1473 = {
  data: [
    {
      examid: 1473,
      qaAnatomyCriteria: "Ferse bis Phalangen müssen abgebildet sein",
      qaCriteriaId: 297,
      colors: {
        Ferse1473Fill: "#6cb52d",
        phalangen1473Fill: "#4cbced",
      },
      exam: "Beide Füße d.pl. obl liegend",
    },
    {
      examid: 1473,
      qaAnatomyCriteria:
        "Metatarsalia und Phalangen dürfen sich nicht übereinander projizieren (außer an der Basis von Metatarsale I und II)",
      qaCriteriaId: 298,
      colors: {
        Metatarsalia1473Fill: "#ffe100",
        phalangen1473Fill: "#4dbced",
        Metatarsalia_one_two1473Fill: "#e72166",
      },
      exam: "Beide Füße d.pl. obl liegend",
    },
  ],
};
export const QaApiResponse1474 = {
  data: [
    {
      examid: 1474,
      qaAnatomyCriteria: "Phalangen überlagern sich nicht",
      qaCriteriaId: 300,
      colors: { Phalangen1474Fill: "#6cb52d" },
      exam: "Beide Vorfüße d.pl. obl liegend",
    },
  ],
};
export const QaApiResponse1501 = {
  data: [
    {
      examid: 1501,
      qaAnatomyCriteria:
        "Vollständige und möglichst gleichmäßige Schwärzung der BWS einschließlich der Übergänge zur HWS und LWS",
      qaCriteriaId: 301,
      colors: {
        BWS1501Fill: "#4dbced",
        HWS_LWS1501Fill: "#8B5007",
      },
      altColors:{
        Wirbelkorper1501Fill: "#E51AE5",
        Querfortsatze1501Fill: "#ffe100",
        HWS_LWS1501Fill: "#8B5007",
      },
      exam: "Brustwirbelsäule v.d stehend",
    },
    {
      examid: 1501,
      qaAnatomyCriteria: "Querfortsätze rechts und links gleich abgebildet",
      qaCriteriaId: 302,
      colors: { Querfortsatze1501Fill: "#ffe100" },
      exam: "Brustwirbelsäule v.d stehend",
    },
    {
      examid: 1501,
      qaAnatomyCriteria: "Darstellung der ovalen Bogenwurzeln",
      qaCriteriaId: 303,
      colors: { Bogenwurzeln1501Fill: "#6cb52d" },
      exam: "Brustwirbelsäule v.d stehend",
    },
    {
      examid: 1501,
      qaAnatomyCriteria: "Dornfortsätze Mitte Wirbelkörper",
      qaCriteriaId: 304,
      colors: {
        Dornfortsatze1501Fill: "#0639F4",
        Wirbelkorper1501Fill: "#E51AE5",
      },
      exam: "Brustwirbelsäule v.d stehend",
    },
  ],
};
export const QaApiResponse1502 = {
  data: [
    {
      examid: 1502,
      qaAnatomyCriteria:
        "Vollständige und möglichst gleichmäßige Schwärzung der BWS einschließlich der Übergänge zur HWS und LWS",
      qaCriteriaId: 305,
      colors: {
        BWS1502Fill: "#87becc",
        HWS_LWS1502Fill: "#8B5007",
      },
      altColors:{
        
        Wirbelkorper1502Fill: "#E51AE5",
        Querfortsatze1502Fill: "#ffe100",
        HWS_LWS1502Fill: "#8B5007",},
      exam: "Brustwirbelsäule v.d stehend",
    },
    {
      examid: 1502,
      qaAnatomyCriteria: "Querfortsätze rechts und links gleich abgebildet",
      qaCriteriaId: 306,
      colors: { Querfortsatze1502Fill: "#ffe100" },
      exam: "Brustwirbelsäule v.d stehend",
    },
    {
      examid: 1502,
      qaAnatomyCriteria: "Darstellung der ovalen Bogenwurzeln",
      qaCriteriaId: 307,
      colors: { Bogenwurzeln1502Fill: "#6cb52d" },
      exam: "Brustwirbelsäule v.d stehend",
    },
    {
      examid: 1502,
      qaAnatomyCriteria: "Dornfortsätze Mitte Wirbelkörper",
      qaCriteriaId: 308,
      colors: {
        Dornfortsatze1502Fill: "#0639F4",
        Wirbelkorper1502Fill: "#E51AE5",
      },
      exam: "Brustwirbelsäule v.d stehend",
    },
  ],
};
export const QaApiResponse1503 = {
  data: [
    {
      examid: 1503,
      qaAnatomyCriteria: "Streng seitliche Abbildung der gesamten BWS",
      qaCriteriaId: 309,
      colors: {
        BWS1503Fill: "#87becc",
      },
      exam: "Brustwirbelsäule lat stehend",
    },
    {
      examid: 1503,
      qaAnatomyCriteria: "Die angrenzenden H- bzw LWK müssen abgebildet sein",
      qaCriteriaId: 310,
      colors: {
        HWS_LWK1503Fill: "#e027b4",
      },
      exam: "Brustwirbelsäule lat stehend",
    },
    {
      examid: 1503,
      qaAnatomyCriteria:
        "Grund- und Deckplatten müssen exakt übereinander projiziert und strichförmig dargestellt sein im Bereich des Zentralstrahles",
      qaCriteriaId: 311,
      colors: {
        Grund_Deckplatten1503Fill: "#8B5007",
      },
      exam: "Brustwirbelsäule lat stehend",
    },
    {
      examid: 1503,
      qaAnatomyCriteria:
        "Gute, nicht überstrahlte Darstellung der Dornfortsätze",
      qaCriteriaId: 312,
      colors: { Dornfortsatze1503Fill: "#1928dd" },
      exam: "Brustwirbelsäule lat stehend",
    },
    {
      examid: 1503,
      qaAnatomyCriteria: "Schulterblatt aus dem Aufnahme Zielbereich",
      qaCriteriaId: 313,
      colors: {},
      exam: "Brustwirbelsäule lat stehend",
    },
  ],
};
export const QaApiResponse1504 = {
  data: [
    {
      examid: 1504,
      qaAnatomyCriteria: "Streng seitliche Abbildung der gesamten BWS",
      qaCriteriaId: 314,
      colors: {
        BWS1504Fill: "#87becc",
      },
      exam: "Brustwirbelsäule lat stehend",
    },
    {
      examid: 1504,
      qaAnatomyCriteria: "Die angrenzenden H- bzw LWK müssen abgebildet sein",
      qaCriteriaId: 315,
      colors: {
        HWS_LWK1504Fill: "#e027b4",
      },
      exam: "Brustwirbelsäule lat stehend",
    },
    {
      examid: 1504,
      qaAnatomyCriteria:
        "Grund- und Deckplatten müssen exakt übereinander projiziert und strichförmig dargestellt sein im Bereich des Zentralstrahles",
      qaCriteriaId: 316,
      colors: {
        Grund_Deckplatten1504Fill: "#8B5007",
      },
      exam: "Brustwirbelsäule lat stehend",
    },
    {
      examid: 1504,
      qaAnatomyCriteria:
        "Gute, nicht überstrahlte Darstellung der Dornfortsätze",
      qaCriteriaId: 317,
      colors: { Dornfortsatze1504Fill: "#1928dd" },
      exam: "Brustwirbelsäule lat stehend",
    },
    {
      examid: 1504,
      qaAnatomyCriteria: "Schulterblatt aus dem Aufnahme Zielbereich",
      qaCriteriaId: 318,
      colors: {},
      exam: "Brustwirbelsäule lat stehend",
    },
  ],
};
export const QaApiResponse1505 = {
  data: [
    {
      examid: 1505,
      qaAnatomyCriteria:
        "Intervertebralgelenke der filmfernen Seite müssen frei einsehbar sein",
      qaCriteriaId: 319,
      colors: { Intervertebralgelenke1505Fill: "#e027b4" },
      exam: "Brustwirbelsäule 75° v.d.obl stehend",
    },
  ],
};
export const QaApiResponse1506 = {
  data: [
    {
      examid: 1506,
      qaAnatomyCriteria:
        "Intervertebralgelenke der filmfernen Seite müssen frei einsehbar sein",
      qaCriteriaId: 320,
      colors: { Intervertebralgelenke1506Fill: "#e027b4" },
      exam: "Brustwirbelsäule 75° v.d.obl liegend",
    },
  ],
};
export const QaApiResponse1507 = {
  data: [
    {
      examid: 1507,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Querfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 321,
      colors: {
        Wirbelkorper_Querfortsatze1507Fill: "#ffe100",
        BWK1507Fill: "#4dbced",
      },
      exam: "1.Brustwirbelkörper v.d liegend",
    },
    {
      examid: 1507,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 322,
      colors: {
        Grund_Deckplatten1507Fill: "#e027b4",
      },
      exam: "1.Brustwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1508 = {
  data: [
    {
      examid: 1508,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Dornfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 323,
      colors: {
        Wirbelkorper_Dornfortsatze1508Fill: "#ffe100",
        BWK1508Fill: "#4dbced",
      },
      exam: "1.Brustwirbelkörper lat liegend",
    },
    {
      examid: 1508,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 324,
      colors: {
        Grund_Deckplatten1508Fill: "#e027b4",
      },
      exam: "1.Brustwirbelkörper lat liegend",
    },
  ],
};
export const QaApiResponse1509 = {
  data: [
    {
      examid: 1509,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Querfortsätze, inkl. Der angrenzende BWK",
      qaCriteriaId: 325,
      colors: {
        Wirbelkorper_Querfortsatze1509Fill: "#ffe100",
        BWK1509Fill: "#4dbced",
      },
      exam: "2.Brustwirbelkörper v.d liegend",
    },
    {
      examid: 1509,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 326,
      colors: {
        Grund_Deckplatten1509Fill: "#e027b4",
      },
      exam: "2.Brustwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1510 = {
  data: [
    {
      examid: 1510,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Dornfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 327,
      colors: {
        Wirbelkorper_Dornfortsatze1510Fill: "#ffe100",
        BWK1510Fill: "#4dbced",
      },
      exam: "2.Brustwirbelkörper lat liegend",
    },
    {
      examid: 1510,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 328,
      colors: {
        Grund_Deckplatten1510Fill: "#e027b4",
      },
      exam: "2.Brustwirbelkörper lat liegend",
    },
  ],
};
export const QaApiResponse1511 = {
  data: [
    {
      examid: 1511,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Querfortsätze, inkl. Der angrenzende BWK",
      qaCriteriaId: 329,
      colors: {
        Wirbelkorper_Querfortsatze1511Fill: "#ffe100",
        BWK1511Fill: "#4dbced",
      },
      exam: "3.Brustwirbelkörper v.d liegend",
    },
    {
      examid: 1511,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 330,
      colors: {
        Grund_Deckplatten1511Fill: "#e027b4",
      },
      exam: "3.Brustwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1512 = {
  data: [
    {
      examid: 1512,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Dornfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 331,
      colors: {
        Wirbelkorper_Dornfortsatze1512Fill: "#ffe100",
        BWK1512Fill: "#4dbced",
      },
      exam: "3.Brustwirbelkörper lat liegend",
    },
    {
      examid: 1512,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 332,
      colors: {
        Grund_Deckplatten1512Fill: "#e027b4",
      },
      exam: "3.Brustwirbelkörper lat liegend",
    },
  ],
};
export const QaApiResponse1513 = {
  data: [
    {
      examid: 1513,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Querfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 333,
      colors: {
        Wirbelkorper_Querfortsatze1513Fill: "#ffe100",
        BWK1513Fill: "#4dbced",
      },
      exam: "4.Brustwirbelkörper v.d liegend",
    },
    {
      examid: 1513,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 334,
      colors: {
        Grund_Deckplatten1513Fill: "#e027b4",
      },
      exam: "4.Brustwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1514 = {
  data: [
    {
      examid: 1514,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Dornfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 335,
      colors: {
        Wirbelkorper_Dornfortsatze1514Fill: "#ffe100",
        BWK1514Fill: "#4dbced",
      },
      exam: "4.Brustwirbelkörper lat liegend",
    },
    {
      examid: 1514,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 336,
      colors: {
        Grund_Deckplatten1514Fill: "#e027b4",
      },
      exam: "4.Brustwirbelkörper lat liegend",
    },
  ],
};
export const QaApiResponse1515 = {
  data: [
    {
      examid: 1515,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Querfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 337,
      colors: {
        Wirbelkorper_Querfortsatze1515Fill: "#ffe100",
        BWK1515Fill: "#4dbced",
      },
      exam: "5.Brustwirbelkörper v.d liegend",
    },
    {
      examid: 1515,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 338,
      colors: {
        Grund_Deckplatten1515Fill: "#e027b4",
      },
      exam: "5.Brustwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1516 = {
  data: [
    {
      examid: 1516,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Dornfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 339,
      colors: {
        Wirbelkorper_Dornfortsatze1516Fill: "#ffe100",
        BWK1516Fill: "#4dbced",
      },
      exam: "5.Brustwirbelkörper lat liegend",
    },
    {
      examid: 1516,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 340,
      colors: {
        Grund_Deckplatten1516Fill: "#e027b4",
      },
      exam: "5.Brustwirbelkörper lat liegend",
    },
  ],
};
export const QaApiResponse1517 = {
  data: [
    {
      examid: 1517,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Querfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 341,
      colors: {
        Wirbelkorper_Querfortsatze1517Fill: "#ffe100",
        BWK1517Fill: "#4dbced",
      },
      exam: "6.Brustwirbelkörper v.d liegend",
    },
    {
      examid: 1517,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 342,
      colors: {
        Grund_Deckplatten1517Fill: "#e027b4",
      },
      exam: "6.Brustwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1518 = {
  data: [
    {
      examid: 1518,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Dornfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 343,
      colors: {
        Wirbelkorper_Dornfortsatze1518Fill: "#ffe100",
        BWK1518Fill: "#4dbced",
      },
      exam: "6.Brustwirbelkörper lat liegend",
    },
    {
      examid: 1518,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 344,
      colors: {
        Grund_Deckplatten1518Fill: "#e027b4",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1519 = {
  data: [
    {
      examid: 1519,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Querfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 345,
      colors: {
        Wirbelkorper_Querfortsatze1519Fill: "#ffe100",
        BWK1519Fill: "#4dbced",
      },
      exam: "7.Brustwirbelkörper v.d liegend",
    },
    {
      examid: 1519,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 346,
      colors: {
        Grund_Deckplatten1519Fill: "#e027b4",
      },
      exam: "7.Brustwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1520 = {
  data: [
    {
      examid: 1520,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Dornfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 347,
      colors: {
        Wirbelkorper_Dornfortsatze1520Fill: "#ffe100",
        BWK1520Fill: "#4dbced",
      },
      exam: "7.Brustwirbelkörper lat liegend",
    },
    {
      examid: 1520,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 348,
      colors: {
        Grund_Deckplatten1520Fill: "#e027b4",
      },
      exam: "7.Brustwirbelkörper lat liegend",
    },
  ],
};
export const QaApiResponse1521 = {
  data: [
    {
      examid: 1521,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Querfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 349,
      colors: {
        Wirbelkorper_Querfortsatze1521Fill: "#ffe100",
        BWK1521Fill: "#4dbced",
      },
      exam: "8.Brustwirbelkörper v.d liegend",
    },
    {
      examid: 1521,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 350,
      colors: {
        Grund_Deckplatten1521Fill: "#e027b4",
      },
      exam: "8.Brustwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1522 = {
  data: [
    {
      examid: 1522,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Dornfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 351,
      colors: {
        Wirbelkorper_Dornfortsatze1522Fill: "#ffe100",
        BWK1522Fill: "#4dbced",
      },
      exam: "8.Brustwirbelkörper lat liegend",
    },
    {
      examid: 1522,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 352,
      colors: {
        Grund_Deckplatten1522Fill: "#e027b4",
      },
      exam: "8.Brustwirbelkörper lat liegend",
    },
  ],
};
export const QaApiResponse1523 = {
  data: [
    {
      examid: 1523,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Querfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 353,
      colors: {
        Wirbelkorper_Querfortsatze1523Fill: "#ffe100",
        BWK1523Fill: "#4dbced",
      },
      exam: "9.Brustwirbelkörper v.d liegend",
    },
    {
      examid: 1523,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 354,
      colors: {
        Grund_Deckplatten1523Fill: "#e027b4",
      },
      exam: "9.Brustwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1524 = {
  data: [
    {
      examid: 1524,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Dornfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 355,
      colors: {
        Wirbelkorper_Dornfortsatze1524Fill: "#ffe100",
        BWK1524Fill: "#4dbced",
      },
      exam: "9.Brustwirbelkörper lat liegend",
    },
    {
      examid: 1524,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 356,
      colors: {
        Grund_Deckplatten1524Fill: "#e027b4",
      },
      exam: "9.Brustwirbelkörper lat liegend",
    },
  ],
};
export const QaApiResponse1525 = {
  data: [
    {
      examid: 1525,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Querfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 357,
      colors: {
        Wirbelkorper_Querfortsatze1525Fill: "#ffe100",
        BWK1525Fill: "#4dbced",
      },
      exam: "10.Brustwirbelkörper v.d liegend",
    },
    {
      examid: 1525,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 358,
      colors: {
        Grund_Deckplatten1525Fill: "#e027b4",
      },
      exam: "10.Brustwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1526 = {
  data: [
    {
      examid: 1526,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Dornfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 359,
      colors: {
        Wirbelkorper_Dornfortsatze1526Fill: "#ffe100",
        BWK1526Fill: "#4dbced",
      },
      exam: "10.Brustwirbelkörper lat liegend",
    },
    {
      examid: 1526,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 360,
      colors: {
        Grund_Deckplatten1526Fill: "#e027b4",
      },
      exam: "10.Brustwirbelkörper lat liegend",
    },
  ],
};
export const QaApiResponse1527 = {
  data: [
    {
      examid: 1527,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Querfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 361,
      colors: {
        Wirbelkorper_Querfortsatze1527Fill: "#ffe100",
        BWK1527Fill: "#4dbced",
      },
      exam: "11.Brustwirbelkörper v.d liegend",
    },
    {
      examid: 1527,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 362,
      colors: {
        Grund_Deckplatten1527Fill: "#e027b4",
      },
      exam: "Grund- und Deckplatte stellen sich strichförmig dar",
    },
  ],
};
export const QaApiResponse1528 = {
  data: [
    {
      examid: 1528,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Dornfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 363,
      colors: {
        Wirbelkorper_Dornfortsatze1528Fill: "#ffe100",
        BWK1528Fill: "#4dbced",
      },
      exam: "11.Brustwirbelkörper lat liegend",
    },
    {
      examid: 1528,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 364,
      colors: {
        Grund_Deckplatten1528Fill: "#e027b4",
      },
      exam: "11.Brustwirbelkörper lat liegend",
    },
  ],
};
export const QaApiResponse1529 = {
  data: [
    {
      examid: 1529,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Querfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 365,
      colors: {
        Wirbelkorper_Querfortsatze1529Fill: "#ffe100",
        BWK1529Fill: "#4dbced",
      },
      exam: "12.Brustwirbelkörper v.d liegend",
    },
    {
      examid: 1529,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 366,
      colors: {
        Grund_Deckplatten1529Fill: "#e027b4",
      },
      exam: "12.Brustwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1530 = {
  data: [
    {
      examid: 1530,
      qaAnatomyCriteria:
        "Komplette Darstellung des gewünschten Wirbelkörpers plus Dornfortsätze, inkl. Der angrenzenden BWK",
      qaCriteriaId: 367,
      colors: {
        Wirbelkorper_Dornfortsatze1530Fill: "#ffe100",
        BWK1530Fill: "#4dbced",
      },
      exam: "12.Brustwirbelkörper lat liegend",
    },
    {
      examid: 1530,
      qaAnatomyCriteria: "Grund- und Deckplatte stellen sich strichförmig dar",
      qaCriteriaId: 368,
      colors: {
        Grund_Deckplatten1530Fill: "#e027b4",
      },
      exam: "12.Brustwirbelkörper lat liegend",
    },
  ],
};
export const QaApiResponse1531 = {
  data: [
    {
      examid: 1531,
      qaAnatomyCriteria: "vollständige Darstellung der BWK 10 bis LWK 3",
      qaCriteriaId: 369,
      colors: {
        BWK_LWK1531Fill: "#FFFF00",
      },
      exam: "Thoracolumbaler Übergang v.d liegend",
    },
    {
      examid: 1531,
      qaAnatomyCriteria:
        "Grund und Deckplatten müssen im Bereich des Zentralstrahles exakt übereinander projiziert sein",
      qaCriteriaId: 370,
      colors: {
        Grund_Deckplatten1531Fill: "#0000FF",
      },
      exam: "Thoracolumbaler Übergang v.d liegend",
    },
    {
      examid: 1531,
      qaAnatomyCriteria: "Dornfortsatz in WK Mitte",
      qaCriteriaId: 371,
      colors: {
        Dornfortsatz1531Fill: "#FF0000",
        WK1531Fill: "#008000",
      },
      exam: "Thoracolumbaler Übergang v.d liegend",
    },
  ],
};
export const QaApiResponse1532 = {
  data: [
    {
      examid: 1532,
      qaAnatomyCriteria: "Vollständige Darstellung der BWK 10 bis LWK 3",
      qaCriteriaId: 372,
      colors: {
        BWK_LWK1532Fill: "#FFFF00",
      },
      exam: "Thoracolumbaler Übergang v.d liegend",
    },
    {
      examid: 1532,
      qaAnatomyCriteria:
        "Grund und Deckplatten müssen im Bereich des Zentralstrahles exakt übereinander projiziert sein",
      qaCriteriaId: 373,
      colors: {
        Grund_Deckplatten1532Fill: "#0000FF",
      },
      exam: "Thoracolumbaler Übergang v.d liegend",
    },
    {
      examid: 1532,
      qaAnatomyCriteria: "Dornfortsatz in WK Mitte",
      qaCriteriaId: 374,
      colors: {
        Dornfortsatz1532Fill: "#FF0000",
        WK1532Fill: "#008000",
      },
      exam: "Thoracolumbaler Übergang v.d liegend",
    },
  ],
};
export const QaApiResponse1533 = {
  data: [
    {
      examid: 1533,
      qaAnatomyCriteria: "Freie Abbildung der unteren BWS und oberen  LWS",
      qaCriteriaId: 375,
      colors: {
        BWS1533Fill: "#30c2e0",
        LWS1533Fill: "#e0e31c",
      },
      altColors: {
        BWS1533Fill: "#30c2e0",
        LWS1533Fill: "#e0e31c",
        WK1533Fill: "#e2901a",
      },
      exam: "Thoracolumbaler Übergang lat liegend",
    },
    {
      examid: 1533,
      qaAnatomyCriteria:
        "Im Zentrum strichförmige Erkennbarkeit der Grund -und Deckplatten der WK",
      qaCriteriaId: 376,
      colors: {
        Grund_Deckplatten1533Fill: "#e027b4",
        WK1533Fill: "#e2901a",
      },
      exam: "Thoracolumbaler Übergang lat liegend",
    },
  ],
};
export const QaApiResponse1534 = {
  data: [
    {
      examid: 1534,
      qaAnatomyCriteria: "Freie Abbildung der unteren BWS und oberen  LWS",
      qaCriteriaId: 377,
      colors: {
        BWS1534Fill: "#30c2e0",
        LWS1534Fill: "#e0e31c",
      },
      altColors: {
        BWS1534Fill: "#30c2e0",
        LWS1534Fill: "#e0e31c",
        WK1534Fill: "#e2901a",
      },
      exam: "Thoracolumbaler Übergang lat liegend",
    },
    {
      examid: 1534,
      qaAnatomyCriteria:
        "Im Zentrum strichförmige Erkennbarkeit der Grund -und Deckplatten der WK",
      qaCriteriaId: 378,
      colors: {
        Grund_Deckplatten1534Fill: "#e027b4",
        WK1534Fill: "#e2901a",
      },
      exam: "Thoracolumbaler Übergang lat liegend",
    },
  ],
};
export const QaApiResponse1535 = {
  data: [
    {
      examid: 1535,
      qaAnatomyCriteria:
        "Kostotransversalgelenke (Rippen Verbindungen) projizieren sich hierbei auf die Mitte der Wirbelkörper",
      qaCriteriaId: 380,
      colors: {
        Rippen_Verbindungen1535Fill: "#e027b4",
        WK1535Fill: "#ebe846",
      },
      exam: "Brustwirbelsäule 45° v.d.obl stehend",
    },
  ],
};
export const QaApiResponse1536 = {
  data: [
    {
      examid: 1536,
      qaAnatomyCriteria:
        "Kostotransversalgelenke (Rippen Verbindungen) projizieren sich auf die Mitte der Wirbelkörper",
      qaCriteriaId: 381,
      colors: {
        Rippen_Verbindungen1536Fill: "#e027b4",
        WK1536Fill: "#e0e31c",
      },
      exam: "Brustwirbelsäule 45° v.d.obl liegend",
    },
  ],
};
export const QaApiResponse1601 = {
  data: [
    {
      examid: 1601,
      qaAnatomyCriteria:
        "Vollständige Darstellung möglichst aller 7 Halswirbelkörper, inkl. der Übergänge zum Schädel und zur Brustwirbelsäule",
      qaCriteriaId: 382,
      colors: {
        HWS1601Fill: "#dae120",
        HWS_Skull1601Fill: "#6600CC",
        HWS_BWS1601Fill: "#30c2e0",
        
      },
      exam: "Halswirbelsäule v.d stehend",
    },
    {
      examid: 1601,
      qaAnatomyCriteria:
        "Möglichst planparallele Darstellung der Grund- und Deckplatten",
      qaCriteriaId: 383,
      colors: {
        Grund_Deckplatten1601Fill: "#0000FF",
      },
      exam: "Halswirbelsäule v.d stehend",
    },
    {
      examid: 1601,
      qaAnatomyCriteria: "Processus spinosi sind mittelständig",
      qaCriteriaId: 384,
      colors: {
        Processus_spinosi1601Fill: "#11a24a",
      },
      exam: "Halswirbelsäule v.d stehend",
    },
    {
      examid: 1601,
      qaAnatomyCriteria: "Guter Einblick in die Zwischenwirbelräume",
      qaCriteriaId: 385,
      colors: {
        Intervertebral_spaces1601Fill: "#F72020",
      },
      exam: "Halswirbelsäule v.d stehend",
    },
    {
      examid: 1601,
      qaAnatomyCriteria: "Kleine Wirbelgelenke sind nicht einsehbar",
      qaCriteriaId: 386,
      colors: {},
      exam: "Halswirbelsäule v.d stehend",
    },
  ],
};
export const QaApiResponse1602 = {
  data: [
    {
      examid: 1602,
      qaAnatomyCriteria:
        "Darstellung der gesamten HWS möglichst mit wenig Überlagerung durch den Unterkiefer",
      qaCriteriaId: 387,
      colors: {
        HWS1602Fill: "#dae120",
      },
      exam: "Halswirbelsäule v.d liegend",
    },
    {
      examid: 1602,
      qaAnatomyCriteria:
        "Kopf nicht verkantet und damit gleichmäßige Darstellung der Wirbelkörper",
      qaCriteriaId: 388,
      colors: {
        WK1602Fill: "#11a24a",
      },
      exam: "Halswirbelsäule v.d liegend",
    },
  ],
};
export const QaApiResponse1603 = {
  data: [
    {
      examid: 1603,
      qaAnatomyCriteria:
        "Symmetrische Abbildung aller / Halswirbelkörper, inkl. der Übergänge zum Schädel und zur Brustwirbelsäule",
      qaCriteriaId: 389,
      colors: {
        HWK1603Fill: "#dae120",
        HWS_BWS1603Fill: "#08afe6",
        HWS_Skull1603Fill: "#e598e5",
      },
      exam: "Halswirbelsäule mit bewegtem Unterkiefer v.d stehend",
    },
    {
      examid: 1603,
      qaAnatomyCriteria: "Processus spinosi (Dornfortsatz) ist mittelständig",
      qaCriteriaId: 390,
      colors: {
        Processus_spinosi1603Fill: "#e51e25",
      },
      exam: "Halswirbelsäule mit bewegtem Unterkiefer v.d stehend",
    },
    {
      examid: 1603,
      qaAnatomyCriteria:
        "Der Unterkiefer ist durch die Bewegung unscharf damit Dens und HWK, die ansonsten von Uk überlagert wären dargestellt",
      qaCriteriaId: 391,
      colors: {
        HWK1603Fill: "#dae120",
        Dens1603Fill: "#003dff",
        UK1603Fill: "#47b649",
      },
      exam: "Halswirbelsäule mit bewegtem Unterkiefer v.d stehend",
    },
  ],
};
export const QaApiResponse1604 = {
  data: [
    {
      examid: 1604,
      qaAnatomyCriteria:
        "Darstellung aller 7 HWK einschließlich ihrer nicht überbelichteten  Dornfortsätze",
      qaCriteriaId: 392,
      colors: { HWK1604Fill: "#dae120", Dornfortsatz1604Fill: "#d646f9" },
      altColors:{HWK1604Fill: "#dae120", Dornfortsatz1604Fill: "#d646f9", HWK7th_1604Fill: "#00d10f"},
      exam: "Halswirbelsäule links anliegend lat stehend",
    },
    {
      examid: 1604,
      qaAnatomyCriteria:
        "Strichförmige Darstellung Deck und Bodenplatten im Zentralstrahl Bereich",
      qaCriteriaId: 393,
      colors: {
        Platten1604Fill: "#cf2927",
      },
      exam: "Halswirbelsäule links anliegend lat stehend",
    },
    {
      examid: 1604,
      qaAnatomyCriteria:
        "Der Dornfortsatz des 7. Halswirbelkörpers sollte mit dargestellt sein",
      qaCriteriaId: 394,
      colors: {
        HWK7th_1602Fill: "#00d10f",
      },
      exam: "Halswirbelsäule links anliegend lat stehend",
    },
  ],
};
export const QaApiResponse1606 = {
  data: [
    {
      examid: 1606,
      qaAnatomyCriteria:
        "Die Zwischenwirbellöcher (Foramina) sind frei einsehbar",
      qaCriteriaId: 396,
      colors: {
        Foramina1606Fill: "#d81e84",
      },
      exam: "Halswirbelsäule links anliegend v.d.obl stehend",
    },
  ],
};
export const QaApiResponse1607 = {
  data: [
    {
      examid: 1607,
      qaAnatomyCriteria:
        "Die Zwischenwirbellöcher (Foramina) sind frei einsehbar",
      qaCriteriaId: 397,
      colors: {
        Foramina1607Fill: "#d81e84",
      },
      exam: "Halswirbelsäule rechts anliegend v.d.obl stehend",
    },
  ],
};
export const QaApiResponse1608 = {
  data: [
    {
      examid: 1608,
      qaAnatomyCriteria: "Freie Darstellung des Dens",
      qaCriteriaId: 398,
      colors: {
        Dens1608Fill: "#d81e84",
      },
      exam: "Dens v.d stehend",
    },
  ],
};
export const QaApiResponse1609 = {
  data: [
    {
      examid: 1609,
      qaAnatomyCriteria:
        "Orthograde, streng seitliche Darstellung der Deckplatten des 4ten Halswirbelkörpers",
      qaCriteriaId: 399,
      colors: {
        HWK_4th_1609Fill: "#00d10f",
      },
      exam: "Halswirbelsäule Inklination lat stehend",
    },
    {
      examid: 1609,
      qaAnatomyCriteria:
        "Alle 7 Halswirbelkörper müssen sich in möglichst maximaler Inklination befinden",
      qaCriteriaId: 400,
      colors: {
        Alle_HWK_1609Fill: "#ebe846",
        Inklination1609Fill: "#08afe6",
      },
      exam: "",
    },
    {
      examid: 1609,
      qaAnatomyCriteria:
        "Intervertebralräume müssen frei einsehbar sein (Inklination)",
      qaCriteriaId: 401,
      colors: {
        Intervertebralraume1609Fill: "#e51e25",
        Inklination1609Fill: "#08afe6",
      },
      exam: "",
    },
    {
      examid: 1609,
      qaAnatomyCriteria: "Alle Hinterkanten der Wirbelkörper ohne Doppelkontur",
      qaCriteriaId: 402,
      colors: {
        Alle_HWK_1609Fill: "#ebe846",
        Hinterkanten1609Fill: "#110bf9",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1610 = {
  data: [
    {
      examid: 1610,
      qaAnatomyCriteria:
        "Orthograde, streng seitliche Darstellung der Deckplatten des 4ten Halswirbelkörpers",
      qaCriteriaId: 403,
      colors: {
        HWK4_1610Fill: "#0000FF",
      },
      exam: "Halswirbelsäule Reklination lat stehend",
    },
    {
      examid: 1610,
      qaAnatomyCriteria:
        "Alle 7 Halswirbelkörper müssen sich in maximaler Reklination befinden",
      qaCriteriaId: 404,
      colors: {
        Alle_HWK_1610Fill: "#ebe846",
        Reklination1610Fill: "#08afe6",
      },
      altColors:{ Alle_HWK_1610Fill: "#ebe846", HWK4_1610Fill: "#0000FF",
        Reklination1610Fill: "#08afe6",},
      exam: "",
    },
    {
      examid: 1610,
      qaAnatomyCriteria: "Alle Hinterkanten der Wirbelkörper ohne Doppelkontur",
      qaCriteriaId: 405,
      colors: {
        Hinterkanten_1610Fill: "#cf2927",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1611 = {
  data: [
    {
      examid: 1611,
      qaAnatomyCriteria:
        "Freie Darstellung von HWK 6 bis BWK 3 in schräger Position",
      qaCriteriaId: 406,
      colors: {
        HWK6_1611Fill: "#d81e84",
        BWK3_1611Fill: "#08afe6",
      },
      exam: "Cervicothorakaler Übergang v.d.obl stehend",
    },
  ],
};
export const QaApiResponse1612 = {
  data: [
    {
      examid: 1612,
      qaAnatomyCriteria:
        "Freie Einsehbarkeit alle bildempfängerfernen Foramina intervertebralia",
      qaCriteriaId: 407,
      colors: {
        Foramina1612Fill: "#d81e84",
      },
      exam: "Halswirbelsäule links anliegend v.d.obl liegend",
    },
  ],
};
export const QaApiResponse1613 = {
  data: [
    {
      examid: 1613,
      qaAnatomyCriteria:
        "Freie Einsehbarkeit alle bildempfängerfernen Foramina intervertebralia",
      qaCriteriaId: 408,
      colors: {
        Foramina1613Fill: "#d81e84",
      },
      exam: "Halswirbelsäule rechts anliegend v.d.obl liegend",
    },
  ],
};
export const QaApiResponse1614 = {
  data: [
    {
      examid: 1614,
      qaAnatomyCriteria:
        "Darstellung aller 7 HWK einschließlich ihrer nicht überbelichteten  Dornfortsätze",
      qaCriteriaId: 409,
      colors: { HWK1614Fill: "#dae120", Dornfortsatz1614Fill: "#d646f9" },
      altColors:{HWK1614Fill: "#dae120", Dornfortsatz1614Fill: "#d646f9", HWK7th_1614Fill: "#00d10f"},
      exam: "Halswirbelsäule rechts anliegend lat stehend",
    },
    {
      examid: 1614,
      qaAnatomyCriteria:
        "Strichförmige Darstellung der Deck- und Bodenplattenfläche im Bereich des Zentralstrahles",
      qaCriteriaId: 410,
      colors: {
        Platten1614Fill: "#cf2927",
      },
      exam: "Halswirbelsäule rechts anliegend lat stehend",
    },
    {
      examid: 1614,
      qaAnatomyCriteria:
        "Die Dornfortsätze einschließlich des 7ten Halswirbelkörpers sollten dargestellt sein",
      qaCriteriaId: 411,
      colors: {
        HWK7th_1614Fill: "#00d10f",
      },
      exam: "Halswirbelsäule rechts anliegend lat stehend",
    },
  ],
};
export const QaApiResponse1616 = {
  data: [
    {
      examid: 1616,
      qaAnatomyCriteria: "Alle 7 HWK lateral einsehbar",
      qaCriteriaId: 413,
      colors: {
        HWK_1616Fill: "#d81e84",
      },
      exam: "Halswirbelsäule mit angestellter Kassette lat liegend",
    },
    {
      examid: 1616,
      qaAnatomyCriteria: "Orthogonale Darstellung der Grund- und Deckplatten",
      qaCriteriaId: 414,
      colors: {
        Platten_1616Fill: "#691a0b",
      },
      exam: "",
    },
    {
      examid: 1616,
      qaAnatomyCriteria:
        "Processus spinosus des 7. HWK vollständig dargestellt",
      qaCriteriaId: 415,
      colors: {
        Processus_spinosus_1616Fill: "#62b22f",
      },
      exam: "",
    },
    {
      examid: 1616,
      qaAnatomyCriteria: "Mandibula überdeckt HWS nicht",
      qaCriteriaId: 416,
      colors: {
        Mandibula_1616Fill: "#ebe31b",
      },
      exam: "",
    },
    {
      examid: 1616,
      qaAnatomyCriteria: "Weichteile beurteilbar",
      qaCriteriaId: 417,
      colors: {
        Weichteile_1616Fill: "#1111f4",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1701 = {
  data: [
    {
      examid: 1701,
      qaAnatomyCriteria:
        "Vollständige Darstellung der Lendenwirbelsäule inklusive des 12ten Brustwirbelkörpers und der Iliosakralgelenke",
      qaCriteriaId: 418,
      colors: {
        spine1701Fill: "#2b4999",
        Tspinebody1701Fill: "#d81e84",
        iliosacraljoint1701Fill: "#62b22f",
      },
      exam: "Lendenwirbelsäule v.d stehend",
    },
    {
      examid: 1701,
      qaAnatomyCriteria: "Querfortsätze der Wirbelkörper sind erkennbar",
      qaCriteriaId: 419,
      colors: {
        Querfortsatze1701Fill: "#1f5527",
        Wirbelkorper1701Fill: "#f8ac2e",
      },
      exam: "Lendenwirbelsäule v.d stehend",
    },
    {
      examid: 1701,
      qaAnatomyCriteria:
        "Dornfortsätze projizieren sich in der Mitte der Wirbelkörper",
      qaCriteriaId: 420,
      colors: {
        Dornfortsatze1701Fill: "#54230e",
        Wirbelkorper1701Fill: "#f8ac2e",
      },
      exam: "Lendenwirbelsäule v.d stehend",
    },
  ],
};
export const QaApiResponse1702 = {
  data: [
    {
      examid: 1702,
      qaAnatomyCriteria:
        "Vollständige Darstellung der Lendenwirbelsäule inklusive des 12ten Brustwirbelkörpers und der Iliosakralgelenke",
      qaCriteriaId: 421,
      colors: {
        spine1702Fill: "#2b4999",
        Tspinebody1702Fill: "#d81e84",
        iliosacraljoint1702Fill: "#62b22f",
      },
      exam: "Lendenwirbelsäule v.d liegend",
    },
    {
      examid: 1702,
      qaAnatomyCriteria: "Querfortsätze der Wirbelkörper sind erkennbar",
      qaCriteriaId: 422,
      colors: {
        Querfortsatze1702Fill: "#1f5527",
        Wirbelkorper1702Fill: "#f8ac2e",
      },
      exam: "",
    },
    {
      examid: 1702,
      qaAnatomyCriteria:
        "Dornfortsätze projizieren sich in der Mitte der Wirbelkörper",
      qaCriteriaId: 423,
      colors: {
        Dornfortsatze1702Fill: "#54230e",
        Wirbelkorper1702Fill: "#f8ac2e",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1703 = {
  data: [
    {
      examid: 1703,
      qaAnatomyCriteria:
        "Exakt laterale Darstellung der Wirbelkörper, inklusiver der Processi articularis (Querfortsätze)",
      qaCriteriaId: 424,
      colors: {
        Querfortsatze1703Fill: "#01656a",
        Wirbelkorper1703Fill: "#f9b220",
      },
      exam: "Lendenwirbelsäule lat stehend",
    },
    {
      examid: 1703,
      qaAnatomyCriteria:
        "BWS-LWS-Übergang und LWS-Kreuzbein-Übergang müssen zu sehen sein",
      qaCriteriaId: 425,
      colors: { BWS_LWS1703Fill: "#1111f4", LWS_Kreuzbein1703Fill: "#6cb52d" },
      exam: "",
    },
    {
      examid: 1703,
      qaAnatomyCriteria:
        "Strichförmige Abbildung der Grund- und Deckenplatten im Bereich des Zentralstrahles",
      qaCriteriaId: 426,
      colors: {
        Grund_Deckplatten1703Fill: "#cf2927",
      },
      exam: "",
    },
    {
      examid: 1703,
      qaAnatomyCriteria: "Einfache Darstellung der hinteren Wirbelkanten",
      qaCriteriaId: 427,
      colors: {
        Wirbelkanten1703Fill: "#08afe6",
      },
      exam: "",
    },
    {
      examid: 1703,
      qaAnatomyCriteria: "Guter Einblick in die Zwischenwirbelräume",
      qaCriteriaId: 428,
      colors: {
        Intervertebral_spaces1703Fill: "#e60a76",
      },
      exam: "",
    },
    {
      examid: 1703,
      qaAnatomyCriteria:
        "Darstellung der Dornfortsätze (Belichtung- Überstrahlungsgefahr)",
      qaCriteriaId: 429,
      colors: { Dornfortsatze1703Fill: "#592583" },
      exam: "",
    },
  ],
};
export const QaApiResponse1704 = {
  data: [
    {
      examid: 1704,
      qaAnatomyCriteria:
        "Exakt laterale Darstellung der Wirbelkörper, inklusiver der Processi articularis (Querfortsätze)",
      qaCriteriaId: 430,
      colors: {
        Querfortsatze1704Fill: "#01656a",
        Wirbelkorper1704Fill: "#f9b220",
      },
      exam: "Lendenwirbelsäule lat liegend",
    },
    {
      examid: 1704,
      qaAnatomyCriteria:
        "BWS-LWS-Übergang, sowie Übergang zum Kreuzbein müssen zu sehen sein",
      qaCriteriaId: 431,
      colors: { BWS_LWS1704Fill: "#1111f4", LWS_Kreuzbein1704Fill: "#6cb52d" },
      exam: "Lendenwirbelsäule lat liegend",
    },
    {
      examid: 1704,
      qaAnatomyCriteria: "Guter Einblick in die Zwischenwirbelräume",
      qaCriteriaId: 908,
      colors: {
        Intervertebral_spaces1704Fill: "#e60a76",
      },
      exam: "Lendenwirbelsäule lat liegend",
    },
    {
      examid: 1704,
      qaAnatomyCriteria:
        "Darstellung der Dornfortsätze (Belichtung- Überstrahlungsgefahr)",
      qaCriteriaId: 909,
      colors: { Dornfortsatze1704Fill: "#592583" },
      exam: "Lendenwirbelsäule lat liegend",
    },
    {
      examid: 1704,
      qaAnatomyCriteria:
        "Strichförmige Abbildung der Grund- und Deckenplatten der Wirbelkörper, vor allem in Objektmitte",
      qaCriteriaId: 432,
      colors: {
        Grund_Deckplatten1704Fill: "#cf2927",
      },
      exam: "Lendenwirbelsäule lat liegend",
    },
    {
      examid: 1704,
      qaAnatomyCriteria: "einfache Darstellung der hinteren Wirbelkanten",
      qaCriteriaId: 433,
      colors: {
        Wirbelkanten1704Fill: "#08afe6",
      },
      exam: "Lendenwirbelsäule lat liegend",
    },
  ],
};
export const QaApiResponse1705 = {
  data: [
    {
      examid: 1705,
      qaAnatomyCriteria: 'Abbildung der bildempfänger nahen ""Hundefigur""',
      qaCriteriaId: 434,
      colors: {
        Hundefigur1705Fill: "#dce11f",
      },
      exam: "Lendenwirbelsäule v.d.obl liegend",
    },
    {
      examid: 1705,
      qaAnatomyCriteria:
        "Hundeschnauze projiziert sich in den Lendenwirbelkörper",
      qaCriteriaId: 435,
      colors: {
        LWK1705Fill: "#175527",
      },
      exam: "Lendenwirbelsäule v.d.obl liegend",
    },
    {
      examid: 1705,
      qaAnatomyCriteria: "Freie Einsehbarkeit der Intervertebralgelenke",
      qaCriteriaId: 436,
      colors: {
        Intervertebralgelenke1705Fill: "#cf2927",
      },
      exam: "Lendenwirbelsäule v.d.obl liegend",
    },
  ],
};
export const QaApiResponse1706 = {
  data: [
    {
      examid: 1706,
      qaAnatomyCriteria: 'Abbildung der bildempfängernahen ""Hundefigur""',
      qaCriteriaId: 437,
      colors: {
        Hundefigur1706Fill: "#dce11f",
      },
      exam: "Lendenwirbelsäule links anliegend v.d.obl stehend",
    },
    {
      examid: 1706,
      qaAnatomyCriteria: "Freie Einsehbarkeit der Intervertebralgelenke",
      qaCriteriaId: 438,
      colors: {
        Intervertebralgelenke1706Fill: "#cf2927",
      },
      exam: "Lendenwirbelsäule links anliegend v.d.obl stehend",
    },
  ],
};
export const QaApiResponse1707 = {
  data: [
    {
      examid: 1707,
      qaAnatomyCriteria:
        "Die gesamte Lendenwirbelsäule ist abgebildet, inkl. des BWS-LWS- und des LWS-Kreuzbein-Überganges",
      qaCriteriaId: 439,
      colors: {
        LWS1707Fill: "#2b4999",
        BWS_LWS1707Fill: "#d81e84",
        LWS_Kreuzbein1707Fill: "#6cb52d",
      },
      exam: "Lendenwirbelsäule Inklination lat stehend",
    },
    {
      examid: 1707,
      qaAnatomyCriteria:
        "Grund- und Deckplatten sind orthograd und somit im Bereich des Zentralstrahles strichförmig dargestellt",
      qaCriteriaId: 440,
      colors: {
        Grund_Deckplatten1707Fill: "#175527",
      },
      exam: "Lendenwirbelsäule Inklination lat stehend",
    },
    {
      examid: 1707,
      qaAnatomyCriteria:
        "Wirbelkörper sind ohne Doppelkonturen der hinteren wirbelkante dargestellt",
      qaCriteriaId: 441,
      colors: {
        Wirbelkanten1707Fill: "#47190c",
        WK1707Fill: "#f9b220",
      },
      exam: "Lendenwirbelsäule Inklination lat stehend",
    },
    {
      examid: 1707,
      qaAnatomyCriteria: "Inklination auf dem Bild vermerken!",
      qaCriteriaId: 442,
      colors: {
        Inklination1707Fill: "#08afe6",
      },
      exam: "Lendenwirbelsäule Inklination lat stehend",
    },
  ],
};
export const QaApiResponse1708 = {
  data: [
    {
      examid: 1708,
      qaAnatomyCriteria:
        "Die gesamte Lendenwirbelsäule ist abgebildet, inkl. des BWS-LWS- und des LWS-Kreuzbein-Überganges",
      qaCriteriaId: 443,
      colors: {
        LWS1708Fill: "#2b4999",
        BWS_LWS1708Fill: "#d81e84",
        LWS_Kreuzbein1708Fill: "#6cb52d",
      },
      exam: "Lendenwirbelsäule Inklination lat stehend",
    },
    {
      examid: 1708,
      qaAnatomyCriteria:
        "Grund- und Deckplatten sind orthograd und somit im Bereich des Zentralstrahles strichförmig dargestellt",
      qaCriteriaId: 444,
      colors: {
        Grund_Deckplatten1708Fill: "#175527",
      },
      exam: "Lendenwirbelsäule Inklination lat stehend",
    },
    {
      examid: 1708,
      qaAnatomyCriteria:
        "Wirbelkörper sind ohne Doppelkonturen der hinteren Wirbelkante dargestellt",
      qaCriteriaId: 445,
      colors: {
        WK1708Fill: "#f9b220",
        Wirbelkanten1708Fill: "#47190c",
        
      },
      exam: "Lendenwirbelsäule Inklination lat stehend",
    },
    {
      examid: 1708,
      qaAnatomyCriteria: "Reklamation auf dem Bild vermerken!",
      qaCriteriaId: 447,
      colors: {
        Inklination1708Fill: "#08afe6",
      },
      exam: "Lendenwirbelsäule Inklination lat stehend",
    },
  ],
};
export const QaApiResponse1709 = {
  data: [
    {
      examid: 1709,
      qaAnatomyCriteria:
        "Entsprechender WK mit Querfortsatz und Dornfortsatz stellt sich in der Mitte des Bildempfängers dar",
      qaCriteriaId: 448,
      colors: {
        Full_WK1709Fill: "#f26a49",
      },
      exam: "1. Lendenwirbelkörper v.d liegend",
    },
    {
      examid: 1709,
      qaAnatomyCriteria: "Angrenzende WK erkennbar",
      qaCriteriaId: 449,
      colors: {
        Angrenzende_WK1709Fill: "#45b649",
      },
      exam: "1. Lendenwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1710 = {
  data: [
    {
      examid: 1710,
      qaAnatomyCriteria:
        "Entsprechender WK stellt sich in der Mitte des Bildempfängers dar",
      qaCriteriaId: 450,
      colors: {
        WK1710Fill: "#c04599",
      },
      exam: "1. Lendenwirbelkörper lat liegend",
    },
    {
      examid: 1710,
      qaAnatomyCriteria: "Angrenzende WK erkennbar",
      qaCriteriaId: 451,
      colors: {
        Angrenzende_WK1710Fill: "#cedc28",
      },
      exam: "1. Lendenwirbelkörper lat liegend",
    },
    {
      examid: 1710,
      qaAnatomyCriteria: "Querfortsatz und Dornfortsatz beurteilbar",
      qaCriteriaId: 452,
      colors: {
        Querfortsatz1710Fill: "#f26a49",
        Dornfortsatz1710Fill: "#45b649",
      },
      exam: "1. Lendenwirbelkörper lat liegend",
    },
  ],
};
export const QaApiResponse1711 = {
  data: [
    {
      examid: 1711,
      qaAnatomyCriteria:
        "Entsprechender WK mit Querfortsatz und Dornfortsatz  stellt sich in der Mitte des Bildempfängers dar",
      qaCriteriaId: 453,
      colors: {
        Full_WK1711Fill: "#f26a49",
      },
      exam: "2. Lendenwirbelkörper v.d liegend",
    },
    {
      examid: 1711,
      qaAnatomyCriteria: "Angrenzende WK erkennbar",
      qaCriteriaId: 454,
      colors: {
        Angrenzende_WK1711Fill: "#45b649",
      },
      exam: "2. Lendenwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1712 = {
  data: [
    {
      examid: 1712,
      qaAnatomyCriteria:
        "Entsprechender WK stellt sich in der Mitte des Bildempfängers dar",
      qaCriteriaId: 455,
      colors: {
        WK1712Fill: "#c04599",
      },
      exam: "2. Lendenwirbelkörper lat liegend",
    },
    {
      examid: 1712,
      qaAnatomyCriteria: "Angrenzende WK erkennbar",
      qaCriteriaId: 456,
      colors: {
        Angrenzende_WK1712Fill: "#cedc28",
      },
      exam: "",
    },
    {
      examid: 1712,
      qaAnatomyCriteria: "Querfortsatz und Dornfortsatz beurteilbar",
      qaCriteriaId: 457,
      colors: {
        Querfortsatz1712Fill: "#f26a49",
        Dornfortsatz1712Fill: "#45b649",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1713 = {
  data: [
    {
      examid: 1713,
      qaAnatomyCriteria:
        "Entsprechender WK mit Querfortsatz und Dornfortsatz  stellt sich in der Mitte des Bildempfängers dar",
      qaCriteriaId: 458,
      colors: {
        Full_WK1713Fill: "#f26a49",
      },
      exam: "3. Lendenwirbelkörper v.d liegend",
    },
    {
      examid: 1713,
      qaAnatomyCriteria: "Angrenzende WK erkennbar",
      qaCriteriaId: 459,
      colors: {
        Angrenzende_WK1713Fill: "#45b649",
      },
      exam: "3. Lendenwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1714 = {
  data: [
    {
      examid: 1714,
      qaAnatomyCriteria:
        "Entsprechender WK stellt sich in der Mitte des Bildempfängers dar",
      qaCriteriaId: 460,
      colors: {
        WK1714Fill: "#c04599",
      },
      exam: "3. Lendenwirbelkörper lat liegend",
    },
    {
      examid: 1714,
      qaAnatomyCriteria: "Angrenzende WK erkennbar",
      qaCriteriaId: 461,
      colors: {
        Angrenzende_WK1714Fill: "#cedc28",
      },
      exam: "",
    },
    {
      examid: 1714,
      qaAnatomyCriteria: "Querfortsatz und Dornfortsatz beurteilbar",
      qaCriteriaId: 462,
      colors: {
        Querfortsatz1714Fill: "#f26a49",
        Dornfortsatz1714Fill: "#45b649",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1715 = {
  data: [
    {
      examid: 1715,
      qaAnatomyCriteria:
        "Entsprechender WK mit Querfortsatz und Dornfortsatz  stellt sich in der Mitte des Bildempfängers dar",
      qaCriteriaId: 463,
      colors: {
        Full_WK1715Fill: "#f26a49",
      },
      exam: "4. Lendenwirbelkörper v.d liegend",
    },
    {
      examid: 1715,
      qaAnatomyCriteria: "Angrenzende WK erkennbar",
      qaCriteriaId: 464,
      colors: {
        Angrenzende_WK1715Fill: "#45b649",
      },
      exam: "4. Lendenwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1716 = {
  data: [
    {
      examid: 1716,
      qaAnatomyCriteria:
        "Entsprechender WK stellt sich in der Mitte des Bildempfängers dar",
      qaCriteriaId: 465,
      colors: {
        WK1716Fill: "#c04599",
      },
      exam: "4. Lendenwirbelkörper lat liegend",
    },
    {
      examid: 1716,
      qaAnatomyCriteria: "Angrenzende WK erkennbar",
      qaCriteriaId: 466,
      colors: {
        Angrenzende_WK1716Fill: "#cedc28",
      },
      exam: "4. Lendenwirbelkörper lat liegend",
    },
    {
      examid: 1716,
      qaAnatomyCriteria: "Querfortsatz und Dornfortsatz beurteilbar",
      qaCriteriaId: 467,
      colors: {
        Querfortsatz1716Fill: "#f26a49",
        Dornfortsatz1716Fill: "#45b649",
      },
      exam: "4. Lendenwirbelkörper lat liegend",
    },
  ],
};
export const QaApiResponse1717 = {
  data: [
    {
      examid: 1717,
      qaAnatomyCriteria:
        "Entsprechender WK mit Querfortsatz und Dornfortsatz  stellt sich in der Mitte des Bildempfängers dar",
      qaCriteriaId: 468,
      colors: {
        Full_WK1717Fill: "#f26a49",
      },
      exam: "5. Lendenwirbelkörper v.d liegend",
    },
    {
      examid: 1717,
      qaAnatomyCriteria: "Angrenzende WK erkennbar",
      qaCriteriaId: 469,
      colors: {
        Angrenzende_WK1717Fill: "#45b649",
      },
      exam: "5. Lendenwirbelkörper v.d liegend",
    },
  ],
};
export const QaApiResponse1718 = {
  data: [
    {
      examid: 1718,
      qaAnatomyCriteria:
        "Entsprechender WK stellt sich in der Mitte des Bildempfängers dar",
      qaCriteriaId: 470,
      colors: {
        WK1718Fill: "#c04599",
      },
      exam: "5. Lendenwirbelkörper lat liegend",
    },
    {
      examid: 1718,
      qaAnatomyCriteria: "Angrenzende WK erkennbar",
      qaCriteriaId: 471,
      colors: {
        Angrenzende_WK1718Fill: "#cedc28",
      },
      exam: "5. Lendenwirbelkörper lat liegend",
    },
    {
      examid: 1718,
      qaAnatomyCriteria: "Querfortsatz und Dornfortsatz beurteilbar",
      qaCriteriaId: 472,
      colors: {
        Querfortsatz1718Fill: "#f26a49",
        Dornfortsatz1718Fill: "#45b649",
      },
      exam: "5. Lendenwirbelkörper lat liegend",
    },
  ],
};
export const QaApiResponse1719 = {
  data: [
    {
      examid: 1719,
      qaAnatomyCriteria: "Symmetrische Darstellung der Foramina des Sacrums",
      qaCriteriaId: 473,
      colors: {
        Foramina1719Fill: "#e72166",
      },
      exam: "Kreuzbein v.d liegend",
    },
    {
      examid: 1719,
      qaAnatomyCriteria:
        "Vollständige Darstellung des Kreuzbeins sowie der Iliosakralgelenke",
      qaCriteriaId: 474,
      colors: {
        Kreuzbein1719Fill: "#dce11f",
        Iliosakralgelenke1719Fill: "#35469d",
      },
      exam: "",
    },
  ],
};
export const QaApiResponse1720 = {
  data: [
    {
      examid: 1720,
      qaAnatomyCriteria:
        "Vollständige und streng laterale Darstellung des Kreuzbeins",
      qaCriteriaId: 475,
      colors: {
        Sacrum1720Fill: "#e72166",
      },
      exam: "Kreuzbein lat liegend",
    },
  ],
};
export const QaApiResponse1721 = {
  data: [
    {
      examid: 1721,
      qaAnatomyCriteria:
        "Überlagerungsfreie und symmetrische Darstellung des Steißbein",
      qaCriteriaId: 476,
      colors: {
        Coccyx1721Fill: "#e72166",
      },
      exam: "Steißbein v.d liegend",
    },
  ],
};
export const QaApiResponse1722 = {
  data: [
    {
      examid: 1722,
      qaAnatomyCriteria:
        "Exakt laterale Abbildung des scharf konturierten seitlichen Steißbeins",
      qaCriteriaId: 477,
      colors: {
        Coccyx1722Fill: "#e72166",
      },
      exam: "Steißbein lat liegend",
    },
    {
      examid: 1722,
      qaAnatomyCriteria: "Keine Doppelkonturen sichtbar",
      qaCriteriaId: 478,
      colors: {},
      exam: "Steißbein lat liegend",
    },
  ],
};
export const QaApiResponse1723 = {
  data: [
    {
      examid: 1723,
      qaAnatomyCriteria:
        "Gute Darstellung der unteren LWS und von oberen Anteilen des os sacrum",
      qaCriteriaId: 479,
      colors: {
        LWS_Sacrum1723Fill: "#dce11f",
      },
      exam: "Lumbosacraler Übergang v.d liegend",
    },
    {
      examid: 1723,
      qaAnatomyCriteria: "Symmetrische Darstellung der Foramina des Sacrum",
      qaCriteriaId: 480,
      colors: {
        Foramina1723Fill: "#e72166",
      },
      exam: "Lumbosacraler Übergang v.d liegend",
    },
  ],
};
export const QaApiResponse1724 = {
  data: [
    {
      examid: 1724,
      qaAnatomyCriteria:
        "Gute Darstellung der unteren LWS und von oberen Anteilen des os sacrum",
      qaCriteriaId: 481,
      colors: {
        LWS_Sacrum1724Fill: "#dce11f",
      },
      exam: "Lumbosacraler Übergang v.d stehend",
    },
    {
      examid: 1724,
      qaAnatomyCriteria: "Symmetrische Darstellung der Foramina des Sacrum",
      qaCriteriaId: 482,
      colors: {
        Foramina1724Fill: "#e72166",
      },
      exam: "Lumbosacraler Übergang v.d stehend",
    },
  ],
};
export const QaApiResponse1725 = {
  data: [
    {
      examid: 1725,
      qaAnatomyCriteria:
        "Exakt laterale Abbildung des scharf konturierten unteren LWS bis hin zum seitlichen Steißbeins",
      qaCriteriaId: 483,
      colors: { UnterenLWS1725Fill: "#dce11f", Coccyx1725Fill: "#45b649" },

      exam: "Lumbosacraler Übergang lat liegend",
    },
    {
      examid: 1725,
      qaAnatomyCriteria:
        "Exakt laterale Abbildung des scharf konturierten unteren LWS bis hin zum seitlichen Steißbeins",
      qaCriteriaId: 484,
      colors: {
        Grund_Deckplatten1725Fill: "#e72166",
      },

      exam: "Lumbosacraler Übergang lat liegend",
    },
    {
      examid: 1725,
      qaAnatomyCriteria: "Keine Doppelkonturen sichtbar",
      qaCriteriaId: 485,
      colors: {},
      exam: "Lumbosacraler Übergang lat liegend",
    },
  ],
};
export const QaApiResponse1726 = {
  data: [
    {
      examid: 1726,
      qaAnatomyCriteria:
        "Exakt laterale Abbildung des scharf konturierten unteren LWS und seitlichen Steißbeins",
      qaCriteriaId: 486,
      colors: { UnterenLWS1726Fill: "#dce11f", Coccyx1726Fill: "#45b649" },

      exam: "Lumbosacraler Übergang lat stehend",
    },
    {
      examid: 1726,
      qaAnatomyCriteria:
        "Im Bereich des Zentralstrahles Deck- und Bodenplatte der LWS Strichförmig",
      qaCriteriaId: 487,
      colors: {
        Grund_Deckplatten1726Fill: "#e72166",
      },
      exam: "Lumbosacraler Übergang lat stehend",
    },
    {
      examid: 1726,
      qaAnatomyCriteria: "Keine Doppelkonturen sichtbar",
      qaCriteriaId: 488,
      colors: {},
      exam: "Lumbosacraler Übergang lat stehend",
    },
  ],
};
export const QaApiResponse1727 = {
  data: [
    {
      examid: 1727,
      qaAnatomyCriteria: 'Abbildung der bildempfängernahen ""Hundefigur""',
      qaCriteriaId: 489,
      colors: {
        Hundefigur1727Fill: "#dce11f",
      },
      exam: "Lendenwirbelsäule rechts anliegend v.d.obl stehend",
    },
    {
      examid: 1727,
      qaAnatomyCriteria: "Freie Einsehbarkeit der Intervertebralgelenke",
      qaCriteriaId: 490,
      colors: {
        Intervertebralgelenke1727Fill: "#e72166",
      },
      exam: "Lendenwirbelsäule rechts anliegend v.d.obl stehend",
    },
  ],
};
export const QaApiResponse1728 = {
  data: [
    {
      examid: 1728,
      qaAnatomyCriteria: "Darstellung aller LWK",
      qaCriteriaId: 491,
      colors: {
        LWK1728Fill: "#e72166",
      },
      exam: "Lendenwirbelsäule mit angestellter Kassette lat liegend",
    },
  ],
};
export const QaApiResponse1801 = {
  data: [
    {
      examid: 1801,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein.",
      qaCriteriaId: 492,
      colors: {
        ribs1801Fill: "#e4ea11",
      },
      exam: "Linke Rippen d.v liegend",
    },
    {
      examid: 1801,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 493,
      colors: {soft1801Fill: "#7E20F7"},
      exam: "Linke Rippen d.v liegend",
    },
  ],
};
export const QaApiResponse1802 = {
  data: [
    {
      examid: 1802,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein.",
      qaCriteriaId: 494,
      colors: {
        ribs1802Fill: "#e4ea11",
      },
      exam: "Rechte Rippen d.v liegend",
    },
    {
      examid: 1802,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 495,
      colors: {soft1802Fill: "#7E20F7"},
      exam: "Rechte Rippen d.v liegend",
    },
  ],
};
export const QaApiResponse1803 = {
  data: [
    {
      examid: 1803,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein",
      qaCriteriaId: 496,
      colors: {
        ribs1803Fill: "#e4ea11",
      },
      exam: "Linke Rippen v.d liegend",
    },
    {
      examid: 1803,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 497,
      colors: {soft1803Fill: "#7E20F7"},
      exam: "Linke Rippen v.d liegend",
    },
  ],
};
export const QaApiResponse1804 = {
  data: [
    {
      examid: 1804,
      qaAnatomyCriteria:
        "Die Rippen müssen vollständig abgebildet und die Knochenstrukturen und -konturen gut erkennbar sein",
      qaCriteriaId: 498,
      colors: {
        ribs1804Fill: "#e4ea11",
      },
      exam: "Rechte Rippen v.d liegend",
    },
    {
      examid: 1804,
      qaAnatomyCriteria:
        "Darstellung von skelettnahen Weichteilen in Abhängigkeit von der Fragestellung",
      qaCriteriaId: 499,
      colors: {soft1804Fill: "#7E20F7"},
      exam: "Rechte Rippen v.d liegend",
    },
  ],
};
