const directionsDE = [
  "Bitte entfernen Sie Haarspangen,Haarnadeln, Kopfschmuck und Zopfgummis mit Metall.",
  "Bitte entfernen Sie Piercings und Zahnersatz aus ihrem Gesicht.",
  "Bitte entfernen Sie Halsketten und anderen Halsschmuck.",
  "Entfernen Sie Ringe, Armbänder,Uhren und anderen Handschmuck.",
  "Bitte ziehen Sie Schuhe und Socken aus und entfernen Sie Fußkettchen und anderen Schmuck.",
  "Bitte legen Sie langärmlige Oberteile ab.",
  "Bitte sehen Sie lange Hosen aus.",
  "Ziehen sie ihr Oberteil aus.",
  "Bitte ziehen Sie die Hose aus.",
];

const directionsPL = [
  "Proszę zdjąć wsówki, spinki do włosów, nakrycia głowy i gumki do warkoczy z metalem.",
  "Proszę usunąć kolczyki i protezy z twarzy.",
  "Proszę zdjąć naszyjniki i inną biżuterię z szyi.",
  "Zdejmij pierścionki, bransoletki, zegarki i inną biżuterię z rąk.",
  "Proszę zdjąć buty i skarpetki oraz zdjąć bransoletki i inną biżuterię.",
  "Proszę zdjąć bluzkę z długim rękawem.",
  "Proszę nosić długie spodnie.",
  "Zdejmij koszulkę.",
  "Proszę zdjąć spodnie.",
];
const directionsEN = [
  "Please remove hair clips, hairpins, headgear, and ponytail holders with metal.",
  "Please remove piercings and dentures from your face.",
  "Please remove necklaces and other neck jewelry.",
  "Remove rings, bracelets, watches, and other hand jewelry.",
  "Please take off shoes and socks and remove anklets and other jewelry.",
  "Please take off long-sleeved tops.",
  "Please wear long pants.",
  "Take off your top.",
  "Please take off the pants.",
];
const directionsNL = [
  "Verwijder alstublieft haarspelden, haarspelden, hoofddeksels en staartjes met metaal.",
  "Verwijder alstublieft piercings en kunstgebitten van je gezicht.",
  "Verwijder alstublieft kettingen en andere nekjuwelen.",
  "Verwijder ringen, armbanden, horloges en andere handjuwelen.",
  "Trek alstublieft schoenen en sokken uit en verwijder enkelbandjes en andere juwelen.",
  "Trek alstublieft lange mouwen uit.",
  "Trek alstublieft lange broeken aan.",
  "Doe je top uit.",
  "Trek alstublieft de broek uit.",
];
const directionsTUR = [
  "Lütfen saç tokaları, saç iğneleri, başlık ve metal olan lastikleri çıkarın.",
  "Lütfen yüzünüzden piercingleri ve protezleri çıkarın.",
  "Lütfen kolyeleri ve diğer boyun takılarını çıkarın.",
  "Yüzükleri, bilezikleri, saatleri ve diğer el takılarını çıkarın.",
  "Lütfen ayakkabıları ve çorapları çıkarın ve ayak bilezikleri ve diğer takıları çıkarın.",
  "Lütfen uzun kollu üstleri çıkarın.",
  "Lütfen uzun pantolon giyin.",
  "Üstünüzü çıkarın.",
  "Lütfen pantolonu çıkarın.",
];

const directionsUKR = [
  "Будь ласка, зніміть заколки для волосся, шпильки для волосся, головні убори та гумки для коси з металу.",
  "Будь ласка, зніміть пірсинг та зубні протези з вашого обличчя.",
  "Будь ласка, зніміть намиста та інші прикраси на шиї.",
  "Зніміть кільця, браслети, годинники та інші рукавички.",
  "Будь ласка, зніміть взуття та шкарпетки та зніміть браслети на нозі та інші прикраси.",
  "Будь ласка, зніміть довгі рукави.",
  "Будь ласка, носіть довгі штани.",
  "Зніміть свій топ.",
  "Будь ласка, зніміть штани.",
];

const directionsMap = {
  de: directionsDE,
  pl: directionsPL,
  gb: directionsEN,
  nl: directionsNL,
  tur: directionsTUR,
  ua: directionsUKR,
};

function useExamPreparationDirections(patPrep, language = null) {
  if (!patPrep || patPrep.length !== 9) return null;
  const lang = language || window.conf.LANG;
  const directionsToUse = directionsMap[lang.toLowerCase()];
  const directions = [];
  for (let i = 0; i < patPrep.length; i++) {
    if (patPrep[i] === "1") {
      directions.push(directionsToUse[i]);
    }
  }

  return directions;
}

export default useExamPreparationDirections;
