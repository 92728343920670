import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useExaminationsInfinite } from "@Utils/FetchHooks/Ris/RisHooks";
import { ClipLoader } from "react-spinners";
import { Button } from "react-bootstrap";
import BaseSearch from "../SearchBars/BaseSearch/BaseSearch";
import styles from "./V3ExamList.module.scss";
import { ExamPlannerExaminationImage } from "@Components/ExamPlannerV3/Components/ExamPlannerImage";
import useWindowSize from "@Utils/Window/windowSize";

const PAGE_SIZE = 20;
const LABEL_ACCESSOR =
  window.conf.LANG === "DE"
    ? "designationde"
    : window.conf.LANG === "PL"
    ? "designationpl"
    : "designationde";
const ABBR = "abbrde";

function V3ExamList({
  examListShrinked = false,
  setExamListShrinked = () => {},
  selectedExams = [],
  selectedExamsSetter = () => {},
  shrinkOnClick = false,
  preSearchText = "Search for exams",
}) {
  const { t } = useTranslation(["common"]);
  const [examsFilter, setExamsFilter] = useState(undefined);
  const { data, isLoading, isError, refetch, fetchNextPage, hasNextPage } =
    useExaminationsInfinite({
      pageSize: PAGE_SIZE,
      filter: examsFilter,
      sort: { field: "designationde", direction: "asc" },
    });
  const [exams, setExams] = useState([]);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width < 1000 && !examListShrinked) {
      setExamListShrinked(true);
    }
  }, [width]);

  useEffect(() => {
    if (!data) return;
    if (!data.pages) return;
    let exams = [];
    data.pages.forEach((page) => {
      if (page?.examinations) {
        exams = exams.concat(page.examinations);
      }
    });
    setExams(exams);
  }, [data]);

  return (
    <div>
      <div className="d-flex mb-2 align-items-center">
        {width > 768 && <h4 className="">{preSearchText}</h4>}
        <BaseSearch
          field={[LABEL_ACCESSOR, ABBR]}
          filterSetter={setExamsFilter}
          className={`${width > 768 && "ml-auto"}`}
        />
        {/* <div className="my-2" title="Shrink window">
          <Button onClick={() => setExamListShrinked(true)}>
            <i className="fas fa-chevron-left" />
          </Button>
        </div> */}
      </div>
      {isLoading && <ClipLoader size={50} />}
      {isError && (
        <>
          <h3>{t("erros.unableToLoad")}</h3>
          <Button onClick={refetch}>{t("buttons.reload")}</Button>
        </>
      )}
      <div className={styles.ExamListWrapper}>
        {!isLoading &&
          exams.map((exam) => (
            <div
              onClick={() => {
                selectedExamsSetter(exam);
                if (shrinkOnClick) {
                  setExamListShrinked(true);
                }
              }}
              className={`${styles.ExamRow} ${
                selectedExams.findIndex((e) => e.id === exam.id) > -1 &&
                styles.selected
              }`}
              key={`${exam.id}_${exam[LABEL_ACCESSOR]}`}
            >
              <ExamPlannerExaminationImage
                examId={exam?.id}
                style={{
                  width: "50px",
                  maxHeight: "50px",
                  marginRight: "10px",
                }}
              />
              {exam[LABEL_ACCESSOR]}
            </div>
          ))}
        {!isLoading && exams.length === 0 && (
          <>
            <h3>{t("erros.unableToLoad")}</h3>
            <Button onClick={refetch}>Reload</Button>
          </>
        )}
        <Button disabled={!hasNextPage} onClick={fetchNextPage}>
          {t("buttons.loadMore")}
        </Button>
      </div>
    </div>
  );
}

export default V3ExamList;
