import { useState, useMemo, useCallback, useEffect } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { useDropzone } from "react-dropzone";
import SelectPatientModal from "../SelectPatientModal/SelectPatientModal";
import "./stylesheets/ImportFileModal.scss";
import { NotificationManager } from "react-notifications";
import { UploadPatientFile } from "../../Utilities/FetchHelpers/PatientUploadsFetches";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";
import { setReloadExaminationsFlag } from "../../Redux/actions";
import { parseDicom } from "dicom-parser";
import base64ArrayBuffer from "../../Utilities/FileUtils/b64ArrayBuffer";
import { UploadDicom } from "../../Utilities/FetchHelpers/UploadDicom";
import { usePatient } from "../../Utilities/FetchHooks/Patients/PatientsHooks";
import isAutherized from "../../Utilities/isAuthorized";
import { useTranslation } from "react-i18next";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "40px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function DocumentImport({ close }) {
  const { keycloak } = useKeycloak();
  const [inp, setInp] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(undefined);
  const [selectPatientModalShown, setSelectPatientModalShown] = useState(false);
  const [patComment, setPatComment] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation(["modals", "common"]);

  const patientQ = usePatient({
    patientId: keycloak.idTokenParsed.pmed_patient_id,
    enabled: keycloak.hasRealmRole("pm_patient"),
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: "image/*,application/pdf",
      onDrop,
    });

  const removeAll = () => {
    setMyFiles([]);
  };

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const getFileImage = (fileType) => {
    switch (fileType) {
      case "image/png":
        return "fas fa-image p-2 ";
      case "image/jpeg":
        return "fas fa-image p-1";
      case "application/pdf":
        return "fas fa-file-pdf p-2";
      default:
        return "fas fa-file p-2";
    }
  };

  const rowClickHandler = (row) => {
    setSelectedPatient(row);
    setSelectPatientModalShown(false);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          file_name: file.name,
          file_type: file.type,
          file: reader.result.slice(reader.result.indexOf(",")),
        });
      reader.onerror = reject;
    });

  const clearState = () => {
    setMyFiles([]);
    setSelectedPatient(undefined);
  };

  const handleSaving = () => {
    let promises = [];
    let metas = [];
    setInp(true);
    myFiles.forEach((file) => {
      promises.push(getBase64(file));
    });
    Promise.all(promises)
      .then((values) => {
        metas = [...values];
        let body = {"imports": metas.map((meta) => {
          return { file_name: meta.file_name,
                    file_mimetype: meta.file_type,
                    status: 0,
                    import_options_json: {
                      dest_patient_id: selectedPatient.id,
                      comment: patComment,
                    },
                    file_base64: meta.file,}
        })};
        UploadPatientFile(keycloak, selectedPatient.id, body)
          .then(() => {
            NotificationManager.success(t("alerts.importingSuccessRefresh"));
            setTimeout(() => {
              dispatch(setReloadExaminationsFlag(true));
            }, 1 * 1000 * 6);
            setTimeout(() => {
              dispatch(setReloadExaminationsFlag(true));
            }, 1 * 1000 * 12);
            setInp(false);
            clearState();
            close();
          })
          .catch((reason) => {
            NotificationManager.warning(t("alerts.unableImportFiles"));
            console.warn("Unable to import files: ", reason);
          });
      })
      .catch((reason) => {
        NotificationManager.warn(t("alerts.unableImportFiles"));
        console.warn("Unable to import files: ", reason);
      })
      .finally(() => {
        setInp(false);
      });
  };

  useEffect(() => {
    if (patientQ.isSuccess) {
      setSelectedPatient(patientQ.data);
    }
  }, [patientQ]);

  return (
    <>
      <SelectPatientModal
        className="super-high-modal"
        amount={5}
        close={() => setSelectPatientModalShown(false)}
        shown={selectPatientModalShown}
        rowClickHandler={rowClickHandler}
      />
      <div className="" style={{ height: "75%" }}>
        <div className="d-flex text-left">
          <h4 className="mr-3">{t("fileImport.docImport")}</h4>
          {inp && (
            <ClipLoader color="#a1a1a1" loading={true} css={true} size={30} />
          )}
        </div>
        <div
          {...getRootProps({
            className: "dropzone mb-3",
            style: style,
          })}
        >
          <input {...getInputProps()} />
          <p>{t("fileImport.dragDropGraphFile")}</p>
        </div>
        <div
          className="file-list d-flex flex-column"
          style={{ overflowY: "auto", maxHeight: "25vh" }}
        >
          <div className="pr-2">
            {myFiles.map((file, idx) => (
              <Row
                key={`${file.path}${idx}`}
                className={`${idx === 0 && "border-top"} border-bottom py-1`}
              >
                <Col
                  xs={1}
                  className="d-flex align-items-center justif-content-center"
                >
                  <div className="btn-box" title="Delete file">
                    <i
                      onClick={removeFile(file)}
                      className={`${getFileImage(file.type)} fa-sm`}
                    />
                  </div>
                </Col>
                <Col xs={9} className="text-left">
                  {file.name}
                </Col>
                <Col
                  xs={2}
                  className="d-flex align-items-center justify-content-right"
                >{`${Math.round(file.size / 1000, 2)} KB`}</Col>
              </Row>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-auto d-flex w-100">
        <textarea
          className="border rounded p-2 w-100"
          placeholder={t("addComment", { ns: "common" })}
          value={patComment}
          onChange={(e) => setPatComment(e.target.value)}
        ></textarea>
      </div>
      <div className="d-flex align-items-center justify-content-between py-1">
        {myFiles.length > 0 && (
          <Button variant="danger" className="mt-3" onClick={removeAll}>
            <i className="fas fa-trash fa-md mr-1" />
            {t("buttons.removeAll", { ns: "common" })}
          </Button>
        )}
        <div>
          <Button
            onClick={() => setSelectPatientModalShown(true)}
            className="mr-1"
            disabled={isAutherized(keycloak, ["pm_patient"])}
          >
            <i className="fas fa-user fa-sm mr-1" />
            {!selectedPatient
              ? t("selectPatient", { ns: "common" })
              : `${selectedPatient.patientsname} ${selectedPatient.patientsvorname}`}
          </Button>
          <Button
            onClick={handleSaving}
            disabled={myFiles.length === 0 || selectedPatient === undefined}
          >
            {inp ? (
              <ClipLoader color="#a1a1a1" loading={true} css={true} size={20} />
            ) : (
              <>
                <i className="fas fa-save fa-sm mr-1" />
                {t("buttons.save", { ns: "common" })}
              </>
            )}
          </Button>
        </div>
      </div>
    </>
  );
}

function getExtension(fname) {
  return fname.slice(((fname.lastIndexOf(".") - 1) >>> 0) + 2);
}

class DcmFile {
  constructor(translate) {
    this.fileName = null;
    this.valid = false;
    this.arrBuffer = null;
    this.dataset = null;
    this.translate = translate;
  }

  recognize() {
    let byteArr = new Uint8Array(this.arrBuffer);
    var s = "";
    var start = 128,
      end = 132;
    for (var j = start; j < end; ++j) {
      s += String.fromCharCode(byteArr[j]);
    }
    this.valid = s === "DICM";
  }

  parseDcm() {
    if (this.valid) {
      this.dataset = parseDicom(new Uint8Array(this.arrBuffer));
      this.sop_instance_uid = this.dataset.string("x00080018");
    } else {
      throw new Error(this.translate("alerts.invalidDCM"));
    }
  }

  setArrBuffer(bytesArr) {
    this.arrBuffer = bytesArr;
    this.recognize();
  }

  getPostBody() {
    return new Promise((resolve, reject) => {
      try {
        let b64 = base64ArrayBuffer(this.arrBuffer);
        resolve({
          sop_instance_uid: this.sop_instance_uid,
          file: b64,
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}

function DatasetBox({ dataset }) {
  const { t } = useTranslation(["modals", "common"]);
  const { patientsName, patientId, modality, studyDate, studyDescription } =
    useMemo(() => {
      let res = {
        patientsName: "",
        patientId: "",
        modality: "",
        studyDate: "",
        studyDescription: "",
      };
      res.patientsName = dataset.string("x00100010") || "";
      res.patientId = dataset.string("x00100020") || "";
      res.modality = dataset.string("x00080060") || "";
      res.studyDate = dataset.string("x00080020") || "";
      res.studyDescription = dataset.string("x00081030") || "";
      return res;
    }, [dataset]);

  return (
    <div className="d-flex flex-column text-left p-2" style={{ gap: "5px" }}>
      {studyDescription !== "" && (
        <div className="d-flex flex-column ">
          <div>{studyDescription}</div>
          <div className="muted" style={{ fontSize: "1.1ch" }}>
            {t("studyDescript", { ns: "common" })}
          </div>
        </div>
      )}

      {patientsName !== "" && (
        <div className="d-flex flex-column ">
          <div>{patientsName.replace("^", " ")}</div>
          <div className="muted" style={{ fontSize: "1.1ch" }}>
            {t("patientname", { ns: "common" })}
          </div>
        </div>
      )}

      {patientId !== "" && (
        <div className="d-flex flex-column ">
          <div>{patientId}</div>
          <div className="muted" style={{ fontSize: "1.1ch" }}>
            {t("id", { ns: "common" })}
          </div>
        </div>
      )}

      {modality !== "" && (
        <div className="d-flex flex-column ">
          <div>{modality}</div>
          <div className="muted" style={{ fontSize: "1.1ch" }}>
            {t("modality", { ns: "common" })}
          </div>
        </div>
      )}

      {studyDate !== "" && (
        <div className="d-flex flex-column ">
          <div>{studyDate}</div>
          <div className="muted" style={{ fontSize: "1.1ch" }}>
            {t("studyDate", { ns: "common" })}
          </div>
        </div>
      )}
    </div>
  );
}

function DicomImport({ close }) {
  const { keycloak } = useKeycloak();
  const [isProcessing, setIsProcessing] = useState(false);
  const [importingInProgress, setImportingInProgress] = useState(false);
  const [myFiles, setMyFiles] = useState([]);
  const [dicoms, setDicoms] = useState([]);
  const { t } = useTranslation(["modals", "common"]);
  const onDrop = useCallback(
    (acceptedFiles) => {
      let myFilesCp = [...myFiles];
      acceptedFiles.forEach((file) => {
        if (
          file.name !== "DICOMDIR" &&
          (file.type === "" || file.type === "application/dicom")
        ) {
          let ext = getExtension(file.name);
          if (ext === "dcm" || ext === "") {
            //just simple checks here later we recognize and validate dcm
            myFilesCp.push(file);
          }
        }
      });
      setMyFiles(myFilesCp);
    },
    [myFiles]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
    });

  const removeAll = () => {
    setMyFiles([]);
    setDicoms([]);
  };

  const removeFile = (dcmfile) => {
    const newFiles = [...myFiles];
    let fidx = newFiles.findIndex((file) => file.name === dcmfile.fileName);
    newFiles.splice(fidx, 1);
    setMyFiles(newFiles);
    const newDcms = [...dicoms];
    newDcms.splice(newDcms.indexOf(dcmfile), 1);
    setDicoms(newDcms);
  };

  const removeFiles = (dcmFiles) => {
    const newFiles = [...myFiles];
    const newDcms = [...dicoms];

    dcmFiles.forEach((dcmfile) => {
      let fidx = newFiles.findIndex((file) => file.name === dcmfile.fileName);
      newFiles.splice(fidx, 1);
      newDcms.splice(newDcms.indexOf(dcmfile), 1);
    });
    setMyFiles(newFiles);
    setDicoms(newDcms);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const loadFileAsByteArr = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (evt) => {
        resolve(evt.target.result);
      };
      reader.onerror = reject;
    });
  };

  const parseFiles = async () => {
    let dcms = [];
    for (let i = 0; i < myFiles.length; i++) {
      let file = myFiles[i];
      let byteArr = await loadFileAsByteArr(file);

      let dcmfile = new DcmFile(t);
      dcmfile.fileName = file.name;
      dcmfile.setArrBuffer(byteArr);

      if (dcmfile.valid) {
        try {
          dcmfile.parseDcm();
        } catch {
          removeFile(file);
        }
      } else {
        removeFile(file);
      }
      dcms.push(dcmfile);
    }
    setDicoms(dcms);
    setIsProcessing(false);
  };

  const save = async () => {
    let fileMetas = [];
    dicoms.forEach((dicom) => {
      fileMetas.push(dicom.getPostBody());
    });

    let files = await Promise.all(fileMetas);
    if (files.length === 0) {
      NotificationManager.error(t("alerts.noFiles2Send"));
    }

    let responsesPromises = [];
    files.forEach((file) => {
      responsesPromises.push(UploadDicom(keycloak, { file }));
    });

    let responses = await Promise.all(responsesPromises);

    let importedSuccesfully = [];
    responses.forEach((resp) => {
      if (Object.keys(resp).includes("sop")) {
        importedSuccesfully.push(
          dicoms.find((dcmFile) => dcmFile.sop_instance_uid === resp.sop)
        );
      }
    });

    if (importedSuccesfully.length === 0) {
      NotificationManager.error(t("alerts.importFailed"));
    }
    if (files.length === importedSuccesfully.length) {
      NotificationManager.success(t("alerts.importSuccess"));
    }
    if (
      importedSuccesfully.length > 0 &&
      importedSuccesfully.length < files.length
    ) {
      NotificationManager.warning(t("alerts.importPartialSuccess"));
    }

    removeFiles(importedSuccesfully);
    setImportingInProgress(false);
  };

  useEffect(() => {
    parseFiles();
  }, [myFiles]);

  return (
    <div>
      <h4>{t("fileImport.dcmImport")}</h4>
      <div
        {...getRootProps({
          className: "dropzone mb-3",
          style: style,
        })}
      >
        <input {...getInputProps()} />
        <p>{t("fileImport.dragDropDcm")}</p>
      </div>
      <div className="d-flex py-1" style={{ gap: "10px", flexWrap: "wrap" }}>
        {isProcessing && <ClipLoader size={50} />}
        {dicoms.map((dcm, key) => (
          <div
            key={key}
            className="dataset-box border rounded"
            onClick={() => removeFile(dcm)}
          >
            <DatasetBox dataset={dcm.dataset} />
          </div>
        ))}
      </div>
      {myFiles.length > 0 && importingInProgress === false && (
        <div className="d-flex" style={{ gap: "10px" }}>
          <Button className="mt-2" variant="danger" onClick={removeAll}>
            <i className="fas fa-trash fa-xl mr-2" />
            {t("buttons.removeAll", { ns: "common" })}
          </Button>
          <Button
            className="mt-2"
            variant="success"
            onClick={() => {
              setImportingInProgress(true);
              setTimeout(save, 100);
            }}
          >
            <i className="fas fa-save fa-xl mr-2" />
            {t("buttons.import", { ns: "common" })}
          </Button>
        </div>
      )}
      {importingInProgress && (
        <div className="d-flex w-100">
          <h5 className="mr-2">{t("fileImport.importing")}</h5>
          <ClipLoader size={25} />
        </div>
      )}
    </div>
  );
}

export default function ImportFileModal({ shown, headerless = false, close }) {
  const [selectedToolIdx, setSelectedToolIdx] = useState(0);
  const { t } = useTranslation(["modals", "common"]);
  const tools = [
    {
      name: t("fileImport.dcmImport"),
      comp: DicomImport,
    },
    {
      name: t("fileImport.docImport"),
      comp: DocumentImport,
    },
  ];

  const ImportToolToRender = tools[selectedToolIdx].comp;

  return (
    <>
      <Modal
        className="top-modal"
        show={shown}
        onHide={close}
        size={"lg"}
        onClick={(e) => e.stopPropagation()}
      >
        <Modal.Header
          className="py-1 px-1 bg-light d-flex"
          style={{ gap: "5px" }}
          closeButton
        >
          {tools.map((tool, key) => (
            <Button
              key={key}
              variant={`${key === selectedToolIdx ? "primary" : "secondary"}`}
              size="sm"
              onClick={() => {
                setSelectedToolIdx(key);
              }}
            >
              {tool.name}
            </Button>
          ))}
        </Modal.Header>
        <Modal.Body className="text-center fixed-body">
          <ImportToolToRender close={close}></ImportToolToRender>
        </Modal.Body>
      </Modal>
    </>
  );
}
