import { useMemo } from "react";
import { capitalize } from "../stringHelpers/stringHelpers";

function updateFilter(searchPhrase, field) {
  let locFilter = undefined;

  if (!searchPhrase || searchPhrase === "") {
    return null;
  }

  if (searchPhrase && searchPhrase !== "" && searchPhrase.includes(" ")) {
    searchPhrase = searchPhrase.replace(" ", "%");
  }

  if (Array.isArray(field)) {
    const filers = [];
    field.forEach((f) => {
      filers.push({
        field: f,
        op: "like",
        value: `%${searchPhrase}%`,
      });
      filers.push({
        field: f,
        op: "like",
        value: `%${searchPhrase.toLowerCase()}%`,
      });
      filers.push({
        field: f,
        op: "like",
        value: `%${searchPhrase.toUpperCase()}%`,
      });
      filers.push({
        field: f,
        op: "like",
        value: `%${capitalize(searchPhrase)}%`,
      });
    });
    locFilter = {
      or: filers,
    };
  } else {
    locFilter = {
      or: [
        {
          field: field,
          op: "like",
          value: `%${searchPhrase}%`,
        },
        {
          field: field,
          op: "like",
          value: `%${searchPhrase.toLowerCase()}%`,
        },
        {
          field: field,
          op: "like",
          value: `%${searchPhrase.toUpperCase()}%`,
        },
        {
          field: field,
          op: "like",
          value: `%${capitalize(searchPhrase)}%`,
        },
      ],
    };
  }
  return locFilter;
}

function usePmedFilter({ field, searchPhrase = "" }) {
  const filter = useMemo(
    () => updateFilter(searchPhrase, field),
    [searchPhrase, field]
  );

  return { filter, updateFilter };
}

export default usePmedFilter;
