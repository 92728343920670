import { Button } from "react-bootstrap";
import styles from "./ContentWrapper.module.scss";
import {
  EXAM_HELPER_ROUTES,
  EXAM_QA_OVERVIEW,
} from "../../../Pages/Consts/ExamHelperRoutes";
import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useExamData from "../../../Components/ExamHelperApp/Hooks/useExamData";

function ContentWrapper({ children }) {
  const { t } = useTranslation(["examHelper", "common"]);
  const url = useRouteMatch();
  const examId = url.params?.examId;
  const { qaAnatomyCriteriaCount } = useExamData(examId, 0);
  const matchingRoute = useMemo(() => {
    return EXAM_HELPER_ROUTES.find((route) => {
      if (route.path) {
        return route.path.includes(url.path);
      } else {
        return route.subMenuItems.find((subMenuItem) => {
          return subMenuItem.path.includes(url.path);
        });
      }
    });
  }, [url]);
  const matchingRouteIndex = EXAM_HELPER_ROUTES.indexOf(matchingRoute);

  const { nextLink, nextName } = useMemo(() => {
    const route =
      matchingRouteIndex < EXAM_HELPER_ROUTES.length - 1 &&
      EXAM_HELPER_ROUTES[matchingRouteIndex + 1]?.path
        ? EXAM_HELPER_ROUTES[matchingRouteIndex + 1]
        : EXAM_HELPER_ROUTES[matchingRouteIndex + 1]?.subMenuItems[0];

    if (
      route &&
      route.id === "EXAM_QA_SINGLE" &&
      qaAnatomyCriteriaCount === 1
    ) {
      return {
        nextLink: EXAM_QA_OVERVIEW?.link || null,
        nextName: EXAM_QA_OVERVIEW?.name(t) || null,
      };
    }
    return { nextLink: route?.link || null, nextName: route?.name(t) || null };
  }, [matchingRouteIndex, qaAnatomyCriteriaCount, t]);

  const { prevLink, prevName } = useMemo(() => {
    const route =
      matchingRouteIndex > 0 && EXAM_HELPER_ROUTES[matchingRouteIndex - 1]?.path
        ? EXAM_HELPER_ROUTES[matchingRouteIndex - 1]
        : EXAM_HELPER_ROUTES[matchingRouteIndex - 1]?.subMenuItems[0];

    return { prevLink: route?.link, prevName: route?.name(t) };
  }, [matchingRouteIndex, t]);

  return (
    <div className={styles.wrapper}>
      {children}
      {matchingRoute && (
        <div className={styles.navigation}>
          {matchingRouteIndex > 0 && (
            <Link
              to={`${examId ? `${prevLink}/${examId}` : prevLink}`}
              data-noprint={true}
            >
              <Button variant="secondary">
                <i className="fas fa-chevron-left mr-2" /> {prevName}
              </Button>
            </Link>
          )}
          {matchingRouteIndex < EXAM_HELPER_ROUTES.length - 1 && (
            <Link
              to={`${examId ? `${nextLink}/${examId}` : nextLink}`}
              className="ml-auto"
            >
              <Button variant="primary" data-noprint={true}>
                {nextName}
                <i className="fas fa-chevron-right ml-2" />
              </Button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

export default ContentWrapper;
