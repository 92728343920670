//Reports related actions
export const SET_REPORT_TYPE = "SET_REPORT_TYPE";
export const ADD_REPORT_SELECTED_ID = "ADD_REPORT_SELECTED_ID";
export const REMOVE_REPORT_SELECTED_ID = "REMOVE_REPORT_SELECTED_ID";
export const ADD_REMOVE_REPORT_SELECTED_ID = "ADD_REMOVE_REPORT_SELECTED_ID";
export const SET_REPORT_DATE_RANGE = "SET_REPORT_DATE_RANGE";
export const ADD_REPORT_FORMAT = "ADD_REPORT_FORMAT";
export const REMOVE_REPORT_FORMAT = "REMOVE_REPORT_FORMAT";
export const SET_INITIAL_REPORT_STATE = "SET_INITIAL_REPORT_STATE";
export const SET_REPORT_IS_PACKAGE = "SET_REPORT_IS_PACKAGE";
export const ADD_REMOVE_REPORT_VARIANT = "ADD_REMOVE_REPORT_VARIANT";
export const CLEAR_REPORT_DETAILS = "CLEAR_REPORT_DETAILS";
export const TOGGLE_CONFIGURATION = "TOGGLE_CONFIGURATION";

//ExamManager related actions
export const SET_OPENED_PATIENT = "SET_OPENED_PATIENT";
export const REMOVE_OPENED_PATIENT = "REMOVE_OPENED_PATIENT";
export const CLEAR_OPENED_PATIENT = "CLEAR_OPENED_PATIENT";
export const SET_WEBVIEWER_AVAILABLE = "SET_WEBVIEWER_AVAILABLE";

//themes related actions
export const SET_ACTIVE_THEME = "SET_ACTIVE_THEME";

//ExanSelecting (patientJournal) related actions
export const SELECT_EXAM = "SELECT_EXAM";
export const UNSELECT_EXAM = "UNSELECT_EXAM";
export const SELECT_EXAM_SERIE = "SELECT_EXAM_SERIE";
export const UNSELECT_EXAM_SERIE = "UNSELECT_EXAM_SERIE";
export const SELECT_EXAM_SERIE_IMAGE = "SELECT_EXAM_SERIE_IMAGE";
export const UNSELECT_EXAM_SERIE_IMAGE = "UNSELECT_EXAM_SERIE_IMAGE";
export const CLEAR_SELECTED_EXAMINATIONS = "CLEAR_SELECTED_EXAMINATIONS";

//Importing Tools related actions
export const ADD_IMPORTING_JOB = "ADD_IMPORTING_JOB";
export const REMOVE_IMPORTING_JOB = "REMOVE_IMPORTING_JOB";
export const UPDATE_IMPORTING_JOB = "UPDATE_IMPORTING_JOB";

//PatientJournal flags
export const SET_RELOAD_PATIENTLIST_FLAG = "SET_RELOAD_PATIENTLIST_FLAG";
export const SET_RELOAD_EXAMINATIONS_FLAG = "SET_RELOAD_EXAMINATIONS_FLAG";
export const SET_SELECT_ALL_EXAMINATIONS_FLAG =
  "SET_SELECT_ALL_EXAMINATIONS_FLAG";
export const SET_EXPAND_ALL_EXAMINATIONS = "SET_EXPAND_ALL_EXAMINATIONS";
export const SET_RELOAD_PATIENTDATA_FLAG = "SET_RELOAD_PATIENTDATA_FLAG";

//Price List related actions
export const ADD_FACILITY_SELECTED_ID = "ADD_FACILITY_SELECTED_ID";
export const CLEAR_SELECTED_FACILITIES = "CLEAR_SELECTED_FACILITIES";
export const ADD_PRICE_INFO = "ADD_PRICE_INFO";
export const ADD_NEW_PRICE = "ADD_NEW_PRICE";
export const REPLACE_NEW_PRICES = "REPLACE_NEW_PRICES";
export const ADD_DEFAULT_PRICES = "ADD_DEFAULT_PRICES";
export const EMPTY_NEW_PRICES = "EMPTY_NEW_PRICES";
export const SET_CHANGED_PRICES = "SET_CHANGED_PRICES";
//ExamPlanner related actions
export const SET_EXAMPLANNER_PATIENT_ID = "SET_EXAMPLANNER_PATIENT_ID";
export const CLEAR_EXAMPLANNER_PATIENT_ID = "CLEAR_EXAMPLANNER_PATIENT_ID";
export const SET_EXAMPLANNER_DEVICE_ID = "SET_EXAMPLANNER_DEVICE_ID";
export const CLEAR_EXAMPLANNER_DEVICE_ID = "CLEAR_EXAMPLANNER_DEVICE_ID";
export const SET_EXAMPLANNER_FACILITY_ID = "SET_EXAMPLANNER_FACILITY_ID";
export const CLEAR_EXAMPLANNER_FACILITY_ID = "CLEAR_EXAMPLANNER_FACILITY_ID";
export const SET_EXAMPLANNER_FACILITY_DOC_ID =
  "SET_EXAMPLANNER_FACILITY_DOC_ID";
export const CLEAR_EXAMPLANNER_FACILITY_DOC_ID =
  "CLEAR_EXAMPLANNER_FACILITY_DOC_ID";
export const SET_EXAMPLANNER_RADIOLOGIST_ID = "SET_EXAMPLANNER_RADIOLOGIST_ID";
export const CLEAR_EXAMPLANNER_RADIOLOGIST_ID =
  "CLEAR_EXAMPLANNER_RADIOLOGIST_ID";
export const ADD_EXAMPLANNER_INDICIATION = "ADD_EXAMPLANNER_INICIATION";
export const REMOVE_EXAMPLANNER_INDICATION = "REMOVE_EXAMPLANNER_INDICATION";
export const CLEAR_EXAMPLANNER_INDICATION = "CLEAR_EXAMPLANNER_INDICATION";
export const ADD_EXAMPLANNER_EXAMINATION = "ADD_EXAMPLANNER_EXAMINATION";
export const REMOVE_EXAMPLANNER_EXAMINATION = "REMOVE_EXAMPLANNER_EXAMINATION";
export const CLEAR_EXAMPLANNER_EXAMINATION = "CLEAR_EXAMPLANNER_EXAMINATION";
export const ADD_EXAMPLANNER_PROCEDURE = "ADD_EXAMPLANNER_PROCEDURE";
export const REMOVE_EXAMPLANNER_PROCEDURE = "REMOVE_EXAMPLANNER_PROCEDURE";
export const CLEAR_EXAMPLANNER_PROCEDURES = "CLEAR_EXAMPLANNER_PROCEDURES";
export const UPDATE_EXAMPLANNER_PROCEDURE = "UPDATE_EXAMPLANNER_PROCEDURE";

//examplanner viewControll actions
export const SELECT_EXAMPLANNER_BODYPART = "SELECT_EXAMPLANNER_BODYPART";
export const CLEAR_EXAMPLANNER_BODYPART = "CLEAR_EXAMPLANNER_BODYPART";
export const SELECT_EXAMPLANNER_DEVICE_TYPE = "SELECT_EXAMPLANNER_DEVICE_TYPE";
export const CLEAR_EXAMPLANNER_VIEW_CONTROLL =
  "CLEAR_EXAMPLANNER_VIEW_CONTROLL";
export const CLEAR_EXAMPLANNER_RIS_DATA = "CLEAR_EXAMPLANNER_RIS_DATA";
export const CLEAR_EXAMPLANNER_STATE = "CLEAR_EXAMPLANNER_STATE";
export const ADD_EXAMPLANNER_BODYPART_TO_HISTORY =
  "ADD_EXAMPLANNER_BODYPART_TO_HISTORY";
export const CLEAR_EXAMPLANNER_BODYPART_HISTORY =
  "CLEAR_EXAMPLANNER_BODYPART_HISTORY";
export const SELECT_PREVIOUS_BODY_PART = "SELECT_PREVIOUS_BODY_PART";

//Exam statistics related actions
export const SET_XMSTATS_FILTER_OBJ = "SET_XMSTATS_FILTER_OBJ";
export const SET_XMSTATS_SELECTED_PATIENT = "SET_XMSTATS_SELECTED_PATIENT";
export const SET_XMSTATS_SELECTED_EXAM = "SET_XMSTATS_SELECTED_EXAM";
export const SET_XMSTATS_SELECTED_SERIE = "SET_XMSTATS_SELECTED_SERIE";
export const SET_XMSTATS_SELECTED_IMAGE = "SET_XMSTATS_SELECTED_IMAGE";
export const SET_XMSTATS_CHANGES_MADE = "SET_XMSTATS_CHANGES_MADE";
export const SET_XMSTATS_RELOAD_FLAG = "SET_XMSTATS_RELOAD_FLAG";
export const SET_XMSTATS_SELECTED_SUBSTANTIATION =
  "SET_XMSTATS_SELECTED_SUBSTANTIATION";
export const RESET_XMSTATS_FILTER_OBJ = "RESET_XMSTATS_FILTER_OBJ";
export const RESET_XMSTATS_STATE = "RESET_XMSTATS_STATE";
export const INCRASE_XMSTATS_SHOULD_SAVE_COUNT =
  "INCRASE_XMSTATS_SHOULD_SAVE_COUNT";
export const SET_XMSTATS_SELECTED_MODALITY = "SET_XMSTATS_SELECTED_MODALITY";
export const SET_XMSTATS_RELOAD_EXAM_FLAG = "SET_XMSTATS_RELOAD_EXAM_FLAG";

//Exam QA
export const SET_QA_SELECTED_EXAM = "SET_QA_SELECTED_EXAM";
export const RESET_QA_SELECTED_EXAM = "RESET_QA_SELECTED_EXAM";
export const SET_QA_SELECTED_SERIE = "SET_QA_SELECTED_SERIE";
export const RESET_QA_SELECTED_SERIE = "RESET_QA_SELECTED_SERIE";
export const SET_QA_SELECTED_IMAGE = "SET_QA_SELECTED_IMAGE";
export const RESET_QA_SELECTED_IMAGE = "RESET_QA_SELECTED_IMAGE";
export const SET_QA_CHANGES = "SET_QA_CHANGES";
export const RESET_QA_CHANGES = "RESET_QA_CHANGES";
export const SAVE_QA_CHANGES = "SAVE_QA_CHANGES";
export const RESET_REFETCH_FLAG = "RESET_REFETCH_FLAG";

//Exam archive
export const CLEAR_EXAM_ARCHIVE_FILTER = "CLEAR_EXAM_ARCHIVE_FILTER";
export const SET_EXAM_ARCHIVE_FILTER = "SET_EXAM_ARCHIVE_FILTER";
export const ADD_REMOVE_EXAM_ARCHIVE_MODALITY =
  "ADD_REMOVE_EXAM_ARCHIVE_MODALITY";
export const SET_EXAM_ARCHIVE_TO_DATE = "SET_EXAM_ARCHIVE_TO_DATE";
export const SET_EXAM_ARCHIVE_FROM_DATE = "SET_EXAM_ARCHIVE_FROM_DATE";
export const SET_EXAM_ARCHIVE_PATIENT_SEARCH =
  "SET_EXAM_ARCHIVE_PATIENT_SEARCH";
export const SET_EXAM_ARCHIVE_DATE_FILTER_ENABLED =
  "SET_EXAM_ARCHIVE_DATE_FILTER_ENABLED";
export const SET_EXAM_ARCHIVE_SORT = "SET_EXAM_ARCHIVE_SORT";
