import { Button } from "react-bootstrap";
import { createContext, useContext, useMemo, useState } from "react";
import { EXAM_LIST_OPTIONS, ExamPlannerContext } from "./ExamPlanner";
import LoadMoreList from "../../../Blueprints/LoadMoreList/LoadMoreList";
import ProcedureModal from "./ProcedureModal";
import {
  useExaminations,
  useProceduresInfinite,
} from "../../../Utilities/FetchHooks/Ris/RisHooks";
import usePmedFilter from "../../../Utilities/Filtering/usePmedFilter";
import useDebounce from "../../../Utilities/Hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import {
  addExamplannerProcedure,
  clearExamplannerProcedures,
  removeExamplannerProcedure,
  updateExamplannerProcedure,
} from "../../../Redux/actions";

const LABEL = window.conf.LANG === "DE" ? "designationde" : "designationpl";

const FILTER_FIELDS = [LABEL];
const PAGE_SIZE = 20;

export const ProceduresListContext = createContext();

function ProcedureRow({ element, handleClick, selectedElements }) {
  const { designationde, designationpl, exam_list, id } = element;
  const dispatch = useDispatch();
  const [detailsShown, setDetailsShown] = useState(false);
  const [editDeleteModalShown, setEditDeleteModalShown] = useState(false);
  const isSelected = selectedElements.findIndex((el) => el.id === id) > -1;
  const procedureName =
    window.conf.LANG === "DE" ? designationde : designationpl;
  const examsq = useExaminations({
    enabled: detailsShown || editDeleteModalShown,
    filter: { field: "id", op: "in", value: exam_list.split(",") },
  });
  const dataForModal = useMemo(() => {
    if (!examsq.data) return null;
    const data = {
      id,
      designationde,
      designationpl,
      exam_list: examsq.data.examinations,
    };
    return data;
  }, [element, examsq.data]);
  const editCallback = (data) => {
    if (!isSelected) return;
    dispatch(
      updateExamplannerProcedure({
        id: id,
        designationde: data.name,
        designationpl: data.name,
        exam_list: data.exams.join(","),
      })
    );
  };

  return (
    <>
      <ProcedureModal
        show={editDeleteModalShown}
        handleClose={() => setEditDeleteModalShown(false)}
        procedureData={dataForModal}
        editProcedureCallback={editCallback}
      />
      <div
        className={`d-flex flex-column border rounded p-2 mx-1 ${
          isSelected ? "bg-warning text-white" : ""
        }`}
        onClick={() => handleClick(element)}
      >
        <div className="d-flex align-items-center w-100">
          <div className="font-weight-bold">{procedureName}</div>
          <Button
            className="ml-auto"
            variant="outline-primary"
            onClick={(e) => {
              e.stopPropagation();
              setEditDeleteModalShown(true);
            }}
          >
            <i className="fas fa-edit" />
          </Button>
          <Button
            variant="outline-primary ml-1"
            onClick={(e) => {
              e.stopPropagation();
              setDetailsShown((prev) => !prev);
            }}
          >
            <i
              className={`fas ${
                detailsShown ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            />
          </Button>
        </div>
        {detailsShown && (
          <div className="d-flex flex-column">
            {examsq.status !== "success" && (
              <div className="d-flex justify-content-center align-items-center">
                <i className="fas fa-spinner fa-spin fa-2x" />
              </div>
            )}
            {examsq.status === "success" &&
              examsq.data?.examinations.map((exam) => (
                <div key={exam.id} className="d-flex">
                  <div className="mr-1">{exam[LABEL]}</div>
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
}

function ProceduresList() {
  const { setExamsOrProcedures } = useContext(ExamPlannerContext);
  const dispatch = useDispatch();
  const selectedElements = useSelector(
    (state) => state.examPlanner.risData.procedures
  );
  const [addProcedureModalShown, setAddProcedureModalShown] = useState(false);
  const [filterString, setFilterString] = useState("");
  const filterStringDebounced = useDebounce(filterString, 500);
  const { filter } = usePmedFilter({
    field: FILTER_FIELDS,
    searchPhrase: filterStringDebounced,
  });
  const procedures = useProceduresInfinite({
    enabled: true,
    pageSize: PAGE_SIZE,
    filter: filter,
  });

  const PreSearchBtns = () => (
    <div className="d-flex">
      <Button onClick={() => setExamsOrProcedures(EXAM_LIST_OPTIONS.EXAMS)}>
        Procedures
      </Button>
      <Button className="ml-1" onClick={() => setAddProcedureModalShown(true)}>
        <i className="fas fa-plus" />
      </Button>
    </div>
  );

  const handleElementSelection = (element) => {
    if (selectedElements.findIndex((el) => el.id === element.id) > -1) {
      dispatch(removeExamplannerProcedure(element));
    } else {
      dispatch(addExamplannerProcedure(element));
    }
  };

  const ListRenderer = (elementList) => {
    return elementList.map((element) => (
      <ProcedureRow
        key={`procedure-expl-procrow-${element.id}`}
        element={element}
        handleClick={handleElementSelection}
        selectedElements={selectedElements}
      />
    ));
  };

  const dataForList = useMemo(() => {
    if (!procedures.data) return [];
    let proceduresList = [];
    procedures.data.pages.forEach((page) => {
      if (page?.procedures) {
        proceduresList = proceduresList.concat(page.procedures);
      }
    });
    return proceduresList;
  }, [procedures.data]);

  return (
    <ProceduresListContext.Provider value={{ reload: procedures.refetch }}>
      <div>
        <ProcedureModal
          show={addProcedureModalShown}
          handleClose={() => setAddProcedureModalShown(false)}
        />
        <LoadMoreList
          isLoading={procedures.isLoading}
          isLoadingNextPage={procedures.isFetchingNextPage}
          hasMoreElements={procedures.hasNextPage}
          loadMore={() => procedures.fetchNextPage()}
          customPreSearchBtn={<PreSearchBtns />}
          elementList={dataForList}
          listRenderer={ListRenderer}
          selectedElements={selectedElements}
          deselectElement={handleElementSelection}
          clearSelectedElements={() => dispatch(clearExamplannerProcedures())}
          customSearchPlaceholder="Search Procedures"
          selectedElementLabelAccessor={LABEL}
          setFilterString={setFilterString}
          filterString={filterString}
        />
      </div>
    </ProceduresListContext.Provider>
  );
}

export default ProceduresList;
