import {
  deleteFetch,
  getFetch,
  MakeUrl,
  postFetch,
  REST_URL,
} from "../../FetchWrapper";

export async function getAnatomy(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/ris/anatomy`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getExaminations(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/ris/examinations`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getProcedures(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/ris/procedures`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getExaminationsInfinite(keycloak, params, pageNr = 1) {
  const url = MakeUrl(
    `${REST_URL}/ris/examinations`,
    params.pageSize,
    pageNr,
    params.filter,
    params.sort
  );
  if (params.indid)
    url.searchParams.append("indid", JSON.stringify(params.indid));
  return getFetch(url, keycloak, params.xmask);
}

export async function getIndicationsInfinite(keycloak, params, pageNr = 1) {
  console.log(params);
  const url = MakeUrl(
    `${REST_URL}/ris/indications`,
    params.pageSize,
    pageNr,
    params.filter,
    params.sort
  );
  if (params.anatomy)
    url.searchParams.append("anatomy", JSON.stringify(params.anatomy));
  return getFetch(url, keycloak, params.xmask);
}

export async function getProceduresInfinite(keycloak, params, pageNr = 1) {
  const url = MakeUrl(
    `${REST_URL}/ris/procedures`,
    params.pageSize,
    pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getExamination(keycloak, params) {
  if (!params.examId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/examinations/${params.examId}`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getExaminationsStats(keycloak, params) {
  if (!params.examId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/examinations/${params.examId}/stats`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getIndications(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/ris/indications`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getMasKvs(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/ris/maskv`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getMasKv(keycloak, params) {
  if (!params.masKvId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/maskv/${params.masKvId}`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getRefPhysStudies(keycloak, params) {
  if (!params.refPhysId) {
    return {};
  }
  const url = MakeUrl(
    `${REST_URL}/ris/refphysicians/${params.refPhysId}/studies`
  );
  return getFetch(url, keycloak, params.xmask);
}

export async function getUserStudies(keycloak, params) {
  if (!params.userId) {
    return {};
  }
  const url = MakeUrl(`${REST_URL}/ris/users/${params.userId}/studies`);
  return getFetch(url, keycloak, params.xmask);
}

export async function getSubstantiations(keycloak, params) {
  const url = MakeUrl(
    `${REST_URL}/ris/substantiations`,
    params.pageSize,
    params.pageNr,
    params.filter,
    params.sort
  );
  return getFetch(url, keycloak, params.xmask);
}

// POST

export async function postProcedure(keycloak, params) {
  const url = MakeUrl(`${REST_URL}/ris/procedures`);
  return postFetch(url, keycloak, params.body);
}

export async function postProcedureById(keycloak, id, params) {
  const url = MakeUrl(`${REST_URL}/ris/procedures/${id}`);
  return postFetch(url, keycloak, params.body);
}

//DELETE
export async function deleteProcedureById(keycloak, id, params) {
  const url = MakeUrl(`${REST_URL}/ris/procedures/${id}`);
  return deleteFetch(url, keycloak);
}
