import "./stylesheets/BodyPartSelector.scss";
import HumanBodyViewController from "./BodyParts/HumanModelViewController";
import ListSelector from "./ListSelector/ListSelector";
import { Button } from "react-bootstrap";
import { useState } from "react";

function BodyPartSelector() {
  const displayMode = window.conf.EXAM_PLANNER.BODY_PART_SELECTION_MODE;

  const views = {
    humanBody: HumanBodyViewController,
    listView: ListSelector,
  };
  const [selectedView, setSelectedView] = useState(undefined);
  const View = selectedView ? views[selectedView] : <></>;

  return (
    <>
      {displayMode === 0 && <HumanBodyViewController />}
      {displayMode === 1 && <ListSelector />}
      {displayMode === 2 &&
        (selectedView === undefined ? (
          <div className="d-flex p-2" style={{ gap: "20px" }}>
            <Button onClick={() => setSelectedView("humanBody")}>
              <i className="fas fa-male fa-sm mr-1" />
              Human Model
            </Button>
            <Button onClick={() => setSelectedView("listView")}>
              <i className="fas fa-list fa-sm mr-1" />
              List View
            </Button>
          </div>
        ) : (
          <View />
        ))}
    </>
  );
}

export default BodyPartSelector;
