import { Modal, Button, Form } from "react-bootstrap";
import { useEffect, useState, useContext } from "react";
import V3ExamList from "../../../Blueprints/V3ExamList/V3ExamList";
import { NotificationManager } from "react-notifications";
import { useKeycloak } from "@react-keycloak/web";

import "./stylesheets/ProceduresModal.scss";
import {
  deleteProcedureById,
  postProcedure,
  postProcedureById,
} from "../../../Utilities/FetchHooks/Helpers/FetchFunctions/ris/RisFetches";
import { ProceduresListContext } from "./ProceduresList";

const LABEL =
  window.conf.LANG === "DE"
    ? "designationde"
    : window.conf.LANG === "PL"
    ? "designationpl"
    : "designationde";

const MIN_PROCEDURE_NAME_LENGTH = 3;

function ProcedureModal({
  show,
  handleClose,
  procedureData,
  editProcedureCallback = null,
}) {
  const { reload } = useContext(ProceduresListContext) || {};
  const { keycloak } = useKeycloak();
  const modalType = procedureData ? "Edit or delete" : "Add";
  const [selectedExams, setSelectedExams] = useState([]);
  const [examListShrinked, setExamListShrinked] = useState(false);
  const [procedureName, setProcedureName] = useState("");

  useEffect(() => {
    if (procedureData) {
      setSelectedExams(procedureData.exam_list);
      setProcedureName(procedureData[LABEL]);
    }
  }, [procedureData]);

  const handleAddProcedure = async () => {
    if (selectedExams.length === 0) {
      NotificationManager.error("Please select exams for the procedure");
      return;
    }
    if (procedureName.length < MIN_PROCEDURE_NAME_LENGTH) {
      NotificationManager.error(
        "Procedure name must be at least 3 characters long"
      );
      return;
    }
    const res = await postProcedure(keycloak, {
      body: {
        name: procedureName.trim(),
        exams: selectedExams.map((exam) => exam.id),
      },
    });
    if (res?.status === "error") {
      NotificationManager.error("Error adding procedure");
    } else {
      NotificationManager.success("Procedure added successfully");
      reload && reload();
      handleClose();
    }
  };

  const handleEditProcedure = async () => {
    if (isEditDisabled()) {
      NotificationManager.error("No changes unable to save");
      return;
    }
    const data = {
      name: procedureName.trim(),
      exams: selectedExams.map((exam) => exam.id),
    };
    const res = await postProcedureById(keycloak, procedureData.id, {
      body: data,
    });
    if (res?.status === "error") {
      NotificationManager.error("Error editing procedure");
    } else {
      NotificationManager.success("Procedure edited successfully");
      reload && reload();
      editProcedureCallback && editProcedureCallback(data);
      handleClose();
    }
  };

  const handleDeleteProcedure = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this procedure?"
    );
    if (!confirmed) {
      NotificationManager.success("Procedure not deleted");
      return;
    }
    const res = await deleteProcedureById(keycloak, procedureData.id);
    if (res?.status === "error") {
      NotificationManager.error("Error deleting procedure");
      return;
    } else {
      NotificationManager.success("Procedure deleted successfully");
      reload && reload();
    }
    handleClose();
  };

  const isEditDisabled = () => {
    const isNameSame = procedureName.trim() === procedureData[LABEL];
    const selectedExamsIds = selectedExams.map((exam) => exam.id).sort();
    const procedureExamsIds = procedureData.exam_list
      .map((exam) => exam.id)
      .sort();
    const isExamsSame =
      selectedExamsIds.length === procedureExamsIds.length &&
      selectedExamsIds.every((id, index) => id === procedureExamsIds[index]);
    return isNameSame && isExamsSame;
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton className="p-1">
        <Modal.Title>{modalType} Procedure</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <V3ExamList
          examListShrinked={examListShrinked}
          setExamListShrinked={(value) => setExamListShrinked(value)}
          selectedExams={selectedExams}
          selectedExamsSetter={(exam) => {
            if (selectedExams.findIndex((ex) => ex.id === exam.id) === -1) {
              setSelectedExams([...selectedExams, exam]);
            } else {
              setSelectedExams(selectedExams.filter((ex) => ex.id !== exam.id));
            }
          }}
        />
        <div className="editSection">
          <h3>Procedure</h3>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              type="text"
              placeholder="Procedure Name"
              value={procedureName}
              onChange={(e) => {
                setProcedureName(e.target.value);
              }}
            />
          </Form>
          <div className="d-flex align-items-center justify-content-between">
            <h4>Selected Examinations</h4>
            <Button
              variant="outline-danger"
              disabled={selectedExams.length < 2}
              className="mr-1"
              onClick={() => setSelectedExams([])}
            >
              <i className="fas fa-trash" />
            </Button>
          </div>
          {selectedExams.map((exam) => (
            <div key={exam.id} className="selectedExamRow">
              <div className="mr-2">{exam[LABEL]}</div>
              <Button
                variant="outline-danger"
                onClick={() => {
                  setSelectedExams(
                    selectedExams.filter((ex) => ex.id !== exam.id)
                  );
                }}
              >
                <i className="fas fa-times" />
              </Button>
            </div>
          ))}
          {!procedureData && (
            <Button
              disabled={
                selectedExams.length < 2 ||
                procedureName.length < MIN_PROCEDURE_NAME_LENGTH
              }
              variant="success"
              onClick={handleAddProcedure}
            >
              <i className="fas fa-plus mr-1" />
              Add Procedure
            </Button>
          )}
          {procedureData && (
            <Button
              disabled={isEditDisabled()}
              variant="success"
              onClick={handleEditProcedure}
            >
              <i className="fas fa-save mr-1" />
              Save Changes
            </Button>
          )}
          {procedureData && (
            <Button variant="danger" onClick={handleDeleteProcedure}>
              <i className="fas fa-trash mr-1" />
              Delete Procedure
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ProcedureModal;
